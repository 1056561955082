import React, { ReactNode } from "react";
import moment from "./moment";
import { DataPoint } from "../types/apiResponses";
import { Select, Input } from "../components/Form";
import ReactDatePicker from "../components/DatePicker";

function renderField(
  dataPoint: DataPoint,
  value: any,
  setField: (name: string, value: any) => any,
  customFieldName?: string,
  nullable?: boolean,
  nullableLabel?: string
): ReactNode {
  switch (dataPoint.type) {
    case "boolean":
      return (
        <Select
          id={dataPoint._id}
          value={
            value && (value === true || value === "true")
              ? "true"
              : value === "false" || value === false
              ? "false"
              : "null"
          }
          defaultValue="null"
          onChange={e =>
            setField(
              customFieldName ? customFieldName : dataPoint._id,
              e.target.value === "null" ? null : e.target.value === "true"
            )
          }
        >
          <option disabled={!nullable} value="null">
            {nullable ? nullableLabel || "Ingen" : "Vennligst velg…"}
          </option>
          <option value="true">Ja</option>
          <option value="false">Nei</option>
        </Select>
      );
    case "date":
      const time = moment(value);

      if (!value || time.isValid()) {
        return (
          <ReactDatePicker
            id={dataPoint._id}
            selected={value ? time.toDate() : undefined}
            onChange={value =>
              setField(customFieldName ? customFieldName : dataPoint._id, value)
            }
            showTimeSelect
            dateFormat="dd.MM.YYY HH:mm"
          />
        );
      }

      return (
        <Input
          id={dataPoint._id}
          type="text"
          placeholder={dataPoint.defaultValue ? dataPoint.defaultValue : null}
          value={value}
          onChange={e =>
            setField(
              customFieldName ? customFieldName : dataPoint._id,
              e.target.value
            )
          }
        />
      );
    case "pick":
    case "pickNumber":
      return (
        <Select
          id={dataPoint._id}
          value={value}
          onChange={e =>
            setField(
              customFieldName ? customFieldName : dataPoint._id,
              e.target.value === ":null:" ? null : e.target.value
            )
          }
        >
          <option selected={!value} disabled={!nullable} value=":null:">
            {nullable ? nullableLabel || "Ingen" : "Vennligst velg"}
          </option>
          {(dataPoint.picks as string[]).map(p => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </Select>
      );
    case "interest":
      return (
        <Input
          id={dataPoint._id}
          type="number"
          placeholder={dataPoint.defaultValue ? dataPoint.defaultValue : null}
          value={value ? value : typeof value === "number" ? 0 : ""}
          onChange={e =>
            setField(
              customFieldName ? customFieldName : dataPoint._id,
              e.target.value
            )
          }
        />
      );
    default:
      return (
        <Input
          id={dataPoint._id}
          type="text"
          placeholder={dataPoint.defaultValue ? dataPoint.defaultValue : null}
          value={value ? value : typeof value === "number" ? 0 : ""}
          onChange={e =>
            setField(
              customFieldName ? customFieldName : dataPoint._id,
              e.target.value
            )
          }
        />
      );
  }
}

export default renderField;
