export enum TriggerType {
  SendNow,
  Scheduled,
  DataPointUpdate,
  DataPointCategoryUpdate
}

export enum SelectionLogic {
  And,
  Or
}

export enum Target {
  DataPoint,
  List,
  Core,
  Handler,
  Action,
  All,
  AdminAction,
  HandlerAction,
  Consent,
  ShipmentInteraction,
  ContactAction
}

export enum TargetLogic {
  Equals,
  NotEquals,
  IsSet,
  IsNotSet,
  GreaterThan,
  LessThan,
  Includes,
  DoesNotInclude
}

export enum MailerPriority {
  Low,
  Medium,
  High
}

export enum VariableType {
  Constant,
  Conditional
}

export enum ThreadStatus {
  Open,
  Closed,
  Resolved,
  WaitingResponse
}

export enum ThreadStatusFilter {
  Open,
  Closed,
  Resolved,
  WaitingResponse,
  All = 'all',
  AllOpen = 'allOpen'
}

export enum Permissions {
  None = 'none',
  Reader = 'reader',
  Handler = 'handler',
  HandlerPlus = 'handlerPlus',
  Writer = 'writer',
  Admin = 'admin',
  ApiReader = 'apiReader',
  ApiWriter = 'apiWriter',
  ApiAdmin = 'apiAdmin'
}

export enum DurationTypes {
  Hours = 'hours',
  Days = 'days'
}

export enum LaneItemType {
  Contact
}

export enum Services {
  Google = 'google',
  Facebook = 'facebook',
  Twitter = 'twitter'
}

export enum RobotTriggerType {
  DataPointSet = 'dataPointSet',
  Date = 'date',
  AggregatorScore = 'aggregatorScore',
  SourceSet = 'sourceSet',
  Collection = 'collection',
  Receiver = 'receiver',
  HandlerAdd = 'handlerAdd',
  HandlerRemove = 'handlerRemove',
  HandlerAction = 'handlerAction',
  AdminAction = 'adminAction',
  List = 'list',
  Action = 'action',
  ThreadCreate = 'threadCreate'
}

export enum RobotActionType {
  Debug = 'debug',
  Delay = 'delay',
  SetDataPoint = 'setDataPoint',
  SendEmail = 'sendEmail',
  MergeData = 'mergeData',
  TogglePersonalization = 'togglePersonalization',
  CreateNote = 'createNote',
  SendInternalMail = 'sendInternalMail',
  ToggleList = 'toggleList',
  ToggleHandler = 'toggleHandler',
  Delete = 'delete',
  Webhook = 'webhook'
}

export enum RobotSplitType {
  None = 'none',
  Condition = 'condition',
  Random = 'random',
  Callback = 'callback'
}

export enum CallbackType {
  ReadEmail = 'readMail',
  SetDataPoint = 'setDataPoint',
  Collection = 'collection'
}

export enum RobotTriggerTimingType {
  Finite = 'finite',
  Infinite = 'infinite',
  Every = 'every'
}

export enum StatType {
  ContactCreation = 'contactCreation',
  SourceRegistration = 'sourceRegistration',
  ShipmentSent = 'shipmentSent',
  ShipmentRead = 'shipmentRead',
  ShipmentEngagement = 'shipmentEngagement',
  ShipmentDeactivation = 'shipmentDeactivation',
  Collection = 'collection',
  SetDataPointValue = 'setDataPointValue',
  PageView = 'pageView',
  Session = 'session',
  Action = 'action',
  Message = 'message'
}

export enum ReceiverType {
  CreateContact = 'createContact',
  UpdateContact = 'updateContact',
  PushAction = 'pushAction'
}

export enum ReceiverInstructionType {
  Value = 'value',
  Path = 'path'
}

export enum ReceiverSetType {
  Set = 'set',
  Increment = 'increment',
  Decrement = 'decrement'
}

export enum AppDataSetType {
  Singleton = 'singleton',
  List = 'list'
}

export enum AppDataSetValueType {
  None = 'none',
  String = 'string',
  Boolean = 'boolean',
  Number = 'number'
}

export enum ActionSource {
  Self = 'self',
  Receiver = 'receiver',
  User = 'user',
  Probot = 'probot'
}

export enum DefaultConsents {
  Basic = 'basic',
  Email = 'email',
  Phone = 'phone',
  Actions = 'actions'
}

export enum MessageType {
  Email = 'email',
  SMS = 'sms'
}

export enum SMSProviders {
  Twilio = 'twilio'
}
