import React, { useContext } from 'react';
import Expander from '../../../components/Expander';
import { Grid } from '@material-ui/core';
import {
  CardExternalLink,
  CardContent,
  CardIcon
} from '../../../components/Card';
import { CreateParameterModal, renderProfileSelectionRule } from '../Show';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import { UiContext } from '../../../context/Ui';
import { PathParameter, Organization } from '../../../types/apiResponses';

interface ParameterExpanderProps {
  organization: Organization;
  parameters: PathParameter[];
  expanded?: boolean;
  onSet: (parameters: PathParameter[]) => any;
}

function ParameterExpander(props: ParameterExpanderProps) {
  const { organization, parameters, expanded, onSet } = props;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  return (
    <Expander label={`Filter (${parameters.length})`} open={expanded}>
      <Grid container spacing={24}>
        {parameters.map(p => {
          const list = organization.lists.find(l => l._id === p.targetRef);

          const dataPoint = organization.dataPoints.find(
            d => d._id === p.targetRef
          );

          return (
            <Grid key={p._id} item xs={12}>
              <CardExternalLink
                href="#"
                clickable
                horizontal
                onClick={e => {
                  e.preventDefault();
                  spawnModal(
                    <CreateParameterModal
                      defaultData={p}
                      organization={organization}
                      onCreate={parameter => {
                        const index = parameters.findIndex(pa =>
                          parameter.__ref
                            ? pa.__ref === parameter.__ref
                            : pa._id === parameter._id
                        );

                        if (index !== -1) {
                          const copy = [...parameters];
                          copy[index] = parameter;

                          onSet(copy);
                        }
                      }}
                      onDelete={ref => {
                        onSet(
                          parameters.filter(pa =>
                            pa.__ref ? pa.__ref !== ref : pa._id !== ref
                          )
                        );
                      }}
                      close={despawnModal}
                    />,
                    'createParameter'
                  );
                }}
              >
                <CardContent tight>
                  {renderProfileSelectionRule(p, list, dataPoint)}
                </CardContent>
                <CardIcon tight>
                  <Icon>edit</Icon>
                </CardIcon>
              </CardExternalLink>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <CardExternalLink
            href="#"
            clickable
            horizontal
            primary={parameters.length < 1}
            secondary={parameters.length > 0}
            onClick={e => {
              e.preventDefault();
              spawnModal(
                <CreateParameterModal
                  organization={organization}
                  onCreate={parameter => {
                    onSet([...parameters, parameter]);
                  }}
                  onDelete={ref => {
                    onSet(parameters.filter(p => p._id !== ref));
                  }}
                  close={despawnModal}
                />,
                'createParameter'
              );
            }}
          >
            <CardContent tight>
              <Text>Legg til regel</Text>
            </CardContent>
            <CardIcon tight>
              <Icon>add</Icon>
            </CardIcon>
          </CardExternalLink>
        </Grid>
      </Grid>
    </Expander>
  );
}

export default ParameterExpander;
