import React from 'react';
import { Services } from '../../constants/enums';
import Text, { TextElement } from '../Text';

interface Props {
  service: Services | string;
  firstParty?: boolean;
  showParty?: boolean;
  element?: TextElement;
}

function Service(props: Props) {
  return (
    <Text element={props.element}>
      {props.service} {props.showParty && !props.firstParty && <sup>3p</sup>}
    </Text>
  );
}

export default Service;
