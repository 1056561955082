import * as qs from 'qs';
import Cookies from 'js-cookie';
import storage from './storage';
import logout from './logout';

export interface Headers {
  [key: string]: string;
}

export interface RequestData {
  [key: string]: any;
}

export type service = 'plz' | 'sso';

export interface ApiOpts {
  endpoint: string;
  method?: string;
  queryParams?: RequestData;
  body?: RequestData;
  service?: service;
  noJSON?: boolean;
  multipart?: boolean;
  urlEncoded?: boolean;
  refreshingToken?: boolean;
  noCredentials?: boolean;
}

async function api<T>(opts: ApiOpts): Promise<T | Response> {
  const selectedGroup = storage.get('group');
  const method = opts.method || 'GET';

  if (selectedGroup) {
    opts.queryParams = opts.queryParams
      ? { ...opts.queryParams, group: selectedGroup }
      : { group: selectedGroup };
  }

  const queryParams = opts.queryParams
    ? `?${qs.stringify(opts.queryParams)}`
    : '';

  const url = getServiceURL(opts.service) + '/' + opts.endpoint + queryParams;

  const headers: Headers = {
    ['x-csrf-token']: Cookies.get('cid') || ''
  };

  let body: string | FormData | undefined = undefined;

  if (method !== 'GET') {
    if (!opts.multipart) {
      headers['Content-Type'] = 'application/json';
    }

    if (opts.urlEncoded) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    if (opts.body) {
      if (selectedGroup) {
        opts.body['group'] = selectedGroup;
      }

      body = opts.multipart
        ? createForm(opts.body)
        : opts.urlEncoded
        ? createEncodedForm(opts.body)
        : JSON.stringify(opts.body);
    }
  }

  const response = await fetch(url, {
    method,
    headers,
    mode: 'cors',
    credentials: opts.noCredentials ? 'omit' : 'include',
    body
  });

  if (
    (response.status === 401 ||
      response.status === 440 ||
      response.status === 403) &&
    opts.endpoint !== 'users/sso/logout'
  ) {
    storage.set('loginRedirect', window.location.pathname);

    logout();
    return response;
  } else if (response.status === 403) {
    console.error('Forbidden');
    return response;
  } else if (response.status === 404) {
    throw new Error('pageNotFound');
  } else if (response.status === 500) {
    throw new Error('internalServerError');
  } else if (response.status === 205) {
    window.location.reload();

    return response;
  }

  if (opts.noJSON) {
    return response;
  }

  return (await response.json()) as T;
}

function getServiceURL(service?: service): string {
  switch (service) {
    case 'sso':
      return process.env.REACT_APP_SSO_URL || '';
    default:
      return process.env.REACT_APP_PLZ_URL || '';
  }
}

function createForm(data: RequestData): FormData {
  const formData = new FormData();

  for (const k in data) {
    if (Array.isArray(data[k]) && typeof data[k] !== 'string') {
      data[k].map((file: File) => formData.append(k, file));
    } else {
      formData.append(k, data[k]);
    }
  }

  return formData;
}

function createEncodedForm(body: RequestData): string {
  return Object.keys(body)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
    .join('&');
}

export default api;
