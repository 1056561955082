import React, { FunctionComponent, ReactNode, MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from '../../util/styled';
import getWindowWidth from '../../util/getWindowWidth';
import { mobileThreshold } from '../../constants/theme';

const windowWidth = getWindowWidth();

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.layout.spacing.normal} 0;
`;

const MenuHeading = styled.p`
  padding: ${props =>
      `${props.theme.layout.spacing.normal} ${
        props.theme.layout.spacing.normal
      }`}
    0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.6;
`;

interface ItemProps {
  primary?: boolean;
  warning?: boolean;
  disabled?: boolean;
}

const ItemWrapper = styled(({ primary, warning, disabled, ...props }) => (
  <NavLink {...props} />
))<ItemProps>`
  display: flex;
  align-items: center;
  margin: 0;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.textSecondary};

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.layout.spacing.normal};
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  ${props =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.text};
        text-decoration: none;
      }

      &:active {
        font-weight: 700;
        text-decoration: none;
      }
    `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};

  &.active {
    color: ${props => props.theme.colors.text};
    font-weight: 700;
  }

  ${props =>
    props.primary &&
    css`
      color: ${props => props.theme.colors.primary};
    `};

  ${props =>
    props.warning &&
    css`
      color: ${props => props.theme.colors.warning};
    `};
`;

const ItemWrapperButton = ItemWrapper.withComponent('a');

const IconPlacer = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-left: ${props => props.theme.layout.spacing.normal};
`;

const MenuSplitter = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

interface Props extends ItemProps {
  to: string;
  exact?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler;
}

const MenuItem: FunctionComponent<Props> = ({
  to,
  exact,
  primary,
  warning,
  icon,
  onClick,
  children
}) => (
  <ItemWrapper
    to={to}
    exact={exact}
    primary={primary}
    warning={warning}
    onClick={onClick}
  >
    <IconPlacer>{icon}</IconPlacer>
    {windowWidth > mobileThreshold && <Text>{children}</Text>}
  </ItemWrapper>
);

interface MenuItemButtonProps extends ItemProps {
  icon?: ReactNode;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  href?: string;
}

const MenuItemButton: FunctionComponent<MenuItemButtonProps> = ({
  primary,
  warning,
  icon,
  onClick,
  disabled,
  href,
  children
}) => (
  <ItemWrapperButton
    primary={primary}
    warning={warning}
    onClick={onClick}
    disabled={disabled}
    href={href}
  >
    <IconPlacer>{icon}</IconPlacer>
    {windowWidth > mobileThreshold && <Text>{children}</Text>}
  </ItemWrapperButton>
);

export default Menu;
export { MenuHeading, MenuItem, MenuItemButton, MenuSplitter };
