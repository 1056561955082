import React, { FunctionComponent, ReactNode } from 'react';
import styled from '../../util/styled';
import { Byline } from '../Chat';
import Icon from '../Icon';

const Timeline = styled.ul`
  list-style: none;
  margin: 0 0 0 ${props => props.theme.layout.spacing.small};
  padding: 0;
  border-left: 1px solid ${props => props.theme.colors.border};
`;

const TimelineItemWrapper = styled.li`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.layout.spacing.normal};
  }
`;

const TimelineContentWrapper = styled.div``;

const Box = styled.div`
  background-color: ${props => props.theme.colors.contentBackground};
  padding: ${props =>
    `${props.theme.layout.spacing.small} ${props.theme.layout.spacing.normal}`};
  box-shadow: ${props => props.theme.shadows.normal};
  border-radius: 32px;
  margin-left: 8px;
  width: 100%;
`;

const Dot = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.background};
  margin-left: -16px;
  margin-top: ${props => props.theme.layout.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
  }
`;

const BylineWrapper = styled.div`
  margin-left: 8px;
`;

interface TimelineItemProps {
  icon: string;
  byline?: ReactNode;
}

const TimelineItem: FunctionComponent<TimelineItemProps> = ({
  icon,
  byline,
  children
}) => {
  return (
    <TimelineItemWrapper>
      <Dot>
        <Icon>{icon}</Icon>
      </Dot>
      <TimelineContentWrapper>
        <Box>{children}</Box>
        {byline && (
          <BylineWrapper>
            <Byline>{byline}</Byline>
          </BylineWrapper>
        )}
      </TimelineContentWrapper>
    </TimelineItemWrapper>
  );
};

export { Timeline, TimelineItem };
