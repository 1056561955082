import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Receivers/List';
import Create from '../views/Receivers/Create';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/webhook-mottakere"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/webhook-mottakere/:receiverID"
        component={Create}
      />
    </Switch>
  );
}
