import React, { useContext, useState, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';
import uuid from 'uuid/v4';
import Grid from '@material-ui/core/Grid';
import {
  Organization,
  Path,
  PathParameter,
  DataPoint,
  Contact,
  List,
  EmailTemplate,
  Collector,
  Robot,
  MergedPath,
  RobotPath,
  RobotAction,
  Automation
} from '../../types/apiResponses';
import {
  SelectionLogic,
  Target,
  TargetLogic,
  RobotSplitType
} from '../../constants/enums';
import history from '../../util/history';
import translateValue from '../../util/translateValue';
import useApi, { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container, Spacer, Flex, FlexKid } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Select, Input } from '../../components/Form';
import EmptyState from '../../components/EmptyState';
import Card, {
  CardContent,
  CardIcon,
  CardExternalLink,
  CardMedia
} from '../../components/Card';
import Button, {
  ButtonExternalLink,
  ButtonList
} from '../../components/Button';
import Icon from '../../components/Icon';
import { UiContext } from '../../context/Ui';
import { OrganizationContext } from '../../context/Organization';
import localize from '../../util/localize';
import {
  selectionLogic,
  targetLogic,
  coreData,
  defaultConsents,
  action
} from '../../constants/localization';
import renderField from '../../util/renderField';
import getContactName from '../../util/getContactName';
import Table, {
  TableScrollWrapper,
  TableHead,
  TableBody,
  Tr,
  Td,
  Th
} from '../../components/Table';
import Hr from '../../components/Hr';
import Canvas, {
  Node,
  Action,
  NodeConnectorDir,
  Branches,
  Branch,
  BranchPos,
  BranchLabel
} from '../../components/Canvas';
import { getLabel, getSplit, getColor, getTrigger } from '../../util/robot';
import TriggerModal from './modals/Trigger';
import ActionTypeModal from './modals/ActionType';
import ActionModal from './modals/Action';
import SplitModal from './modals/Split';
import { AppsContext } from '../../context/Apps';
import labelDataPoints, { labelDataPoint } from '../../util/labelDataPoints';
import { mergeConsents } from '../../util/mergeConsents';

function renderPaths(
  paths: MergedPath[],
  organization: Organization,
  editable: boolean,
  connector?: boolean,
  onDrop?: (e: DropResult) => any,
  onActionTypeClick?: (path: RobotPath) => any,
  onActionClick?: (path: RobotPath, action: RobotAction) => any,
  onSplitClick?: (path: RobotPath) => any
): ReactNode {
  return paths.map(p => {
    return (
      <>
        <Node
          connectorDir={
            p.parent
              ? NodeConnectorDir.Branching
              : connector
              ? NodeConnectorDir.Top
              : undefined
          }
          actionable={editable}
          actionableAction={() =>
            editable &&
            typeof onActionTypeClick === 'function' &&
            onActionTypeClick(p)
          }
          bottomDot={true}
          hits={p.parent ? p.hits : undefined}
        >
          <DragDropContext
            onDragEnd={e => typeof onDrop === 'function' && onDrop(e)}
          >
            <Droppable droppableId={p._id} isDropDisabled={!editable}>
              {d => (
                <div ref={d.innerRef}>
                  {p.actions.map((a, k) => (
                    <Draggable
                      isDragDisabled={!editable}
                      key={a._id}
                      draggableId={a._id}
                      index={k}
                    >
                      {dr => (
                        <div
                          ref={dr.innerRef}
                          {...dr.draggableProps}
                          {...dr.dragHandleProps}
                        >
                          <Action
                            href={editable ? '#' : undefined}
                            clickable={editable}
                            onClick={e => {
                              e.preventDefault();

                              if (
                                editable &&
                                typeof onActionClick === 'function'
                              ) {
                                onActionClick(p, a);
                              }
                            }}
                          >
                            {getLabel(a)}
                          </Action>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {d.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Node>
        {p.split.__type !== RobotSplitType.None && (
          <>
            <Node connectorDir={NodeConnectorDir.Top}>
              <Action
                href="#"
                clickable={editable}
                secondary
                opaque
                onClick={e => {
                  e.preventDefault();

                  if (editable && typeof onSplitClick === 'function') {
                    onSplitClick(p);
                  }
                }}
              >
                {getSplit(p.split, organization)}
              </Action>
            </Node>
            <Branches>
              {p.split.paths.map((s, k) => {
                const mergedPaths = p.mergedPaths.filter(
                  m => m.parent === s._id
                );

                return (
                  <Branch
                    key={s._id}
                    child
                    label={
                      <BranchLabel color={getColor(p.split, k)}>
                        {s.label}
                      </BranchLabel>
                    }
                    pos={
                      k === 0
                        ? BranchPos.Start
                        : k === p.split.paths.length - 1
                        ? BranchPos.End
                        : undefined
                    }
                  >
                    {mergedPaths.length > 0 ? (
                      renderPaths(
                        mergedPaths,
                        organization,
                        editable,
                        connector,
                        onDrop,
                        onActionTypeClick,
                        onActionClick,
                        onSplitClick
                      )
                    ) : (
                      <Node />
                    )}
                  </Branch>
                );
              })}
            </Branches>
          </>
        )}
      </>
    );
  });
}

function handleMergePathDnD(path: MergedPath, e: DropResult): MergedPath {
  if (!e.destination) {
    return path;
  }

  if (path._id === e.destination.droppableId) {
    const [removed] = path.actions.splice(e.source.index, 1);
    path.actions.splice(e.destination.index, 0, removed);

    return path;
  } else if (path.mergedPaths) {
    return {
      ...path,
      mergedPaths: path.mergedPaths.map(p => handleMergePathDnD(p, e))
    };
  }

  return path;
}

interface ShowParams {
  organizationID: string;
  robotID: string;
}

function Show(props: RouteComponentProps<ShowParams>) {
  const organizationID = props.match.params.organizationID;
  const robotID = props.match.params.robotID;
  const isUpdate = robotID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/robots';
  const endpoint = isUpdate ? baseEndpoint + '/' + robotID : baseEndpoint;

  const organization = useContext(OrganizationContext).data;
  const apps = useContext(AppsContext).data;
  const uiContext = useContext(UiContext);

  const [robotData, loading, fetch] = useApi<Robot | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true,
    onSuccess: robot => setRobot(robot as Robot)
  });

  const [patchData, patching, patch] = useApi<null>({
    endpoint,
    method: 'PATCH',
    initialData: null,
    onSuccess: () => fetch({ endpoint })
  });

  const [deletaData, deleting, destroy] = useApi<null>({
    endpoint,
    method: 'DELETE',
    initialData: null,
    onSuccess: () => history.replace('../proboter')
  });

  const [robot, setRobot] = useState(null as Robot | null);

  const [path, cruddingPath, crudPath] = useApi<RobotPath | null>({
    endpoint,
    initialData: null,
    onSuccess: () => fetch({ endpoint })
  });

  function onDrop(e: DropResult) {
    if (robot && e.destination) {
      const pathToCrud = robot.paths.find(
        p => p._id === e.destination!.droppableId
      );

      if (pathToCrud) {
        const [removed] = pathToCrud.actions.splice(e.source.index, 1);
        pathToCrud.actions.splice(e.destination.index, 0, removed);

        crudPath({
          method: 'PATCH',
          endpoint: endpoint + '/paths/' + pathToCrud._id,
          body: {
            actions: pathToCrud.actions
          }
        });
      }

      setRobot({
        ...robot,
        mergedPaths: robot.mergedPaths.map(p => handleMergePathDnD(p, e))
      });
    }
  }

  if (!robot) {
    return (
      <Container spacious>
        <BusyBoy busy={loading}>{null}</BusyBoy>
      </Container>
    );
  }

  return (
    <BusyBoy busy={loading || patching || deleting} exposeChildren>
      <Container spacious hugTop hugBottom>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="title">
              {robot.name}
            </Text>
          </FlexKid>
          <Flex>
            <ButtonList>
              <ButtonExternalLink
                href="#"
                variant="warning"
                onClick={e => {
                  e.preventDefault();
                  destroy({
                    endpoint,
                    method: 'DELETE',
                    askBeforeFetch: 'Er du sikker på at du vil slette?'
                  });
                }}
              >
                <Icon>delete_outline</Icon> Slett
              </ButtonExternalLink>
              {robot.active ? (
                <ButtonExternalLink
                  key="deactivate"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    patch({
                      endpoint,
                      method: 'PATCH',
                      body: {
                        active: false
                      }
                    });
                  }}
                >
                  <Icon>pause</Icon> Sett på pause
                </ButtonExternalLink>
              ) : (
                <ButtonExternalLink
                  key="activate"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    patch({
                      endpoint,
                      method: 'PATCH',
                      body: {
                        active: true
                      }
                    });
                  }}
                >
                  <Icon>play_arrow</Icon> Aktiver
                </ButtonExternalLink>
              )}
            </ButtonList>
          </Flex>
        </Flex>
      </Container>
      <Hr />
      <Spacer />
      <Canvas>
        <Branch>
          <Node entryPoint hits={robot.hits} hitsBottom>
            <Action
              primary
              opaque
              clickable={!robot.active}
              onClick={e => {
                e.preventDefault();
                !robot.active &&
                  uiContext.modal.spawnModal(
                    <TriggerModal
                      organization={organization}
                      robot={robot}
                      close={id => {
                        fetch({ endpoint });
                        uiContext.modal.despawnModal(id);
                      }}
                    />,
                    'trigger'
                  );
              }}
            >
              {getTrigger(robot.trigger, organization, apps)}
            </Action>
          </Node>
          {renderPaths(
            robot.mergedPaths,
            organization,
            !robot.active,
            true,
            onDrop,
            path => {
              uiContext.modal.spawnModal(
                <ActionTypeModal
                  organization={organization}
                  robot={robot}
                  path={path}
                  close={id => {
                    fetch({ endpoint });
                    uiContext.modal.despawnModal(id);
                  }}
                />,
                'actionType'
              );
            },
            (path, action) => {
              uiContext.modal.spawnModal(
                <ActionModal
                  organization={organization}
                  robot={robot}
                  path={path}
                  actionID={action._id}
                  close={id => {
                    fetch({ endpoint });
                    uiContext.modal.despawnModal(id);
                  }}
                />,
                'actionType'
              );
            },
            path => {
              uiContext.modal.spawnModal(
                <SplitModal
                  organization={organization}
                  robot={robot}
                  path={path}
                  close={id => {
                    fetch({ endpoint });
                    uiContext.modal.despawnModal(id);
                  }}
                />,
                'split'
              );
            }
          )}
        </Branch>
      </Canvas>
    </BusyBoy>
  );
}

interface CreateParameterModalProps {
  organization: Organization;
  close: (id: string) => any;
  onCreate: (data: PathParameter) => any;
  onDelete?: (ref: string) => any;
  defaultData?: PathParameter;
}

const defaultParameter: PathParameter = {
  _id: '',
  selectionLogic: SelectionLogic.And,
  target: Target.DataPoint,
  targetLogic: TargetLogic.Equals,
  targetRef: null,
  value: null
};

export function CreateParameterModal(props: CreateParameterModalProps) {
  const { organization, defaultData, close, onCreate, onDelete } = props;

  const [parameter, setParameter] = useState(
    defaultData ? defaultData : { ...defaultParameter, __ref: uuid() }
  );

  const { members } = useContext(OrganizationContext);
  const apps = useContext(AppsContext).data;

  const dataPointSet: DataPoint | undefined =
    parameter.target === Target.DataPoint
      ? organization.dataPoints.find(d => d._id === parameter.targetRef)
      : undefined;

  const consents = mergeConsents(defaultConsents, organization.consents);

  const [shipments] = useApi<Automation[]>({
    endpoint: 'organizations/' + organization._id + '/automations/search',
    initialData: [],
    fetchOnMount: true
  });

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete parameter._id;
          }

          onCreate(parameter);
          close('createParameter');
        }}
      >
        <Label htmlFor="selectionLogic">Valglogikk *</Label>
        <Select
          id="selectionLogic"
          required
          value={parameter.selectionLogic}
          onChange={e =>
            setParameter({
              ...parameter,
              selectionLogic: parseInt(e.target.value)
            })
          }
        >
          <option value={0}>{localize(selectionLogic, '0')}</option>
          <option value={1}>{localize(selectionLogic, '1')}</option>
        </Select>
        <Label htmlFor="target">Mål *</Label>
        <Select
          id="target"
          required
          value={parameter.target}
          onChange={e =>
            setParameter({
              ...parameter,
              target: parseInt(e.target.value),
              targetRef: null
            })
          }
        >
          <option value={0}>Karakteristikk</option>
          <option value={1}>Liste</option>
          <option value={2}>Kjerneinformasjon (navn, e-post, etc)</option>
          <option value={3}>Saksbehandler</option>
          <option value={4}>Handling</option>
          <option value={6}>Administratorhandling</option>
          <option value={7}>Saksbehandlerhandling</option>
          <option value={10}>Profilhandling</option>
          <option value={8}>Samtykke</option>
          <option value={9}>Utsendelsesinteraksjon</option>
          <option value={5}>Alle</option>
        </Select>
        {(parameter.target === Target.AdminAction ||
          parameter.target === Target.HandlerAction ||
          parameter.target === Target.ContactAction) && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic !== 0 ? parameter.targetLogic : ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              <option value={4}>ER ELDRE ENN</option>
              <option value={5}>ER NYERE ENN</option>
            </Select>
            <Label htmlFor="targetRef">Verdi *</Label>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Input
                  id="targetRef"
                  type="number"
                  required
                  value={parameter.targetRef || undefined}
                  onChange={e =>
                    setParameter({
                      ...parameter,
                      targetRef: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={9}>
                <Select
                  value={parameter.value || ''}
                  required
                  onChange={e =>
                    setParameter({
                      ...parameter,
                      value: e.target.value
                    })
                  }
                >
                  <option value="" disabled>
                    Vennligst velg…
                  </option>
                  <option value="hour">time(r)</option>
                  <option value="day">dag(er)</option>
                  <option value="week">uke(r)</option>
                  <option value="month">måned(er)</option>
                </Select>
              </Grid>
            </Grid>
          </>
        )}
        {parameter.target === Target.Consent && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value={0}>HAR SAMTYKKE</option>
              <option value={1}>HAR IKKE SAMTYKKE</option>
            </Select>
          </>
        )}
        {parameter.target === Target.Handler && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value={0}>ER LIK</option>
              <option value={1}>ER IKKE LIK</option>
              <option value={2}>ER SATT</option>
              <option value={3}>ER IKKE SATT</option>
            </Select>
          </>
        )}
        {parameter.target === Target.ShipmentInteraction && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value={0}>HAR ÅPNET</option>
              <option value={1}>HAR IKKE ÅPNET</option>
            </Select>
          </>
        )}
        {parameter.target === Target.List && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value={0}>FINNES I</option>
              <option value={1}>FINNES IKKE I</option>
            </Select>
          </>
        )}
        {(parameter.target === Target.DataPoint ||
          parameter.target === Target.List) && (
          <>
            <Label htmlFor="targetRef">
              {parameter.target === Target.DataPoint
                ? 'Karakteristikk'
                : 'Liste'}{' '}
              *
            </Label>
            <Select
              id="targetRef"
              required
              value={parameter.targetRef || ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetRef: e.target.value
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              {parameter.target === Target.DataPoint
                ? labelDataPoints(organization.dataPoints, apps).map(d => (
                    <option key={d._id} value={d._id}>
                      {d.label}
                    </option>
                  ))
                : organization.lists.map(l => (
                    <option key={l._id} value={l._id}>
                      {l.label}
                    </option>
                  ))}
            </Select>
          </>
        )}
        {parameter.target === Target.DataPoint && dataPointSet !== undefined && (
          <>
            <Label htmlFor="targetLogic">Regel *</Label>
            <Select
              id="targetLogic"
              required
              value={parameter.targetLogic}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetLogic: parseInt(e.target.value)
                })
              }
            >
              <option value={0}>{localize(targetLogic, '0')}</option>
              <option value={1}>{localize(targetLogic, '1')}</option>
              <option value={2}>{localize(targetLogic, '2')}</option>
              <option value={3}>{localize(targetLogic, '3')}</option>
              {(dataPointSet.type === 'number' ||
                dataPointSet.type === 'interest' ||
                dataPointSet.type === 'pickNumber') && (
                <>
                  <option value={4}>{localize(targetLogic, '4')}</option>
                  <option value={5}>{localize(targetLogic, '5')}</option>
                </>
              )}
            </Select>
          </>
        )}
        {parameter.target === Target.DataPoint &&
          parameter.targetLogic !== TargetLogic.IsSet &&
          parameter.targetLogic !== TargetLogic.IsNotSet &&
          dataPointSet !== undefined && (
            <>
              <Label htmlFor={dataPointSet._id}>Verdi *</Label>
              {renderField(dataPointSet, parameter.value, (_, value) => {
                setParameter({
                  ...parameter,
                  value
                });
              })}
            </>
          )}
        {parameter.target === Target.Handler &&
          parameter.targetLogic !== TargetLogic.IsSet &&
          parameter.targetLogic !== TargetLogic.IsNotSet && (
            <>
              <Label htmlFor="targetRef">Bruker *</Label>
              <Select
                id="targetRef"
                required
                value={parameter.targetRef || ''}
                onChange={e =>
                  setParameter({
                    ...parameter,
                    targetRef: e.target.value
                  })
                }
              >
                <option value="" disabled>
                  Vennligst velg…
                </option>
                {members.map(m => (
                  <option key={m._id} value={m.user}>
                    {m.userData!.name}
                  </option>
                ))}
              </Select>
            </>
          )}
        {parameter.target === Target.Consent && (
          <>
            <Label htmlFor="targetRef">Samtykke *</Label>
            <Select
              id="targetRef"
              required
              value={parameter.targetRef || ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetRef: e.target.value
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              {Object.keys(consents).map(c => (
                <option key={c} value={c}>
                  {consents[c]}
                </option>
              ))}
            </Select>
          </>
        )}
        {parameter.target === Target.ShipmentInteraction && (
          <>
            <Label htmlFor="targetRef">Utsendelse *</Label>
            <Select
              id="targetRef"
              required
              value={parameter.targetRef || ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetRef: e.target.value
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              {shipments.map(s => (
                <option key={s._id} value={s._id}>
                  {s.name}
                </option>
              ))}
            </Select>
          </>
        )}
        {parameter.target === Target.Core && (
          <>
            <Label htmlFor="targetRef">Informasjonstype *</Label>
            <Select
              id="targetRef"
              required
              value={parameter.targetRef || ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetRef: e.target.value
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              {Object.keys(coreData).map(k => (
                <option key={k} value={k}>
                  {coreData[k].charAt(0).toUpperCase() + coreData[k].slice(1)}
                </option>
              ))}
            </Select>
            {parameter.targetRef && (
              <>
                <Label htmlFor="targetLogic">Regel *</Label>
                <Select
                  id="targetLogic"
                  required
                  value={parameter.targetLogic}
                  onChange={e =>
                    setParameter({
                      ...parameter,
                      targetLogic: parseInt(e.target.value)
                    })
                  }
                >
                  <option value={0}>{localize(targetLogic, '0')}</option>
                  <option value={1}>{localize(targetLogic, '1')}</option>
                  {parameter.targetRef !== 'randomName' &&
                    parameter.targetRef !== 'aggregatorScore' && (
                      <>
                        <option value={2}>{localize(targetLogic, '2')}</option>
                        <option value={3}>{localize(targetLogic, '3')}</option>
                      </>
                    )}
                  {parameter.targetRef === 'aggregatorScore' && (
                    <>
                      <option value={4}>{localize(targetLogic, '4')}</option>
                      <option value={5}>{localize(targetLogic, '5')}</option>
                    </>
                  )}
                </Select>
                {parameter.targetRef === 'randomName' && (
                  <>
                    <Label htmlFor="value">Verdi *</Label>
                    <Select
                      id="value"
                      required
                      value={
                        parameter.value === true
                          ? 'true'
                          : parameter.value === false
                          ? 'false'
                          : 'none'
                      }
                      defaultValue="none"
                      onChange={e =>
                        setParameter({
                          ...parameter,
                          value: e.target.value === 'true'
                        })
                      }
                    >
                      <option value="none" disabled>
                        Vennligst velg…
                      </option>
                      <option value="true">Ja</option>
                      <option value="false">Nei</option>
                    </Select>
                  </>
                )}
                {parameter.targetRef !== 'randomName' &&
                  parameter.targetLogic !== TargetLogic.IsSet &&
                  parameter.targetLogic !== TargetLogic.IsNotSet && (
                    <>
                      <Label htmlFor="value">Verdi *</Label>
                      <Input
                        id="value"
                        type={
                          parameter.targetRef === 'aggregatorScore'
                            ? 'number'
                            : 'text'
                        }
                        required
                        value={parameter.value}
                        onChange={e =>
                          setParameter({
                            ...parameter,
                            value:
                              parameter.targetRef === 'aggregatorScore'
                                ? parseFloat(e.target.value)
                                : e.target.value
                          })
                        }
                      />
                    </>
                  )}
              </>
            )}
          </>
        )}
        {parameter.target === Target.Action && (
          <>
            <Label htmlFor="targetRef">Handlingsfelt *</Label>
            <Select
              id="targetRef"
              required
              value={parameter.targetRef || ''}
              onChange={e =>
                setParameter({
                  ...parameter,
                  targetRef: e.target.value
                })
              }
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              <option value="category">Kategori</option>
              <option value="action">Handling</option>
              <option value="label">Etikett</option>
              <option value="value">Verdi</option>
            </Select>
            {parameter.targetRef && (
              <>
                <Label htmlFor="targetLogic">Regel *</Label>
                <Select
                  id="targetLogic"
                  required
                  value={parameter.targetLogic}
                  onChange={e =>
                    setParameter({
                      ...parameter,
                      targetLogic: parseInt(e.target.value)
                    })
                  }
                >
                  <option value={0}>{localize(targetLogic, '0')}</option>
                  <option value={1}>{localize(targetLogic, '1')}</option>
                  {parameter.targetRef === 'value' ? (
                    <>
                      <option value={4}>{localize(targetLogic, '4')}</option>
                      <option value={5}>{localize(targetLogic, '5')}</option>
                    </>
                  ) : (
                    <>
                      <option value={6}>{localize(targetLogic, '6')}</option>
                      <option value={7}>{localize(targetLogic, '7')}</option>
                    </>
                  )}
                </Select>
                <Label htmlFor="value">Verdi *</Label>
                <Input
                  id="value"
                  type={parameter.targetRef === 'value' ? 'number' : 'text'}
                  required
                  value={parameter.value}
                  onChange={e =>
                    setParameter({
                      ...parameter,
                      value:
                        parameter.targetRef === 'value'
                          ? parseFloat(e.target.value)
                          : e.target.value
                    })
                  }
                />
              </>
            )}
          </>
        )}
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createParameter');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createParameter');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

interface PreviewPathModalProps {
  organization: Organization;
  path: Path;
  close: (id: string) => any;
  forceEmail?: boolean;
  forcePhone?: boolean;
  consents?: string[];
}

export function PreviewPathModal(props: PreviewPathModalProps) {
  const { organization, path, close, forceEmail, forcePhone, consents } = props;

  const { members } = useContext(OrganizationContext);

  const [contacts, loading, _, initialFetch] = useApi<Contact[]>({
    endpoint: `organizations/${organization._id}/tools/preview-path`,
    method: 'POST',
    body: { path },
    initialData: [],
    fetchOnMount: true,
    queryParams: {
      forceEmail,
      forcePhone,
      consents: consents ? consents.join(',') : undefined
    }
  });

  const dataPoints: DataPoint[] = [];
  let listsAreRelevant = false;
  let handlersAreRelevant = false;

  for (let i = 0; i < path.parameters.length; i++) {
    const parameter = path.parameters[i];

    if (parameter.target == Target.DataPoint) {
      const dp = organization.dataPoints.find(
        d => d._id === parameter.targetRef
      );

      if (dp) {
        dataPoints.push(dp);
      }
    } else if (parameter.target === Target.List) {
      listsAreRelevant = true;
    } else if (parameter.target === Target.Handler) {
      handlersAreRelevant = true;
    }
  }

  return (
    <Container minWidth="38rem">
      <Text centered bottomGutter>
        <span style={{ display: 'inline-block', maxWidth: '20rem' }}>
          <strong>MERK:</strong> Forhåndsvisningen viser bare et utdrag av de
          som potensielt vil bli påvirket av filteret per dags dato. Dette
          utdraget kan endre seg over tid.
        </span>
      </Text>
      <BusyBoy busy={loading}>
        {!loading && initialFetch && contacts.length < 1 ? (
          <EmptyState>Ingen treff!</EmptyState>
        ) : (
          <Card>
            <TableScrollWrapper>
              <Table>
                <TableHead>
                  <Tr>
                    <Th>Navn</Th>
                    <Th>{forceEmail ? 'E-post' : 'Telefonnummer'} </Th>
                    {listsAreRelevant && <Th>Lister</Th>}
                    {handlersAreRelevant && <Th>Saksbehandlere</Th>}
                    {dataPoints.map(d => (
                      <Th key={d._id}>{d.label}</Th>
                    ))}
                  </Tr>
                </TableHead>
                <TableBody>
                  {contacts.map(c => {
                    const receiverAddress = forcePhone ? c.phone : c.email;

                    return (
                      <Tr key={c._id}>
                        <Td>{getContactName(c)}</Td>
                        <Td>
                          {receiverAddress ? (
                            receiverAddress
                          ) : (
                            <Text variant="subheading">Ingen</Text>
                          )}
                        </Td>
                        {listsAreRelevant && (
                          <Td>
                            {c.lists.length > 0 ? (
                              c.lists
                                .map(l => {
                                  const ref = organization.lists.find(
                                    ref => ref._id === l
                                  );

                                  return ref ? ref.label : l;
                                })
                                .join(', ')
                            ) : (
                              <Text variant="subheading">Ingen</Text>
                            )}
                          </Td>
                        )}
                        {handlersAreRelevant && (
                          <Td>
                            {c.handlers && c.handlers.length > 0 ? (
                              c.handlers
                                .map(h => {
                                  const ref = members.find(m => m.user === h);
                                  return ref ? ref.userData!.name : h;
                                })
                                .join(', ')
                            ) : (
                              <Text variant="subheading">Ingen</Text>
                            )}
                          </Td>
                        )}
                        {dataPoints.map(d => {
                          const data = c.profile.find(
                            p => p.dataPoint === d._id
                          );

                          const value = data ? (
                            translateValue(d.type, data.value)
                          ) : (
                            <Text variant="subheading">Ingen</Text>
                          );

                          return <Td key={d._id}>{value}</Td>;
                        })}
                      </Tr>
                    );
                  })}
                </TableBody>
              </Table>
            </TableScrollWrapper>
          </Card>
        )}
      </BusyBoy>
      <ButtonList align="right" gutterTop>
        <ButtonExternalLink
          href="#"
          onClick={e => {
            e.preventDefault();
            close('previewPath');
          }}
        >
          Lukk
        </ButtonExternalLink>
      </ButtonList>
    </Container>
  );
}

export function renderProfileSelectionRule(
  p: PathParameter,
  list?: List,
  dataPoint?: DataPoint,
  dontShowLogic?: boolean
) {
  let memberName = 'Mangler navn';

  const { members } = useContext(OrganizationContext);
  const apps = useContext(AppsContext).data;

  if (p.target === Target.Handler) {
    const member = members.find(m => m.user === p.targetRef);

    if (member) {
      memberName = member.userData!.name;
    }
  }

  return (
    <Text>
      {!dontShowLogic && (
        <>
          <strong>{localize(selectionLogic, `${p.selectionLogic}`)}</strong>{' '}
        </>
      )}
      {p.target === Target.List ? (
        <span>
          profil{' '}
          <strong>
            FINNES {p.targetLogic === TargetLogic.NotEquals && 'IKKE'} I LISTE
          </strong>{' '}
          {list ? list.label : p.targetRef}
        </span>
      ) : p.target === Target.Handler ? (
        <span>
          Saksbehandler{' '}
          <strong>{localize(targetLogic, `${p.targetLogic}`)}</strong>{' '}
          {p.targetLogic !== TargetLogic.IsSet &&
          p.targetLogic !== TargetLogic.IsNotSet
            ? memberName
            : ''}
        </span>
      ) : p.target === Target.AdminAction ||
        p.target === Target.HandlerAction ||
        p.target === Target.ContactAction ? (
        <span>
          Siste{' '}
          {p.target === Target.AdminAction
            ? 'administratorhandling'
            : p.target === Target.ContactAction
            ? 'profilhandling'
            : 'saksbehandlerhandling'}{' '}
          er{' '}
          <strong>
            {p.targetLogic === TargetLogic.LessThan ? 'NYERE ENN' : 'ELDRE ENN'}
          </strong>{' '}
          {p.targetRef}{' '}
          {p.value === 'hour'
            ? 'time(r)'
            : p.value === 'day'
            ? 'dag(er)'
            : p.value === 'week'
            ? 'uke(r)'
            : 'måned(er)'}
        </span>
      ) : p.target === Target.ShipmentInteraction ? (
        <span>
          <strong>
            {p.targetLogic === TargetLogic.Equals
              ? 'HAR ÅPNET'
              : 'HAR IKKE ÅPNET'}
          </strong>{' '}
          utsendelse
        </span>
      ) : p.target === Target.Consent ? (
        <span>
          <strong>
            {p.targetLogic === TargetLogic.Equals ? 'HAR' : 'HAR IKKE'} SAMTYKKE
          </strong>{' '}
          {p.targetRef && p.targetRef in defaultConsents
            ? defaultConsents[p.targetRef]
            : p.targetRef}
        </span>
      ) : p.target === Target.Action ? (
        <span>
          {localize(action, p.targetRef as string)}{' '}
          <strong>{localize(targetLogic, `${p.targetLogic}`)}</strong> {p.value}
        </span>
      ) : (
        <span>
          {dataPoint
            ? labelDataPoint(dataPoint, apps).label
            : p.targetRef
            ? localize(coreData, p.targetRef as string)
                .charAt(0)
                .toUpperCase() +
              localize(coreData, p.targetRef as string).slice(1)
            : 'Alle'}{' '}
          {p.target !== Target.All && (
            <>
              <strong>{localize(targetLogic, `${p.targetLogic}`)}</strong>{' '}
              {p.targetLogic !== TargetLogic.IsSet &&
              p.targetLogic !== TargetLogic.IsNotSet &&
              dataPoint
                ? translateValue(dataPoint.type, p.value, true)
                : typeof p.value === 'boolean'
                ? p.value
                  ? 'ja'
                  : 'nei'
                : p.value}
            </>
          )}
        </span>
      )}
    </Text>
  );
}

interface PickTemplateModalProps {
  organizationID: string;
  automationID?: string;
  path?: Path;
  campaignID?: string;
  collector?: Collector;
  template?: EmailTemplate;
  close: (id: string) => any;
}

export function PickTemplateModal(props: PickTemplateModalProps) {
  const {
    template,
    organizationID,
    automationID,
    path,
    campaignID,
    collector,
    close
  } = props;

  const [templates, loading, hasMore, search, initialFetch] =
    useSearch<EmailTemplate>({
      endpoint: `organizations/${organizationID}/email-templates/search?notEmpty=1`,
      fetchOnMount: true
    });

  let patchEndpoint: string = '';

  let element: { _id: string } = { _id: '' };

  if (path) {
    patchEndpoint = `organizations/${organizationID}/automations/${automationID}/paths/${path._id}`;

    element = path;
  } else if (collector) {
    patchEndpoint = `organizations/${organizationID}/campaigns/${campaignID}/collectors/${collector._id}`;

    element = collector;
  } else if (template) {
    patchEndpoint = `organizations/${organizationID}/email-templates/${template._id}`;

    element = template;
  }

  const [data, patching, patch] = useApi<{ _id: string }>({
    method: 'PATCH',
    initialData: element,
    endpoint: patchEndpoint,
    onSuccess: () => {
      history.replace(element._id + '/utform');

      close('pickTemplate');
    }
  });

  return (
    <Container minWidth="38rem">
      <Text variant="title" bottomGutter centered>
        Velg mal
      </Text>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <CardExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              history.replace(element._id + '/utform');
              close('pickTemplate');
            }}
            clickable
            horizontal
            secondary
          >
            <CardContent tight>
              <Text>Standardmal</Text>
            </CardContent>
            <CardIcon tight>
              <Icon>chevron_right</Icon>
            </CardIcon>
          </CardExternalLink>
        </Grid>
      </Grid>
      <BusyBoy busy={loading}>
        {initialFetch && templates.length > 0 && (
          <>
            <Hr />
            <Grid container spacing={24}>
              {templates.map(t => (
                <Grid key={t._id} item xs={12}>
                  <CardExternalLink
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      patch({
                        method: 'PATCH',
                        endpoint: patchEndpoint,
                        body: path
                          ? {
                              email: {
                                ...path.email,
                                html: t.html,
                                json: t.json
                              }
                            }
                          : template
                          ? {
                              html: t.html,
                              json: t.json
                            }
                          : {
                              receipt: t.html,
                              receiptJson: t.json
                            }
                      });
                    }}
                    clickable
                    horizontal
                    secondary
                  >
                    <CardMedia height={100} width={100}>
                      <img src={t.preview} alt={t.name} />
                    </CardMedia>
                    <CardContent tight>
                      <Text>{t.name}</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>chevron_right</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </BusyBoy>
    </Container>
  );
}

export default Show;
