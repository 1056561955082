import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DataPoint } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { Organization as IOrganization } from '../../types/apiResponses';
import Form, { Label, Input } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { OrganizationContext } from '../../context/Organization';
import Doorman from '../../components/Doorman';

interface CreateCategoryParams {
  organizationID: string;
  categoryID: string;
}

function CreateCategory(props: RouteComponentProps<CreateCategoryParams>) {
  const organizationContext = useContext(OrganizationContext);
  const organizationID = props.match.params.organizationID;
  const categoryID = props.match.params.categoryID;
  const isUpdate = categoryID !== 'opprett';
  const baseEndpoint =
    'organizations/' + organizationID + '/data-point-categories';
  const endpoint = isUpdate ? baseEndpoint + '/' + categoryID : baseEndpoint;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<DataPoint>(
    {
      label: ''
    },
    {
      onSuccess: organization => {
        history.push('../kategorier');
        organizationContext.setData(organization as IOrganization);
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? 'organizations/' + organizationID : undefined,
      prefillFn: organization =>
        (organization as IOrganization).dataPointCategories.find(
          c => c._id === categoryID
        )
    }
  );

  const [_, deleting, destroy] = useApi<IOrganization | null>({
    method: 'DELETE',
    endpoint:
      'organizations/' +
      organizationID +
      '/data-point-categories/' +
      categoryID,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: organization => {
      history.push('../kategorier');
      organizationContext.setData(organization as IOrganization);
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} karakteristikkategori
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="label">Navn *</Label>
            <Input
              id="label"
              type="text"
              required
              placeholder="Favorittsmaker (eksempel)"
              value={data.label}
              onChange={e => setField('label', e.target.value)}
            />
            <ButtonList align="right">
              <ButtonLink to="../kategorier">Avbryt</ButtonLink>
              <Doorman>
                <>
                  {data && data._id && (
                    <ButtonExternalLink
                      onClick={() => destroy()}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default CreateCategory;
