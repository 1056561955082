import React, { FunctionComponent, useState, useEffect } from 'react';
import styled, { keyframes, css } from '../../util/styled';
import Icon from '../../components/Icon';
import Fader from '../Fader';
import useTimer from '../../hooks/useTimer';

const loading = keyframes`
  0% {
    transform: scale(1) rotate(0);
    opacity: 0.5;
  }

  25% {
    transform: scale(1.3) rotate(-16deg);
    opacity: 1;
  }

  50% {
    transform: scale(1) rotate(0);
    opacity: 0.5;
  }

  75% {
    transform: scale(1.3) rotate(16deg);
    opacity: 1;
  }

  100% {
    transform: scale(1) rotate(0);
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  0% {
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

const fadeOutIn = keyframes`
  0% {
    opacity: .6
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .2;
  }
`;

interface BlinkProps {
  busy?: boolean;
}

const Blink = styled.div<BlinkProps>`
  ${props =>
    props.busy &&
    css`
      animation: ${fadeOutIn} 1s ease-in-out infinite;
    `};
`;

interface LoaderProps {
  fadeOut?: boolean;
}

const Loader = styled(Fader)<LoaderProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    animation: ${loading} 2s ease-in-out infinite;
    font-size: 2.5rem;
  }

  ${props =>
    props.fadeOut &&
    css`
      animation: ${fadeOut} ${props => props.theme.animation.timing.normal}
        ease-out;
    `};
`;

interface Props {
  busy?: boolean;
  exposeChildren?: boolean;
}

const BusyBoy: FunctionComponent<Props> = ({
  busy,
  exposeChildren,
  children
}) => {
  const [internalBusy, setInternalBusy] = useState(false);
  const [exitBusy, setExitBusyTimer] = useTimer();
  const [dontShowLoader, setShowLoaderTimer] = useTimer();

  useEffect(
    () => {
      if (!busy && internalBusy) {
        setInternalBusy(false);
        setExitBusyTimer(200);
      } else if (busy && !internalBusy) {
        setInternalBusy(true);
        setShowLoaderTimer(500);
      }
    },
    [busy]
  );

  if (exposeChildren) {
    return <Blink busy={busy}>{children}</Blink>;
  }

  if (busy && children !== null) {
    return <Blink>{children}</Blink>;
  } else if (!exposeChildren && (busy || (!busy && exitBusy))) {
    return dontShowLoader ? null : (
      <Loader fadeOut={exitBusy}>
        <Icon>thumb_up</Icon>
      </Loader>
    );
  }

  return <Fader>{children}</Fader>;
};

export default BusyBoy;
