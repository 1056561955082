import { Address } from '../types/apiResponses';
import getAddress from './getAddress';

function renderAddress(address: Address): string {
  const checkedAddress = getAddress(address);

  return checkedAddress.hasAddress
    ? checkedAddress.addressArr.join(', ')
    : 'INGEN';
}

export default renderAddress;
