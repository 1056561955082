import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Automation, Robot } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderAutomationSidebarContent } from '../../routing/Automation';
import Menu, { MenuItem, MenuSplitter } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Helper from '../../components/Helper';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const baseUrl = '/organisasjoner/' + organizationID;
  const isUnderDevelopment = window.location.href.includes('under-arbeid');
  const isActive = !isUnderDevelopment;

  const [robots, loading, _, initialFetch] = useApi<Robot[]>({
    endpoint: 'organizations/' + organizationID + '/robots/search',
    initialData: [],
    fetchOnMount: true
  });

  const filteredRobots = robots.filter(a => {
    if (isUnderDevelopment) {
      return !a.active;
    }

    return a.active;
  });

  return (
    <>
      <Helper
        id="automations"
        title="Hva er en ProBot 🤖?"
        example={
          <>
            <Text>
              Klesbutikken du driver skal lansere en ny høstkolleksjon hvor
              fargepaletten preges av oransje. Du tenker dette er en god
              anledning for å prøvekjøre automasjonsverktøyet. Målet er å sende
              ut et nyhetsbrev som selger inn denne kolleksjonen til alle som
              har "oransje" som sin favorittfarge.
            </Text>
            <Text topGutter>
              Du oppretter en ny automasjon og kaller den "Høstkolleksjon 2019".
              Du legger til én utløser, av type <em>Send så snart som mulig</em>
              . Deretter oppretter du en ny e-post, kaller den "Nyhetsbrev til
              oransje-elskere", skriver inn et vettugt emnefelt og legger til
              nyhetsbrevet. Under <em>Filter</em> legger du til én regel:{' '}
              karakterstikken "Favorittfarge" skal være "ER LIK" "oransje".
            </Text>
            <Text topGutter>
              Når automasjonen er satt som aktiv vil nyhetsbrevet bli sendt ut
              til alle som matcher regelsettet. Det vil si, alle med "oransje"
              som sin favorittfarge.
            </Text>
          </>
        }
        exampleTitle="Ny høstkolleksjon"
      >
        <Text>
          Automasjon lar deg sende ut e-poster og nyhetsbrev uten å løfte en
          finger! Eller, du må jo løfte et par fingre for å opprette en
          automasjon, men når det er gjort er det bare å lene seg tilbake.
        </Text>
        <Text topGutter>
          En automasjon kan være en smule kompleks å konfigurere, ettersom det
          er et verktøy med mye kraft i seg. Men, i all hovedsak gjør du to ting
          når du setter opp en automasjon: 1) Legger til én eller flere utløsere
          (altså, under hvilke omstendigheter denne automasjonen skal
          aktiveres), og 2) Setter opp én eller flere e-poster, samt hvilke
          segmenter disse e-postene skal sendes til.
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {isUnderDevelopment ? 'ProBoter under arbeid' : 'Aktive ProBoter'}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderAutomationSidebarContent(baseUrl)}
          <MenuSplitter />
          <Menu>
            <MenuItem
              to={(isActive ? 'proboter/' : '') + 'opprett'}
              icon={<Icon>add</Icon>}
              primary
            >
              Ny ProBot
            </MenuItem>
            {initialFetch && !loading && robots.length < 1 && (
              <ShowOff>
                Opprett din første ProBot 🤖 ved å trykke på knappen over!
              </ShowOff>
            )}
          </Menu>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {!loading && initialFetch && filteredRobots.length < 1 && (
                <EmptyState>Ingen ProBoter!</EmptyState>
              )}
              {filteredRobots.length > 0 && (
                <Grid container spacing={24}>
                  {filteredRobots.map(r => (
                    <Grid key={r._id} item sm={4}>
                      <CardLink
                        to={(isActive ? 'proboter/' : '') + r._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{r.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
