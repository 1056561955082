import React, { FunctionComponent, createContext, useState } from 'react';
import { Organization, Member } from '../types/apiResponses';

const defaultOrganization: Organization = {
  _id: '',
  name: '',
  groups: [],
  members: [],
  dataPoints: [],
  dataPointCategories: [],
  lists: [],
  variables: [],
  notificationEmail: null,
  invites: [],
  consents: [],
  apps: [],
  theme: {
    color: null
  }
};

const OrganizationContext = createContext({
  data: defaultOrganization,
  members: [] as Member[],
  setData: (data: Organization) => {},
  setMembers: (members: Member[]) => {}
});

const OrganizationProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState(defaultOrganization);
  const [members, setMembers] = useState([] as Member[]);

  return (
    <OrganizationContext.Provider
      value={{
        data: state,
        members,
        setData: setState,
        setMembers
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export { OrganizationContext, OrganizationProvider };
