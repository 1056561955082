import React, { useContext } from 'react';
import { Container, VerticalContainer } from '../../../helpers/Layout';
import {
  Robot,
  Organization,
  RobotAction,
  RobotPath
} from '../../../types/apiResponses';
import {
  CardExternalLink,
  CardContent,
  CardIcon
} from '../../../components/Card';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import Action from './Action';
import { UiContext } from '../../../context/Ui';
import Split from './Split';
import { RobotSplitType } from '../../../constants/enums';

interface ActionModalProps {
  organization: Organization;
  robot: Robot;
  path: RobotPath;
  action?: RobotAction;
  close: (id: string) => any;
}

function ActionType(props: ActionModalProps) {
  const { organization, robot, path, close } = props;

  const { spawnModal } = useContext(UiContext).modal;

  return (
    <Container minWidth="38rem" spacious>
      <VerticalContainer>
        <CardExternalLink
          href="#"
          horizontal
          clickable
          onClick={e => {
            e.preventDefault();

            spawnModal(
              <Action
                organization={organization}
                robot={robot}
                path={path}
                close={close}
              />,
              'action'
            );
          }}
        >
          <CardContent>
            <Text>Handling</Text>
          </CardContent>
          <CardIcon>
            <Icon>gesture</Icon>
          </CardIcon>
        </CardExternalLink>
      </VerticalContainer>
      {path.split.__type === RobotSplitType.None && (
        <VerticalContainer>
          <CardExternalLink
            href="#"
            horizontal
            clickable
            onClick={e => {
              e.preventDefault();

              spawnModal(
                <Split
                  organization={organization}
                  robot={robot}
                  path={path}
                  close={close}
                />,
                'split'
              );
            }}
          >
            <CardContent>
              <Text>Splitt</Text>
            </CardContent>
            <CardIcon>
              <Icon>call_split</Icon>
            </CardIcon>
          </CardExternalLink>
        </VerticalContainer>
      )}
    </Container>
  );
}

export default ActionType;
