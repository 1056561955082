import styled from '../../util/styled';

const Pre = styled.pre`
  font-size: inherit;
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  padding: 18px ${props => props.theme.layout.spacing.normal};
  border-radius: ${props => props.theme.layout.rounding};
  background-color: white;
  margin: 0;
`;

export default Pre;
