import React, { useContext, useState } from 'react';
import { Link, Switch, Route, RouteComponentProps } from 'react-router-dom';
import Prism from 'prismjs';
import Grid from '@material-ui/core/Grid';
import moment from '../../util/moment';
import history from '../../util/history';
import translateValue from '../../util/translateValue';
import getContactName from '../../util/getContactName';
import createForm from '../../util/createForm';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import {
  Campaign,
  Collector,
  CollectorDataPointReference,
  SelectFromRule,
  DataPoint,
  Collection
} from '../../types/apiResponses';
import Heading from '../../components/Heading';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Text from '../../components/Text';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import BusyBoy from '../../helpers/BusyBoy';
import Icon from '../../components/Icon';
import Menu, {
  MenuItem,
  MenuItemButton,
  MenuSplitter
} from '../../components/Menu';
import Form, { Input, Select, Label } from '../../components/Form';
import Button, {
  ButtonExternalLink,
  ButtonList,
  ButtonLink
} from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import Card, {
  CardExternalLink,
  CardLink,
  CardContent,
  CardIcon
} from '../../components/Card';
import Expander from '../../components/Expander';
import Pre from '../../components/Pre';
import Hr from '../../components/Hr';
import { UiContext } from '../../context/Ui';
import { OrganizationContext } from '../../context/Organization';
import Table, {
  TableScrollWrapper,
  TableHead,
  TableBody,
  Tr,
  Td,
  Th
} from '../../components/Table';
import Doorman from '../../components/Doorman';
import { PickTemplateModal } from '../Automation/Show';
import Editor from '../EmailTemplates/Editor';
import HTMLPreview from '../../components/HTMLPreview';
import ReactDatePicker from '../../components/DatePicker';

interface CreateParams {
  organizationID: string;
  campaignID?: string;
}

function renderMenu(
  organizationID: string,
  baseUrl: string,
  isUpdate: boolean,
  campaignID?: string
) {
  return (
    <>
      <Menu>
        <MenuItem
          exact
          to={baseUrl + (isUpdate ? `/${campaignID}` : '/opprett')}
          icon={<Icon>info</Icon>}
        >
          Informasjon
        </MenuItem>
        <Doorman>
          {isUpdate ? (
            <MenuItem
              exact
              to={
                baseUrl +
                (isUpdate ? `/${campaignID}/innsamlere` : '/opprett/innsamlere')
              }
              icon={<Icon>contact_mail</Icon>}
            >
              Innsamlere
            </MenuItem>
          ) : (
            <MenuItemButton disabled icon={<Icon>contact_mail</Icon>}>
              Innsamlere
            </MenuItemButton>
          )}
        </Doorman>
      </Menu>
      {isUpdate && (
        <>
          <MenuSplitter />
          <Menu>
            <MenuItem
              to={baseUrl + '/' + campaignID + '/skjemaer'}
              icon={<Icon>library_books</Icon>}
            >
              Skjemaer
            </MenuItem>
            <MenuItem
              to={baseUrl + '/' + campaignID + '/integrasjon'}
              icon={<Icon>code</Icon>}
            >
              For utviklere
            </MenuItem>
          </Menu>
          <MenuSplitter />
          <Menu>
            <MenuItem
              to={baseUrl + '/' + campaignID + '/innsamlinger'}
              icon={<Icon>move_to_inbox</Icon>}
            >
              Innsamlinger
            </MenuItem>
            <MenuItem
              to={
                '/organisasjoner/' +
                organizationID +
                '/rapporter/innsamlinger/' +
                campaignID
              }
              icon={<Icon>insert_chart_outlined</Icon>}
            >
              Rapporter
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const campaignID = props.match.params.campaignID;
  const isUpdate = campaignID && campaignID !== 'opprett' ? true : false;
  const baseUrl = '/organisasjoner/' + organizationID + '/kampanjer';
  const endpoint = 'organizations/' + organizationID + '/campaigns';

  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID"
        render={() => (
          <Basic
            organizationID={organizationID}
            isUpdate={isUpdate}
            campaignID={campaignID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/skjemaer"
        render={() => (
          <Integration
            organizationID={organizationID}
            isUpdate={false}
            campaignID={campaignID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/integrasjon"
        render={() => (
          <Integration
            organizationID={organizationID}
            isUpdate={false}
            campaignID={campaignID}
            baseUrl={baseUrl}
            endpoint={endpoint}
            developers
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/innsamlinger"
        render={() => (
          <Collections
            organizationID={organizationID}
            isUpdate={false}
            campaignID={campaignID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/innsamlere"
        render={() => (
          <Collectors
            organizationID={organizationID}
            isUpdate={isUpdate}
            campaignID={campaignID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/innsamlere/:collectorID"
        component={CreateCollector}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/innsamlere/:collectorID/utform"
        component={Editor}
      />
    </Switch>
  );
}

interface BasicProps {
  organizationID: string;
  isUpdate: boolean;
  endpoint: string;
  baseUrl: string;
  campaignID?: string;
}

function Basic(props: BasicProps) {
  const { organizationID, isUpdate, endpoint, baseUrl, campaignID } = props;
  const prefillEndpoint = endpoint + '/' + campaignID;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Campaign>(
    {
      name: '',
      notifyOnCollection: true,
      start: moment().toDate(),
      end: moment()
        .add(1, 'month')
        .toDate()
    },
    {
      onSuccess: campaign => {
        if (!isUpdate) {
          history.push((campaign as Campaign)._id + '/innsamlere');
        }
      },
      endpoint: isUpdate ? prefillEndpoint : endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? prefillEndpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<Campaign | null>({
    method: 'DELETE',
    endpoint: prefillEndpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../kampanjer');
    }
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} kampanje
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(organizationID, baseUrl, isUpdate, campaignID)}
        </Sidebar>
        <Content sidebar>
          <BusyBoy
            busy={loadingPrefill || submitting || deleting}
            exposeChildren
          >
            <Container spacious>
              <Form onSubmit={submit}>
                <Label htmlFor="name">Navn *</Label>
                <Input
                  id="name"
                  type="text"
                  required
                  placeholder="Min kampanje"
                  value={data.name}
                  onChange={e => setField('name', e.target.value)}
                />
                <Label htmlFor="notifyOnCollection">
                  Send varsel ved deltakelse
                </Label>
                <Select
                  id="notifyOnCollection"
                  value={`${data.notifyOnCollection}`}
                  onChange={e => setField('notifyOnCollection', e.target.value)}
                >
                  <option value="true">Ja</option>
                  <option value="false">Nei</option>
                </Select>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <Label htmlFor="start">Aktiv fra *</Label>
                    <ReactDatePicker
                      id="start"
                      selected={
                        data.start ? moment(data.start).toDate() : new Date()
                      }
                      onChange={value => setField('start', value)}
                      showTimeSelect
                      dateFormat="dd.MM.YYY HH:mm"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Label htmlFor="end">Aktiv til *</Label>
                    <ReactDatePicker
                      id="end"
                      selected={
                        data.end ? moment(data.end).toDate() : new Date()
                      }
                      onChange={value => setField('end', value)}
                      showTimeSelect
                      dateFormat="dd.MM.YYY HH:mm"
                    />
                  </Grid>
                </Grid>
                <ButtonList align="right">
                  <ButtonLink to="../kampanjer">Avbryt</ButtonLink>
                  {isUpdate && (
                    <ButtonExternalLink
                      onClick={() => destroy()}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Doorman>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isUpdate && !hasMadeChanges}
                    >
                      {submitting ? 'Lagrer…' : 'Lagre'}
                    </Button>
                  </Doorman>
                </ButtonList>
              </Form>
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface IntegrationProps extends BasicProps {
  developers?: boolean;
}

function Integration(props: IntegrationProps) {
  const { developers, organizationID, endpoint, baseUrl, campaignID } = props;

  const [campaign, loading, _, initialFetch] = useApi<Campaign | null>({
    endpoint: endpoint + '/' + campaignID,
    initialData: null,
    fetchOnMount: true
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {developers ? 'For utviklere' : 'Skjemaer'}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(organizationID, baseUrl, true, campaignID)}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            {!loading &&
              initialFetch &&
              campaign &&
              campaign.collectors.length < 1 && (
                <EmptyState>Ingen innsamlere!</EmptyState>
              )}
            <BusyBoy busy={loading}>
              {campaign &&
                campaign.collectors.map(c => (
                  <React.Fragment key={c._id}>
                    <Text element="h2" variant="display2" bottomGutter>
                      {c.name}
                    </Text>
                    <CollectorIntegration
                      campaignID={campaignID || ''}
                      baseEndpoint={'campaigns/' + campaignID + '/collectors'}
                      collectorID={c._id}
                      developers={developers}
                    />
                    <Hr />
                  </React.Fragment>
                ))}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface CollectorIntegrationProps {
  baseEndpoint: string;
  campaignID: string;
  collectorID: string;
  developers?: boolean;
}

function CollectorIntegration(props: CollectorIntegrationProps) {
  const { baseEndpoint, campaignID, collectorID, developers } = props;
  const endpoint = baseEndpoint + '/' + collectorID;

  const [collector, loading] = useApi<Collector | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true
  });

  if (developers) {
    return (
      <BusyBoy busy={loading}>
        {collector && (
          <>
            <Label htmlFor="endpoint" gutterTop>
              Read endpoint (GET)
            </Label>
            <Input
              id="endpoint"
              type="text"
              value={process.env.REACT_APP_PLZ_URL + '/' + endpoint}
              onFocus={e => e.target.select()}
            />
            <Label htmlFor="collect" gutterTop>
              Collect endpoint (POST)
            </Label>
            <Input
              id="collect"
              type="text"
              value={
                process.env.REACT_APP_PLZ_URL + '/' + endpoint + '/collect'
              }
              onFocus={e => e.target.select()}
            />
            <Label htmlFor="endpoint" gutterTop>
              Forhåndsvisning respons
            </Label>
            <Pre
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  JSON.stringify(collector, null, 2),
                  Prism.languages.javascript,
                  'javascript'
                )
              }}
            />
          </>
        )}
      </BusyBoy>
    );
  }

  return (
    <BusyBoy busy={loading}>
      {collector && (
        <>
          <Text variant="subheading" bottomGutter topGutter>
            HTML-kode
          </Text>
          <Pre
            dangerouslySetInnerHTML={{
              __html: Prism.highlight(
                createForm(campaignID, collector),
                Prism.languages.html,
                'html'
              )
            }}
          />
          <Text variant="subheading" bottomGutter topGutter>
            Forhåndsvisning
          </Text>
          <Card>
            <CardContent tight>
              <div
                dangerouslySetInnerHTML={{
                  __html: createForm(campaignID, collector)
                }}
              />
            </CardContent>
          </Card>
        </>
      )}
    </BusyBoy>
  );
}

function Collections(props: BasicProps) {
  const { endpoint, organizationID, baseUrl, campaignID } = props;

  const organization = useContext(OrganizationContext).data;

  const [campaign] = useApi<Campaign | null>({
    endpoint: endpoint + '/' + campaignID,
    initialData: null,
    fetchOnMount: true
  });

  const [collections, loading, _, initialFetch] = useApi<Collection[]>({
    endpoint: endpoint + '/' + campaignID + '/collections',
    initialData: [],
    fetchOnMount: true
  });

  const dataPoints: { key: string; value: string; type: string }[] = [];

  for (let i = 0; i < collections.length; i++) {
    const collection = collections[i];

    for (let j = 0; j < collection.data.length; j++) {
      const data = collection.data[j];

      if (!dataPoints.some(dp => dp.key === data.dataPoint)) {
        const dpVal = organization.dataPoints.find(
          dp => dp._id === data.dataPoint
        );

        dataPoints.push({
          key: data.dataPoint,
          value: dpVal ? dpVal.label : data.dataPoint,
          type: dpVal ? dpVal.type : ''
        });
      }
    }
  }

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Innsamlinger
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(organizationID, baseUrl, true, campaignID)}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            {!loading && initialFetch && collections.length < 1 && (
              <EmptyState>Ingen innsamlinger!</EmptyState>
            )}
            <BusyBoy busy={loading}>
              {collections.length > 0 && (
                <Card>
                  <TableScrollWrapper>
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th>Navn</Th>
                          <Th>E-post</Th>
                          <Th>Innsamler</Th>
                          {dataPoints.map(dp => (
                            <Th key={dp.key}>{dp.value}</Th>
                          ))}
                          <Th>Tidspunkt</Th>
                          <Th>Kilde</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {collections.map(c => {
                          let collectorName: string = '';

                          if (campaign) {
                            const collector = campaign.collectors.find(
                              cl => cl._id === c.collector
                            );

                            if (collector) {
                              collectorName = collector.name;
                            }
                          }

                          return (
                            <Tr key={c._id}>
                              {c.contact ? (
                                <>
                                  <Td>
                                    <Link
                                      to={'../../profiler/' + c.contact._id}
                                    >
                                      {getContactName(c.contact)}
                                    </Link>
                                  </Td>
                                  <Td>{c.contact.email}</Td>
                                </>
                              ) : (
                                <Td colSpan={2}>
                                  <Text variant="subheading">
                                    Kontakt slettet
                                  </Text>
                                </Td>
                              )}
                              <Td>{collectorName}</Td>
                              {dataPoints.map(dp => {
                                const value = c.data.find(
                                  d => d.dataPoint === dp.key
                                );

                                return (
                                  <Td key={dp.key}>
                                    {value ? (
                                      translateValue(dp.type, value.value)
                                    ) : (
                                      <Text variant="subheading">Ingen</Text>
                                    )}
                                  </Td>
                                );
                              })}
                              <Td>
                                {moment(c.date).format('D.M.YY [kl.] HH:mm:ss')}
                              </Td>
                              <Td>
                                {c.referer ? (
                                  c.referer
                                ) : (
                                  <Text variant="subheading">Ingen</Text>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableScrollWrapper>
                </Card>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

function Collectors(props: BasicProps) {
  const { isUpdate, organizationID, endpoint, baseUrl, campaignID } = props;

  const [campaign, loading, _, initialFetch] = useApi<Campaign>({
    endpoint: endpoint + '/' + campaignID,
    initialData: {
      _id: '',
      name: '',
      group: null,
      notifyOnCollection: true,
      organization: '',
      createdAt: '',
      start: '',
      end: '',
      collectors: []
    },
    fetchOnMount: true
  });

  const collectors = campaign.collectors;

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Innsamlere
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(organizationID, baseUrl, isUpdate, campaignID)}
          <MenuSplitter />
          <Menu>
            <MenuItem to="innsamlere/opprett" icon={<Icon>add</Icon>} primary>
              Ny innsamler
            </MenuItem>
          </Menu>
        </Sidebar>
        <Content sidebar>
          <BusyBoy busy={loading}>
            <Container spacious>
              {!loading && initialFetch && collectors.length < 1 && (
                <EmptyState>Ingen innsamlere!</EmptyState>
              )}
              {collectors.length > 0 && (
                <Grid container spacing={24}>
                  {collectors.map(c => (
                    <Grid key={c._id} item sm={4}>
                      <CardLink to={`innsamlere/${c._id}`} clickable horizontal>
                        <CardContent tight>
                          <Text>{c.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface CreateCollectorParams {
  organizationID: string;
  campaignID: string;
  collectorID: string;
}

function CreateCollector(props: RouteComponentProps<CreateCollectorParams>) {
  const organizationID = props.match.params.organizationID;
  const campaignID = props.match.params.campaignID;
  const collectorID = props.match.params.collectorID;
  const isUpdate = collectorID !== 'opprett';

  const organization = useContext(OrganizationContext).data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const endpoint = 'organizations/' + organizationID + '/campaigns';
  const prefillEndpoint = endpoint + '/' + campaignID;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Collector>(
    {
      name: '',
      limit: null,
      receipt: null,
      receiptPlain: null,
      receiptSubject: null,
      dataPoints: []
    },
    {
      endpoint:
        prefillEndpoint + '/collectors' + (isUpdate ? `/${collectorID}` : ''),
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? prefillEndpoint : undefined,
      prefillFn: isUpdate
        ? campaign =>
            (campaign as Campaign).collectors.find(c => c._id === collectorID)
        : undefined,
      onSuccess: collector => {
        if (
          (isUpdate && !data.receiptSubject && !data.receipt) ||
          !data.receiptSubject
        ) {
          history.push('../innsamlere');
        } else {
          spawnModal(
            <PickTemplateModal
              organizationID={organizationID}
              campaignID={campaignID}
              collector={collector as Collector}
              close={despawnModal}
            />,
            'pickTemplate'
          );
        }
      }
    }
  );

  const [_, deleting, destroy] = useApi<Campaign | null>({
    method: 'DELETE',
    endpoint: prefillEndpoint + '/collectors/' + data._id,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../innsamlere');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} innsamler
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min innsamler"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <Label htmlFor="limit">Innsamlinger per bruker</Label>
            <Input
              id="limit"
              type="text"
              value={data.limit ? `${data.limit}` : ''}
              placeholder="Ubegrenset"
              onChange={e => setField('limit', parseInt(e.target.value))}
            />
            <Expander label={`Karakteristikker (${data.dataPoints.length})`}>
              <Grid container spacing={24}>
                {data.dataPoints.map(d => {
                  const dataPoint = organization.dataPoints.find(
                    p => p._id === d.ref
                  );

                  return (
                    <Grid key={d.ref} item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreateDataPointModal
                              defaultData={d}
                              dataPoints={organization.dataPoints.filter(
                                dp =>
                                  dp._id === d.ref ||
                                  !data.dataPoints.some(
                                    dpp => dpp.ref === dp._id
                                  )
                              )}
                              onCreate={dataPoint => {
                                const index = data.dataPoints.findIndex(
                                  d => d.ref === dataPoint.ref
                                );

                                if (index !== -1) {
                                  const copy = [...data.dataPoints];
                                  copy[index] = dataPoint;

                                  setField('dataPoints', copy);
                                }
                              }}
                              onDelete={ref => {
                                setField(
                                  'dataPoints',
                                  data.dataPoints.filter(d => d.ref !== ref)
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createDataPoint'
                          );
                        }}
                      >
                        <CardContent tight>
                          <Text>{dataPoint ? dataPoint.label : d.ref}</Text>
                          <Text variant="subheading">
                            {d.required ? 'Påkrevd' : 'Ikke påkrevd'}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    primary={data.dataPoints.length < 1}
                    secondary={data.dataPoints.length > 0}
                    onClick={e => {
                      e.preventDefault();
                      spawnModal(
                        <CreateDataPointModal
                          dataPoints={organization.dataPoints.filter(
                            d => !data.dataPoints.some(dp => dp.ref === d._id)
                          )}
                          onCreate={dataPoint => {
                            setField('dataPoints', [
                              ...data.dataPoints,
                              dataPoint
                            ]);
                          }}
                          close={despawnModal}
                        />,
                        'createDataPoint'
                      );
                    }}
                  >
                    <CardContent tight>
                      <Text>Legg til karakteristikk</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>add</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              </Grid>
            </Expander>
            <Expander label="Kvitterings-epost">
              <Label htmlFor="receiptSubject">Emnefelt</Label>
              <Input
                id="receiptSubject"
                type="text"
                value={data.receiptSubject}
                placeholder="Takk for at du…"
                onChange={e => setField('receiptSubject', e.target.value)}
              />
              {data.receipt && (
                <>
                  <HTMLPreview gutterTop html={data.receipt} />
                  <CardLink
                    to={collectorID + '/utform'}
                    clickable
                    horizontal
                    secondary
                  >
                    <CardContent tight>
                      <Text>Rediger kvitteringse-post</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>edit</Icon>
                    </CardIcon>
                  </CardLink>
                </>
              )}
            </Expander>
            <ButtonList align="right">
              <ButtonLink to="../innsamlere">
                {isUpdate ? 'Tilbake' : 'Avbryt'}
              </ButtonLink>
              {isUpdate && (
                <ButtonExternalLink
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    destroy();
                  }}
                  variant="warning"
                >
                  Slett
                </ButtonExternalLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting
                  ? 'Lagrer…'
                  : data.receiptSubject && (!isUpdate || !data.receipt)
                  ? 'Lagre og start å designe'
                  : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

interface CreateDataPointModalProps {
  close: (id: string) => any;
  onCreate: (data: CollectorDataPointReference) => any;
  onDelete?: (ref: string) => any;
  defaultData?: CollectorDataPointReference;
  dataPoints: DataPoint[];
}

const defaultDataPoint: CollectorDataPointReference = {
  _id: '',
  ref: '',
  selectFrom: [],
  required: false
};

function CreateDataPointModal(props: CreateDataPointModalProps) {
  const { defaultData, dataPoints, close, onCreate, onDelete } = props;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [dataPoint, setDataPoint] = useState(
    defaultData ? defaultData : defaultDataPoint
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (dataPoint.ref.length > 0) {
            delete dataPoint._id;
            onCreate(dataPoint);
          }

          close('createDataPoint');
        }}
      >
        <Label htmlFor="dataPoint">Karakteristikk *</Label>
        <Select
          id="dataPoint"
          required
          value={dataPoint.ref}
          onChange={e => setDataPoint({ ...dataPoint, ref: e.target.value })}
        >
          <option value="" disabled>
            Vennligst velg…
          </option>
          {dataPoints.map(d => (
            <option key={d._id} value={d._id}>
              {d.label}
            </option>
          ))}
        </Select>
        <Label htmlFor="required">Påkrevd</Label>
        <Select
          id="required"
          value={`${dataPoint.required}`}
          onChange={e =>
            setDataPoint({ ...dataPoint, required: e.target.value === 'true' })
          }
        >
          <option value="false">Nei</option>
          <option value="true">Ja</option>
        </Select>
        <Expander label={`Valgmuligheter (${dataPoint.selectFrom.length})`}>
          <Grid container spacing={24}>
            {dataPoint.selectFrom.map(s => (
              <Grid key={s.value} item xs={12}>
                <CardExternalLink
                  href="#"
                  clickable
                  horizontal
                  onClick={e => {
                    e.preventDefault();
                    spawnModal(
                      <CreateSelectFromModal
                        close={despawnModal}
                        defaultData={s}
                        onCreate={selectFrom => {
                          const index = dataPoint.selectFrom.findIndex(
                            d => d.value === selectFrom.value
                          );

                          if (index !== -1) {
                            const copy = [...dataPoint.selectFrom];
                            copy[index] = selectFrom;

                            setDataPoint({
                              ...dataPoint,
                              selectFrom: copy
                            });
                          }
                        }}
                        onDelete={value => {
                          setDataPoint({
                            ...dataPoint,
                            selectFrom: dataPoint.selectFrom.filter(
                              s => s.value !== value
                            )
                          });
                        }}
                      />,
                      'createSelectFrom'
                    );
                  }}
                >
                  <CardContent tight>
                    <Text>{s.value}</Text>
                    <Text variant="subheading">
                      {s.limit
                        ? `${s.limit} innsamlinger per kampanje`
                        : 'Ubegrenset'}
                    </Text>
                  </CardContent>
                  <CardIcon tight>
                    <Icon>edit</Icon>
                  </CardIcon>
                </CardExternalLink>
              </Grid>
            ))}
            <Grid item xs={12}>
              <CardExternalLink
                href="#"
                clickable
                horizontal
                secondary
                onClick={e => {
                  e.preventDefault();
                  spawnModal(
                    <CreateSelectFromModal
                      close={despawnModal}
                      onCreate={selectFrom =>
                        setDataPoint({
                          ...dataPoint,
                          selectFrom: [...dataPoint.selectFrom, selectFrom]
                        })
                      }
                    />,
                    'createSelectFrom'
                  );
                }}
              >
                <CardContent tight>
                  <Text>Legg til valgmulighet</Text>
                </CardContent>
                <CardIcon tight>
                  <Icon>add</Icon>
                </CardIcon>
              </CardExternalLink>
            </Grid>
          </Grid>
        </Expander>
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createDataPoint');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              onClick={() => {
                onDelete(defaultData.ref);
                close('createDataPoint');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

interface CreateSelectFromModalProps {
  close: (id: string) => any;
  onCreate: (data: SelectFromRule) => any;
  onDelete?: (ref: string) => any;
  defaultData?: SelectFromRule;
}

const defaultSelectFrom: SelectFromRule = {
  _id: '',
  value: ''
};

function CreateSelectFromModal(props: CreateSelectFromModalProps) {
  const { onCreate, close, onDelete, defaultData } = props;

  const [selectFrom, setSelectFrom] = useState(
    defaultData ? defaultData : defaultSelectFrom
  );

  return (
    <Container minWidth="33rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (selectFrom.value.length > 0) {
            delete selectFrom._id;
            onCreate(selectFrom);
          }

          close('createSelectFrom');
        }}
      >
        <Label htmlFor="value">Verdi *</Label>
        <Input
          id="value"
          type="text"
          required
          value={selectFrom.value}
          onChange={e =>
            setSelectFrom({ ...selectFrom, value: e.target.value })
          }
        />
        <Label htmlFor="limit">Innsamlinger per kampanje</Label>
        <Input
          id="limit"
          type="text"
          placeholder="Ubegrenset"
          value={selectFrom.limit ? selectFrom.limit : ''}
          onChange={e =>
            setSelectFrom({ ...selectFrom, limit: parseInt(e.target.value) })
          }
        />
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createSelectFrom');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                onDelete(defaultData.value);
                close('createSelectFrom');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

export default Create;
