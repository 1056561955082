import React from 'react';
import styled, { css } from '../../util/styled';

interface WrapperProps {
  inverted?: boolean;
  line?: boolean;
  textAdjust?: boolean;
  width?: string;
}

const Wrapper = styled.div<WrapperProps>`
  height: 8px;
  background-color: ${props =>
    props.inverted
      ? props.theme.colors.contentBackground
      : props.theme.colors.background};
  border-radius: ${props => props.theme.layout.rounding};

  ${props =>
    props.line &&
    css`
      height: 2px;
      margin-top: 5px;
    `};

  ${props =>
    props.textAdjust &&
    css`
      margin: 8px 0;
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

interface BarProps {
  percentage: number;
  line?: boolean;
}

const Bar = styled.div<BarProps>`
  height: 8px;
  width: ${props => props.percentage}%;
  background-color: ${props => props.theme.colors.good};
  border-radius: ${props => props.theme.layout.rounding};

  ${props =>
    props.percentage < 75 &&
    props.percentage > 33 &&
    css`
      background-color: ${props.theme.colors.neutral};
    `};

  ${props =>
    props.percentage < 34 &&
    css`
      background-color: ${props.theme.colors.warning};
    `};

  ${props =>
    props.line &&
    css`
      height: 2px;
    `};
`;

interface Props {
  score: number;
  inverted?: boolean;
  line?: boolean;
  textAdjust?: boolean;
  width?: string;
}

function AggregatorScore(props: Props) {
  let { score, inverted, line, textAdjust, width } = props;

  score = score > 10 ? 10 : score;

  if (!score) {
    score = 0;
  }

  return (
    <Wrapper
      inverted={inverted}
      line={line}
      textAdjust={textAdjust}
      width={width}
    >
      <Bar percentage={(score / 10) * 100} line={line} />
    </Wrapper>
  );
}

export default AggregatorScore;
