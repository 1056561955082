import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { EmailTemplate } from '../../types/apiResponses';
import { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container, Flex } from '../../helpers/Layout';
import Text from '../../components/Text';
import {
  CardLink,
  CardContent,
  CardIcon,
  CardMedia
} from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import Doorman from '../../components/Doorman';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const [emailTemplates, loading, hasMore, search, hasFetched] = useSearch<
    EmailTemplate
  >({
    endpoint: 'organizations/' + organizationID + '/email-templates/search',
    fetchOnMount: true
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          E-postmaler
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem
                to="e-postmaler/opprett"
                icon={<Icon>add</Icon>}
                primary
              >
                Ny e-postmal
              </MenuItem>
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {hasFetched && emailTemplates.length < 1 && (
                <EmptyState>Ingen e-postmaler!</EmptyState>
              )}
              {hasFetched && emailTemplates.length > 0 && (
                <Grid container spacing={24}>
                  {emailTemplates.map(e => (
                    <Grid key={e._id} item xs={12} md={4}>
                      <CardLink to={'e-postmaler/' + e._id} clickable>
                        <CardMedia variant="full">
                          <img src={e.preview} alt={e.name} />
                        </CardMedia>
                        <Flex>
                          <CardContent tight>
                            <Text>{e.name}</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </Flex>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
