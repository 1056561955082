import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactSelect from 'react-select';
import useForm from '../../hooks/useForm';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, FileInput } from '../../components/Form';
import Button, { ButtonList, ButtonLink } from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { OrganizationContext } from '../../context/Organization';

interface ImportParams {
  organizationID: string;
}

interface ImportFields {
  file: File | null;
  list: string | null;
}

function Import(props: RouteComponentProps<ImportParams>) {
  const organization = useContext(OrganizationContext).data;
  const organizationID = props.match.params.organizationID;
  const endpoint = 'organizations/' + organizationID + '/contacts/import';

  const { data, submitting, hasMadeChanges, setField, submit } = useForm<
    ImportFields
  >(
    {
      file: null,
      list: null
    },
    {
      onSuccess: () => {
        history.push('../profiler');
      },
      endpoint,
      method: 'POST',
      multipart: true
    }
  );

  const selectedList =
    organization && data.list
      ? organization.lists.find(l => l._id === data.list)
      : undefined;

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Importer profiler
        </Text>
      </Container>
      <BusyBoy busy={submitting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="csv">CSV-fil *</Label>
            <FileInput
              id="csv"
              type="file"
              required
              onChange={e =>
                e.target.files &&
                e.target.files.length > 0 &&
                setField('csv', e.target.files[0])
              }
            />
            {organization && organization.lists.length > 0 && (
              <>
                <Label htmlFor="list">Lister</Label>
                <ReactSelect
                  classNamePrefix="react-select"
                  placeholder="Vennligst velg…"
                  value={
                    selectedList && data.list
                      ? {
                          value: data.list,
                          label: selectedList.label
                        }
                      : undefined
                  }
                  onChange={val =>
                    setField(
                      'list',
                      //@ts-ignore
                      val && !Array.isArray(val) ? val.value : null
                    )
                  }
                  options={organization.lists.map(l => ({
                    value: l._id,
                    label: l.label
                  }))}
                />
              </>
            )}
            <ButtonList align="right">
              <ButtonLink to="../profiler">Avbryt</ButtonLink>
              <Button
                type="submit"
                variant="primary"
                disabled={!hasMadeChanges}
              >
                {submitting ? 'Importerer…' : 'Importer'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Import;
