import styled from '../../util/styled';

interface LinkProps {
  color?: string;
}

const ExternalLink = styled.a<LinkProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export { ExternalLink };
