//@ts-ignore Please someone type this package
import Bee from 'bee-plugin';
import api from './api';
import { Organization, EmailTemplate } from '../types/apiResponses';

interface Template {
  [key: string]: any;
}

interface Emailer {
  html: string;
  json: string;
}

interface Opts {
  onSave: (json: string, html: string) => any;
  onAutoSave: (json: string, html: string) => any;
  onSend: (html: string) => any;
}

async function initializeBee(
  organization: Organization,
  template: Emailer,
  opts: Opts
) {
  try {
    const token = await api<{ token: string }>({
      endpoint: 'organizations/' + organization._id + '/tools/bee-token'
    });

    if ('access_token' in token) {
      let templateToUse: Template;

      if (template.json) {
        templateToUse = JSON.parse(template.json);
      } else {
        const beeFreeTemplate = await fetch(
          'https://rsrc.getbee.io/api/templates/base-m-bee'
        );

        templateToUse = await beeFreeTemplate.json();
      }

      const instance = new Bee(token);

      instance.start(
        {
          uid: organization._id,
          container: 'beeContainer',
          language: 'nb-NO',
          autosave: 30,
          mergeTags: [
            {
              name: 'Avmeldingslenke',
              value: '<a href="print{type:unsubscribe;}">Meld deg av</a>'
            },
            {
              name: 'Navn',
              value: 'print{type:name;}'
            },
            {
              name: 'E-postadresse',
              value: 'print{type:email;}'
            },
            {
              name: 'Hvisom–dersom',
              value: '{{if:a=b}}Hvisom{{else}}Dersom{{endif}}'
            },
            ...organization.variables.map(v => ({
              name: `Variabel: ${v.label}`,
              value: `print{type:variable;ref:${v.label};}`
            })),
            ...organization.dataPoints.map(d => ({
              name: `Karakteristikk: ${d.label}`,
              value: `print{type:dataPoint;ref:${d.label};}`
            }))
          ],
          //@ts-ignore Plz someone less lazy than me type
          onSave: (json, html) => {
            opts.onSave(json as string, html as string);
          },
          //@ts-ignore Plz someone less lazy than me type
          onAutoSave: (json, html) => {
            opts.onAutoSave(json as string, html as string);
          },
          //@ts-ignore Plz someone less lazy than me type
          onSaveAsTemplate: json => {
            console.log('onSaveAsTemplate', json);
          },
          //@ts-ignore Plz someone less lazy than me type
          onSend: html => {
            opts.onSend(html);
          },
          //@ts-ignore Plz someone less lazy than me type
          onError: error => {
            console.error(error);
          }
        },
        templateToUse
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export default initializeBee;
