import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Campaigns/List';
import Create from '../views/Campaigns/Create';
import Menu, { MenuItem } from '../components/Menu';
import Icon from '../components/Icon';

export function renderCampaignsSidebarContent(baseUrl: string) {
  return (
    <Menu>
      <MenuItem exact to={baseUrl + '/kampanjer'} icon={<Icon>alarm_on</Icon>}>
        Aktive
      </MenuItem>
      <MenuItem
        exact
        to={baseUrl + '/kampanjer/kommende'}
        icon={<Icon>arrow_right_alt</Icon>}
      >
        Kommende
      </MenuItem>
      <MenuItem
        to={baseUrl + '/kampanjer/inaktive'}
        icon={<Icon>alarm_off</Icon>}
      >
        Inaktive
      </MenuItem>
    </Menu>
  );
}

export default function campaignsRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/kommende"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/inaktive"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/kampanjer/:campaignID/*"
        component={Create}
      />
    </Switch>
  );
}
