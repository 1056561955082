import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Personalization/List';
import Create from '../views/Personalization/Create';

export default function personalizationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/personalisering"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/personalisering/:personalizationID"
        component={Create}
      />
    </Switch>
  );
}
