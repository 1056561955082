import FileSaver from 'file-saver';
import api, { ApiOpts } from './api';

interface Opts extends ApiOpts {
  fileName: string;
  onDownloading?: () => any;
  onDownloaded?: () => any;
}

async function download(opts: Opts) {
  if (typeof opts.onDownloading === 'function') {
    opts.onDownloading();
  }

  const resource = (await api({ ...opts, noJSON: true })) as Response;

  FileSaver.saveAs(await resource.blob(), opts.fileName);

  if (typeof opts.onDownloaded === 'function') {
    opts.onDownloaded();
  }
}

export default download;
