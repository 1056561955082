import {
  TriggerType,
  SelectionLogic,
  Target,
  TargetLogic,
  MailerPriority,
  VariableType,
  ThreadStatus,
  DurationTypes,
  LaneItemType,
  Services,
  RobotTriggerType,
  RobotActionType,
  RobotSplitType,
  RobotTriggerTimingType,
  ReceiverType,
  ReceiverInstructionType,
  ReceiverSetType,
  Permissions,
  ActionSource,
  DefaultConsents,
  MessageType,
  SMSProviders
} from '../constants/enums';

// ludens-sso
export interface SSOUser {
  id: string;
  name: string;
  email: string;
  profileImage: string;
}

export interface AccessResponse {
  data: SSOUser;
  accessToken: {
    token: string;
    expires: number;
  };
  refreshToken: {
    token: string;
    user: string;
    created: string;
    expires: string;
  };
}

// plz
export interface User {
  _id: string;
  name: string;
  email: string;
  profileImage: string;
  phone?: string | null;
  permissions?: Permissions;
}

export interface Member {
  _id: string;
  user: string;
  permissions: Permissions;
  userData?: User;
}

export interface DataPoint {
  _id: string;
  type: string;
  label: string;
  categories: string[];
  defaultValue: any;
  consents: string[];
  picks: string[] | number[];
  app?: string;
  appRef?: string;
}

export interface DataPointCategory {
  _id: string;
  label: string;
}

export interface List {
  _id: string;
  label: string;
  description: string | null;
  user: string | null;
}

export interface VariableCondition {
  _id: string;
  rules: PathParameter[];
  value: unknown;
  __ref?: string;
}

export interface Variable {
  _id: string;
  label: string;
  type: VariableType;
  value: unknown;
  conditions: VariableCondition[];
}

export interface Group {
  _id: string;
  name: string;
  email: string | null;
  profileSelectionRules: PathParameter[];
  user: string | null;
  members?: string[];
}

export interface OrganizationSettings {
  defaultInterestWeight: number | null;
  aggregatorScoreCollection: number | null;
  aggregatorScoreMessage: number | null;
  aggregatorScoreDataPoint: number | null;
  aggregatorScoreDataPointInterest: number | null;
}

export interface OrganizationApp {
  _id: string;
  app: string;
  appData?: App;
  installedBy: string | null;
  installedDate: string;
}

export interface SMSProviderSettings {
  type: SMSProviders;
}

export interface Organization {
  _id: string;
  name: string;
  groups: Group[];
  members: Member[];
  dataPoints: DataPoint[];
  dataPointCategories: DataPointCategory[];
  lists: List[];
  variables: Variable[];
  notificationEmail: string | null;
  invites: Invite[];
  logo?: string | null;
  theme?: {
    color: string | null;
  };
  consents: string[];
  settings?: OrganizationSettings;
  apps: OrganizationApp[];
  smsProviderSettings?: SMSProviderSettings;
}

export interface AppActionDefinition {
  label: string | null;
  hide: boolean;
  defaultValue: string | number | null;
  pickValues: string[];
}

export interface AppAction {
  _id: string;
  name: string;
  namePlural: string;
  nameAction: string;
  sourceLabel: string | null;
  settings: AppActionSettings;
  category: AppActionDefinition;
  action: AppActionDefinition;
  label: AppActionDefinition;
  value: AppActionDefinition;
}

export interface AppActionSettings {
  showInMainMenu: boolean;
  handleable: boolean;
}

export interface AppSettings {
  showOnContactPage: boolean;
}

export interface AppPersonalization {
  _id: string;
  name: string;
  js: string | null;
  html: string | null;
  cssUrl: string | null;
}

export interface App {
  _id: string;
  name: string;
  slug: string;
  logo: string | null;
  dataPoints: DataPoint[];
  actions: AppAction[];
  personalizations: AppPersonalization[];
  settings: AppSettings;
}

export interface Profile {
  _id: string;
  dataPoint: string;
  value: any;
  programmatic: boolean;
}

export interface Log extends Profile {
  submitDate: string;
}

export interface Note {
  _id: string;
  created: Date;
  lastUpdated: Date;
  originalAuthor: Contact | null;
  note: string;
  parsedNote?: string;
  notification: boolean;
  notificationToHandlers?: boolean;
  notificationRef?: string;
  notificationSchedule?: string;
}

export interface AggregatorScoreHistoryItem {
  _id: string;
  score: number;
  timestamp: Date;
}

export interface Address {
  line1: string | null;
  line2: string | null;
  zip: string | null;
  county: string | null;
  city: string | null;
  country: string | null;
}

export interface Service {
  service: Services | string | null;
  url: string | null;
}

export interface SourceLogItem {
  _id: string;
  firstParty: Service;
  thirdParty: Service;
  timestamp: string;
}

export interface Source {
  _id: string;
  service: Services | string;
  isFirstParty: boolean;
  used: number;
  lastUse: string;
}

export interface Acquisition {
  type: string;
  firstParty: Service;
  thirdParty: Service;
  creationTimestamp: Date;
  acquisitionTimestamp: Date | null;
  acquisitionLabel?: string;
}

export interface ExternalIdentifier {
  _id: string;
  __ref?: string;
  service: string;
  identifier: string;
}

export interface Action {
  _id: string;
  organization: string;
  contact: string | Contact;
  category: string;
  action: string;
  label: string | null;
  value: number;
  handleable: boolean;
  timestamp: Date;
  source: ActionSource;
  sourceRef?: string;
  user?: string;
  app?: string;
  appRef?: string;
  handled?: boolean;
  handledBy?: string;
  handledTimestamp?: string;
  comment?: string;
}

export interface PopulatedHandler {
  _id: string;
  name: string;
}

export interface Consent {
  _id: string;
  __type: DefaultConsents | string;
  signed: Date;
}

export enum ConsentLogType {
  Consent = 'consent',
  Withdrawal = 'withdrawal'
}

export interface ConsentLog extends Consent {
  _id: string;
  __type: ConsentLogType;
  consentType: string;
  logDate: Date;
  referrer?: string;
}

export interface ContactLink {
  _id: string;
  contact: string;
}

export interface PopulatedContactLink {
  _id: string;
  contact: Contact;
}

export interface Contact {
  _id: string;
  email?: string;
  organization: string;
  externalIdentifiers: ExternalIdentifier[];
  active: boolean;
  consents: Consent[];
  consentLog: ConsentLog[];
  phone?: string | null;
  address: Address;
  name: string;
  randomName: boolean;
  lists: string[];
  profile: Profile[];
  log: Log[];
  notes: Note[];
  threadCount?: number;
  lastAction: string | null;
  lastHandlerAction: string | null;
  lastAdminAction: string | null;
  aggregatorScore: number;
  aggregatorScoreHistory: AggregatorScoreHistoryItem[];
  sourceLog: SourceLogItem[];
  sources: Source[];
  acquisition: Acquisition;
  handlers: string[];
  links: ContactLink[];
  populatedLinks?: PopulatedContactLink[];
  populatedHandlers?: PopulatedHandler[];
}

export interface SelectFromRule {
  _id: string;
  value: any;
  limit?: number;
}

export interface CollectorDataPointReference {
  _id: string;
  ref: string;
  selectFrom: SelectFromRule[];
  required: boolean;
  data?: DataPoint;
}

export interface Collector {
  _id: string;
  name: string;
  limit: number | null;
  receipt?: string;
  receiptJson?: string;
  receiptPlain?: string;
  receiptSubject?: string;
  dataPoints: CollectorDataPointReference[];
}

export interface Campaign {
  _id: string;
  name: string;
  organization: string;
  group: string | null;
  notifyOnCollection: boolean;
  createdAt: string;
  start: string;
  end: string;
  collectors: Collector[];
}

export interface Collection {
  _id: string;
  organization: string;
  campaign: string;
  collector: string;
  contact?: Contact;
  date: string;
  referer: string | null;
  data: Profile[];
}

export interface Duration {
  durationType: DurationTypes;
  value: number;
}

export interface Trigger {
  _id: string;
  type: TriggerType;
  key: string | null;
  value: string | null;
  scheduleDate: string | null;
  delay: Duration;
  fulfilled: boolean;
}

export interface PathParameter {
  _id: string;
  selectionLogic?: SelectionLogic;
  target: Target;
  targetLogic: TargetLogic;
  targetRef: string | null;
  value: any;
  __ref?: string;
}

export interface Path {
  _id: string;
  name: string;
  parameters: PathParameter[];
  email: Email;
  type: MessageType;
}

export interface Automation {
  _id: string;
  name: string;
  organization: string;
  active: boolean;
  triggers: Trigger[];
  paths: Path[];
}

export interface Email {
  from?: string;
  to?: string;
  subject: string;
  plain: string;
  html: string;
  json: string;
  templateRef: string | null;
  preview?: string;
}

export interface Mailer {
  _id: string;
  organization: string;
  contactRef: string | null;
  automationRef: string | null;
  triggerRef: string | null;
  pathRef: string | null;
  to: string;
  scheduleDate: Date;
  sent: boolean;
  sentDate: Date | null;
  subject: string;
  from: string;
  plain: string;
  html: string | null;
  priority: MailerPriority;
  opened: boolean;
  openDate: Date | null;
}

interface UserRef {
  userRef: string;
  isContact: boolean;
  populatedName?: string;
  aggregatorScore?: number;
}

export interface Participant extends UserRef {
  _id: string;
}

export interface UnreadCount extends UserRef {
  _id: string;
  count: number;
}

export interface ReadBy extends UserRef {
  _id: string;
  timestamp: string;
}

export interface Message extends UserRef {
  _id: string;
  message: string;
  timestamp: string;
  readBy: ReadBy[];
}

export interface Thread {
  _id: string;
  organization: string;
  status: ThreadStatus;
  participants: Participant[];
  lastMessage: string | null;
  messageCount: number;
  unreadCount: UnreadCount[];
  title: string;
  message?: string;
  messages: Message[];
}

export interface Thread {
  _id: string;
}

export interface ThreadCount {
  count: number;
}

export interface Invite {
  _id: string;
  email: string;
  permissions: Permissions;
}

export interface Personalization {
  _id: string;
  organization: string;
  app: string | null;
  appRef: string | null;
  name: string;
  active: boolean;
  forRobot: boolean;
  parameters: PathParameter[];
  js: string | null;
  html: string | null;
  htmlQuerySelector: string | null;
  htmlPlacement: string | null;
  appData?: {
    name: string;
    slug: string;
  };
}

export interface EmailTemplate {
  _id: string;
  organization: string;
  name: string;
  html: string;
  json: string;
  preview: string;
}

export interface LaneItem {
  _id: string;
  type: LaneItemType;
  ref: string;
  pos: number;
  contact?: Contact;
}

export interface Lane {
  _id: string;
  name: string;
  items: LaneItem[];
}

export interface Board {
  _id: string;
  name: string;
  organization: string;
  user: string;
  lanes: Lane[];
}

export interface RobotInstructions {
  [key: string]: any;
}

export interface RobotPathRef {
  _id: string;
  label: string;
}

export interface RobotSplit {
  __type: RobotSplitType;
  instructions: RobotInstructions;
  paths: RobotPathRef[];
}

export interface RobotAction {
  _id: string;
  ref?: string;
  __type: RobotActionType;
  instructions: RobotInstructions;
}

export interface RobotPath {
  _id: string;
  name: string;
  parent: string | null;
  actions: RobotAction[];
  split: RobotSplit;
  hits: number;
}

export interface RobotTrigger {
  __type: RobotTriggerType;
  instructions?: RobotInstructions;
  timingType: RobotTriggerTimingType;
  timingValue: number;
  timingDurationType: string | null;
  timingDurationValue: number | null;
}

export interface MergedPath extends RobotPath {
  mergedPaths: MergedPath[];
  parentData?: {
    ref: string;
    label: string;
  };
}

export interface Robot {
  _id: string;
  name: string;
  organization: string;
  active: boolean;
  trigger: RobotTrigger;
  triggered: boolean;
  hits: number;
  paths: RobotPath[];
  mergedPaths: MergedPath[];
}

export interface ReceiverInstruction {
  _id: string;
  __ref?: string;
  target: string;
  __type: ReceiverInstructionType;
  setType: ReceiverSetType;
  value: string | null;
  path: string | null;
}

export interface RunIfInstruction {
  _id: string;
  __ref?: string;
  logic: SelectionLogic;
  query: TargetLogic;
  path: string;
  value: string | null;
}

export interface ReceiverJob {
  _id: string;
  __ref?: string;
  __type: ReceiverType | null;
  meta: string | null;
  instructions: ReceiverInstruction[];
}

export interface Receiver {
  _id: string;
  organization: string;
  active: boolean;
  name: string;
  runIf: RunIfInstruction[];
  jobs: ReceiverJob[];
  externalIdentifierService: string | null;
  externalIdentifierPath: string | null;
  successMessage: string | null;
  failMessage: string | null;
  token: string | null;
  triggers: number;
  successfulTriggers: number;
  failedTriggers: number;
  url?: string;
  basicAuthUrl?: string;
}

export interface EventDateParticipant {
  _id: string;
  contact: Contact;
  timestamp: string;
}

export interface EventDate {
  _id: string;
  name: string;
  start?: string;
  end?: string;
  slots?: number;
  participants: EventDateParticipant[];
}

export interface Event {
  _id: string;
  name: string;
  organization: string;
  dates: EventDate[];
}

export interface AuthResponse {
  code: number;
  message: string;
}

export interface ApiKey {
  _id: string;
  permissions: Permissions;
  apiKey: string;
  organization: string;
  timestamp: string;
}
