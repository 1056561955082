import React, { useContext, Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactSelect from 'react-select';
import uuid from 'uuid/v4';
import { Contact, ExternalIdentifier } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import { Container, Flex, FlexKid, Spacer } from '../../helpers/Layout';
import Text from '../../components/Text';
import theme, { mobileThreshold } from '../../constants/theme';
import Form, { Label, Input, Select } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { OrganizationContext } from '../../context/Organization';
import Expander from '../../components/Expander';
import categorizeDataPoints from '../../util/categorizeDataPoints';
import renderField from '../../util/renderField';
import { Grid } from '@material-ui/core';
import { CardExternalLink, CardContent, CardIcon } from '../../components/Card';
import { UiContext } from '../../context/Ui';
import Icon from '../../components/Icon';
import { AppsContext } from '../../context/Apps';
import Opacity from '../../components/Opacity';
import { defaultConsents } from '../../constants/localization';
import getWindowWidth from '../../util/getWindowWidth';
import useApi from '../../hooks/useApi';

const windowWidth = getWindowWidth();

interface CreateParams {
  organizationID: string;
  contactID: string;
}

interface CrudContact extends Contact {
  [key: string]: any;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organization = useContext(OrganizationContext).data;
  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const isUpdate = contactID !== undefined;
  const baseEndpoint = 'organizations/' + organizationID + '/contacts';
  const endpoint = isUpdate ? baseEndpoint + '/' + contactID : baseEndpoint;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;
  const apps = useContext(AppsContext).data;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<CrudContact>(
    {
      email: null,
      phone: null,
      name: null,
      address: {
        line1: null,
        line2: null,
        zip: null,
        county: null,
        city: null,
        country: null
      },
      lists: [],
      active: true,
      externalIdentifiers: [],
      handlers: [],
      populatedHandlers: [],
      consents: []
    },
    {
      onSuccess: contact => {
        if (isUpdate) {
          history.push('../' + contactID);
        } else {
          history.push('../profiler/' + (contact as Contact)._id);
        }
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate
        ? 'organizations/' + organizationID + '/contacts/' + contactID
        : undefined,
      prefillFn: contact => serializeProfile(contact as Contact)
    }
  );

  const contactCountOpts = {
    endpoint: 'organizations/' + organizationID + '/contacts/search/count'
  };

  const [contactCount, counting, count] = useApi<{
    total: number;
  }>({
    ...contactCountOpts,
    initialData: {
      total: 0
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} profil
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting} exposeChildren>
        <Container spacious hugTop>
          <Flex align="flex-start">
            <FlexKid maxWidth="40rem" flex={1}>
              <Form
                id="createContact"
                onSubmit={e => {
                  e.preventDefault();

                  if (contactCount.total > 0) {
                    alert(
                      'Det finnes allerede en profil med e-postadressen ' +
                        data.email +
                        '.'
                    );
                  } else {
                    submit();
                  }
                }}
              >
                <Label htmlFor="name">Navn</Label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Per Eksempel"
                  value={data.name}
                  onChange={e => setField('name', e.target.value)}
                />
                <BusyBoy busy={counting} exposeChildren>
                  <Label htmlFor="email">E-post</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="per@eksempel.no"
                    invalid={contactCount.total > 0}
                    value={data.email}
                    onChange={e => setField('email', e.target.value)}
                    onBlur={() =>
                      count({
                        ...contactCountOpts,
                        queryParams: {
                          email: data.email
                        }
                      })
                    }
                  />
                  {contactCount.total > 0 && (
                    <Text color={theme.colors.warning}>
                      Det finnes allerede en profil med denne e-postadressen!
                    </Text>
                  )}
                </BusyBoy>
                <Label htmlFor="phone">Telefonnummer</Label>
                <Input
                  id="phone"
                  type="text"
                  placeholder="999 99 999"
                  value={data.phone || ''}
                  onChange={e => setField('phone', e.target.value)}
                />
                {organization && organization.lists.length > 0 && (
                  <>
                    <Label htmlFor="lists">Lister</Label>
                    <ReactSelect
                      classNamePrefix="react-select"
                      placeholder="Vennligst velg…"
                      isMulti
                      value={data.lists
                        .filter(f =>
                          organization.lists.find(ll => ll._id === f)
                        )
                        .map(l => {
                          const ls = organization.lists.find(
                            ll => ll._id === l
                          );

                          return {
                            value: l,
                            label: ls ? ls.label : ''
                          };
                        })}
                      onChange={val =>
                        setField(
                          'lists',
                          Array.isArray(val) ? val.map(v => v.value) : []
                        )
                      }
                      options={organization.lists.map(l => ({
                        value: l._id,
                        label: l.label
                      }))}
                    />
                  </>
                )}
                <Label htmlFor="active">
                  Aktiv (endre denne kun dersom du har tillatelse fra kontakten)
                </Label>
                <Select
                  id="active"
                  value={data.active ? 'true' : 'false'}
                  onChange={e => setField('active', e.target.value === 'true')}
                >
                  <option value="true">Ja</option>
                  <option value="false">Nei</option>
                </Select>
                <Expander label="Adresse">
                  <>
                    <Label htmlFor="addressLine1">Gateadresse</Label>
                    <Input
                      id="addressLine1"
                      type="text"
                      placeholder="Norgeveien 1"
                      value={data.address.line1 || ''}
                      onChange={e =>
                        setField('address', {
                          ...data.address,
                          line1: e.target.value
                        })
                      }
                    />
                    <Label htmlFor="zip">Postnummer</Label>
                    <Input
                      id="zip"
                      type="text"
                      placeholder="0001"
                      value={data.address.zip || ''}
                      onChange={e =>
                        setField('address', {
                          ...data.address,
                          zip: e.target.value
                        })
                      }
                    />
                    <Label htmlFor="city">Sted</Label>
                    <Input
                      id="city"
                      type="text"
                      placeholder="Kardemomme By"
                      value={data.address.city || ''}
                      onChange={e =>
                        setField('address', {
                          ...data.address,
                          city: e.target.value
                        })
                      }
                    />
                    <Label htmlFor="county">Fylke</Label>
                    <Input
                      id="county"
                      type="text"
                      placeholder="Vest-Agder"
                      value={data.address.county || ''}
                      onChange={e =>
                        setField('address', {
                          ...data.address,
                          county: e.target.value
                        })
                      }
                    />
                  </>
                </Expander>
                {organization &&
                  organization.dataPoints.length > 0 &&
                  categorizeDataPoints(organization, apps).map(c =>
                    c.dataPoints.length > 0 ? (
                      <Expander key={c._id} label={c.label}>
                        {c.dataPoints.map((d, k) => {
                          const value = data[d._id];
                          let hasConsent = true;

                          if (d.consents.length > 0) {
                            hasConsent = d.consents.every(cc =>
                              data.consents.some(ccc => cc === ccc.__type)
                            );
                          }

                          if (!hasConsent) {
                            return (
                              <Fragment key={d._id}>
                                <Label htmlFor={d._id} gutterTop={k !== 0}>
                                  {d.label}
                                </Label>
                                <Opacity>
                                  <Input
                                    id={d._id}
                                    type="text"
                                    disabled
                                    value={value}
                                  />
                                </Opacity>
                                <Spacer height="0.25em" />
                                <Text color={theme.colors.warning}>
                                  Mangler følgende samtykke(r) for å endre:{' '}
                                  {d.consents
                                    .filter(
                                      cc =>
                                        !data.consents.some(
                                          ccc => ccc.__type === cc
                                        )
                                    )
                                    .map(cc =>
                                      cc in defaultConsents
                                        ? defaultConsents[cc]
                                        : cc
                                    )
                                    .join(', ')}
                                </Text>
                              </Fragment>
                            );
                          }

                          return (
                            <Fragment key={d._id}>
                              <Label htmlFor={d._id} gutterTop={k !== 0}>
                                {d.label}
                              </Label>
                              {renderField(d, value, setField, undefined, true)}
                            </Fragment>
                          );
                        })}
                      </Expander>
                    ) : null
                  )}
                <Expander
                  label={`Tredjepartsidentiteter (${data.externalIdentifiers.length})`}
                >
                  <Grid container spacing={24}>
                    {data.externalIdentifiers.map(i => (
                      <Grid key={i._id} item xs={12}>
                        <CardExternalLink
                          href="#"
                          clickable
                          horizontal
                          onClick={e => {
                            e.preventDefault();
                            spawnModal(
                              <CreateExternalIdentifierModal
                                defaultData={i}
                                onCreate={externalIdentifier => {
                                  const index = data.externalIdentifiers.findIndex(
                                    idf => idf._id === externalIdentifier._id
                                  );

                                  if (index !== -1) {
                                    const copy = [...data.externalIdentifiers];
                                    copy[index] = externalIdentifier;

                                    setField('externalIdentifiers', copy);
                                  }
                                }}
                                onDelete={ref => {
                                  setField(
                                    'externalIdentifiers',
                                    data.externalIdentifiers.filter(idf =>
                                      idf.__ref
                                        ? idf.__ref !== ref
                                        : idf._id !== ref
                                    )
                                  );
                                }}
                                close={despawnModal}
                              />,
                              'createExternalIdentifier'
                            );
                          }}
                        >
                          <CardContent tight>
                            <Text>{i.identifier}</Text>
                            <Text variant="subheading">{i.service}</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        primary={data.externalIdentifiers.length < 1}
                        secondary={data.externalIdentifiers.length > 0}
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreateExternalIdentifierModal
                              onCreate={externalIdentifier => {
                                setField('externalIdentifiers', [
                                  ...data.externalIdentifiers,
                                  externalIdentifier
                                ]);
                              }}
                              onDelete={ref => {
                                setField(
                                  'externalIdentifiers',
                                  data.externalIdentifiers.filter(
                                    i => i._id !== ref
                                  )
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createExternalIdentifier'
                          );
                        }}
                      >
                        <CardContent tight>
                          <Text>Legg til tredjepartsidentitet</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>add</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  </Grid>
                </Expander>
                <ButtonList align="right">
                  <ButtonLink to={isUpdate ? '../' + contactID : '../profiler'}>
                    Avbryt
                  </ButtonLink>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                    form="createContact"
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </ButtonList>
              </Form>
            </FlexKid>
            {windowWidth > mobileThreshold && (
              <FlexKid
                width="14rem"
                spaceLeft
                spacious
                sticky
                topOffset={`calc(-40px + ${theme.layout.spacing.normal})`}
              >
                <Spacer height="40px" />
                <ButtonList vertical>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                    form="createContact"
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                  <ButtonLink to={isUpdate ? '../' + contactID : '../profiler'}>
                    Avbryt
                  </ButtonLink>
                </ButtonList>
              </FlexKid>
            )}
          </Flex>
        </Container>
      </BusyBoy>
    </Content>
  );
}

interface CreateExternalIdentifierModalProps {
  close: (id: string) => any;
  onCreate: (data: ExternalIdentifier) => any;
  onDelete?: (ref: string) => any;
  defaultData?: ExternalIdentifier;
}

const defaultExternalIdentifier: ExternalIdentifier = {
  _id: '',
  __ref: '',
  service: '',
  identifier: ''
};

function CreateExternalIdentifierModal(
  props: CreateExternalIdentifierModalProps
) {
  const { defaultData, close, onCreate, onDelete } = props;

  const [externalIdentifier, setExternalIdentifier] = useState(
    defaultData ? defaultData : { ...defaultExternalIdentifier, __ref: uuid() }
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete externalIdentifier._id;
          }

          onCreate(externalIdentifier);
          close('createExternalIdentifier');
        }}
      >
        <Label htmlFor="service">Tjeneste *</Label>
        <Input
          id="service"
          type="text"
          required
          value={externalIdentifier.service}
          placeholder="GitHub, Facebook etc"
          onChange={e =>
            setExternalIdentifier({
              ...externalIdentifier,
              service: e.target.value
            })
          }
        />
        <Label htmlFor="identifier">Brukernavn *</Label>
        <Input
          id="identifier"
          type="text"
          required
          value={externalIdentifier.identifier}
          placeholder="l33tUzr1337"
          onChange={e =>
            setExternalIdentifier({
              ...externalIdentifier,
              identifier: e.target.value
            })
          }
        />
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createExternalIdentifier');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createExternalIdentifier');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

function serializeProfile(contact: Contact): CrudContact {
  const crudContact: CrudContact = contact;

  for (let i = 0; i < contact.profile.length; i++) {
    const profile = contact.profile[i];

    crudContact[profile.dataPoint] = profile.value;
  }

  return crudContact;
}

export default Create;
