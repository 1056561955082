import * as React from 'react';
import styled, { css } from '../../util/styled';
import Icon from '../Icon';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 calc(${props => props.theme.layout.spacing.small} / 3);

  i {
    font-size: 1.125rem;
    width: 1.125rem;
    height: 1.125rem;
    color: ${props => props.theme.colors.primary};
  }
`;

const StyledNotifier = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  font-size: 0.75em;
  display: flex;
  top: -0.5rem;
  right: -0.5rem;
  background: transparent;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

interface Props {
  count: number;
  icon: string;
  a11yTitle?: string;
}

const IconNotifier: React.FunctionComponent<Props> = ({
  count,
  icon,
  a11yTitle
}) => {
  return (
    <Wrapper title={a11yTitle}>
      <Icon>{icon}</Icon>
      <StyledNotifier>{count}</StyledNotifier>
    </Wrapper>
  );
};

export default IconNotifier;
