import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Organization as IOrganization } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderContactsSidebarContent } from '../../routing/Contacts';
import Menu, { MenuItem, MenuSplitter } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Doorman from '../../components/Doorman';
import { UserContext } from '../../context/User';
import hasPermissions from '../../util/hasPermissions';
import { permissionsAdmin } from '../../util/prefilledPermissions';

interface ListsParams {
  organizationID: string;
}

function Lists(props: RouteComponentProps<ListsParams>) {
  const organizationID = props.match.params.organizationID;
  const userContext = React.useContext(UserContext);
  const [organization, loading] = useApi<IOrganization | null>({
    endpoint: 'organizations/' + organizationID,
    initialData: null,
    fetchOnMount: true
  });

  const isAdmin = organization
    ? hasPermissions(organization, userContext.data.id, permissionsAdmin)
    : false;

  const baseUrl = '/organisasjoner/' + organizationID;

  const publicLists =
    organization && organization.lists.filter(list => !list.user);
  const privateLists =
    organization &&
    organization.lists.filter(list => list.user === userContext.data.id);

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Lister
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactsSidebarContent(baseUrl)}
          <Doorman type="handler">
            <>
              <MenuSplitter />
              <Menu>
                <MenuItem to="lister/opprett" icon={<Icon>add</Icon>} primary>
                  Ny liste
                </MenuItem>
                {organization && organization.lists.length < 1 && (
                  <ShowOff>
                    Lag din første liste ved å trykke på knappen over!
                  </ShowOff>
                )}
              </Menu>
            </>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <Text element="h2" variant="headline" bottomGutter>
              Offentlige lister
            </Text>
            <BusyBoy busy={loading}>
              {publicLists && publicLists.length < 1 && (
                <EmptyState>Ingen offenlige lister!</EmptyState>
              )}
              <Grid container spacing={24}>
                {publicLists &&
                  publicLists.map(l => (
                    <Grid key={l._id} item xs={12} md={12}>
                      <CardLink
                        to={isAdmin ? 'lister/' + l._id : '#'}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{l.label}</Text>
                          {l.description && (
                            <Text variant="subheading">{l.description}</Text>
                          )}
                        </CardContent>
                        {isAdmin && (
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        )}
                      </CardLink>
                    </Grid>
                  ))}
              </Grid>
              <Text element="h2" variant="headline" bottomGutter topGutter>
                Private lister
              </Text>
              {privateLists && privateLists.length < 1 && (
                <EmptyState>Ingen private lister!</EmptyState>
              )}
              <Grid container spacing={24}>
                {privateLists &&
                  privateLists.map(l => (
                    <Grid key={l._id} item xs={12} md={12}>
                      <CardLink to={'lister/' + l._id} horizontal clickable>
                        <CardContent tight>
                          <Text>{l.label}</Text>
                          {l.description && (
                            <Text variant="subheading">{l.description}</Text>
                          )}
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
              </Grid>
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default Lists;
