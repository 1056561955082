import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, Link } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line } from 'recharts';
import Grid from '@material-ui/core/Grid';
import moment from '../../util/moment';
import theme from '../../constants/theme';
import {
  Contact,
  Organization,
  Member,
  ContactLink
} from '../../types/apiResponses';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import BusyBoy from '../../helpers/BusyBoy';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Card, {
  CardContent,
  CardIcon,
  CardExternalLink,
  CardLink
} from '../../components/Card';
import useApi from '../../hooks/useApi';
import {
  OrganizationContext,
  OrganizationProvider
} from '../../context/Organization';
import categorizeDataPoints from '../../util/categorizeDataPoints';
import Opacity from '../../components/Opacity';
import Menu, {
  MenuItem,
  MenuItemButton,
  MenuSplitter
} from '../../components/Menu';
import Icon from '../../components/Icon';
import history from '../../util/history';
import translateValue from '../../util/translateValue';
import Doorman from '../../components/Doorman';
import AggregatorScore from '../../components/AggregatorScore';
import renderAddress from '../../util/renderAddress';
import getAddress from '../../util/getAddress';
import Service from '../../components/Service';
import localize from '../../util/localize';
import {
  acquisitionTypes,
  defaultConsents
} from '../../constants/localization';
import { ContactContext } from '../../context/Contact';
import { AppsContext, AppsProvider } from '../../context/Apps';
import { UiContext } from '../../context/Ui';
import Form, { Label, Input } from '../../components/Form';
import Downshift from 'downshift';
import List, { ListItem } from '../../components/List';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../components/Button';
import getContactName from '../../util/getContactName';
import SearchAndSelect from '../../components/SearchAndSelect';
import ShowPrint from './ShowPrint';
import { ThemeProvider } from '../../util/styled';

interface CreateParams {
  organizationID: string;
  contactID: string;
}

function Show(props: RouteComponentProps<CreateParams>) {
  const organization = useContext(OrganizationContext).data;
  const members = useContext(OrganizationContext).members;

  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const endpoint = 'organizations/' + organizationID + '/contacts/' + contactID;
  const baseUrl = '/organisasjoner/' + organizationID;

  const contactContext = useContext(ContactContext);
  const apps = useContext(AppsContext).data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [contact, loading, fetch, hasFetched] = useApi<Contact | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  const [_, deleting, destroy] = useApi<Contact | null>({
    method: 'DELETE',
    endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../profiler');
    }
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3" bottomGutter>
          {contactContext.data ? nameWithSleep(contactContext.data) : 'Laster…'}
        </Text>
        <Text element="h2" variant="title">
          Profil
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(baseUrl, contactID, apps, contact)}
          <Doorman type="handler">
            <MenuSplitter />
            <Menu>
              <MenuItem
                to={contactID + '/rediger'}
                icon={<Icon>edit</Icon>}
                primary
              >
                Rediger profil
              </MenuItem>
            </Menu>
          </Doorman>
          <MenuSplitter />
          {!!contact && (
            <Menu>
              <MenuItem
                to="#"
                icon={<Icon>print</Icon>}
                primary
                onClick={() => {
                  const printWindow = window.open('', 'PrintOfContact');

                  if (printWindow) {
                    const doc = printWindow.document;
                    doc.write('<div id="printApp"></div>');

                    // Reactception
                    ReactDOM.render(
                      <ThemeProvider theme={theme}>
                        <OrganizationProvider>
                          <AppsProvider>
                            <ShowPrint
                              contact={contact}
                              organization={organization}
                              apps={apps}
                              doc={doc}
                            />
                          </AppsProvider>
                        </OrganizationProvider>
                      </ThemeProvider>,
                      doc.getElementById('printApp')
                    );
                  }
                }}
              >
                Printversjon
              </MenuItem>
            </Menu>
          )}
          <Doorman type="writer">
            <MenuSplitter />
            <Menu>
              <MenuItemButton
                href="#"
                onClick={e => {
                  e.preventDefault();
                  destroy();
                }}
                icon={<Icon>delete_outline</Icon>}
                warning
              >
                Slett profil
              </MenuItemButton>
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <BusyBoy busy={loading || deleting} exposeChildren={hasFetched}>
            {contact && organization && (
              <Container spacious>
                <Grid container spacing={24} direction="column">
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Profilskår™
                    </Text>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <AggregatorScore
                          score={contact.aggregatorScore}
                          inverted
                        />
                      </Grid>
                      {contact.aggregatorScoreHistory.length > 1 && (
                        <Grid item xs={12}>
                          <ResponsiveContainer height={80}>
                            <LineChart
                              data={contact.aggregatorScoreHistory.map(h => ({
                                score: h.score,
                                timestamp: moment(h.timestamp).format('D.M.YY')
                              }))}
                            >
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0%"
                                  y1="0%"
                                  x2="0%"
                                  y2="100%"
                                >
                                  {renderStops(
                                    Math.min(
                                      ...contact.aggregatorScoreHistory.map(
                                        h => h.score
                                      )
                                    ),
                                    Math.max(
                                      ...contact.aggregatorScoreHistory.map(
                                        h => h.score
                                      )
                                    )
                                  )}
                                </linearGradient>
                              </defs>
                              <Line
                                type="monotone"
                                dataKey="score"
                                dot={false}
                                activeDot={false}
                                strokeWidth={2}
                                stroke="url(#colorUv)"
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Saksbehandlere
                    </Text>
                    <Grid container spacing={24}>
                      {'populatedHandlers' in contact &&
                        contact.populatedHandlers!.map(h => (
                          <Grid key={h._id} item sm={4}>
                            <CardExternalLink
                              href="#"
                              clickable
                              onClick={e => {
                                e.preventDefault();

                                if (
                                  window.confirm(
                                    'Er du sikker på at du vil fjerne ' +
                                      h.name +
                                      ' som saksbehandler?'
                                  )
                                ) {
                                  fetch({
                                    endpoint: endpoint + '/handlers/' + h._id,
                                    method: 'DELETE'
                                  });
                                }
                              }}
                              horizontal
                            >
                              <CardContent tight>
                                <Text>{h.name}</Text>
                              </CardContent>
                              <CardIcon tight>
                                <Icon>delete</Icon>
                              </CardIcon>
                            </CardExternalLink>
                          </Grid>
                        ))}
                      <Grid item sm={4}>
                        <CardExternalLink
                          href="#"
                          horizontal
                          primary
                          clickable
                          onClick={e => {
                            e.preventDefault();

                            spawnModal(
                              <HandlerModal
                                organization={organization}
                                members={members}
                                filter={contact.handlers}
                                onCreate={handler => {
                                  fetch({
                                    endpoint: endpoint + '/handlers',
                                    method: 'POST',
                                    body: {
                                      userID: handler
                                    }
                                  });
                                }}
                                close={despawnModal}
                              />,
                              'handler'
                            );
                          }}
                        >
                          <CardContent tight>
                            <Text>Legg til saksbehandler</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>add</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Samtykker
                    </Text>
                    <Grid container spacing={24}>
                      {contact.consents.length ? (
                        contact.consents.map(c => {
                          const defaultConsent =
                            c.__type in defaultConsents
                              ? defaultConsents[c.__type]
                              : undefined;

                          return (
                            <Grid key={c._id} item sm={4}>
                              <Card horizontal>
                                <CardContent tight>
                                  <Text>{defaultConsent || c.__type}</Text>
                                  <Text variant="subheading">
                                    Siden {moment(c.signed).format('D.M.YYYY')}
                                  </Text>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item sm={4}>
                          <Opacity>
                            <Card horizontal>
                              <CardContent tight>
                                <Text>INGEN</Text>
                              </CardContent>
                            </Card>
                          </Opacity>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Basisprofil
                    </Text>
                    <Grid container spacing={24}>
                      <Grid item xs={12} md={4}>
                        <Opacity disable={contact.email !== null}>
                          <Card horizontal>
                            <CardContent tight>
                              <Text>
                                {contact.email ? contact.email : 'INGEN'}
                              </Text>
                              <Text variant="subheading">E-post</Text>
                            </CardContent>
                          </Card>
                        </Opacity>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Opacity disable={contact.phone !== null}>
                          <Card horizontal>
                            <CardContent tight>
                              <Text>
                                {contact.phone ? contact.phone : 'INGEN'}
                              </Text>
                              <Text variant="subheading">Telefonnummer</Text>
                            </CardContent>
                          </Card>
                        </Opacity>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Opacity
                          disable={getAddress(contact.address).hasAddress}
                        >
                          <Card horizontal>
                            <CardContent tight>
                              <Text>{renderAddress(contact.address)}</Text>
                              <Text variant="subheading">Adresse</Text>
                            </CardContent>
                          </Card>
                        </Opacity>
                      </Grid>
                    </Grid>
                  </Grid>
                  {organization &&
                    organization.dataPoints.length > 0 &&
                    categorizeDataPoints(organization, apps, true).map(c =>
                      c.dataPoints.length > 0 ? (
                        <Grid key={c._id} item>
                          <Text variant="headline" bottomGutter>
                            {c.label}
                          </Text>
                          {c.dataPoints.length > 0 && (
                            <Grid container spacing={24}>
                              {c.dataPoints.map(d => {
                                const value = contact.profile.find(
                                  p => p.dataPoint === d._id
                                );

                                return (
                                  <Grid key={d._id} item xs={12} md={4}>
                                    <Opacity disable={value !== undefined}>
                                      <Card horizontal>
                                        <CardContent tight fill>
                                          <Text>
                                            {value || d.type === 'interest'
                                              ? translateValue(
                                                  d.type,
                                                  value ? value.value : 0,
                                                  true
                                                )
                                              : 'INGEN'}
                                          </Text>
                                          <Text variant="subheading" capitalize>
                                            {d.label}
                                          </Text>
                                        </CardContent>
                                      </Card>
                                    </Opacity>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </Grid>
                      ) : null
                    )}
                  {contact.lists.length > 0 && (
                    <Grid item>
                      <Text variant="headline" bottomGutter>
                        Lister
                      </Text>
                      <Grid container spacing={24}>
                        {contact.lists.map(l => {
                          const list = organization.lists.find(
                            ol => ol._id === l
                          );

                          if (!list) {
                            return null;
                          }

                          return (
                            <Grid key={l} item xs={12} md={4}>
                              <Card horizontal>
                                <CardContent tight>
                                  <Text>{list ? list.label : l}</Text>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                  {contact.profile.some(p => p.programmatic) && (
                    <Grid item>
                      <Text variant="headline" bottomGutter>
                        Programmatiske karakteristikker
                      </Text>
                      <Grid container spacing={24}>
                        {contact.profile
                          .filter(p => p.programmatic)
                          .map(p => (
                            <Grid key={p._id} item xs={12} md={4}>
                              <Card horizontal>
                                <CardContent tight fill>
                                  <Text>
                                    {translateValue('interest', p.value, true)}
                                  </Text>
                                  <Text variant="subheading" capitalize>
                                    {p.dataPoint}
                                  </Text>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  )}
                  {contact.externalIdentifiers.length > 0 && (
                    <Grid item>
                      <Text variant="headline" bottomGutter>
                        Tredjepartsidentiteter
                      </Text>
                      <Grid container spacing={24}>
                        {contact.externalIdentifiers.map(i => (
                          <Grid key={i._id} item xs={12} md={4}>
                            <Card horizontal>
                              <CardContent tight>
                                <Text>{i.identifier}</Text>
                                <Text variant="subheading">{i.service}</Text>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Opprinnelse
                    </Text>
                    <Grid container spacing={24}>
                      <Grid item xs={12} md={4}>
                        <Card horizontal>
                          <CardContent tight>
                            <Text>
                              {localize(
                                acquisitionTypes,
                                contact.acquisition.type
                              )}
                            </Text>
                            <Text variant="subheading">
                              {contact.acquisition.acquisitionLabel
                                ? contact.acquisition.acquisitionLabel
                                : 'Opprettelsesmåte'}
                            </Text>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card horizontal>
                          <CardContent tight>
                            <Text>
                              {moment(
                                contact.acquisition.creationTimestamp
                              ).format('D.M.YYYY [kl] HH:mm:ss')}
                            </Text>
                            <Text variant="subheading">
                              Opprettelsestidspunkt
                            </Text>
                          </CardContent>
                        </Card>
                      </Grid>
                      {contact.acquisition.firstParty.service && (
                        <Grid item xs={12} md={4}>
                          <Card horizontal>
                            <CardContent tight>
                              <Service
                                service={contact.acquisition.firstParty.service}
                              />
                              <Text variant="subheading">Førstepartskilde</Text>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {contact.acquisition.thirdParty.service && (
                        <Grid item xs={12} md={4}>
                          <Card horizontal>
                            <CardContent tight>
                              <Service
                                service={contact.acquisition.thirdParty.service}
                              />
                              <Text variant="subheading">Tredjepartskilde</Text>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {contact.sources.length > 0 && (
                    <Grid item>
                      <Text variant="headline" bottomGutter>
                        Kilder
                      </Text>
                      <Grid container spacing={24}>
                        {contact.sources
                          .sort((a, b) => {
                            if (a.used > b.used) {
                              return -1;
                            } else if (a.used < b.used) {
                              return 1;
                            }

                            return 0;
                          })
                          .map(s => (
                            <Grid key={s._id} item xs={12} md={4}>
                              <Card horizontal>
                                <CardContent tight>
                                  <Service
                                    service={s.service}
                                    firstParty={s.isFirstParty}
                                    showParty
                                  />
                                  <Text variant="subheading">
                                    Registrert {s.used} gang(er)
                                  </Text>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Text variant="headline" bottomGutter>
                      Lenkede profiler
                    </Text>
                    <Grid container spacing={24}>
                      {(contact.populatedLinks || [])
                        .filter(l => !!l.contact)
                        .map(l => (
                          <Grid key={l._id} item sm={4}>
                            <CardLink
                              to={
                                '/organisasjoner/' +
                                organization._id +
                                '/profiler/' +
                                l.contact._id
                              }
                              clickable
                              horizontal
                            >
                              <CardContent tight>
                                <Text>
                                  <Link
                                    to={
                                      '/organisasjoner/' +
                                      organization._id +
                                      '/profiler/' +
                                      l.contact._id
                                    }
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    {getContactName(l.contact)}
                                  </Link>
                                </Text>
                              </CardContent>
                              <CardIcon tight>
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (
                                      window.confirm(
                                        'Er du sikker på at du vil fjerne lenken?'
                                      )
                                    ) {
                                      fetch({
                                        endpoint: endpoint + '/links/' + l._id,
                                        method: 'DELETE'
                                      });
                                    }
                                  }}
                                >
                                  <Icon>delete</Icon>
                                </a>
                              </CardIcon>
                            </CardLink>
                          </Grid>
                        ))}
                      <Grid item sm={4}>
                        <CardExternalLink
                          href="#"
                          horizontal
                          primary
                          clickable
                          onClick={e => {
                            e.preventDefault();

                            spawnModal(
                              <LinkModal
                                organization={organization}
                                contact={contact._id}
                                onCreate={link => {
                                  fetch({
                                    endpoint: endpoint + '/links',
                                    method: 'POST',
                                    body: {
                                      contact: link.contact
                                    }
                                  });
                                }}
                                close={despawnModal}
                                existingLinks={contact.links}
                              />,
                              'link'
                            );
                          }}
                        >
                          <CardContent tight>
                            <Text>Opprett ny lenke</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>add</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface HandlerModalProps {
  organization: Organization;
  members: Member[];
  filter: string[];
  close: (id: string) => any;
  onCreate: (id: string) => any;
  onDelete?: (ref: string) => any;
  defaultData?: string;
}

export function HandlerModal(props: HandlerModalProps) {
  const { members, filter, defaultData, close, onCreate, onDelete } = props;
  const [handler, setHandler] = useState(defaultData ? defaultData : null);
  const selectedHandler = members.find(m => m.user === defaultData);

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (handler) {
            onCreate(handler);
          }

          close('handler');
        }}
      >
        <Label htmlFor="handler">Bruker *</Label>
        <Downshift
          initialSelectedItem={selectedHandler}
          onChange={selection =>
            selection && setHandler((selection as Member).user)
          }
          itemToString={item => (item ? (item as Member).userData!.name : '')}
        >
          {({
            inputValue,
            getInputProps,
            getMenuProps,
            getItemProps,
            isOpen,
            highlightedIndex,
            selectedItem
          }) => (
            <div>
              <Input id="handler" required {...getInputProps()} />
              {isOpen ? (
                <Card>
                  <List {...getMenuProps()}>
                    {members
                      .filter(m => !filter.some(f => f === m.user))
                      .filter(
                        m =>
                          !inputValue ||
                          m
                            .userData!.name.toLocaleLowerCase()
                            .includes(inputValue.toLocaleLowerCase())
                      )
                      .map((m, i) => (
                        <ListItem
                          tight
                          hover={highlightedIndex === i}
                          active={
                            selectedItem &&
                            (selectedItem as Member).user === m.user
                          }
                          {...getItemProps({
                            key: m.user,
                            index: i,
                            item: m
                          })}
                        >
                          {m.userData!.name}
                        </ListItem>
                      ))}
                  </List>
                </Card>
              ) : null}
            </div>
          )}
        </Downshift>
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('handler');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && handler && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                onDelete(handler);
                close('handler');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

interface LinkModalProps {
  organization: Organization;
  contact: string;
  existingLinks: ContactLink[];
  close: (id: string) => any;
  onCreate: (link: ContactLink) => any;
}

function LinkModal(props: LinkModalProps) {
  const { organization, contact, existingLinks, close, onCreate } = props;
  const [link, setLink] = useState(null as null | ContactLink);

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (link) {
            onCreate(link);
          }

          close('link');
        }}
      >
        <Label htmlFor="contact">Profil *</Label>
        <SearchAndSelect
          fieldId="contact"
          endpoint={`organizations/${organization._id}/contacts/search`}
          labelKey="name"
          searchKey="name"
          filterFn={result =>
            result._id === contact ||
            existingLinks.some(l => l.contact === result._id)
              ? false
              : true
          }
          onSelect={contact =>
            setLink({
              _id: '',
              contact: contact._id
            })
          }
        />
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('link');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          <Button type="submit" variant="primary">
            Legg til
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

function renderStops(min: number, max: number) {
  const primaryOffset =
    max > 7.4 ? <stop offset="0%" stopColor={theme.colors.primary} /> : null;
  const neutralOffset =
    max > 3.3 && min < 7.5 ? (
      <stop
        offset={100 - ((7.5 - min) * 100) / (max - min) + '%'}
        stopColor={theme.colors.neutral}
      />
    ) : null;
  const warningOffset =
    min < 3.4 ? <stop offset="100%" stopColor={theme.colors.warning} /> : null;

  return (
    <>
      {primaryOffset}
      {neutralOffset}
      {warningOffset}
    </>
  );
}

export function nameWithSleep(contact: Contact) {
  if (contact.active) {
    return contact.name;
  }

  return <>{contact.name} (inaktiv)</>;
}

export default Show;
