import React from 'react';
import Downshift from 'downshift';
import styled, { css } from '../../util/styled';
import { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import Card from '../Card';
import List, { ListItem } from '../List';

interface FormProps {
  noMaxWidth?: boolean;
}

const Form = styled.form<FormProps>`
  margin: 0;
  padding: 0;

  > *:not(:last-child) {
    margin-bottom: ${props => props.theme.layout.spacing.normal};
  }

  .react-select__control {
    border-radius: ${props => props.theme.layout.rounding};
    background-color: ${props => props.theme.colors.contentBackground};
    border: 1px solid ${props => props.theme.colors.border};
  }

  .react-select__value-container {
    height: 3.5em;
    padding: 0 ${props => props.theme.layout.spacing.normal};
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  .react-select__placeholder {
    margin: 0;
    color: inherit;
    opacity: 0.54;
  }

  .react-select__multi-value {
    flex-shrink: 0;
    color: ${props => props.theme.colors.text};
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.layout.rounding};
  }

  .react-select__single-value {
    color: ${props => props.theme.colors.text};
  }

  .react-select__multi-value__label {
    color: ${props => props.theme.colors.text};
  }

  .react-select__menu {
    z-index: 3;
    background-color: ${props => props.theme.colors.contentBackground};
  }

  .react-select__option--is-focused {
    background-color: ${props => props.theme.colors.background};
  }

  .react-select__option {
    &:active {
      background-color: ${props => props.theme.colors.contentBackground};
    }
  }

  ${props =>
    !props.noMaxWidth &&
    css`
      max-width: 40rem;
    `};
`;

interface LabelProps {
  gutterTop?: boolean;
}

const Label = styled.label<LabelProps>`
  display: block;
  margin-bottom: ${props => props.theme.layout.spacing.small}!important;
  color: ${props => props.theme.colors.textSecondary};

  ${props =>
    props.gutterTop &&
    css`
      margin-top: ${props.theme.layout.spacing.small};
    `};
`;

interface InputProps {
  monospace?: boolean;
  invalid?: boolean;
}

const Input = styled.input<InputProps>`
  font-size: 1rem;
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  padding: 0 ${props => props.theme.layout.spacing.normal};
  color: inherit;
  border-radius: ${props => props.theme.layout.rounding};
  height: 3.5rem;
  background-color: ${props => props.theme.colors.contentBackground};

  ::placeholder {
    opacity: 0.54;
  }

  ${props =>
    props.monospace &&
    css`
      font-family: monospace;
    `}

  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.warning};
      color: ${props.theme.colors.warning};
    `};
`;

interface TextAreaProps {
  oneLiner?: boolean;
  inline?: boolean;
}

const TextAreaComponent = Input.withComponent('textarea');
const TextArea = styled(TextAreaComponent)<TextAreaProps>`
  resize: vertical;
  padding-top: 18px;
  padding-bottom: 18px;
  height: 10rem;

  ${props =>
    props.oneLiner &&
    css`
      height: 57px;
      margin-bottom: -6px;
    `};

  ${props =>
    props.inline &&
    css`
      border-top: none;
      border-left: none;
      border-bottom: none;
    `};
`;

const FileInput = styled(Input)`
  padding-top: 18px;
`;

const Select = styled.select`
  background-color: ${props => props.theme.colors.contentBackground};
  font-size: 1rem;
  width: 100%;
  padding: 0 ${props => props.theme.layout.spacing.normal};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.layout.rounding};
  color: inherit;
  text-indent: 16px;
  height: 3.5em;
`;

interface Searchable {
  _id: string;
  name: string;
}

interface ExternalInputProps {
  endpoint: string;
  label?: string;
  onChange: (selection: Searchable) => any;
}

function ExternalInput(props: ExternalInputProps) {
  const { endpoint, label, onChange } = props;

  const [results, searching, hasMore, search] = useSearch<Searchable>({
    endpoint,
    limit: 5
  });

  return (
    <Downshift
      onChange={selection => selection && onChange(selection as Searchable)}
      itemToString={item => (item ? (item as Searchable).name : '')}
      onInputValueChange={value =>
        search({
          endpoint,
          queryParams: {
            name: value
          }
        })
      }
    >
      {({
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        selectedItem
      }) => (
        <div>
          {label && <Label {...getLabelProps()}>{label}</Label>}
          <Input required {...getInputProps()} />
          <BusyBoy busy={searching} exposeChildren>
            {isOpen ? (
              <Card>
                <List {...getMenuProps()}>
                  {results.map((result, index) => (
                    <ListItem
                      tight
                      hover={highlightedIndex === index}
                      active={
                        selectedItem &&
                        (selectedItem as Searchable)._id === result._id
                      }
                      {...getItemProps({
                        key: result._id,
                        index,
                        item: result
                      })}
                    >
                      {result.name}
                    </ListItem>
                  ))}
                </List>
              </Card>
            ) : null}
          </BusyBoy>
        </div>
      )}
    </Downshift>
  );
}

export default Form;
export { Label, Input, TextArea, FileInput, Select, ExternalInput };
