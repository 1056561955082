import React from 'react';
import styled, { css } from '../../util/styled';

interface WrapperProps {
  gutterTop?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${props => props.theme.colors.contentBackground};
  border-radius: ${props => props.theme.layout.rounding};
  border: 1px solid ${props => props.theme.colors.border};
  padding: ${props => props.theme.layout.spacing.small};

  ${props =>
    props.gutterTop &&
    css`
      margin-top: ${props => props.theme.layout.spacing.normal};
    `};
`;

interface Props {
  html: string;
  gutterTop?: boolean;
}

function HTMLPreview(props: Props) {
  const { html, gutterTop } = props;

  return (
    <Wrapper gutterTop={gutterTop} dangerouslySetInnerHTML={{ __html: html }} />
  );
}

export default HTMLPreview;
