import styled, { css } from '../../util/styled';

interface Props {
  size?: string;
  image?: string;
  contain?: boolean;
  baseUrl?: string;
}

const Avatar = styled.div<Props>`
  width: ${props => props.size || '5rem'};
  height: ${props => props.size || '5rem'};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  padding-bottom: 3px;
  font-size: 1.2rem;

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center;
      background-size: ${props.contain ? 'contain' : 'cover'};
    `};
`;

export default Avatar;
