import storage from './storage';

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

function getTheme(): Theme {
  const theme = storage.get('theme');
  return theme === 'light' ? Theme.Light : Theme.Dark;
}

export default getTheme;
