interface KeyValuePair {
  [key: string]: string;
}

export function mergeConsents(defaults: KeyValuePair, custom: string[]) {
  let consents: KeyValuePair = {};

  if (custom) {
    for (let i = 0; i < custom.length; i++) {
      const name: string = custom[i];
      consents[name] = name;
    }
  }

  return { ...defaults, ...consents };
}
