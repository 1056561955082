import React, { useContext } from 'react';
import styled, { css } from '../../util/styled';
import { UiContext } from '../../context/Ui';
import { FADE_IN_TOP, FADE_OUT_BOTTOM } from '../../constants/animations';

interface Props {
  despawn?: boolean;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
  padding: ${props => props.theme.layout.spacing.normal};
  flex-direction: column;
`;

const Notification = styled.div<Props>`
  background-color: ${props => props.theme.colors.contentBackground};
  border-radius: ${props => props.theme.layout.rounding};
  box-shadow: ${props => props.theme.shadows.normal};
  animation: ${FADE_IN_TOP} 0.24s 0.24s ease-out both;
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.layout.spacing.small};
  }

  ${props =>
    props.despawn &&
    css`
      animation: ${FADE_OUT_BOTTOM} 0.12s ease-out both;
    `};
`;

const Icon = styled.div`
  color: white;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  border-top-left-radius: ${props => props.theme.layout.rounding};
  border-bottom-left-radius: ${props => props.theme.layout.rounding};
`;

const Content = styled.div`
  padding: ${props => props.theme.layout.spacing.normal};
`;

function Notifications() {
  const notificationContext = useContext(UiContext).notifications;
  const notificationState = notificationContext.data;
  const hasNotifications = notificationState.stack.length > 0;

  return hasNotifications ? (
    <>
      <Wrapper>
        {notificationState.stack.map(n => (
          <Notification key={n.id} despawn={n.fadeOut}>
            {n.icon && <Icon>{n.icon}</Icon>}
            <Content>{n.content}</Content>
          </Notification>
        ))}
      </Wrapper>
    </>
  ) : null;
}

export default Notifications;
