import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid/v4';
import {
  Variable,
  Organization as IOrganization,
  VariableCondition
} from '../../types/apiResponses';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { CardContent, CardIcon, CardExternalLink } from '../../components/Card';
import { OrganizationContext } from '../../context/Organization';
import { UiContext } from '../../context/Ui';
import { variableTypes } from '../../constants/localization';
import localize from '../../util/localize';
import { VariableType } from '../../constants/enums';
import Expander from '../../components/Expander';
import Icon from '../../components/Icon';
import Doorman from '../../components/Doorman';
import {
  CreateParameterModal,
  renderProfileSelectionRule
} from '../Automation/Show';

interface CreateParams {
  organizationID: string;
  variableID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const variableID = props.match.params.variableID;
  const isUpdate = variableID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/variables';
  const endpoint = isUpdate ? baseEndpoint + '/' + variableID : baseEndpoint;

  const organizationContext = useContext(OrganizationContext);
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const organization = organizationContext.data;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Variable>(
    {
      label: '',
      type: 0,
      value: null,
      conditions: []
    },
    {
      onSuccess: organization => {
        history.push('../variabler');
        organizationContext.setData(organization as IOrganization);
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? 'organizations/' + organizationID : undefined,
      prefillFn: organization =>
        (organization as IOrganization).variables.find(
          v => v._id === variableID
        )
    }
  );

  const [_, deleting, destroy] = useApi<IOrganization | null>({
    method: 'DELETE',
    endpoint: 'organizations/' + organizationID + '/variables/' + variableID,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: organization => {
      history.push('../variabler');
      organizationContext.setData(organization as IOrganization);
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} variabel
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="label">Navn *</Label>
            <Input
              id="label"
              type="text"
              required
              placeholder="Min variabel"
              value={data.label}
              onChange={e => setField('label', e.target.value)}
            />
            <Label htmlFor="type">Type *</Label>
            <Select
              id="type"
              value={data.type}
              required
              onChange={e => setField('type', parseInt(e.target.value))}
            >
              <option value={0}>{localize(variableTypes, '0')}</option>
              <option value={1}>{localize(variableTypes, '1')}</option>
            </Select>
            {data.type === VariableType.Constant && (
              <>
                <Label htmlFor="value">Verdi *</Label>
                <Input
                  id="value"
                  type="text"
                  required
                  value={data.value as string}
                  onChange={e => setField('value', e.target.value)}
                />
              </>
            )}
            {data.type === VariableType.Conditional && (
              <Expander label={`Betingelser (${data.conditions.length})`} open>
                <Grid container spacing={24}>
                  {data.conditions.map(c => {
                    return (
                      <Grid key={c._id} item xs={12}>
                        <CardExternalLink
                          href="#"
                          clickable
                          horizontal
                          onClick={e => {
                            e.preventDefault();
                            spawnModal(
                              <CreateConditionModal
                                defaultData={c}
                                organization={organization}
                                onCreate={condition => {
                                  const index = data.conditions.findIndex(
                                    ca => ca._id === condition._id
                                  );

                                  if (index !== -1) {
                                    const copy = [...data.conditions];
                                    copy[index] = condition;

                                    setField('conditions', copy);
                                  }
                                }}
                                onDelete={ref => {
                                  setField(
                                    'conditions',
                                    data.conditions.filter(ca =>
                                      ca.__ref
                                        ? ca.__ref !== ref
                                        : ca._id !== ref
                                    )
                                  );
                                }}
                                close={despawnModal}
                              />,
                              'createCondition'
                            );
                          }}
                        >
                          <CardContent tight>
                            <Text>
                              <strong>{c.value as string}: </strong>
                              {c.rules.map((p, k) => {
                                const list = organization.lists.find(
                                  l => l._id === p.targetRef
                                );

                                const dataPoint = organization.dataPoints.find(
                                  d => d._id === p.targetRef
                                );

                                return renderProfileSelectionRule(
                                  p,
                                  list,
                                  dataPoint,
                                  k === 0
                                );
                              })}
                            </Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    );
                  })}
                  <Doorman>
                    <Grid item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        primary={data.conditions.length < 1}
                        secondary={data.conditions.length > 0}
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreateConditionModal
                              organization={organization}
                              onCreate={condition => {
                                setField('conditions', [
                                  ...data.conditions,
                                  condition
                                ]);
                              }}
                              onDelete={ref => {
                                setField(
                                  'conditions',
                                  data.conditions.filter(c => c._id !== ref)
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createCondition'
                          );
                        }}
                      >
                        <CardContent tight>
                          <Text>Legg til betingelse</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>add</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  </Doorman>
                </Grid>
              </Expander>
            )}
            {data && data._id && (
              <>
                <Label htmlFor="parseCode">
                  Bruk ved å lime følgende kode inn i e-post:
                </Label>
                <Input
                  id="parseCode"
                  type="text"
                  value={`print{type:variable;ref:${data.label};}`}
                  readOnly
                  onFocus={e => e.target.select()}
                />
              </>
            )}
            <ButtonList align="right">
              <ButtonLink to="../variabler">Avbryt</ButtonLink>
              <Doorman>
                <>
                  {data && data._id && (
                    <ButtonExternalLink
                      onClick={() => destroy()}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

interface CreateConditionModalProps {
  organization: IOrganization;
  close: (id: string) => any;
  onCreate: (data: VariableCondition) => any;
  onDelete?: (ref: string) => any;
  defaultData?: VariableCondition;
}

const defaultCondition: VariableCondition = {
  _id: '',
  rules: [],
  value: null
};

function CreateConditionModal(props: CreateConditionModalProps) {
  const { organization, defaultData, close, onCreate, onDelete } = props;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [condition, setCondition] = useState(
    defaultData ? defaultData : { ...defaultCondition, __ref: uuid() }
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete condition._id;
          }

          onCreate(condition);
          close('createCondition');
        }}
      >
        <Label htmlFor="value">Verdi *</Label>
        <Input
          id="value"
          type="text"
          required
          value={condition.value as string}
          onChange={e =>
            setCondition({
              ...condition,
              value: e.target.value
            })
          }
        />
        <Expander label={`Filter (${condition.rules.length})`} open>
          <Grid container spacing={24}>
            {condition.rules.map(p => {
              const list = organization.lists.find(l => l._id === p.targetRef);

              const dataPoint = organization.dataPoints.find(
                d => d._id === p.targetRef
              );

              return (
                <Grid key={p._id} item xs={12}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    onClick={e => {
                      e.preventDefault();
                      spawnModal(
                        <CreateParameterModal
                          defaultData={p}
                          organization={organization}
                          onCreate={parameter => {
                            const index = condition.rules.findIndex(
                              pa => pa._id === parameter._id
                            );

                            if (index !== -1) {
                              const copy = [...condition.rules];
                              copy[index] = parameter;

                              setCondition({
                                ...condition,
                                rules: copy
                              });
                            }
                          }}
                          onDelete={ref => {
                            setCondition({
                              ...condition,
                              rules: condition.rules.filter(pa =>
                                pa.__ref ? pa.__ref !== ref : pa._id !== ref
                              )
                            });
                          }}
                          close={despawnModal}
                        />,
                        'createParameter'
                      );
                    }}
                  >
                    <CardContent tight>
                      {renderProfileSelectionRule(p, list, dataPoint)}
                    </CardContent>
                    <CardIcon tight>
                      <Icon>edit</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <CardExternalLink
                href="#"
                clickable
                horizontal
                primary={condition.rules.length < 1}
                secondary={condition.rules.length > 0}
                onClick={e => {
                  e.preventDefault();
                  spawnModal(
                    <CreateParameterModal
                      organization={organization}
                      onCreate={parameter => {
                        setCondition({
                          ...condition,
                          rules: [...condition.rules, parameter]
                        });
                      }}
                      onDelete={ref => {
                        setCondition({
                          ...condition,
                          rules: condition.rules.filter(p => p._id !== ref)
                        });
                      }}
                      close={despawnModal}
                    />,
                    'createParameter'
                  );
                }}
              >
                <CardContent tight>
                  <Text>Legg til regel</Text>
                </CardContent>
                <CardIcon tight>
                  <Icon>add</Icon>
                </CardIcon>
              </CardExternalLink>
            </Grid>
          </Grid>
        </Expander>
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createCondition');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createCondition');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

export default Create;
