import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import history from "../../util/history";
import localize from "../../util/localize";
import { permissions } from "../../constants/localization";
import Content from "../../components/Content";
import { Container } from "../../helpers/Layout";
import Text from "../../components/Text";
import useForm from "../../hooks/useForm";
import Button, { ButtonList, ButtonLink } from "../../components/Button";
import Form, { Label, Select } from "../../components/Form";
import { ApiKey } from "../../types/apiResponses";
import { Permissions } from "../../constants/enums";
import BusyBoy from "../../helpers/BusyBoy";
import Fader from "../../helpers/Fader";

interface CreateApiKeyProps {
  organizationID?: string;
}

export default function CreateApiKey(
  props: RouteComponentProps<CreateApiKeyProps>
) {
  const organizationID = props.match.params.organizationID;
  const [apiKey, setApiKey] = useState(undefined as undefined | string);

  const { data, setField, submit, hasMadeChanges, submitting } = useForm<
    ApiKey
  >(
    {
      permissions: ""
    },
    {
      endpoint: "organizations/" + organizationID + "/api-keys",
      method: "POST",
      onSuccess: (key: ApiKey) => {
        setApiKey(key.apiKey);
      }
    }
  );

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Opprett API-nøkkel
        </Text>
      </Container>
      <Container spacious hugTop>
        <BusyBoy busy={submitting} exposeChildren>
          {!apiKey ? (
            <Form onSubmit={submit}>
              <Label htmlFor="permissions">Tilgang</Label>
              <Select
                id="permissions"
                value={data.permissions}
                onChange={e => setField("permissions", e.target.value)}
              >
                <option value="" disabled>
                  Vennligst velg…
                </option>
                <option value={Permissions.Reader}>
                  {localize(permissions, Permissions.ApiReader)}
                </option>
                <option value={Permissions.Writer}>
                  {localize(permissions, Permissions.ApiWriter)}
                </option>
                <option value={Permissions.Admin}>
                  {localize(permissions, Permissions.ApiAdmin)}
                </option>
              </Select>
              <ButtonList align="right">
                <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
                <Button
                  disabled={!hasMadeChanges || submitting}
                  type="submit"
                  variant="primary"
                >
                  {submitting ? "Lagrer…" : "Lagre"}
                </Button>
              </ButtonList>
            </Form>
          ) : (
            <Fader variant="top">
              <Text>Her er din nøkkel:</Text>
              <Text variant="title">{apiKey}</Text>
              <Text topGutter bottomGutter>
                Ta vare på nøkkelen, ettersom dette er siste gang den vises.
              </Text>
              <ButtonList>
                <ButtonLink to="../innstillinger">
                  Tilbake til instillinger
                </ButtonLink>
              </ButtonList>
            </Fader>
          )}
        </BusyBoy>
      </Container>
    </Content>
  );
}
