import { Collector, CollectorDataPointReference } from '../types/apiResponses';

function createForm(campaign: string, collector: Collector): string {
  return `<form method="post" action="${
    process.env.REACT_APP_PLZ_URL
  }/campaigns/${campaign}/collectors/${collector._id}/collect">
  <label for="email">Din e-postadresse *</label>
  <input id="email" name="email" type="email" required />
  ${collector.dataPoints
    .map(d => {
      return d.data
        ? `<label for="${d.ref}">${d.data.label}${
            d.required ? ' *' : ''
          }</label>
  ${renderInput(d)}`
        : '';
    })
    .join('\n  ')}
  <button type="submit">Send inn</button>
</form>
`;
}

function renderInput(dataPoint: CollectorDataPointReference): string {
  if (!dataPoint.data) {
    return '';
  }

  if (dataPoint.selectFrom.length > 0) {
    return `<select id="${dataPoint.ref}" name="${dataPoint.ref}">
      ${dataPoint.selectFrom
        .map(s => {
          return `<option value="${s.value}"${
            dataPoint.data && dataPoint.data.defaultValue === s.value
              ? ' selected'
              : ''
          }>${s.value}</option>`;
        })
        .join('\n      ')}
  </select>`;
  } else if (dataPoint.data.type === 'boolean') {
    return `<select id="${dataPoint.ref}" name="${dataPoint.ref}">
    <option disabled${
      !dataPoint.data.defaultValue ? ' selected' : ''
    }>Vennligst velg…</option>
    <option value="false"${
      dataPoint.data.defaultValue === true ? ' selected' : ''
    }>Nei</option>
    <option value="true"${
      dataPoint.data.defaultValue === false ? ' selected' : ''
    }>Ja</option>
  </select>`;
  } else if (dataPoint.data.type === 'pick') {
    return `<select id="${dataPoint.ref}" name="${dataPoint.ref}">
    <option disabled${
      !dataPoint.data.defaultValue ? ' selected' : ''
    }>Vennligst velg…</option>
    ${(dataPoint.data.picks as string[])
      .map(
        p =>
          `<option value="${p}"${
            dataPoint.data && dataPoint.data.defaultValue === p
              ? ' selected'
              : ''
          }>${p}</option>`
      )
      .join('\n    ')}
  </select>`;
  }

  let type = 'text';

  switch (dataPoint.data.type) {
    case 'number':
      type = 'number';
      break;
  }

  return `<input id="${dataPoint.ref}" name="${
    dataPoint.ref
  }" type="${type}" value="${
    dataPoint.data.defaultValue ? dataPoint.data.defaultValue : ''
  }" ${dataPoint.required ? 'required' : ''} />`;
}

export default createForm;
