import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from '../../util/styled';
import Card, { CardContent } from '../Card';
import Icon from '../Icon';
import Text from '../Text';
import { mobileThreshold } from '../../constants/theme';

const Wrapper = styled.div`
  font-size: 13px;

  @media (max-width: ${mobileThreshold}px) {
    display: none;
  }
`;

const ShowOff: FunctionComponent = ({ children }) => {
  return (
    <Wrapper>
      <Card>
        <CardContent tighter>
          <Grid container spacing={8}>
            <Grid item>
              <Icon>arrow_upward</Icon>
            </Grid>
            <Grid item>
              <Text>{children}</Text>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default ShowOff;
