import React, { FunctionComponent } from 'react';
import { StyledComponent } from 'styled-components';
import styled, { css } from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

export type TextVariant =
  | 'body'
  | 'headline'
  | 'subheading'
  | 'title'
  | 'display3'
  | 'display2';

export type TextElement = 'p' | 'h1' | 'h2' | 'h3' | 'span';

interface SharedProps {
  variant?: TextVariant;
  centered?: boolean;
  bottomGutter?: boolean;
  topGutter?: boolean;
  capitalize?: boolean;
  small?: boolean;
  align?: 'left' | 'center' | 'right';
  weight?: number;
  color?: string;
  size?: string;
  lineThrough?: boolean;
}

interface ElementProps extends SharedProps {}

interface TextProps extends SharedProps {
  element?: TextElement;
}

const P = styled.p<ElementProps>`
  margin: 0;
  font-weight: 400;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};

  ${props =>
    props.variant === 'display3' &&
    css`
      font-family: ${props.theme.fonts.display};
      font-weight: 700;
      font-size: 2.5rem;

      @media (max-width: ${mobileThreshold}px) {
        font-size: 2rem;
      }
    `};

  ${props =>
    props.variant === 'display2' &&
    css`
      font-family: ${props.theme.fonts.display};
      font-size: 1.7rem;
    `};

  ${props =>
    props.variant === 'headline' &&
    css`
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${props.theme.colors.textSecondary};
      font-size: 1em;
    `};

  ${props =>
    props.variant === 'title' &&
    css`
      font-weight: 700;
      font-size: 1.25rem;
    `};

  ${props =>
    props.variant === 'subheading' &&
    css`
      color: ${props.theme.colors.textSecondary};
      font-family: ${props.theme.fonts.primary};
    `};

  ${props =>
    props.bottomGutter &&
    css`
      margin-bottom: ${props.theme.layout.spacing.normal};
    `};

  ${props =>
    props.topGutter &&
    css`
      margin-top: ${props.theme.layout.spacing.normal};
    `};

  ${props =>
    props.small &&
    css`
      font-size: 13px;
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};

  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `};

  ${props =>
    props.lineThrough &&
    css`
      text-decoration: line-through;
    `};
`;

const H1 = P.withComponent('h1');
const H2 = H1.withComponent('h2');
const H3 = H2.withComponent('h3');
const Span = P.withComponent('span');

const Text: FunctionComponent<TextProps> = ({ element, children, ...rest }) => {
  let Elem: StyledComponent<any, any>;

  switch (element) {
    case 'h1':
      Elem = H1;
      break;
    case 'h2':
      Elem = H2;
      break;
    case 'h3':
      Elem = H3;
      break;
    case 'span':
      Elem = Span;
      break;
    default:
      Elem = P;
      break;
  }

  return <Elem {...rest}>{children}</Elem>;
};

export default Text;
