import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as qs from "qs";
import history from "../../util/history";
import { Main } from "../../components/Wrapper";
import Card, {
  CardTitle,
  CardActions,
  CardContent,
} from "../../components/Card";
import Text from "../../components/Text";
import Fader from "../../helpers/Fader";
import Button, {
  ButtonExternalLink,
  ButtonList,
  ButtonLink,
} from "../../components/Button";
import { UserContext } from "../../context/User";
import { Centerer } from "../../helpers/Layout";
import { Switch, Route, RouteComponentProps } from "react-router";
import Form, { Label, Input } from "../../components/Form";
import useForm from "../../hooks/useForm";
import BusyBoy from "../../helpers/BusyBoy";
import { AuthResponse } from "../../types/apiResponses";
import theme from "../../constants/theme";

const LoginCard = styled.div`
  max-width: 35rem;
  width: 100%;
`;

function Login() {
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.data.loggedIn) {
      history.replace("/");
    }
  }, [user.data.loggedIn]);

  return (
    <Main>
      <Centerer>
        <LoginCard>
          <Fader variant="top">
            <Card>
              <Switch>
                <Route exact path="/logg-inn" component={LoginStart} />
                <Route exact path="/logg-inn/e-post" component={LoginEmail} />
                <Route exact path="/logg-inn/ny-bruker" component={Register} />
              </Switch>
            </Card>
          </Fader>
        </LoginCard>
      </Centerer>
    </Main>
  );
}

function LoginStart() {
  return (
    <>
      <CardTitle>
        <Text element="h1" variant="display3" centered>
          Logg inn
        </Text>
      </CardTitle>
      <CardActions>
        <ButtonList vertical>
          <ButtonExternalLink
            href={
              process.env.REACT_APP_PLZ_URL +
              "/users/sso?callbackPath=/sign-on&locale=no&autoLogin=true"
            }
            variant="primary"
          >
            Logg inn med Ludens
          </ButtonExternalLink>
        </ButtonList>
      </CardActions>
    </>
  );
}

interface Credentials {
  email: string;
  password: string;
  passwordRepeat?: string;
}

function LoginEmail(props: RouteComponentProps<any>) {
  const [err, setErr] = useState(null as null | string);

  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const { data, setField, submit, submitting } = useForm<Credentials>(
    {
      email: queryParams.email || "",
      password: "",
    },
    {
      endpoint: "users/login/email",
      method: "POST",
      onSuccess: (data) => {
        const authResponse = data as AuthResponse;

        if (authResponse.code !== 200) {
          setErr(authResponse.message);
        } else {
          history.push("/sign-on");
        }
      },
    }
  );

  return (
    <>
      <CardTitle hugBottom>
        <Text element="h1" variant="display3" centered>
          Logg inn
        </Text>
      </CardTitle>
      <CardContent>
        <BusyBoy exposeChildren busy={submitting}>
          <Form onSubmit={submit}>
            {queryParams.fromRegister === "1" && (
              <Text color={theme.colors.good}>
                Brukeren er nå registrert! Vennligst logg inn.
              </Text>
            )}
            <Label htmlFor="email">E-post *</Label>
            <Input
              type="email"
              id="email"
              value={data.email}
              onChange={(e) => setField("email", e.target.value)}
              required
            />
            <Label htmlFor="password">Passord *</Label>
            <Input
              type="password"
              id="password"
              value={data.password}
              onChange={(e) => setField("password", e.target.value)}
              required
            />
            {printErr(err)}
            <ButtonList align="right" tight>
              <ButtonLink to="/logg-inn">Tilbake</ButtonLink>
              <ButtonLink to="/logg-inn/ny-bruker">Ny bruker</ButtonLink>
              <Button type="submit" variant="primary">
                {submitting ? "Logger inn…" : "Logg inn"}
              </Button>
            </ButtonList>
          </Form>
        </BusyBoy>
      </CardContent>
    </>
  );
}

function Register() {
  const [err, setErr] = useState(null as null | string);

  const { data, setField, submit, submitting } = useForm<Credentials>(
    {
      email: "",
      password: "",
      passwordRepeat: "",
    },
    {
      endpoint: "users/register",
      method: "POST",
      noCredentials: true,
      onSuccess: (response) => {
        const authResponse = response as AuthResponse;

        if (authResponse.code !== 200) {
          setErr(authResponse.message);
        } else {
          history.push("/logg-inn/e-post?fromRegister=1&email=" + data.email);
        }
      },
    }
  );

  return (
    <>
      <CardTitle hugBottom>
        <Text element="h1" variant="display3" centered>
          Registrer ny bruker
        </Text>
      </CardTitle>
      <CardContent>
        <BusyBoy exposeChildren busy={submitting}>
          <Form onSubmit={submit}>
            <Label htmlFor="email">E-post *</Label>
            <Input
              type="email"
              id="email"
              value={data.email}
              onChange={(e) => setField("email", e.target.value)}
              required
            />
            <Label htmlFor="password">Passord *</Label>
            <Input
              type="password"
              id="password"
              value={data.password}
              onChange={(e) => setField("password", e.target.value)}
              required
            />
            <Label htmlFor="passwordRepeat">Gjenta passord *</Label>
            <Input
              type="password"
              id="passwordRepeat"
              value={data.passwordRepeat}
              onChange={(e) => setField("passwordRepeat", e.target.value)}
              required
            />
            {printErr(err)}
            <ButtonList align="right" tight>
              <ButtonLink to="/logg-inn">Tilbake</ButtonLink>
              <Button type="submit" variant="primary">
                {submitting ? "Registrerer…" : "Registrer"}
              </Button>
            </ButtonList>
          </Form>
        </BusyBoy>
      </CardContent>
    </>
  );
}

function printErr(err: null | string) {
  if (!err) {
    return null;
  }

  let message: string = "";

  switch (err) {
    case "wrongUsernameOrPassword":
      message = "Feil e-post eller passord!";
      break;
    case "userAlreadyExists":
      message = "Det eksisterer allerede en bruker med denne e-posten!";
      break;
    case "passwordMismatch":
      message = "Passordene matcher ikke!";
      break;
  }

  return <Text color={theme.colors.warning}>{message}</Text>;
}

export default Login;
