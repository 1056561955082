import React, { FunctionComponent, useContext } from 'react';
import { Permissions } from '../../constants/enums';
import { OrganizationContext } from '../../context/Organization';
import { UserContext } from '../../context/User';
import hasPermissions from '../../util/hasPermissions';
import {
  permissionsAll,
  permissionsHandler,
  permissionsHandlerPlus,
  permissionsWriter,
  permissionsAdmin
} from '../../util/prefilledPermissions';

export type DoormanPermissions =
  | 'all'
  | 'handler'
  | 'handlerPlus'
  | 'writer'
  | 'admin';

interface Props {
  type?: DoormanPermissions;
  permissions?: Permissions[];
  self?: boolean;
  selfID?: string;
  selfCompare?: string[];
}

const Doorman: FunctionComponent<Props> = ({
  type,
  permissions,
  self,
  selfID,
  selfCompare,
  children
}) => {
  const organization = useContext(OrganizationContext).data;
  const user = useContext(UserContext).data;

  let pm: Permissions[] = permissionsWriter;

  if (permissions) {
    pm = permissions;
  } else if (type) {
    switch (type) {
      case 'all':
        pm = permissionsAll;
        break;
      case 'handler':
        pm = permissionsHandler;
        break;
      case 'handlerPlus':
        pm = permissionsHandlerPlus;
        break;
      case 'writer':
        pm = permissionsWriter;
        break;
      case 'admin':
        pm = permissionsAdmin;
        break;
    }
  }

  if (hasPermissions(organization, user.id, pm)) {
    return <>{children}</>;
  }

  if (self && selfID && Array.isArray(selfCompare)) {
    if (selfCompare.some(c => c === selfID)) {
      return <>{children}</>;
    }
  }

  return null;
};

export default Doorman;
