import React, { FunctionComponent } from 'react';
import styled, { css } from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

interface SidebarWrapperProps {
  rightSidebar?: boolean;
}

const SidebarWrapper = styled.div<SidebarWrapperProps>`
  display: grid;
  grid-template-columns: ${props => props.theme.layout.sidebarWidth} 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar content';

  @media (max-width: ${mobileThreshold}px) {
    grid-template-columns: ${props => props.theme.layout.sidebarWidthMobile} 1fr;
  }

  ${props =>
    props.rightSidebar &&
    css`
      grid-template-columns: ${props => props.theme.layout.sidebarWidth} 1fr ${props =>
          props.theme.layout.sidebarWidth};
      grid-template-areas: 'sidebar content sidebar-right';
    `};
`;

const Content = styled.div`
  grid-area: content;
`;

const StyledSidebar = styled.div<SidebarWrapperProps>`
  grid-area: ${props => (props.rightSidebar ? 'sidebar-right' : 'sidebar')};
  margin: ${props => props.theme.layout.spacing.large} 0;
  padding: ${props => `0 0 0 ${props.theme.layout.spacing.large}`};

  > div {
    position: sticky;
    top: 0;
  }

  @media (max-width: ${mobileThreshold}px) {
    padding: ${props => `0 0 0 ${props.theme.layout.spacing.small}`};
  }
`;

const Sidebar: FunctionComponent<SidebarWrapperProps> = ({
  rightSidebar,
  children
}) => {
  return (
    <StyledSidebar rightSidebar={rightSidebar}>
      <div>{children}</div>
    </StyledSidebar>
  );
};

export default Sidebar;
export { SidebarWrapper, Content };
