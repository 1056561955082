import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactSelect from 'react-select';
import Grid from '@material-ui/core/Grid';
import {
  DataPoint,
  Organization as IOrganization
} from '../../types/apiResponses';
import { dataPointTypes, defaultConsents } from '../../constants/localization';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import renderField from '../../util/renderField';
import Content from '../../components/Content';
import { OrganizationContext } from '../../context/Organization';
import { UiContext } from '../../context/Ui';
import Expander from '../../components/Expander';
import Card, {
  CardContent,
  CardIcon,
  CardExternalLink
} from '../../components/Card';
import Icon from '../../components/Icon';
import Doorman from '../../components/Doorman';

interface CreateParams {
  organizationID: string;
  dataPointID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const dataPointID = props.match.params.dataPointID;
  const isUpdate = dataPointID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/data-points';
  const endpoint = isUpdate ? baseEndpoint + '/' + dataPointID : baseEndpoint;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;
  const organizationContext = useContext(OrganizationContext);
  const organization = organizationContext.data;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<DataPoint>(
    {
      label: '',
      type: 'text',
      categories: [],
      defaultValue: null,
      consents: [],
      picks: []
    },
    {
      onSuccess: organization => {
        history.push('../karakteristikker');
        organizationContext.setData(organization as IOrganization);
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? 'organizations/' + organizationID : undefined,
      prefillFn: organization =>
        (organization as IOrganization).dataPoints.find(
          d => d._id === dataPointID
        )
    }
  );

  const [_, deleting, destroy] = useApi<IOrganization | null>({
    method: 'DELETE',
    endpoint: 'organizations/' + organizationID + '/data-points/' + dataPointID,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: organization => {
      history.push('../karakteristikker');
      organizationContext.setData(organization as IOrganization);
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} karakteristikk
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="label">Navn *</Label>
            <Input
              id="label"
              type="text"
              required
              placeholder="Favorittfarge (eksempel)"
              value={data.label}
              onChange={e => setField('label', e.target.value)}
            />
            <Label htmlFor="type">Type *</Label>
            <Select
              id="type"
              required
              disabled={isUpdate}
              value={data.type}
              onChange={e => setField('type', e.target.value)}
            >
              {Object.keys(dataPointTypes).map(
                k =>
                  k in dataPointTypes && (
                    <option key={k} value={k}>
                      {dataPointTypes[k].charAt(0).toUpperCase() +
                        dataPointTypes[k].slice(1)}
                    </option>
                  )
              )}
            </Select>
            {(data.type === 'pick' || data.type === 'pickNumber') && (
              <>
                <Expander label={`Valgmuligheter (${data.picks.length})`} open>
                  <Grid container spacing={24}>
                    {(data.picks as string[]).map(p => (
                      <Grid key={p} item xs={12}>
                        <CardExternalLink
                          href="#"
                          clickable
                          horizontal
                          onClick={e => {
                            e.preventDefault();
                            spawnModal(
                              <CreatePickModal
                                number={data.type === 'pickNumber'}
                                defaultData={p}
                                onCreate={pick => {
                                  const index = data.picks.findIndex(
                                    pi => pi === p
                                  );

                                  if (index !== -1) {
                                    const copy = [...data.picks];
                                    copy[index] = pick;

                                    setField('picks', copy);
                                  }
                                }}
                                onDelete={() => {
                                  setField(
                                    'picks',
                                    (data.picks as string[]).filter(
                                      pi => pi !== p
                                    )
                                  );
                                }}
                                close={despawnModal}
                              />,
                              'createPick'
                            );
                          }}
                        >
                          <CardContent tight>
                            <Text>{p}</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        primary={data.picks.length < 1}
                        secondary={data.picks.length > 0}
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreatePickModal
                              number={data.type === 'pickNumber'}
                              onCreate={pick => {
                                setField('picks', [...data.picks, pick]);
                              }}
                              onDelete={ref => {
                                setField(
                                  'picks',
                                  (data.picks as string[]).filter(
                                    p => p !== ref
                                  )
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createPick'
                          );
                        }}
                      >
                        <CardContent tight>
                          <Text>Legg til valgmulighet</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>add</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  </Grid>
                </Expander>
              </>
            )}
            {data.type !== 'interest' && (
              <>
                <Label htmlFor="defaultValue">Standardverdi</Label>
                {renderField(
                  data,
                  data.defaultValue,
                  setField,
                  'defaultValue',
                  true
                )}
              </>
            )}
            {organization && organization.dataPointCategories.length > 0 && (
              <>
                <Label htmlFor="categories">Kategorier</Label>
                <ReactSelect
                  classNamePrefix="react-select"
                  placeholder="Vennligst velg…"
                  isMulti
                  value={data.categories.map(c => {
                    const dp = organization.dataPointCategories.find(
                      ct => ct._id === c
                    );

                    return {
                      value: c,
                      label: dp ? dp.label : ''
                    };
                  })}
                  onChange={val =>
                    setField(
                      'categories',
                      Array.isArray(val) ? val.map(v => v.value) : []
                    )
                  }
                  options={organization.dataPointCategories.map(c => ({
                    value: c._id,
                    label: c.label
                  }))}
                />
              </>
            )}
            <Label htmlFor="consents">Samtykke(r)</Label>
            <ReactSelect
              classNamePrefix="react-select"
              placeholder="Ingen"
              isMulti
              value={data.consents.map(c => {
                const defaultConsent =
                  c in defaultConsents ? defaultConsents[c] : undefined;

                return {
                  value: c,
                  label: defaultConsent || c
                };
              })}
              onChange={val =>
                setField(
                  'consents',
                  Array.isArray(val) ? val.map(v => v.value) : []
                )
              }
              options={Object.keys(defaultConsents).map(k => ({
                value: k,
                label: defaultConsents[k]
              }))}
            />
            {data && data._id && (
              <>
                <Label htmlFor="parseCode">
                  Bruk ved å lime følgende kode inn i e-post:
                </Label>
                <Input
                  id="parseCode"
                  type="text"
                  value={`print{type:dataPoint;ref:${data.label};}`}
                  readOnly
                  onFocus={e => e.target.select()}
                />
              </>
            )}
            <ButtonList align="right">
              <ButtonLink to="../karakteristikker">Avbryt</ButtonLink>
              <Doorman>
                <>
                  {data && data._id && (
                    <ButtonExternalLink
                      onClick={() => destroy()}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

interface CreatePickModalProps {
  close: (id: string) => any;
  onCreate: (data: string) => any;
  onDelete?: (ref: string) => any;
  defaultData?: string;
  number?: boolean;
}

function CreatePickModal(props: CreatePickModalProps) {
  const { defaultData, close, onCreate, onDelete, number } = props;

  const [pick, setPick] = useState(defaultData ? defaultData : '');

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          onCreate(pick);
          close('createPick');
        }}
      >
        <Label htmlFor="value">Verdi *</Label>
        <Input
          id="value"
          type={number ? 'number' : 'text'}
          required
          value={pick}
          onChange={e => setPick(e.target.value)}
        />
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createPick');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              onClick={() => {
                onDelete(pick);
                close('createPick');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

export default Create;
