import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

interface Props {
  variant?: 'normal' | 'primary' | 'secondary' | 'warning';
  circle?: boolean;
  small?: boolean;
  opaque?: boolean;
  disabled?: boolean;
}

const ButtonStyles = css<Props>`
  border: 1px solid ${props => props.theme.colors.border};
  padding: 0 ${props => props.theme.layout.spacing.normal};
  height: 3.5em;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  background: transparent;
  width: auto;
  overflow: visible;
  font-family: inherit;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.primary};
  cursor: pointer;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};
  display: inline-flex;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  border-radius: 32px;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.contentBackground};
    background-color: ${props => props.theme.colors.text};
    transform: scale(1.06);
    text-decoration: none;
  }

  i {
    margin-right: 0.4em;
  }

  ${props =>
    props.variant === 'primary' &&
    css`
      border-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};

      &:hover,
      &:focus {
        background-color: ${props.theme.colors.primary};
      }
    `};

  ${props =>
    props.variant === 'secondary' &&
    css`
      border-color: ${props.theme.colors.border};
      color: ${props.theme.colors.border};

      &:hover,
      &:focus {
        background-color: ${props.theme.colors.border};
      }
    `};

  ${props =>
    props.variant === 'warning' &&
    css`
      border-color: ${props.theme.colors.warning};
      color: ${props.theme.colors.warning};

      &:hover,
      &:focus {
        background-color: ${props.theme.colors.warning};
        color: white;
      }
    `};

  ${props =>
    props.circle &&
    css`
      border-radius: 50%;
      padding: 0;
      width: 3.6rem;
      height: 3.6rem;
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `};

  ${props =>
    props.small &&
    css`
      height: auto;
      padding: calc(${props.theme.layout.spacing.small} / 2);
    `};

  ${props =>
    props.opaque &&
    css`
      background-color: ${props.theme.colors.contentBackground};
    `};
`;

const Button = styled.button<Props>`
  ${ButtonStyles};
`;

const ButtonLink = styled(({ ...props }) => <Link {...props} />)<Props>`
  ${ButtonStyles};
`;

const ButtonExternalLink = styled.a<Props>`
  ${ButtonStyles};
`;

const IconButton = styled(Button)`
  i {
    margin-right: 0;
  }
`;

interface ButtonListProps {
  align?: 'left' | 'right' | 'center' | 'stretch';
  gutterTop?: boolean;
  gutterBottom?: boolean;
  tight?: boolean;
  vertical?: boolean;
  dontForceMobileVertical?: boolean;
}

const ButtonList = styled.div<ButtonListProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > *:not(:last-child) {
    margin-right: ${props => props.theme.layout.spacing.normal};

    ${props =>
      props.tight &&
      css`
        margin-right: ${props => props.theme.layout.spacing.small};
      `};
  }

  ${props =>
    props.align === 'center' &&
    css`
      justify-content: center;
    `};

  ${props =>
    props.align === 'right' &&
    css`
      justify-content: flex-end;
    `};

  ${props =>
    props.align === 'stretch' &&
    css`
      justify-content: stretch;

      > * {
        flex: 1;
      }
    `};

  ${props =>
    props.gutterTop &&
    css`
      margin-top: ${props.theme.layout.spacing.normal};
    `};

  ${props =>
    props.gutterBottom &&
    css`
      margin-bottom: ${props.theme.layout.spacing.normal};
    `};

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;

      > * {
        width: 100%;
      }

      > *:not(:last-child) {
        margin-bottom: ${props => props.theme.layout.spacing.normal};
        margin-right: 0;

        ${props.tight &&
          css`
            margin-bottom: ${props => props.theme.layout.spacing.small};
          `};
      }
    `};

  ${props =>
    !props.dontForceMobileVertical &&
    css`
      @media (max-width: ${mobileThreshold}px) {
        flex-direction: column;

        > * {
          width: 100%;
          margin-left: 0;
        }

        > *:not(:last-child) {
          margin-bottom: ${props => props.theme.layout.spacing.small};
          margin-right: 0;
        }
      }
    `};
`;

const InvisiStyles = css`
  background: transparent;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};

  &:hover,
  &:focus {
    transform: scale(1.1);
    text-decoration: none;
  }

  &:active {
    transform: scale(1);
  }
`;

const InvisiButton = styled.button`
  ${InvisiStyles};
`;

const InvisiLinkButton = styled(({ ...props }) => <Link {...props} />)`
  ${InvisiStyles};
`;

const TextButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.textSecondary};
  text-decoration: none;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  > i {
    margin-left: 0.25em;
  }
`;

export default Button;
export {
  ButtonLink,
  ButtonExternalLink,
  IconButton,
  ButtonList,
  InvisiLinkButton,
  TextButton,
  InvisiButton
};
