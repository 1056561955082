import { DataPoint, OrganizationApp } from '../types/apiResponses';

function labelDataPoints(
  dataPoints: DataPoint[],
  apps: OrganizationApp[]
): DataPoint[] {
  return dataPoints.map(d => labelDataPoint(d, apps));
}

function labelDataPoint(
  dataPoint: DataPoint,
  apps: OrganizationApp[]
): DataPoint {
  if (dataPoint.app) {
    const app = apps.find(a => a.app === dataPoint.app);

    if (app) {
      return {
        ...dataPoint,
        label: app.appData!.name + ': ' + dataPoint.label
      };
    }
  }

  return dataPoint;
}

export default labelDataPoints;
export { labelDataPoint };
