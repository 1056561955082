import React, { FunctionComponent } from 'react';
import styled from '../../util/styled';

interface Props {
  value: number;
}

const Wrapper = styled.div`
  width: 100%;
  height: 6px;
`;

const Bar = styled.div<Props>`
  height: 6px;
  width: ${props => props.value}%;
  background-color: ${props => props.theme.colors.primary};
`;

const Progress: FunctionComponent<Props> = ({ value }) => (
  <Wrapper>
    <Bar value={value} />
  </Wrapper>
);

export default Progress;
