import React, { useContext, ReactNode, useState, useEffect } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import InfiniteScroller from 'react-infinite-scroller';
import MonacoEditor from 'react-monaco-editor';
import Grid from '@material-ui/core/Grid';
import moment from '../../util/moment';
import {
  Automation,
  Trigger,
  Organization,
  Path,
  Mailer,
  EmailTemplate
} from '../../types/apiResponses';
import { TriggerType, DurationTypes, MessageType } from '../../constants/enums';
import history from '../../util/history';
import useForm from '../../hooks/useForm';
import useApi, { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container, Flex } from '../../helpers/Layout';
import Text from '../../components/Text';
import Content from '../../components/Content';
import Form, { Label, Input, Select, TextArea } from '../../components/Form';
import EmptyState from '../../components/EmptyState';
import Card, {
  CardLink,
  CardContent,
  CardIcon,
  CardExternalLink,
  CardMedia
} from '../../components/Card';
import Button, {
  ButtonExternalLink,
  ButtonList,
  ButtonLink
} from '../../components/Button';
import Heading from '../../components/Heading';
import Menu, {
  MenuItem,
  MenuSplitter,
  MenuItemButton
} from '../../components/Menu';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Expander from '../../components/Expander';
import Icon from '../../components/Icon';
import { UiContext } from '../../context/Ui';
import { OrganizationContext } from '../../context/Organization';
import localize from '../../util/localize';
import { triggerTypes } from '../../constants/localization';
import Table, {
  TableHead,
  TableBody,
  Tr,
  Td,
  Th
} from '../../components/Table';
import Editor from '../EmailTemplates/Editor';
import HTMLPreview from '../../components/HTMLPreview';
import {
  PickTemplateModal,
  CreateParameterModal,
  renderProfileSelectionRule,
  PreviewPathModal
} from '../Automation/Show';
import Hr from '../../components/Hr';
import getTheme, { Theme } from '../../util/getTheme';
import ReactDatePicker from '../../components/DatePicker';

const theme = getTheme();

function renderMenu(
  organizationID: string,
  baseUrl: string,
  isUpdate: boolean,
  automationID?: string,
  automation?: Automation
) {
  return (
    <>
      <Menu>
        <MenuItem
          exact
          to={baseUrl + (isUpdate ? `/${automationID}` : '/opprett')}
          icon={<Icon>info</Icon>}
        >
          Informasjon
        </MenuItem>
        {isUpdate ? (
          <>
            <MenuItem
              exact
              to={
                baseUrl +
                (isUpdate ? `/${automationID}/utlosere` : '/opprett/utlosere')
              }
              icon={<Icon>access_time</Icon>}
            >
              Utløsere
            </MenuItem>
            <MenuItem
              exact
              to={
                baseUrl +
                (isUpdate ? `/${automationID}/meldinger` : '/opprett/meldinger')
              }
              icon={<Icon>message</Icon>}
            >
              Meldinger
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItemButton disabled icon={<Icon>access_time</Icon>}>
              Utløsere
            </MenuItemButton>
            <MenuItemButton disabled icon={<Icon>message</Icon>}>
              Meldinger
            </MenuItemButton>
          </>
        )}
      </Menu>
      {isUpdate && automation && automation.triggers.some(t => t.fulfilled) && (
        <>
          <MenuSplitter />
          <Menu>
            <MenuItem
              exact
              to={baseUrl + `/${automationID}/utsendelser`}
              icon={<Icon>local_shipping</Icon>}
            >
              Utsendelser
            </MenuItem>
            <MenuItem
              to={
                '/organisasjoner/' +
                organizationID +
                '/rapporter/utsendelser/' +
                automationID
              }
              icon={<Icon>insert_chart_outlined</Icon>}
            >
              Rapporter
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}

interface CreateParams {
  organizationID: string;
  automationID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const automationID = props.match.params.automationID;
  const isUpdate = automationID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/automations';
  const endpoint = isUpdate ? baseEndpoint + '/' + automationID : baseEndpoint;
  const baseUrl = '/organisasjoner/' + organizationID + '/utsendelser';

  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID"
        render={() => (
          <Basic
            organizationID={organizationID}
            isUpdate={isUpdate}
            automationID={automationID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/utlosere"
        render={() => (
          <Triggers
            organizationID={organizationID}
            isUpdate={isUpdate}
            automationID={automationID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/utlosere/:triggerID"
        component={CreateTrigger}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/meldinger"
        render={() => (
          <Paths
            organizationID={organizationID}
            isUpdate={isUpdate}
            automationID={automationID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/meldinger/:pathID"
        component={CreatePath}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/meldinger/:pathID/utform"
        component={Editor}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/:automationID/utsendelser"
        render={() => (
          <Fulfills
            organizationID={organizationID}
            isUpdate={isUpdate}
            automationID={automationID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
    </Switch>
  );
}

interface BasicProps {
  organizationID: string;
  isUpdate: boolean;
  endpoint: string;
  baseUrl: string;
  automationID?: string;
}

function Basic(props: BasicProps) {
  const { isUpdate, organizationID, endpoint, baseUrl, automationID } = props;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Automation>(
    {
      _id: '',
      name: '',
      organization: '',
      triggers: [],
      paths: [],
      active: false
    },
    {
      onSuccess: automation => {
        if (!isUpdate) {
          history.push((automation as Automation)._id + '/utlosere');
        }
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<Automation | null>({
    method: 'DELETE',
    endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push(baseUrl);
    }
  });

  const [__, duplicating, duplicate] = useApi<Automation | null>({
    method: 'POST',
    endpoint: endpoint + '/duplicate',
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil lage en kopi?',
    onSuccess: (shipment: Automation | null) => {
      if (shipment) {
        history.push(baseUrl + '/' + shipment._id);
        window.location.reload();
      }
    }
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} utsendelse
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(organizationID, baseUrl, isUpdate, automationID, data)}
          {canActivate(data) && (
            <>
              <MenuSplitter />
              <Menu>
                <ActiveState
                  automation={data}
                  onSet={() => setField('active', !data.active)}
                />
              </Menu>
            </>
          )}
          <MenuSplitter />
          <Menu>
            <MenuItemButton
              onClick={() => duplicate()}
              icon={<Icon>file_copy</Icon>}
              primary
            >
              Lag kopi
            </MenuItemButton>
            <MenuItemButton
              onClick={() => destroy()}
              icon={<Icon>delete_outline</Icon>}
              warning
            >
              Slett
            </MenuItemButton>
          </Menu>
        </Sidebar>
        <Content sidebar>
          <BusyBoy
            busy={loadingPrefill || submitting || deleting || duplicating}
            exposeChildren
          >
            <Container spacious>
              <Form onSubmit={submit}>
                <Label htmlFor="name">Navn *</Label>
                <Input
                  id="label"
                  type="text"
                  required
                  placeholder="Min utsendelse"
                  value={data.name}
                  onChange={e => setField('name', e.target.value)}
                />
                <ButtonList align="right">
                  <ButtonLink to="../utsendelser">Avbryt</ButtonLink>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </ButtonList>
              </Form>
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

function Triggers(props: BasicProps) {
  const { isUpdate, organizationID, endpoint, baseUrl, automationID } = props;

  const organization = useContext(OrganizationContext).data;

  const [automation, loading, fetch, initialFetch] = useApi<Automation>({
    endpoint,
    initialData: {
      _id: '',
      name: '',
      organization: '',
      active: false,
      triggers: [],
      paths: []
    },
    fetchOnMount: true
  });

  const triggers = automation.triggers;

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Utløsere
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(
            organizationID,
            baseUrl,
            isUpdate,
            automationID,
            automation
          )}
          {canActivate(automation) && (
            <>
              <MenuSplitter />
              <Menu>
                <ActiveState automation={automation} onSet={fetch} />
              </Menu>
            </>
          )}
        </Sidebar>
        <Content sidebar>
          <Container hugBottom spacious>
            <ButtonLink to="utlosere/opprett">
              <Icon>add</Icon> Ny utløser
            </ButtonLink>
          </Container>
          <BusyBoy busy={loading}>
            <Container spacious>
              {!loading && initialFetch && triggers.length < 1 && (
                <EmptyState>Ingen utløsere!</EmptyState>
              )}
              {triggers.length > 0 && (
                <Grid container spacing={24} direction="column">
                  {triggers.map(t => (
                    <Grid key={t._id} item>
                      <CardLink to={`utlosere/${t._id}`} clickable horizontal>
                        <CardContent tight>
                          <Text>{localize(triggerTypes, `${t.type}`)}</Text>
                          <Text variant="subheading">
                            {renderTriggerKey(t, organization)}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface CreateTriggerParams {
  organizationID: string;
  automationID: string;
  triggerID: string;
}

function CreateTrigger(props: RouteComponentProps<CreateTriggerParams>) {
  const organizationID = props.match.params.organizationID;
  const automationID = props.match.params.automationID;
  const triggerID = props.match.params.triggerID;
  const isUpdate = triggerID !== 'opprett';

  const organization = useContext(OrganizationContext).data;

  const endpoint = 'organizations/' + organizationID + '/automations';
  const prefillEndpoint = endpoint + '/' + automationID;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Trigger>(
    {
      type: 0,
      delay: {
        durationType: DurationTypes.Hours,
        value: 0
      }
    },
    {
      endpoint:
        prefillEndpoint + '/triggers' + (isUpdate ? `/${triggerID}` : ''),
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? prefillEndpoint : undefined,
      prefillFn: isUpdate
        ? automation =>
            (automation as Automation).triggers.find(t => t._id === triggerID)
        : undefined,
      onSuccess: () => {
        if (!isUpdate) {
          history.push('../meldinger');
        } else {
          history.push('../utlosere');
        }
      }
    }
  );

  const [_, deleting, destroy] = useApi<Automation | null>({
    method: 'DELETE',
    endpoint: prefillEndpoint + '/triggers/' + data._id,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../utlosere');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} utløser
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="type">Utløsertype *</Label>
            <Select
              id="type"
              required
              disabled={isUpdate}
              value={data.type}
              onChange={e => setField('type', parseInt(e.target.value))}
            >
              <option value={0}>{localize(triggerTypes, '0')}</option>
              <option value={1}>{localize(triggerTypes, '1')}</option>
            </Select>
            {renderTriggerFields(data, organization, setField)}
            {(data.type === TriggerType.DataPointUpdate ||
              data.type === TriggerType.DataPointCategoryUpdate) && (
              <>
                <Label htmlFor="delay">Utsett sending med…</Label>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <Input
                      type="number"
                      value={data.delay.value}
                      onChange={e =>
                        setField('delay', {
                          ...data.delay,
                          value: parseInt(e.target.value)
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={data.delay.durationType}
                      onChange={e =>
                        setField('delay', {
                          ...data.delay,
                          durationType: e.target.value
                        })
                      }
                    >
                      <option value={DurationTypes.Hours}>timer</option>
                      <option value={DurationTypes.Days}>dager</option>
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}
            <ButtonList align="right">
              <ButtonLink to="../utlosere">Avbryt</ButtonLink>
              {isUpdate && (
                <ButtonExternalLink onClick={() => destroy()} variant="warning">
                  Slett
                </ButtonExternalLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

function Paths(props: BasicProps) {
  const { isUpdate, organizationID, endpoint, baseUrl, automationID } = props;

  const [automation, loading, fetch, initialFetch] = useApi<Automation>({
    endpoint,
    initialData: {
      _id: '',
      name: '',
      organization: '',
      active: false,
      triggers: [],
      paths: []
    },
    fetchOnMount: true
  });

  const paths = automation.paths;

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Meldinger
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(
            organizationID,
            baseUrl,
            isUpdate,
            automationID,
            automation
          )}
          {canActivate(automation) && (
            <>
              <MenuSplitter />
              <Menu>
                <ActiveState automation={automation} onSet={fetch} />
              </Menu>
            </>
          )}
        </Sidebar>
        <Content sidebar>
          <Container hugBottom spacious>
            <ButtonLink to="meldinger/opprett">
              <Icon>add</Icon> Ny melding
            </ButtonLink>
          </Container>
          <BusyBoy busy={loading}>
            <Container spacious>
              {!loading && initialFetch && paths.length < 1 && (
                <EmptyState>Ingen meldinger!</EmptyState>
              )}
              {paths.length > 0 && (
                <Grid container spacing={24}>
                  {paths.map(p => (
                    <Grid key={p._id} item sm={4}>
                      <CardLink to={`meldinger/${p._id}`} clickable>
                        {p.email.preview && (
                          <CardMedia variant="full">
                            <img src={p.email.preview} alt={p.name} />
                          </CardMedia>
                        )}
                        <Flex>
                          <CardContent tight>
                            <Text>{p.name}</Text>
                            <Text variant="subheading">{p.email.subject}</Text>
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </Flex>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

type CreateEmailType = 'template' | 'plain' | 'design' | 'sms';

interface CreatePathParams {
  organizationID: string;
  automationID: string;
  pathID: string;
}

function CreatePath(props: RouteComponentProps<CreatePathParams>) {
  const organizationID = props.match.params.organizationID;
  const automationID = props.match.params.automationID;
  const pathID = props.match.params.pathID;
  const isUpdate = pathID !== 'opprett';

  const organization = useContext(OrganizationContext).data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [type, setType] = useState('template' as CreateEmailType);
  const [selectedTemplate, setSelectedTemplate] = useState(
    null as EmailTemplate | null
  );

  const endpoint = 'organizations/' + organizationID + '/automations';
  const prefillEndpoint = endpoint + '/' + automationID;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    setData,
    submit
  } = useForm<Path>(
    {
      name: '',
      parameters: [],
      type: MessageType.Email,
      email: {
        subject: '',
        plain: '',
        html: '',
        templateRef: null
      }
    },
    {
      endpoint: prefillEndpoint + '/paths' + (isUpdate ? `/${pathID}` : ''),
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? prefillEndpoint : undefined,
      prefillFn: isUpdate
        ? automation => {
            const path = (automation as Automation).paths.find(
              p => p._id === pathID
            );

            if (path) {
              if (path.email.json) {
                setType('design');
              } else if (!path.email.templateRef) {
                if (path.type === MessageType.SMS) {
                  setType(MessageType.SMS);
                } else {
                  setType('plain');
                }
              } else if (path.type === MessageType.SMS) {
                setType(MessageType.SMS);
              }
            }

            return path;
          }
        : undefined,
      onSuccess: path => {
        {
          if (isUpdate || type !== 'design') {
            history.push('../meldinger');
          } else {
            spawnModal(
              <PickTemplateModal
                organizationID={organizationID}
                automationID={automationID}
                path={path as Path}
                close={despawnModal}
              />,
              'pickTemplate'
            );
          }
        }
      }
    }
  );

  const [_, deleting, destroy] = useApi<Automation | null>({
    method: 'DELETE',
    endpoint: prefillEndpoint + '/paths/' + data._id,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../meldinger');
    }
  });

  const [templates] = useApi<EmailTemplate[]>({
    endpoint:
      'organizations/' + organizationID + '/email-templates/search?notEmpty=1',
    initialData: [],
    fetchOnMount: true
  });

  useEffect(() => {
    if (data.email.templateRef) {
      const template = templates.find(t => t._id === data.email.templateRef);

      if (template) {
        setSelectedTemplate(template);
      }
    }
  }, [templates.length, data.email.templateRef]);

  useEffect(() => {
    if (data.type === 'sms') {
      setField('email', { ...data.email, subject: 'SMS' });
    }
  }, [data.type]);

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} melding
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Meldingsnavn *</Label>
            <Input
              id="name"
              type="text"
              placeholder="Min melding"
              required
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <Label htmlFor="type">Meldingstype *</Label>
            <Select
              id="type"
              name="type"
              defaultValue="template"
              disabled={isUpdate}
              value={type}
              onChange={e => {
                setType(e.target.value as CreateEmailType);
                setData({
                  ...data,
                  type:
                    e.target.value === MessageType.SMS
                      ? MessageType.SMS
                      : MessageType.Email,
                  email: {
                    subject: '',
                    plain: '',
                    html: '',
                    templateRef: null
                  }
                });
              }}
            >
              <option value="template">E-postmal</option>
              <option value="plain">Enkel HTML</option>
              <option value="design">Lag ny fra e-postmal</option>
              {!!organization.smsProviderSettings && (
                <option value="sms">SMS</option>
              )}
            </Select>
            {type === 'template' && (
              <>
                <Label htmlFor="template">E-postmal *</Label>
                <Select
                  id="template"
                  name="template"
                  defaultValue="none"
                  value={data.email.templateRef || 'none'}
                  onChange={e =>
                    setField('email', {
                      ...data.email,
                      templateRef: e.target.value
                    })
                  }
                >
                  <option value="none" disabled>
                    Vennligst velg…
                  </option>
                  {templates.map(t => (
                    <option key={t._id} value={t._id}>
                      {t.name}
                    </option>
                  ))}
                </Select>
              </>
            )}
            {type === 'plain' && (
              <>
                <Label>HTML *</Label>
                <MonacoEditor
                  language="html"
                  theme={theme === Theme.Dark ? 'hc-black' : 'vs'}
                  height="300"
                  options={{
                    colorDecorators: true,
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    contextmenu: false,
                    extraEditorClassName:
                      theme === Theme.Dark
                        ? 'profiler-monaco-dark'
                        : 'profiler-monaco-light'
                  }}
                  value={data.email.html || ''}
                  onChange={html =>
                    setField('email', {
                      ...data.email,
                      html
                    })
                  }
                />
              </>
            )}
            {type === MessageType.SMS && (
              <>
                <Label htmlFor="plain">SMS-melding *</Label>
                <TextArea
                  id="plain"
                  required
                  value={data.email.plain}
                  placeholder="Hei, hvordan…"
                  onChange={e =>
                    setField('email', {
                      ...data.email,
                      plain: e.target.value
                    })
                  }
                />
              </>
            )}
            {type !== MessageType.SMS && (
              <>
                <Label htmlFor="subject">Emnefelt *</Label>
                <Input
                  id="subject"
                  type="text"
                  required
                  value={data.email.subject}
                  placeholder="Hei, hvordan…"
                  onChange={e =>
                    setField('email', {
                      ...data.email,
                      subject: e.target.value
                    })
                  }
                />
              </>
            )}

            <Expander label={`Filter (${data.parameters.length})`} open>
              <Grid container spacing={24}>
                {data.parameters.map(p => {
                  const list = organization.lists.find(
                    l => l._id === p.targetRef
                  );

                  const dataPoint = organization.dataPoints.find(
                    d => d._id === p.targetRef
                  );

                  return (
                    <Grid key={p._id} item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreateParameterModal
                              defaultData={p}
                              organization={organization}
                              onCreate={parameter => {
                                const index = data.parameters.findIndex(pa =>
                                  pa.__ref
                                    ? pa.__ref === parameter.__ref
                                    : pa._id === parameter._id
                                );

                                if (index !== -1) {
                                  const copy = [...data.parameters];
                                  copy[index] = parameter;

                                  setField('parameters', copy);
                                }
                              }}
                              onDelete={ref => {
                                setField(
                                  'parameters',
                                  data.parameters.filter(pa =>
                                    pa.__ref ? pa.__ref !== ref : pa._id !== ref
                                  )
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createParameter'
                          );
                        }}
                      >
                        <CardContent tight>
                          {renderProfileSelectionRule(p, list, dataPoint)}
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  );
                })}
                <Grid item xs={6}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    secondary
                    onClick={e => {
                      e.preventDefault();
                      spawnModal(
                        <PreviewPathModal
                          organization={organization}
                          forceEmail={type !== 'sms'}
                          forcePhone={type === 'sms'}
                          //consents={[DefaultConsents.Email]} Re-enable this
                          path={data}
                          close={despawnModal}
                        />,
                        'previewPath'
                      );
                    }}
                  >
                    <CardContent tight>
                      <Text>Forhåndsvis filter</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>remove_red_eye</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
                <Grid item xs={6}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    primary={data.parameters.length < 1}
                    secondary={data.parameters.length > 0}
                    onClick={e => {
                      e.preventDefault();
                      spawnModal(
                        <CreateParameterModal
                          organization={organization}
                          onCreate={parameter => {
                            setField('parameters', [
                              ...data.parameters,
                              parameter
                            ]);
                          }}
                          onDelete={ref => {
                            setField(
                              'parameters',
                              data.parameters.filter(p => p._id !== ref)
                            );
                          }}
                          close={despawnModal}
                        />,
                        'createParameter'
                      );
                    }}
                  >
                    <CardContent tight>
                      <Text>Legg til regel</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>add</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              </Grid>
            </Expander>
            <ButtonList align="right">
              <ButtonLink to="../meldinger">
                {isUpdate ? 'Tilbake' : 'Avbryt'}
              </ButtonLink>
              {isUpdate && (
                <ButtonExternalLink
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    destroy();
                  }}
                  variant="warning"
                >
                  Slett
                </ButtonExternalLink>
              )}
              {isUpdate && type === 'design' && (
                <ButtonLink to={pathID + '/utform'}>
                  Gå til design-modus
                </ButtonLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting
                  ? 'Lagrer…'
                  : isUpdate || type !== 'design'
                  ? 'Lagre'
                  : 'Lagre og start å designe'}
              </Button>
            </ButtonList>
            {(data.email.html || selectedTemplate) && (
              <>
                <Hr />
                <Text variant="headline">Forhåndsvisning</Text>
                <HTMLPreview
                  html={
                    selectedTemplate
                      ? selectedTemplate.html
                      : !data.email.json
                      ? data.email.html.replace(/(?:\r\n|\r|\n)/g, '<br>')
                      : data.email.html
                  }
                />
              </>
            )}
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

function Fulfills(props: BasicProps) {
  const { endpoint, organizationID, baseUrl, automationID } = props;

  const [fulfills, loading, hasMore, fetch, initialFetch] = useSearch<Mailer>({
    endpoint: endpoint + '/fulfills'
  });

  const [shipment] = useApi<Automation | null>({
    endpoint,
    fetchOnMount: true,
    initialData: null
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Utsendelser
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderMenu(
            organizationID,
            baseUrl,
            true,
            automationID,
            shipment ? shipment : undefined
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading} exposeChildren>
              {!loading && initialFetch && fulfills.length < 1 && (
                <EmptyState>Ingen utsendelser!</EmptyState>
              )}
              <InfiniteScroller
                hasMore={!loading && hasMore}
                initialLoad
                loadMore={() =>
                  fetch({ endpoint: endpoint + '/fulfills', paginate: true })
                }
              >
                {fulfills.length > 0 && (
                  <Card>
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th>Til</Th>
                          <Th>Meldingsnavn</Th>
                          <Th>Emnefelt</Th>
                          <Th>Sendt</Th>
                          <Th>Åpnet</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {fulfills.map(f => {
                          const path = shipment
                            ? shipment.paths.find(p => p._id === f.pathRef)
                            : undefined;

                          return (
                            <Tr key={f._id}>
                              <Td>{f.to}</Td>
                              <Td>{path ? path.name : 'Laster…'}</Td>
                              <Td>{f.subject}</Td>
                              <Td>
                                {f.sent && f.sentDate
                                  ? moment(f.sentDate).format(
                                      'D.M.YYYY [kl] HH:mm:ss'
                                    )
                                  : 'Sendes ca ' +
                                    moment(f.scheduleDate).format(
                                      'D.M.YYYY [kl] HH:mm'
                                    )}
                              </Td>
                              <Td>
                                {f.opened && f.openDate
                                  ? moment(f.openDate).format(
                                      'D.M.YYYY [kl] HH:mm:ss'
                                    )
                                  : 'Ikke åpnet'}
                              </Td>
                            </Tr>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Card>
                )}
              </InfiniteScroller>
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

function renderTriggerKey(
  trigger: Trigger,
  organization: Organization
): ReactNode {
  switch (trigger.type) {
    case TriggerType.Scheduled:
      return moment(trigger.scheduleDate || '').format('D.M.YY [kl.] HH:mm:ss');
    case TriggerType.DataPointUpdate:
      const dataPoint = organization.dataPoints.find(
        d => d._id === trigger.key
      );

      return dataPoint ? dataPoint.label : trigger.key;
    case TriggerType.DataPointCategoryUpdate:
      const dataPointCategory = organization.dataPointCategories.find(
        d => d._id === trigger.key
      );

      return dataPointCategory ? dataPointCategory.label : trigger.key;
    default:
      return null;
  }
}

function renderTriggerFields(
  trigger: Trigger,
  organization: Organization,
  setField: (key: string, value: any) => any
): ReactNode {
  switch (trigger.type) {
    case TriggerType.Scheduled:
      return (
        <>
          <Label htmlFor="scheduleDate">Tidspunkt å sende *</Label>
          <ReactDatePicker
            id="scheduleDate"
            selected={
              trigger.scheduleDate
                ? moment(trigger.scheduleDate).toDate()
                : undefined
            }
            onChange={value => setField('scheduleDate', value)}
            showTimeSelect
            dateFormat="dd.MM.YYY HH:mm"
          />
        </>
      );
    case TriggerType.DataPointUpdate:
      return (
        <>
          <Label htmlFor="key">Karakteristikk *</Label>
          <Select
            id="key"
            required
            value={trigger.key ? trigger.key : ''}
            onChange={e => setField('key', e.target.value)}
          >
            <option value="" disabled>
              Vennligst velg…
            </option>
            {organization.dataPoints.map(d => (
              <option key={d._id} value={d._id}>
                {d.label}
              </option>
            ))}
          </Select>
        </>
      );
    case TriggerType.DataPointCategoryUpdate:
      return (
        <>
          <Label htmlFor="key">Karateristikkategori *</Label>
          <Select
            id="key"
            required
            value={trigger.key ? trigger.key : ''}
            onChange={e => setField('key', e.target.value)}
          >
            <option value="" disabled>
              Vennligst velg…
            </option>
            {organization.dataPointCategories.map(c => (
              <option key={c._id} value={c._id}>
                {c.label}
              </option>
            ))}
          </Select>
        </>
      );
    default:
      return null;
  }
}

interface ActiveStateProps {
  automation: Automation;
  onSet?: () => any;
}

function ActiveState(props: ActiveStateProps) {
  const { automation, onSet } = props;

  const endpoint =
    'organizations/' +
    automation.organization +
    '/automations/' +
    automation._id +
    '/send';

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [_, settingActive, set] = useApi<null>({
    endpoint,
    initialData: null,
    onSuccess: () => {
      if (typeof onSet === 'function') {
        onSet();
      }

      despawnModal('confirmAutomation');
    }
  });

  const setFn = () => {
    set({
      endpoint,
      method: 'POST',
      body: {
        active: !automation.active
      }
    });
  };

  const plan = automation.triggers.some(t => t.type === TriggerType.Scheduled);

  return (
    <MenuItemButton
      icon={<Icon>{automation.active ? 'pause' : 'send'}</Icon>}
      primary={!automation.active}
      onClick={() => {
        if (!automation.active) {
          spawnModal(
            <ConfirmAutomationModal
              automation={automation}
              close={despawnModal}
              onConfirm={setFn}
            />,
            'confirmAutomation'
          );
        } else {
          setFn();
        }
      }}
    >
      {settingActive
        ? 'Laster…'
        : automation.active
        ? 'Sett på pause'
        : plan
        ? 'Planlegg'
        : 'Send'}
    </MenuItemButton>
  );
}

interface ConfirmAutomationModalProps {
  automation: Automation;
  onConfirm: () => any;
  close: (id: string) => any;
}

export function ConfirmAutomationModal(props: ConfirmAutomationModalProps) {
  const { automation, onConfirm, close } = props;

  const organization = useContext(OrganizationContext).data;

  const [loading, setLoading] = useState(false);

  const sendOnTime = automation.triggers.some(
    t => t.type === TriggerType.Scheduled
  );

  return (
    <Container minWidth="38rem">
      <Text variant="title" bottomGutter>
        {sendOnTime ? 'Planlegg' : 'Send'} utsendelse
      </Text>
      <Text bottomGutter>
        Er du sikker på at du vil {sendOnTime ? 'planlegge' : 'sende'}{' '}
        utsendelsen?
      </Text>
      {automation.triggers.length > 0 && (
        <Text bottomGutter>
          Den vil sendes:{' '}
          <strong>
            {automation.triggers[0].type === TriggerType.SendNow
              ? 'Så snart som mulig'
              : moment(automation.triggers[0].scheduleDate || '').format(
                  'D.M.YY [kl.] HH:mm:ss'
                )}
          </strong>
        </Text>
      )}
      <Hr />
      <Text variant="headline" bottomGutter>
        Meldinger
      </Text>
      <Card secondary>
        <Table>
          <TableHead>
            <Th>Meldingsnavn</Th>
            <Th>Mottakere</Th>
          </TableHead>
          <TableBody>
            {automation.paths.map(p => (
              <MessageRow
                key={p._id}
                automation={automation}
                organization={organization}
                path={p}
                sendOnTime={sendOnTime}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
      {sendOnTime ? (
        <Text variant="subheading" topGutter>
          <span style={{ display: 'inline-block', maxWidth: '30rem' }}>
            * Ved utsendelser som sendes på et spesifikt tidspunkt, vil filteret
            brukes på tidspunktet utsendelsen sendes. Derfor vil ikke dette
            tallet være 100% presist akkurat nå.
          </span>
        </Text>
      ) : (
        ''
      )}
      <ButtonList align="right" gutterTop>
        <ButtonExternalLink
          href="#"
          onClick={e => {
            e.preventDefault();
            close('previewPath');
          }}
        >
          Avbryt
        </ButtonExternalLink>
        <ButtonExternalLink
          variant="primary"
          href="#"
          onClick={e => {
            e.preventDefault();

            if (!loading) {
              setLoading(true);
              onConfirm();
            }
          }}
        >
          {loading
            ? sendOnTime
              ? 'Planlegger…'
              : 'Sender…'
            : sendOnTime
            ? 'Planlegg!'
            : 'Send!'}
        </ButtonExternalLink>
      </ButtonList>
    </Container>
  );
}

interface MessageRowProps {
  automation: Automation;
  organization: Organization;
  path: Path;
  sendOnTime: boolean;
}

function MessageRow(props: MessageRowProps) {
  const { automation, organization, path, sendOnTime } = props;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [response, loading] = useApi<{ count: number }>({
    endpoint: `organizations/${automation.organization}/tools/preview-path`,
    method: 'POST',
    body: { path },
    initialData: { count: 0 },
    fetchOnMount: true,
    queryParams: {
      forceEmail: path.type !== MessageType.SMS,
      forcePhone: path.type === MessageType.SMS,
      count: true
      //consents: DefaultConsents.Email Re-enable this
    }
  });

  return (
    <Tr>
      <Td>{path.name}</Td>
      <Td>
        {loading ? (
          'Laster…'
        ) : (
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              spawnModal(
                <PreviewPathModal
                  organization={organization}
                  forceEmail={path.type !== MessageType.SMS}
                  forcePhone={path.type === MessageType.SMS}
                  path={path}
                  close={despawnModal}
                  //consents={[DefaultConsents.Email]} Re-enable this
                />,
                'previewPath'
              );
            }}
          >
            {response.count}
          </a>
        )}
        {sendOnTime ? ' *' : ''}
      </Td>
    </Tr>
  );
}

function canActivate(automation: Automation): boolean {
  return (
    automation.active ||
    (automation.triggers.length > 0 &&
      automation.paths.length > 0 &&
      automation.triggers.some(t => !t.fulfilled))
  );
}

export default Create;
