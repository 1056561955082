import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Event } from '../../types/apiResponses';
import { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const baseEndpoint = 'organizations/' + organizationID + '/events';

  const [events, loading, hasMore, search, hasFetched] = useSearch<Event>({
    endpoint: baseEndpoint + '/search',
    fetchOnMount: true
  });

  return (
    <>
      <Helper id="events" title="Hva er arrangementer?">
        <Text>
          Arrangementer lar deg invitere kontakter til arrangementer! Enkelt og
          greit.
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Arrangementer
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem
                to="arrangementer/opprett"
                icon={<Icon>add</Icon>}
                primary
              >
                Nytt arrangement
              </MenuItem>
              {hasFetched && events.length < 1 && (
                <ShowOff>
                  Legg til ditt første arrangement ved å trykke på knappen over!
                </ShowOff>
              )}
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading} exposeChildren={hasFetched}>
              {hasFetched && events.length < 1 && (
                <EmptyState>Ingen arrangementer!</EmptyState>
              )}
              {hasFetched && events.length > 0 && (
                <Grid container spacing={24}>
                  {events.map(p => (
                    <Grid key={p._id} item sm={4}>
                      <CardLink
                        to={'arrangementer/' + p._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{p.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
