import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Organization as IOrganization } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input } from '../../components/Form';
import Button, { ButtonList, ButtonLink } from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';

interface CreateParams {
  organizationID?: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const isUpdate = organizationID !== undefined;
  const baseEndpoint = 'organizations';
  const endpoint = isUpdate
    ? baseEndpoint + '/' + organizationID
    : baseEndpoint;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<IOrganization>(
    {
      name: ''
    },
    {
      onSuccess: (o: IOrganization) =>
        history.push('/organisasjoner/' + o._id + '/karakteristikker'),
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? 'organizations/' + organizationID : undefined
    }
  );

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} organisasjon
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min organisasjon (eksempel)"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <ButtonList align="right">
              <ButtonLink to="/">Avbryt</ButtonLink>
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Create;
