import React, { useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import BusyBoy from '../../helpers/BusyBoy';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Card from '../../components/Card';
import useApi from '../../hooks/useApi';
import { Thread, Contact } from '../../types/apiResponses';
import Helper from '../../components/Helper';

import Correspondence from '../../components/Correspondence';
import { ThreadStatusFilter } from '../../constants/enums';
import { ContactContext } from '../../context/Contact';
import { AppsContext } from '../../context/Apps';
import { nameWithSleep } from './Show';

interface MessagesParams {
  organizationID: string;
  contactID: string;
  threadID?: string;
}

function Messages(props: RouteComponentProps<MessagesParams>) {
  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const threadID = props.match.params.threadID;
  const endpoint =
    'organizations/' + organizationID + '/correspondence/threads';
  const baseUrl = '/organisasjoner/' + organizationID;

  const contactContext = useContext(ContactContext);
  const apps = useContext(AppsContext).data;

  useApi<Contact | null>({
    endpoint: 'organizations/' + organizationID + '/contacts/' + contactID,
    fetchOnMount: true,
    initialData: null,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  const [thread, loading, fetchThread] = useApi<Thread | null>({
    endpoint,
    initialData: null
  });

  useEffect(() => {
    if (threadID) {
      fetchThread({
        endpoint: endpoint + '/' + threadID
      });
    }
  }, [threadID]);

  return (
    <>
      <Helper id="messages" title="Hvordan bruke meldinger">
        <Text>
          Meldinger-systemet lar deg kommunisere med kundene dine via
          meldingstråder. Du kan selv starte en samtale med en kunde ved å
          opprette en meldingstråd, eller besvare henvendelser fra kunder dersom
          du har satt opp muligheten for det.
        </Text>
        <Text topGutter>
          Alle meldingstråder har en status knyttet til seg.{' '}
          <strong>Åpen</strong>, <strong>lukket</strong> eller{' '}
          <strong>løst</strong>. Hold styr på henvendelsene ved å tilegne hver
          meldingstråd riktig status. Alle nye meldingstråder har status{' '}
          <strong>åpen</strong>.
        </Text>
        <Text topGutter>
          Meldinger blir sendt til kontaktens e-post, og kontakten kan velge å
          besvare meldingen direkte i e-postklienten sin.
        </Text>
        <Text variant="headline" topGutter bottomGutter>
          Tips: Send og les meldinger fra hvor som helst i Profiler
        </Text>
        <Text>
          Ved å trykke på den runde grønne meldingsknappen nederst til høyre kan
          du lese og skrive meldinger uansett hvor du er i Profiler-appen.
        </Text>
      </Helper>
      <Heading sidebar>
        {contactContext.data && (
          <Text element="h1" variant="display3" bottomGutter>
            {nameWithSleep(contactContext.data)}
          </Text>
        )}
        <Text element="h2" variant="title">
          Meldinger
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(
            baseUrl,
            contactID,
            apps,
            contactContext.data
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            {threadID ? (
              <BusyBoy busy={loading}>
                {thread && thread._id === threadID && (
                  <Card>
                    <Correspondence
                      key={threadID}
                      organization={organizationID}
                      defaultFilter={{
                        participant: contactID
                      }}
                      defaultThread={thread}
                    />
                  </Card>
                )}
              </BusyBoy>
            ) : (
              <Card>
                <Correspondence
                  key="threads"
                  organization={organizationID}
                  defaultFilter={{
                    participant: contactID,
                    status: ThreadStatusFilter.All
                  }}
                />
              </Card>
            )}
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default Messages;
