import React, { useContext } from 'react';
import styled from 'styled-components';
import { Main } from '../../components/Wrapper';
import { Organization } from '../../types/apiResponses';
import {
  CardLink,
  CardExternalLink,
  CardContent,
  CardIcon
} from '../../components/Card';
import BusyBoy from '../../helpers/BusyBoy';
import Text from '../../components/Text';
import Fader from '../../helpers/Fader';
import useApi from '../../hooks/useApi';
import { Centerer, VerticalContainer } from '../../helpers/Layout';
import Icon from '../../components/Icon';
import { ButtonExternalLink } from '../../components/Button';
import { UserContext, defaultUser } from '../../context/User';
import logout from '../../util/logout';
import { Permissions } from '../../constants/enums';

const OrganizationsWrapper = styled.div`
  max-width: 40rem;
  width: 100%;
`;

function Organizations() {
  const userContext = useContext(UserContext);

  const [organizations, loading] = useApi<Organization[]>({
    endpoint: 'organizations',
    initialData: [],
    fetchOnMount: true
  });

  async function handleLogout() {
    await logout();
    userContext.setData(defaultUser);
  }

  return (
    <Main>
      <Centerer>
        <OrganizationsWrapper>
          <Fader variant="top">
            <VerticalContainer spacious>
              <Text element="h1" variant="display3" centered>
                Vennligst velg organisasjon
              </Text>
            </VerticalContainer>
            {userContext.data.permissions === Permissions.Admin && (
              <VerticalContainer>
                <CardLink
                  horizontal
                  clickable
                  to="/opprett-organisasjon"
                  secondary
                >
                  <CardContent>
                    <Text>Opprett organisasjon</Text>
                  </CardContent>
                  <CardIcon>
                    <Icon>add</Icon>
                  </CardIcon>
                </CardLink>
              </VerticalContainer>
            )}
            <BusyBoy busy={loading}>
              {organizations.map(o => (
                <VerticalContainer key={o._id}>
                  <CardExternalLink
                    horizontal
                    clickable
                    href={'/organisasjoner/' + o._id + '/profiler'}
                  >
                    <CardContent>
                      <Text>{o.name}</Text>
                    </CardContent>
                    <CardIcon>
                      <Icon>arrow_forward_ios</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </VerticalContainer>
              ))}
            </BusyBoy>
          </Fader>
        </OrganizationsWrapper>
        <ButtonExternalLink
          href="#"
          onClick={e => {
            e.preventDefault();
            handleLogout();
          }}
        >
          Logg ut
        </ButtonExternalLink>
      </Centerer>
    </Main>
  );
}

export default Organizations;
