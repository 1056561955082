import React, { FunctionComponent, ReactNode } from "react";
import xssModule from "xss";
import styled, { css } from "../../util/styled";
import Hr from "../Hr";
import { mobileThreshold } from "../../constants/theme";

const xss = new xssModule.FilterXSS({
  stripIgnoreTag: true,
  stripIgnoreTagBody: true
});

interface ChatProps {
  hug?: boolean;
}

const Chat = styled.div<ChatProps>`
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;

  ${props =>
    !props.hug &&
    css`
      padding: ${props => props.theme.layout.spacing.normal};
    `};
`;

interface ChatItemProps {
  thirdParty?: boolean;
  byline?: ReactNode;
  actions?: ReactNode;
  secondaryContent?: ReactNode;
  spaceful?: boolean;
}

const ChatItemWrapper = styled.div<ChatItemProps>`
  max-width: 70%;

  @media (max-width: ${mobileThreshold}px) {
    max-width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: ${props =>
      props.spaceful
        ? props.theme.layout.spacing.large
        : props.theme.layout.spacing.small};
  }

  ${props =>
    props.thirdParty &&
    css`
      margin-left: auto;
    `};
`;

const Box = styled.div<ChatItemProps>`
  display: inline-block;
  position: relative;
  background-color: ${props => props.theme.colors.contentBackground};
  padding: ${props =>
    `${props.theme.layout.spacing.small} ${props.theme.layout.spacing.normal}`};
  box-shadow: ${props => props.theme.shadows.normal};
  border-radius: 32px
    ${props =>
      props.thirdParty &&
      css`
        background-color: ${props.theme.colors.primary};
        color: white;
        margin-left: auto;

        a {
          color: white !important;
          text-decoration: underline !important;
        }
      `};
`;

export const Byline = styled.div`
  padding: ${props => `.5rem ${props.theme.layout.spacing.normal} 0`};
  font-size: 13px;
  color: ${props => props.theme.colors.textSecondary};
`;

const Actions = styled.div`
  position: absolute;
  right: calc(-${props => props.theme.layout.spacing.normal});
  top: 8px;
`;

const ChatItem: FunctionComponent<ChatItemProps> = ({
  thirdParty,
  byline,
  actions,
  secondaryContent,
  spaceful,
  children
}) => {
  return (
    <ChatItemWrapper thirdParty={thirdParty} spaceful={spaceful || !!byline}>
      <Box thirdParty={thirdParty}>
        {typeof children === "string" ? (
          <div dangerouslySetInnerHTML={{ __html: xss.process(children) }} />
        ) : (
          children
        )}
        {secondaryContent && (
          <>
            <Hr tight />
            {secondaryContent}
          </>
        )}
      </Box>
      {byline && <Byline>{byline}</Byline>}
      {actions && <Byline>{actions}</Byline>}
    </ChatItemWrapper>
  );
};

export default Chat;
export { ChatItem };
