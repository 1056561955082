import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import history from '../../util/history';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Form, { Label, Input } from '../../components/Form';
import { Organization } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import Button, { ButtonList, ButtonLink } from '../../components/Button';
import Text from '../../components/Text';
import Hr from '../../components/Hr';
import Expander from '../../components/Expander';

interface Props {
  organizationID?: string;
}

export default function Update(props: RouteComponentProps<Props>) {
  const organizationID = props.match.params.organizationID;

  const { data, setField, submit, hasMadeChanges, submitting } = useForm<
    Organization
  >(
    {
      _id: '',
      notificationEmail: null,
      name: '',
      members: [],
      dataPoints: [],
      dataPointCategories: [],
      lists: [],
      variables: [],
      settings: {
        defaultInterestWeight: null,
        aggregatorScoreCollection: null,
        aggregatorScoreMessage: null,
        aggregatorScoreDataPoint: null,
        aggregatorScoreDataPointInterest: null
      }
    },
    {
      endpoint: 'organizations/' + organizationID,
      method: 'PATCH',
      prefillEndpoint: 'organizations/' + organizationID,
      onSuccess: () => {
        history.push('../innstillinger');
        window.location.reload();
      }
    }
  );

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Endre innstillinger
        </Text>
      </Container>
      <Container spacious hugTop>
        <Form
          onSubmit={e => {
            submit(e);
          }}
        >
          <Label htmlFor="name">Navn *</Label>
          <Input
            type="text"
            id="name"
            placeholder="Navn"
            value={data.name}
            onChange={e => setField('name', e.target.value)}
          />
          <Label htmlFor="email">E-post</Label>
          <Input
            type="email"
            id="email"
            placeholder="E-post"
            value={data.notificationEmail || ''}
            onChange={e => setField('notificationEmail', e.target.value)}
          />
          <Label htmlFor="color">Fargetema</Label>
          <Input
            type="color"
            id="color"
            placeholder="#05af8a"
            value={data.theme ? data.theme.color || '#05af8a' : '#05af8a'}
            onChange={e => setField('theme', { color: e.target.value })}
          />
          <Expander label="Vektinger">
            <Label htmlFor="defaultInterestWeight">
              Standard interessevekt
            </Label>
            <Input
              type="number"
              id="defaultInterestWeight"
              placeholder="0.75"
              value={data.settings!.defaultInterestWeight || undefined}
              onChange={e =>
                setField('settings', {
                  ...data.settings!,
                  defaultInterestWeight: e.target.value
                })
              }
            />
            <Label htmlFor="aggregatorScoreCollection" gutterTop>
              Profilskår™ innsamlingsvekt
            </Label>
            <Input
              type="number"
              id="aggregatorScoreCollection"
              placeholder="3"
              value={data.settings!.aggregatorScoreCollection || undefined}
              onChange={e =>
                setField('settings', {
                  ...data.settings!,
                  aggregatorScoreCollection: e.target.value
                })
              }
            />
            <Label htmlFor="aggregatorScoreDataPoint" gutterTop>
              Profilskår™ karakteristikkvekt
            </Label>
            <Input
              type="number"
              id="aggregatorScoreDataPoint"
              placeholder="0.5"
              value={data.settings!.aggregatorScoreDataPoint || undefined}
              onChange={e =>
                setField('settings', {
                  ...data.settings!,
                  aggregatorScoreDataPoint: e.target.value
                })
              }
            />
            <Label htmlFor="aggregatorScoreDataPointInterest" gutterTop>
              Profilskår™ interessevekt
            </Label>
            <Input
              type="number"
              id="aggregatorScoreDataPointInterest"
              placeholder="0.25"
              value={
                data.settings!.aggregatorScoreDataPointInterest || undefined
              }
              onChange={e =>
                setField('settings', {
                  ...data.settings!,
                  aggregatorScoreDataPointInterest: e.target.value
                })
              }
            />
            <Label htmlFor="aggregatorScoreMessage" gutterTop>
              Profilskår™ korrespondansevekt
            </Label>
            <Input
              type="number"
              id="aggregatorScoreMessage"
              placeholder="1"
              value={data.settings!.aggregatorScoreMessage || undefined}
              onChange={e =>
                setField('settings', {
                  ...data.settings!,
                  aggregatorScoreMessage: e.target.value
                })
              }
            />
          </Expander>
          <ButtonList align="right">
            <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
            <Button disabled={!hasMadeChanges} type="submit" variant="primary">
              {submitting ? 'Lagrer…' : 'Lagre'}
            </Button>
          </ButtonList>
        </Form>
      </Container>
    </Content>
  );
}
