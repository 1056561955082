import React, {
  useState,
  useContext,
  useEffect,
  FunctionComponent,
  Fragment
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as qs from 'qs';
import Grid from '@material-ui/core/Grid';
import ReactSelect from 'react-select';
import InfiniteScroller from 'react-infinite-scroller';
import moment from '../../util/moment';
import {
  Contact,
  DataPoint,
  Organization,
  Member,
  OrganizationApp
} from '../../types/apiResponses';
import useApi, { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import {
  Container,
  Centerer,
  Flex,
  FlexKid,
  Spacer
} from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select } from '../../components/Form';
import Card, { CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderContactsSidebarContent } from '../../routing/Contacts';
import Menu, {
  MenuItem,
  MenuItemButton,
  MenuSplitter
} from '../../components/Menu';
import getContactName from '../../util/getContactName';
import Table, {
  TableScrollWrapper,
  TableHead,
  TableBody,
  Tr,
  Td,
  Th,
  SortableTh,
  SortDirection
} from '../../components/Table';
import { OrganizationContext } from '../../context/Organization';
import { UiContext } from '../../context/Ui';
import ShowOff from '../../components/ShowOff';
import Button, {
  ButtonExternalLink,
  ButtonList
} from '../../components/Button';
import storage from '../../util/storage';
import translateValue from '../../util/translateValue';
import Expander from '../../components/Expander';
import categorizeDataPoints from '../../util/categorizeDataPoints';
import renderField from '../../util/renderField';
import download from '../../util/download';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';
import AggregatorScore from '../../components/AggregatorScore';
import { ContactsStats } from '../../types/stats';
import { calculatePercentageGrowth } from '../../util/stats';
import theme, { mobileThreshold } from '../../constants/theme';
import { numberFormat } from '../../util/intl';
import localize from '../../util/localize';
import { acquisitionTypes, action } from '../../constants/localization';
import Fader from '../../helpers/Fader';
import { AppsContext } from '../../context/Apps';
import labelDataPoints from '../../util/labelDataPoints';
import getWindowWidth from '../../util/getWindowWidth';
import IconNotifier from '../../components/IconNotifier';

const windowWidth = getWindowWidth();

interface ListParams {
  organizationID: string;
}

interface Sort {
  field: string;
  dir: SortDirection;
}

interface Filter {
  name?: string;
  email?: string;
  randomName?: boolean;
  active?: boolean;
  notes?: string;
  sources?: string;
  lists?: string;
  addressLine1?: string;
  programmaticLabel?: string;
  programmaticValue?: string;
  actionCategory?: string;
  actionAction?: string;
  actionLabel?: string;
  actionValue?: string;
  i?: string;
  [key: string]: string | boolean | undefined;
}

interface GuiFilter {
  email: boolean;
  phone: boolean;
  handlers: boolean;
  lists: boolean;
  notes: boolean;
  sources: boolean;
  dataPoints: DataPoint[];
  [key: string]: any;
}

const defaultGuiFilter: GuiFilter = {
  email: true,
  phone: false,
  handlers: true,
  lists: true,
  addressLine1: false,
  addressCity: false,
  addressZip: false,
  addressCounty: false,
  notes: false,
  sources: false,
  dataPoints: []
};

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;

  const qsFilter = handleTypes(
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
  );

  const guiFilterFromStorage = storage.getUnserialize<GuiFilter>(
    'profilesGuiFilter'
  );

  const sortFromStorage = storage.getUnserialize<Sort>('profileSort');

  const [sort, setSort] = useState(
    (!!sortFromStorage && 'field' in sortFromStorage
      ? sortFromStorage
      : { field: 'aggregatorScore', dir: 'desc' }) as Sort
  );
  const [filter, setFilter] = useState((qsFilter ? qsFilter : {}) as Filter);
  const [standardGuiFilter, setStandardGuiFilter] = useState(defaultGuiFilter);
  const [guiFilter, setGuiFilter] = useState(
    guiFilterFromStorage || defaultGuiFilter
  );
  const [dataPoints, setDataPoints] = useState([] as DataPoint[]);

  const { data, members } = useContext(OrganizationContext);
  const organization = data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;
  const { spawnNotification, despawnNotification } = useContext(
    UiContext
  ).notifications;
  const apps = useContext(AppsContext).data;

  const baseUrl = '/organisasjoner/' + organizationID;
  const endpoint = 'organizations/' + organizationID + '/contacts/search';

  const queryOpts = {
    endpoint,
    queryParams: handleFilter(filter),
    limit: 20
  };

  const [contacts, loading, hasMore, fetch, initialFetch] = useSearch<Contact>({
    ...queryOpts
  });

  const filterLength = Object.keys(filter).filter(k => k !== 'i').length;
  const hasActiveFilters = filterLength !== 0;

  const [searchCount, _, doSearchCount] = useApi<{
    total: number;
  }>({
    ...queryOpts,
    endpoint: queryOpts.endpoint + '/count',
    initialData: { total: 0 },
    fetchOnMount: true
  });

  const [stats] = useApi<ContactsStats>({
    endpoint: 'organizations/' + organizationID + '/stats/contacts',
    fetchOnMount: true,
    initialData: {
      total: 0,
      acquisitionLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      acquisitionsByChannel: [],
      acquisitionsByFpSource: [],
      acquisitionsByTpSource: []
    }
  });

  useEffect(() => {
    const dataPoints = labelDataPoints(organization.dataPoints, apps);

    if (!guiFilterFromStorage) {
      //@ts-ignore WTF?
      setGuiFilter({
        ...guiFilter,
        dataPoints
      });
    }

    //@ts-ignore WTF?
    setStandardGuiFilter({
      ...guiFilter,
      dataPoints
    });

    setDataPoints(dataPoints);
  }, [organization._id, apps.length]);

  const programmaticData = contacts.reduce<string[]>((previous, current) => {
    return [
      ...previous,
      ...current.profile
        .filter(
          p => p.programmatic && !previous.some(prev => prev === p.dataPoint)
        )
        .map(p => p.dataPoint)
    ];
  }, []);

  const percentageGrowth = calculatePercentageGrowth(
    stats.acquisitionLast7Days.currentPeriod,
    stats.acquisitionLast7Days.lastPeriod
  );

  function doSort(field: string, dir: SortDirection) {
    fetch({
      ...queryOpts,
      queryParams: {
        ...handleFilter(filter),
        ...handleSort({
          field,
          dir
        }),
        getThreadCount: 'true'
      }
    });

    setSort({ field, dir });
  }

  return (
    <>
      <Helper id="contacts" title="Profiler: din gullgruve">
        <Text>
          Her finner du listen over alle kontaktene dine, eller{' '}
          <em>profilene</em> som vi kaller det. Profiler inneholder data om dine
          kunder, såkalte <em>karakteristikker</em>. Ved å bruke søkefunksjonen
          kan du finne frem til spesifikke segmenter som kan kontaktes ved å
          bruke det innebygde meldingssystemet, eksporteres til bruk i andre
          systemer eller bare titte på for å få en oversikt over hvem kundene
          dine faktisk er.
        </Text>
        <Text variant="headline" topGutter bottomGutter>
          Tips: Organiser profilene dine i lister
        </Text>
        <Text>
          Ved å opprette <em>lister</em> kan du kategorisere profilene dine slik
          det gir mening for deg. <em>VIP-kunder</em> og{' '}
          <em>Sjokolade-elskere</em> er alle eksempler på lister, som både gjør
          profil-databasen din mer oversiktlig, samt at de kan brukes i
          automasjoner.
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Profiler
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactsSidebarContent(baseUrl)}
          <MenuSplitter />
          <Menu>
            <Doorman type="admin">
              <MenuItem to="profiler/opprett" icon={<Icon>add</Icon>} primary>
                Ny profil
              </MenuItem>
            </Doorman>
            <Doorman>
              <MenuItem
                to="profiler/importer"
                icon={<Icon>cloud_upload</Icon>}
                primary
              >
                Importer profiler
              </MenuItem>
            </Doorman>
            {contacts.length > 0 && (
              <>
                <MenuItemButton
                  href="#"
                  icon={<Icon>view_list</Icon>}
                  primary
                  onClick={e => {
                    e.preventDefault();
                    download({
                      endpoint: endpoint + '/export',
                      queryParams: {
                        ...handleFilter(filter),
                        skip: 0,
                        limit: 10000
                      },
                      fileName: 'profiler.csv',
                      onDownloading: () =>
                        spawnNotification(
                          'downloadCsv',
                          'Laster ned…',
                          <Icon>view_list</Icon>
                        ),
                      onDownloaded: () => despawnNotification('downloadCsv')
                    });
                  }}
                >
                  Last ned CSV
                </MenuItemButton>
                <MenuItemButton
                  href="#"
                  icon={<Icon>border_all</Icon>}
                  primary
                  onClick={e => {
                    e.preventDefault();
                    download({
                      endpoint: endpoint + '/export',
                      queryParams: {
                        ...handleFilter(filter),
                        skip: 0,
                        limit: 10000,
                        excel: true
                      },
                      fileName: 'profiler.xls',
                      onDownloading: () =>
                        spawnNotification(
                          'downloadExcel',
                          'Laster ned…',
                          <Icon>border_all</Icon>
                        ),
                      onDownloaded: () => despawnNotification('downloadExcel')
                    });
                  }}
                >
                  Last ned Excel
                </MenuItemButton>
              </>
            )}
            <Doorman>
              {initialFetch && !loading && contacts.length < 1 && (
                <ShowOff>
                  Legg til din første profil ved å trykke på{' '}
                  <strong>Ny profil</strong>, eller importer fra CSV ved å
                  trykke på <strong>Importer profiler</strong>.
                </ShowOff>
              )}
            </Doorman>
          </Menu>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            {windowWidth > mobileThreshold && (
              <Fader variant="top">
                <Flex>
                  <FlexKid flex={1} flexBasis="33.332%">
                    <Card horizontal secondary>
                      <CardContent tight>
                        <Text variant="title">
                          {numberFormat.format(stats.total)}
                          {searchCount.total !== stats.total && (
                            <Text element="span" weight={400} size="1rem">
                              {' · '}
                              {numberFormat.format(searchCount.total)} i
                              søkefilter
                            </Text>
                          )}
                        </Text>
                        <Text variant="subheading">Totalt antall</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>group</Icon>
                      </CardIcon>
                    </Card>
                  </FlexKid>
                  <FlexKid flex={1} flexBasis="33.332%" spaceLeft>
                    <Card horizontal secondary>
                      <CardContent tight>
                        <Text variant="title">
                          {numberFormat.format(
                            stats.acquisitionLast7Days.currentPeriod
                          )}{' '}
                          {stats.acquisitionLast7Days.currentPeriod > 0 && (
                            <Text
                              element="span"
                              weight={400}
                              color={
                                percentageGrowth >= 0
                                  ? theme.colors.good
                                  : theme.colors.warning
                              }
                            >
                              {percentageGrowth > 0 && '+'}
                              {percentageGrowth === 0
                                ? '+∞'
                                : numberFormat.format(percentageGrowth)}
                              %
                            </Text>
                          )}
                        </Text>
                        <Text variant="subheading">Nye siste 7 dager</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>trending_up</Icon>
                      </CardIcon>
                    </Card>
                  </FlexKid>
                  <FlexKid flex={1} flexBasis="33.332%" spaceLeft>
                    <Card horizontal secondary>
                      <CardContent tight>
                        <Text variant="title">
                          {stats.acquisitionsByChannel.length > 0
                            ? localize(
                                acquisitionTypes,
                                stats.acquisitionsByChannel[0]._id.type
                              )
                            : 'Ingen'}
                        </Text>
                        <Text variant="subheading">
                          Vanligste første interaksjon
                        </Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>touch_app</Icon>
                      </CardIcon>
                    </Card>
                  </FlexKid>
                </Flex>
                <Spacer spacious />
              </Fader>
            )}
            <Grid container direction="column" spacing={24}>
              {organization && (
                <Grid item>
                  <Flex justify="space-between">
                    <FlexKid spaceRight>
                      <Button
                        variant="primary"
                        onClick={() =>
                          spawnModal(
                            <SearchModal
                              organization={organization}
                              apps={apps}
                              members={members}
                              initialFilter={filter}
                              close={despawnModal}
                              search={filter => {
                                setFilter(filter);

                                fetch({
                                  ...queryOpts,
                                  queryParams: {
                                    ...handleFilter(filter),
                                    ...handleSort(sort),
                                    getThreadCount: 'true'
                                  }
                                });

                                doSearchCount({
                                  ...queryOpts,
                                  endpoint: endpoint + '/count',
                                  queryParams: handleFilter(filter)
                                });

                                window.history.pushState(
                                  null,
                                  '',
                                  'profiler?' + qs.stringify(filter)
                                );
                              }}
                            />,
                            'profileSearch'
                          )
                        }
                      >
                        <Icon>search</Icon>Søk{' '}
                        {hasActiveFilters && <>({filterLength})</>}
                      </Button>
                    </FlexKid>
                    <FlexKid flex={1}>
                      <Form noMaxWidth>
                        <ReactSelect
                          classNamePrefix="react-select"
                          placeholder="Felter å vise"
                          isMulti
                          value={flattenGuiFilter(guiFilter, true)}
                          options={[
                            ...flattenGuiFilter(standardGuiFilter),
                            ...programmaticData.map(p => ({
                              value: p,
                              label: p
                            }))
                          ]}
                          onChange={v => {
                            if (Array.isArray(v)) {
                              const unflattenedGuiFilter = unflattenGuiFilter(
                                v
                              );

                              setGuiFilter(unflattenedGuiFilter);

                              storage.setSerialize(
                                'profilesGuiFilter',
                                unflattenedGuiFilter
                              );
                            }
                          }}
                        />
                      </Form>
                    </FlexKid>
                  </Flex>
                </Grid>
              )}
              {organization && (
                <Grid item>
                  <Card>
                    <BusyBoy busy={loading} exposeChildren>
                      <InfiniteScroller
                        hasMore={!loading && hasMore}
                        initialLoad
                        loadMore={() =>
                          fetch({
                            ...queryOpts,
                            queryParams: {
                              ...handleFilter(filter),
                              ...handleSort(sort),
                              getThreadCount: 'true'
                            },
                            paginate: true
                          })
                        }
                      >
                        <TableScrollWrapper>
                          <Table>
                            <TableHead>
                              <Tr>
                                <SortableTh
                                  isSorting={sort.field === 'name'}
                                  sortDirection={sort.dir}
                                  onToggleSort={dir => {
                                    doSort('name', dir);
                                  }}
                                >
                                  Navn
                                </SortableTh>
                                {guiFilter.email && <Th>E-post</Th>}
                                {guiFilter.phone && <Th>Telefonnummer</Th>}
                                {guiFilter.handlers && <Th>Saksbehandlere</Th>}
                                {guiFilter.lists && <Th>Lister</Th>}
                                {guiFilter.addressLine1 && <Th>Gateadresse</Th>}
                                {guiFilter.addressCity && <Th>Sted</Th>}
                                {guiFilter.addressZip && <Th>Postnummer</Th>}
                                {guiFilter.addressCounty && <Th>Fylke</Th>}
                                {dataPoints
                                  .filter(d =>
                                    guiFilter.dataPoints.some(
                                      p => p._id === d._id
                                    )
                                  )
                                  .map(d => (
                                    <Th
                                      key={d._id}
                                      centered={d.type === 'interest'}
                                      right={d.type === 'number'}
                                    >
                                      {d.label}
                                    </Th>
                                  ))}
                                {programmaticData
                                  .filter(p =>
                                    guiFilter.dataPoints.some(d => d._id === p)
                                  )
                                  .map(p => (
                                    <Th key={p} centered>
                                      {p}
                                    </Th>
                                  ))}
                                {guiFilter.notes && <Th>Notater</Th>}
                                {guiFilter.sources && <Th>Kilder</Th>}
                                <SortableTh
                                  isSorting={sort.field === 'aggregatorScore'}
                                  sortDirection={sort.dir}
                                  onToggleSort={dir => {
                                    doSort('aggregatorScore', dir);
                                  }}
                                  centered
                                >
                                  Profilskår™
                                </SortableTh>
                                <SortableTh
                                  isSorting={sort.field === 'lastAdminAction'}
                                  sortDirection={sort.dir}
                                  onToggleSort={dir => {
                                    doSort('lastAdminAction', dir);
                                  }}
                                >
                                  Siste administratorhandling
                                </SortableTh>
                                <SortableTh
                                  isSorting={sort.field === 'lastAction'}
                                  sortDirection={sort.dir}
                                  onToggleSort={dir => {
                                    doSort('lastAction', dir);
                                  }}
                                >
                                  Siste brukerhandling
                                </SortableTh>
                                <SortableTh
                                  isSorting={
                                    sort.field ===
                                    'acquisition.creationTimestamp'
                                  }
                                  sortDirection={sort.dir}
                                  onToggleSort={dir => {
                                    doSort(
                                      'acquisition.creationTimestamp',
                                      dir
                                    );
                                  }}
                                >
                                  Registrert
                                </SortableTh>
                              </Tr>
                            </TableHead>
                            <TableBody>
                              {initialFetch && !loading && contacts.length < 1 && (
                                <Tr>
                                  <Td
                                    colSpan={
                                      dataPoints.filter(d =>
                                        guiFilter.dataPoints.find(
                                          p => p._id === d._id
                                        )
                                      ).length + 8
                                    }
                                  >
                                    <Text variant="headline" centered>
                                      Ingen profiler!
                                    </Text>
                                  </Td>
                                </Tr>
                              )}
                              {contacts.map(c => (
                                <Tr key={c._id}>
                                  <Td>
                                    <Flex direction="row">
                                      <Link to={'profiler/' + c._id}>
                                        {getContactName(c)}
                                      </Link>
                                      {c.notes.length > 0 && (
                                        <IconNotifier
                                          a11yTitle={`${c.name} har ${c.notes.length} notater.`}
                                          count={c.notes.length}
                                          icon="list_alt"
                                        />
                                      )}
                                      {!!c.threadCount && c.threadCount > 0 && (
                                        <IconNotifier
                                          a11yTitle={`${c.name} har ${c.threadCount} meldingstråder.`}
                                          count={c.threadCount}
                                          icon="chat_bubble_outlined"
                                        />
                                      )}
                                    </Flex>
                                  </Td>
                                  {guiFilter.email && (
                                    <Td>
                                      {c.email ? (
                                        c.email
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.phone && (
                                    <Td>
                                      {c.phone ? (
                                        c.phone
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.handlers && (
                                    <Td>
                                      {(c.handlers || []).length > 0 ? (
                                        c.handlers
                                          .map(h => {
                                            const user = members.find(
                                              m => m.user === h
                                            );

                                            if (user) {
                                              return user.userData!.name;
                                            }

                                            return 'Mangler navn';
                                          })
                                          .join(', ')
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.lists && (
                                    <Td>
                                      {c.lists.length > 0 ? (
                                        c.lists
                                          .filter(f =>
                                            organization.lists.find(
                                              li => li._id === f
                                            )
                                          )
                                          .map(l => {
                                            const ref = organization.lists.find(
                                              li => li._id === l
                                            );

                                            return ref ? ref.label : l;
                                          })
                                          .join(', ')
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.addressLine1 && (
                                    <Td>
                                      {(c.address && c.address.line1) || (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.addressCity && (
                                    <Td>
                                      {(c.address && c.address.city) || (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.addressZip && (
                                    <Td>
                                      {(c.address && c.address.zip) || (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.addressCounty && (
                                    <Td>
                                      {(c.address && c.address.county) || (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {dataPoints
                                    .filter(d =>
                                      guiFilter.dataPoints.find(
                                        p => p._id === d._id
                                      )
                                    )
                                    .map(d => {
                                      const data = c.profile.find(
                                        p => p.dataPoint === d._id
                                      );

                                      const value =
                                        data || d.type === 'interest' ? (
                                          translateValue(
                                            d.type,
                                            data ? data.value : 0,
                                            false,
                                            '60px'
                                          )
                                        ) : (
                                          <Text variant="subheading">
                                            Ingen
                                          </Text>
                                        );

                                      if (d.type === 'interest') {
                                        return (
                                          <Td key={d._id}>
                                            <Centerer noPadding>
                                              {value}
                                            </Centerer>
                                          </Td>
                                        );
                                      }

                                      return (
                                        <Td
                                          key={d._id}
                                          right={d.type === 'number'}
                                        >
                                          {value}
                                        </Td>
                                      );
                                    })}
                                  {programmaticData
                                    .filter(p =>
                                      guiFilter.dataPoints.some(
                                        d => d._id === p
                                      )
                                    )
                                    .map(p => {
                                      const data = c.profile.find(
                                        pr => pr.dataPoint === p
                                      );

                                      const value = data ? (
                                        translateValue(
                                          'interest',
                                          data.value,
                                          false,
                                          '60px'
                                        )
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      );

                                      return (
                                        <Td key={p}>
                                          <Centerer noPadding>{value}</Centerer>
                                        </Td>
                                      );
                                    })}
                                  {guiFilter.notes && (
                                    <Td>
                                      {(c.notes || []).length > 0 ? (
                                        c.notes
                                          .slice(0, 1)
                                          .map(l =>
                                            l.note.length > 16
                                              ? l.note.substr(0, 16) + '...'
                                              : l.note
                                          )
                                          .join(' · ')
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  {guiFilter.sources && (
                                    <Td>
                                      {(c.sources || []).length > 0 ? (
                                        c.sources.map(l => l.service).join(', ')
                                      ) : (
                                        <Text variant="subheading">Ingen</Text>
                                      )}
                                    </Td>
                                  )}
                                  <Td>
                                    <Centerer noPadding>
                                      <AggregatorScore
                                        score={c.aggregatorScore}
                                        width="60px"
                                      />
                                    </Centerer>
                                  </Td>
                                  <Td>
                                    {c.lastAdminAction ? (
                                      moment(c.lastAdminAction).format(
                                        'D.M.YY [kl.] HH:mm:ss'
                                      )
                                    ) : (
                                      <Text variant="subheading">Ingen</Text>
                                    )}
                                  </Td>
                                  <Td>
                                    {c.lastAction ? (
                                      moment(c.lastAction).format(
                                        'D.M.YY [kl.] HH:mm:ss'
                                      )
                                    ) : (
                                      <Text variant="subheading">Ingen</Text>
                                    )}
                                  </Td>
                                  <Td>
                                    {!!c.acquisition ? (
                                      moment(
                                        c.acquisition.creationTimestamp
                                      ).format('D.M.YY [kl.] HH:mm:ss')
                                    ) : (
                                      <Text variant="subheading">Ingen</Text>
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                            </TableBody>
                          </Table>
                        </TableScrollWrapper>
                      </InfiniteScroller>
                    </BusyBoy>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface ValueLabel {
  value: string;
  label: string;
}

function translateLabel(data: string): string {
  switch (data) {
    case 'name':
      return 'Navn';
    case 'email':
      return 'E-post';
    case 'phone':
      return 'Telefonnummer';
    case 'handlers':
      return 'Saksbehandlere';
    case 'lists':
      return 'Lister';
    case 'addressLine1':
      return 'Gateadresse';
    case 'addressCity':
      return 'Sted';
    case 'addressZip':
      return 'Postnummer';
    case 'addressCounty':
      return 'Fylke';
    case 'notes':
      return 'Notater';
    case 'sources':
      return 'Kilder';
    default:
      return data;
  }
}

function unflattenGuiFilter(values: ValueLabel[]): GuiFilter {
  return {
    email: values.some(v => v.value === 'email'),
    phone: values.some(v => v.value === 'phone'),
    handlers: values.some(v => v.value === 'handlers'),
    lists: values.some(v => v.value === 'lists'),
    addressLine1: values.some(v => v.value === 'addressLine1'),
    addressCity: values.some(v => v.value === 'addressCity'),
    addressZip: values.some(v => v.value === 'addressZip'),
    addressCounty: values.some(v => v.value === 'addressCounty'),
    addressCountry: values.some(v => v.value === 'addressCountry'),
    notes: values.some(v => v.value === 'notes'),
    sources: values.some(v => v.value === 'sources'),
    dataPoints: values
      .filter(
        v =>
          v.value !== 'email' &&
          v.value !== 'phone' &&
          v.value !== 'handlers' &&
          v.value !== 'lists' &&
          v.value !== 'addressLine1' &&
          v.value !== 'addressCity' &&
          v.value !== 'addressZip' &&
          v.value !== 'addressCounty' &&
          v.value !== 'addressCountry' &&
          v.value !== 'notes' &&
          v.value !== 'sources'
      )
      .map(v => ({
        _id: v.value,
        label: v.label,
        type: '',
        categories: [],
        defaultValue: null,
        consents: [],
        picks: []
      }))
  };
}

function flattenGuiFilter(
  filter: GuiFilter,
  removeFalse?: boolean
): ValueLabel[] {
  const flattenedFilter: ValueLabel[] = [];

  Object.keys(filter).map(k => {
    const f = filter[k];

    if (
      typeof f === 'boolean' &&
      (removeFalse === undefined || (removeFalse && f))
    ) {
      flattenedFilter.push({
        value: k,
        label: translateLabel(k)
      });
    } else if (Array.isArray(f)) {
      for (let i = 0; i < f.length; i++) {
        const e = f[i];

        flattenedFilter.push({
          value: e._id,
          label: e.label
        });
      }
    }
  });

  return flattenedFilter;
}

const addressSearches = {
  addressLine1: 'Gateadresse',
  addressCity: 'Sted',
  addressCounty: 'Fylke',
  addressZip: 'Postnummer'
};

interface SearchModalProps {
  organization: Organization;
  apps: OrganizationApp[];
  members: Member[];
  search: (filter: Filter) => any;
  close: (id: string) => any;
  initialFilter?: Filter;
}

const SearchModal: FunctionComponent<SearchModalProps> = ({
  organization,
  apps,
  members,
  search,
  close,
  initialFilter
}) => {
  const [filter, setFilter] = useState(
    initialFilter ? initialFilter : ({} as Filter)
  );

  const selectedList = filter.lists
    ? organization.lists.find(l => l._id === filter.lists)
    : undefined;

  const unserializedInstructions = filter.i
    ? (JSON.parse(filter.i) as { [key: string]: string })
    : {};

  return (
    <Container minWidth="50rem" spacious>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Form
            id="contactSearch"
            onSubmit={e => {
              e.preventDefault();
              search(filter);
              close('profileSearch');
            }}
          >
            <Label htmlFor="active">Aktiv</Label>
            <Select
              id="active"
              value={
                typeof filter.active === 'boolean'
                  ? filter.active
                    ? 'true'
                    : 'false'
                  : ''
              }
              onChange={e => {
                if (e.target.value.length === 0) {
                  delete filter.active;
                  setFilter(filter);
                } else {
                  setFilter({ ...filter, active: e.target.value === 'true' });
                }
              }}
            >
              <option value="">Vennligst velg…</option>
              <option value="true">Ja</option>
              <option value="false">Nei</option>
            </Select>
            <Label htmlFor="randomName">Anonym</Label>
            <Select
              id="randomName"
              value={
                typeof filter.randomName === 'boolean'
                  ? filter.randomName
                    ? 'true'
                    : 'false'
                  : ''
              }
              onChange={e => {
                if (e.target.value.length === 0) {
                  delete filter.randomName;
                  setFilter(filter);
                } else {
                  setFilter({
                    ...filter,
                    randomName: e.target.value === 'true'
                  });
                }
              }}
            >
              <option value="">Vennligst velg…</option>
              <option value="true">Ja</option>
              <option value="false">Nei</option>
            </Select>
            <Label htmlFor="name">Navn</Label>
            <Input
              id="name"
              type="text"
              value={filter.name || ''}
              onChange={e => setFilter({ ...filter, name: e.target.value })}
            />
            <Label htmlFor="email">E-post</Label>
            <Input
              id="email"
              type="email"
              value={filter.email || ''}
              onChange={e => setFilter({ ...filter, email: e.target.value })}
            />
            <Label htmlFor="lists">Liste</Label>
            <ReactSelect
              key={filter.lists}
              id="lists"
              classNamePrefix="react-select"
              placeholder="Vennligst velg…"
              value={
                filter.lists
                  ? {
                      value: filter.lists,
                      label: selectedList
                        ? selectedList.label
                        : filter.lists === 'none'
                        ? 'Ingen'
                        : filter.lists
                    }
                  : undefined
              }
              onChange={val =>
                val &&
                !Array.isArray(val) &&
                //@ts-ignore
                setFilter({ ...filter, lists: val.value })
              }
              options={[
                { value: '', label: 'Alle' },
                { value: 'none', label: 'Ingen' },
                ...organization.lists.map(l => ({
                  value: l._id,
                  label: l.label
                }))
              ]}
            />
            <Label htmlFor="notes">Notater</Label>
            <Input
              id="notes"
              type="text"
              value={filter.notes || ''}
              onChange={e => setFilter({ ...filter, notes: e.target.value })}
            />
            <Label htmlFor="handlers">Saksbehandler</Label>
            <Select
              id="handlers"
              defaultValue=""
              value={(filter.handlers as string) || ''}
              onChange={e => setFilter({ ...filter, handlers: e.target.value })}
            >
              <option value="">Vennligst velg…</option>
              {members
                .sort((a, b) =>
                  a.userData!.name.localeCompare(b.userData!.name)
                )
                .map(m => (
                  <option key={m._id} value={m.user}>
                    {m.userData!.name}
                  </option>
                ))}
            </Select>
            <Expander label="Adresse">
              {Object.keys(addressSearches).map(k => (
                <Fragment key={k}>
                  <Label htmlFor={k}>{addressSearches[k]}</Label>
                  <Grid container spacing={24}>
                    <Grid item xs={5} md={3}>
                      <Select
                        disabled={!filter[k]}
                        value={
                          filter && filter[k] && k in unserializedInstructions
                            ? unserializedInstructions[k]
                            : 'eq'
                        }
                        onChange={e =>
                          filter &&
                          filter[k] &&
                          setFilter({
                            ...filter,
                            i: JSON.stringify({
                              ...unserializedInstructions,
                              [k]: e.target.value
                            })
                          })
                        }
                      >
                        <option value="eq">Er lik</option>
                        <option value="ne">Er ikke lik</option>
                        <option value="contains">Inneholder</option>
                      </Select>
                    </Grid>
                    <Grid item xs={7} md={9}>
                      <Input
                        id={k}
                        type="text"
                        value={(filter[k] as string) || ''}
                        onChange={e =>
                          setFilter({ ...filter, [k]: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </Expander>
            {organization.dataPoints.length > 0 &&
              categorizeDataPoints(organization, apps).map(c =>
                c.dataPoints.length > 0 ? (
                  <Expander key={c._id} label={c.label}>
                    {c.dataPoints.map((d, k) => {
                      const value = filter[d._id];

                      return (
                        <Fragment key={d._id}>
                          <Label htmlFor={d._id} gutterTop={k !== 0}>
                            {d.label}
                          </Label>
                          <Grid container spacing={24}>
                            <Grid
                              item
                              xs={
                                d._id in unserializedInstructions &&
                                (unserializedInstructions[d._id] === 'ex' ||
                                  unserializedInstructions[d._id] === 'nex')
                                  ? 12
                                  : 5
                              }
                              md={
                                d._id in unserializedInstructions &&
                                (unserializedInstructions[d._id] === 'ex' ||
                                  unserializedInstructions[d._id] === 'nex')
                                  ? 12
                                  : 3
                              }
                            >
                              <Select
                                value={
                                  d._id in unserializedInstructions
                                    ? unserializedInstructions[d._id]
                                    : 'eq'
                                }
                                onChange={e =>
                                  setFilter({
                                    ...filter,
                                    i: JSON.stringify({
                                      ...unserializedInstructions,
                                      [d._id]: e.target.value
                                    })
                                  })
                                }
                              >
                                <option value="eq">Er lik</option>
                                <option value="ne">Er ikke lik</option>
                                <option value="contains">Inneholder</option>
                                <option value="gt">Er større enn</option>
                                <option value="lt">Er mindre enn</option>
                                <option value="ex">Er satt</option>
                                <option value="nex">Er ikke satt</option>
                              </Select>
                            </Grid>
                            {d._id in unserializedInstructions &&
                            (unserializedInstructions[d._id] === 'ex' ||
                              unserializedInstructions[d._id] ===
                                'nex') ? null : (
                              <Grid item xs={7} md={9}>
                                {renderField(
                                  d,
                                  value,
                                  (n, v) => setFilter({ ...filter, [n]: v }),
                                  undefined,
                                  true,
                                  'Vennligst velg…'
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Expander>
                ) : null
              )}
            <Expander label="Programmatiske karakterestikker">
              <Label htmlFor="programmaticLabel">Navn</Label>
              <Input
                id="programmaticLabel"
                type="text"
                value={filter.programmaticLabel}
                onChange={e =>
                  setFilter({ ...filter, programmaticLabel: e.target.value })
                }
              />
              <Label htmlFor="programmaticValue" gutterTop>
                Verdi
              </Label>
              <Grid container spacing={24}>
                <Grid item xs={5} md={3}>
                  <Select
                    disabled={!filter.programmaticLabel}
                    value={
                      filter &&
                      filter.programmaticLabel &&
                      filter.programmaticLabel in unserializedInstructions
                        ? unserializedInstructions[filter.programmaticLabel]
                        : 'eq'
                    }
                    onChange={e =>
                      filter &&
                      filter.programmaticLabel &&
                      setFilter({
                        ...filter,
                        i: JSON.stringify({
                          ...unserializedInstructions,
                          [filter.programmaticLabel]: e.target.value
                        })
                      })
                    }
                  >
                    <option value="eq">Er lik</option>
                    <option value="ne">Er ikke lik</option>
                    <option value="contains">Inneholder</option>
                    <option value="gt">Er større enn</option>
                    <option value="lt">Er mindre enn</option>
                  </Select>
                </Grid>
                <Grid item xs={7} md={9}>
                  <Input
                    id="programmaticValue"
                    type="text"
                    value={filter.programmaticValue}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        programmaticValue: e.target.value
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Expander>
            <Expander label="Handlinger">
              {Object.keys(action).map((k, i) => {
                const value = action[k];
                const identifier =
                  'action' + k.charAt(0).toUpperCase() + k.slice(1);

                return (
                  <>
                    <Label htmlFor={identifier} gutterTop={i !== 0}>
                      {value}
                    </Label>
                    <Grid container spacing={24}>
                      <Grid item xs={5} md={3}>
                        <Select
                          value={
                            identifier in unserializedInstructions
                              ? unserializedInstructions[identifier]
                              : 'eq'
                          }
                          onChange={e =>
                            setFilter({
                              ...filter,
                              i: JSON.stringify({
                                ...unserializedInstructions,
                                [identifier]: e.target.value
                              })
                            })
                          }
                        >
                          <option value="eq">Er lik</option>
                          <option value="ne">Er ikke lik</option>
                          <option value="contains">Inneholder</option>
                          <option value="containsNot">Inneholder ikke</option>
                        </Select>
                      </Grid>
                      <Grid item xs={7} md={9}>
                        <Input
                          id={identifier}
                          type="text"
                          value={(filter[identifier] as string) || ''}
                          onChange={e =>
                            setFilter({
                              ...filter,
                              [identifier]: e.target.value
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Expander>
          </Form>
        </FlexKid>
        <FlexKid
          width="14rem"
          spaceLeft
          spacious
          sticky
          topOffset={`calc(-40px + ${theme.layout.spacing.normal})`}
        >
          <Spacer height="40px" />
          <ButtonList vertical>
            <Button type="submit" variant="primary" form="contactSearch">
              Søk
            </Button>
            {Object.keys(filter).length > 0 && (
              <ButtonExternalLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setFilter({});
                  window.history.pushState(null, '', 'profiler');
                }}
              >
                Nullstill
              </ButtonExternalLink>
            )}
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('profileSearch');
              }}
            >
              Avbryt
            </ButtonExternalLink>
          </ButtonList>
        </FlexKid>
      </Flex>
    </Container>
  );
};

function handleFilter(filter: Filter): Filter {
  const filterCopy = { ...filter };

  if (filterCopy.programmaticLabel && filterCopy.programmaticValue) {
    filterCopy[filterCopy.programmaticLabel] = filterCopy.programmaticValue;

    delete filterCopy.programmaticLabel;
    delete filterCopy.programmaticValue;
  }

  return filterCopy;
}

function handleSort(sort: Sort): Filter {
  storage.setSerialize('profileSort', sort);

  return {
    sort: `${sort.field}--${sort.dir}`
  };
}

function handleTypes(filter: Filter): Filter {
  if ('active' in filter && typeof filter.active !== 'boolean') {
    filter.active = true;
  }

  if ('randomName' in filter && typeof filter.randomName !== 'boolean') {
    filter.randomName = true;
  }

  return filter;
}

export default List;
