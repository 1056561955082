import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import Grid from "@material-ui/core/Grid";
import history from "../../util/history";
import { Group, Organization } from "../../types/apiResponses";
import useForm from "../../hooks/useForm";
import useApi from "../../hooks/useApi";
import { OrganizationContext } from "../../context/Organization";
import { UiContext } from "../../context/Ui";
import { Container } from "../../helpers/Layout";
import Content from "../../components/Content";
import Text from "../../components/Text";
import BusyBoy from "../../helpers/BusyBoy";
import Form, { Label, Input } from "../../components/Form";
import Expander from "../../components/Expander";
import { CardExternalLink, CardContent, CardIcon } from "../../components/Card";
import Icon from "../../components/Icon";
import {
  CreateParameterModal,
  renderProfileSelectionRule,
} from "../Automation/Show";
import Button, {
  ButtonList,
  ButtonExternalLink,
  ButtonLink,
} from "../../components/Button";
import { UserContext } from "../../context/User";
import { HandlerModal } from "../Contacts/Show";

interface CreateParams {
  organizationID: string;
  groupID: string;
}

function CreateGroup(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const groupID = props.match.params.groupID;
  const isPrivate = props.location.search.includes("privat");
  const isUpdate = groupID !== "opprett";
  const baseEndpoint = "organizations/" + organizationID + "/groups";
  const endpoint = isUpdate ? baseEndpoint + "/" + groupID : baseEndpoint;

  const user = useContext(UserContext).data;
  const organization = useContext(OrganizationContext).data;
  const members = useContext(OrganizationContext).members;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  if (!user.id) {
    return null;
  }

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit,
  } = useForm<Group>(
    {
      name: "",
      email: "",
      user: isPrivate ? user.id : undefined,
      profileSelectionRules: [],
    },
    {
      onSuccess: () => {
        history.push("../innstillinger");
        window.location.reload();
      },
      endpoint,
      method: isUpdate ? "PATCH" : "POST",
      prefillEndpoint: isUpdate ? "organizations/" + organizationID : undefined,
      prefillFn: (organization) =>
        (organization as Organization).groups.find((d) => d._id === groupID),
    }
  );

  const [_, deleting, destroy] = useApi<Group | null>({
    method: "DELETE",
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: "Er du sikker på at du vil slette?",
    onSuccess: () => {
      history.push("../innstillinger");
      window.location.reload();
    },
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? "Rediger" : "Opprett"}{" "}
          {isPrivate ? "privat gruppe" : "organisasjonsgruppe"}
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              placeholder="Min gruppe"
              value={data.name}
              required
              onChange={(e) => setField("name", e.target.value)}
            />
            <Label htmlFor="email">E-post</Label>
            <Input
              id="email"
              type="email"
              value={data.email || ""}
              onChange={(e) => setField("email", e.target.value)}
            />
            {!isPrivate && (
              <Expander label={`Medlemmer (${(data.members || []).length})`}>
                <Grid container spacing={24}>
                  {(data.members || []).map((m) => {
                    const member = members.find((u) => u.user === m);

                    if (!member || !member.userData) {
                      return null;
                    }

                    return (
                      <Grid key={m} item xs={12}>
                        <CardExternalLink
                          href="#"
                          clickable
                          horizontal
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              window.confirm(
                                "Er du sikker på at du vil fjerne " +
                                  member!.userData!.name +
                                  " som medlem av denne gruppen?"
                              )
                            ) {
                              setField("members", [
                                ...(data.members || []).filter((u) => u !== m),
                              ]);
                            }
                          }}
                        >
                          <CardContent tight>
                            {member.userData.name}
                          </CardContent>
                          <CardIcon tight>
                            <Icon>delete</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      secondary
                      onClick={(e) => {
                        e.preventDefault();
                        spawnModal(
                          <HandlerModal
                            organization={organization}
                            members={members}
                            filter={data.members || []}
                            onCreate={(handler) => {
                              setField("members", [
                                ...(data.members || []),
                                handler,
                              ]);
                            }}
                            onDelete={(handler) => {
                              setField("members", [
                                ...(data.members || []).filter(
                                  (m) => m !== handler
                                ),
                              ]);
                            }}
                            close={despawnModal}
                          />,
                          "addMember"
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>Legg til medlem</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>add</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                </Grid>
              </Expander>
            )}
            <Expander
              label={`Filter (${data.profileSelectionRules.length})`}
              open
            >
              <Grid container spacing={24}>
                {data.profileSelectionRules.map((p) => {
                  const list = organization.lists.find(
                    (l) => l._id === p.targetRef
                  );

                  const dataPoint = organization.dataPoints.find(
                    (d) => d._id === p.targetRef
                  );

                  return (
                    <Grid key={p._id} item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        onClick={(e) => {
                          e.preventDefault();
                          spawnModal(
                            <CreateParameterModal
                              defaultData={p}
                              organization={organization}
                              onCreate={(parameter) => {
                                const index = data.profileSelectionRules.findIndex(
                                  (pa) => pa._id === parameter._id
                                );

                                if (index !== -1) {
                                  const copy = [...data.profileSelectionRules];
                                  copy[index] = parameter;

                                  setField("profileSelectionRules", copy);
                                }
                              }}
                              onDelete={(ref) => {
                                setField(
                                  "profileSelectionRules",
                                  data.profileSelectionRules.filter((pa) =>
                                    pa.__ref ? pa.__ref !== ref : pa._id !== ref
                                  )
                                );
                              }}
                              close={despawnModal}
                            />,
                            "createParameter"
                          );
                        }}
                      >
                        <CardContent tight>
                          {renderProfileSelectionRule(p, list, dataPoint)}
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    primary={data.profileSelectionRules.length < 1}
                    secondary={data.profileSelectionRules.length > 0}
                    onClick={(e) => {
                      e.preventDefault();
                      spawnModal(
                        <CreateParameterModal
                          organization={organization}
                          onCreate={(parameter) => {
                            setField("profileSelectionRules", [
                              ...data.profileSelectionRules,
                              parameter,
                            ]);
                          }}
                          onDelete={(ref) => {
                            setField(
                              "profileSelectionRules",
                              data.profileSelectionRules.filter(
                                (p) => p._id !== ref
                              )
                            );
                          }}
                          close={despawnModal}
                        />,
                        "createParameter"
                      );
                    }}
                  >
                    <CardContent tight>
                      <Text>Legg til regel</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>add</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              </Grid>
            </Expander>
            <ButtonList align="right">
              <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
              {isUpdate && (
                <ButtonExternalLink onClick={() => destroy()} variant="warning">
                  Slett
                </ButtonExternalLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? "Lagrer…" : "Lagre"}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default CreateGroup;
