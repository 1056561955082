import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container } from '../../helpers/Layout';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';
import Text from '../../components/Text';
import {
  DataPoint as IDataPoint,
  Organization as IOrganization
} from '../../types/apiResponses';
import Content from '../../components/Content';
import Card, { CardContent, CardIcon } from '../../components/Card';
import Button, { ButtonList } from '../../components/Button';
import reorder from '../../util/reorder';
import useApi from '../../hooks/useApi';
import api from '../../util/api';
import history from '../../util/history';
import BusyBoy from '../../helpers/BusyBoy';
import { Grid } from '@material-ui/core';
import theme from '../../constants/theme';
import Icon from '../../components/Icon';
import { OrganizationContext } from '../../context/Organization';

interface ReorderParams {
  organizationID: string;
}

function getItemStyle(isDragging: boolean, draggableStyle) {
  return {
    background: isDragging
      ? theme.colors.primary
      : theme.colors.contentBackground,
    color: isDragging ? '#fff' : theme.colors.text,
    ...draggableStyle
  };
}

export default function Reorder({ match }: RouteComponentProps<ReorderParams>) {
  const [orderedDataPoints, setOrderedDataPoints] = React.useState<
    IDataPoint[]
  >([]);
  const [savingOrder, setSavingOrder] = React.useState(false);
  const { setData } = React.useContext(OrganizationContext);
  const organizationID = match.params.organizationID;
  const endpoint = 'organizations/' + organizationID;
  const [_] = useApi<IOrganization | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true,
    onSuccess: (result: IOrganization | null) => {
      if (result) {
        setOrderedDataPoints([...result.dataPoints]);
      }
    }
  });

  async function saveOrder() {
    try {
      setSavingOrder(true);

      const result = (await api<IOrganization>({
        endpoint,
        method: 'PATCH',
        body: {
          dataPoints: orderedDataPoints
        }
      })) as IOrganization;

      if (result) {
        setOrderedDataPoints([...result.dataPoints]);
        setData(result);
      }

      setSavingOrder(false);
      history.push('../karakteristikker');
    } catch (error) {
      setSavingOrder(false);
    }
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    const items = reorder<IDataPoint>(
      orderedDataPoints,
      result.source.index,
      result.destination.index
    );

    setOrderedDataPoints(items);
  }

  return (
    <BusyBoy busy={savingOrder}>
      <Content>
        <Container maxWidth="38rem" minWidth="35rem" spacious>
          <DragDropContext onDragEnd={onDragEnd}>
            <Text element="h2" variant="display3" bottomGutter>
              Endre rekkefølge
            </Text>
            <Text variant="subheading" bottomGutter>
              Endre rekkefølge ved å dra karakteristikkene dit du ønsker i
              listen.
            </Text>

            <Droppable droppableId="droppable" direction="vertical">
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Grid container spacing={16} direction="column">
                      {orderedDataPoints.map((dataPoint, index) => {
                        return (
                          <Grid item key={dataPoint._id}>
                            <Draggable
                              key={dataPoint._id}
                              draggableId={dataPoint._id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <Card
                                    horizontal
                                    noTransition
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <CardContent tight>
                                      <Text>{dataPoint.label}</Text>
                                    </CardContent>

                                    <CardIcon tight>
                                      <Icon>reorder</Icon>
                                    </CardIcon>
                                  </Card>
                                );
                              }}
                            </Draggable>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <ButtonList align="right" gutterTop>
            <Button
              onClick={() => {
                history.push('../karakteristikker');
              }}
            >
              Avbryt
            </Button>
            <Button variant="primary" onClick={saveOrder}>
              Lagre
            </Button>
          </ButtonList>
        </Container>
      </Content>
    </BusyBoy>
  );
}
