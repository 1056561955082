import { useState, useEffect, useContext } from 'react';
import api, { ApiOpts } from '../util/api';
import { UserContext } from '../context/User';
import useSnackbar from './useSnackbar';
import localize from '../util/localize';
import { errorMessages } from '../constants/localization';

interface Opts<T> extends ApiOpts {
  initialData: T;
  fetchOnMount?: boolean;
  askBeforeFetch?: string;
  onSuccess?: (resource: T) => any;
  onFail?: () => any;
}

interface ExtendedApiOpts extends ApiOpts {
  askBeforeFetch?: string;
  onSuccess?: (resource: any) => any;
}

type Request = (fetchOpts?: ExtendedApiOpts) => Promise<void>;

function useApi<T>(opts: Opts<T>): [T, boolean, Request, boolean] {
  const user = useContext(UserContext);
  const [data, setData] = useState(opts.initialData);
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enqueueSnack] = useSnackbar();

  async function fetchData(fetchOpts?: ExtendedApiOpts) {
    const optsToUse = fetchOpts || opts;

    if (!optsToUse.askBeforeFetch || window.confirm(optsToUse.askBeforeFetch)) {
      setLoading(true);
      setHasFetched(true);

      try {
        const response = await api<T>(optsToUse);

        setLoading(false);
        setData(response as T);

        if (fetchOpts && typeof fetchOpts.onSuccess === 'function') {
          fetchOpts.onSuccess(response as T);
        }

        if (typeof opts.onSuccess === 'function') {
          opts.onSuccess(response as T);
        }
      } catch (error) {
        setLoading(false);

        if (typeof opts.onFail === 'function') {
          opts.onFail();
        }

        if ('message' in error && error.message === 'notLoggedIn') {
          user.logOut();
        } else {
          console.error(error);
          if ('message' in error) {
            const message =
              error.message === 'Failed to fetch'
                ? 'Klarte ikke hente ressurs'
                : error.message;

            enqueueSnack(localize(errorMessages, message), {
              variant: 'error'
            });
          } else {
            enqueueSnack('Det har skjedd en feil i systemet. ', {
              variant: 'error'
            });
          }
        }
      }
    }
  }

  if (opts.fetchOnMount) {
    useEffect(() => {
      fetchData();
    }, []);
  }

  return [data, loading, fetchData, hasFetched];
}

export interface SearchOpts<T> extends ApiOpts {
  initialResults?: T[];
  fetchOnMount?: boolean;
  limit?: number;
  reverse?: boolean;
  onInitialFetch?: () => any;
  onSuccess?: (results: T[]) => any;
}

interface SearchApiOpts extends ApiOpts {
  paginate?: boolean;
}

type SearchRequest = (searchOpts: SearchApiOpts) => Promise<void>;

const LIMIT = 50;

function useSearch<T>(
  opts: SearchOpts<T>
): [T[], boolean, boolean, SearchRequest, boolean] {
  const user = useContext(UserContext);
  const [results, setResults] = useState(opts.initialResults || []);
  const [hasMore, setHasMore] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enqueueSnack] = useSnackbar();

  async function fetchData(searchOpts: SearchApiOpts) {
    let skip = 0;

    setHasMore(false);
    setLoading(true);

    try {
      if (searchOpts.paginate) {
        skip = results.length;
      }

      const limit = opts.limit || LIMIT;

      const response = (await api<T[]>({
        ...searchOpts,
        queryParams: { ...(searchOpts.queryParams || {}), skip, limit }
      })) as T[];

      setLoading(false);
      setHasFetched(true);

      if (opts.reverse) {
        setResults(
          searchOpts.paginate
            ? [...response.reverse(), ...results]
            : response.reverse()
        );
      } else {
        setResults(searchOpts.paginate ? [...results, ...response] : response);
      }

      if (Array.isArray(response) && response.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      if (!searchOpts.paginate && typeof opts.onInitialFetch === 'function') {
        opts.onInitialFetch();
      }

      if (typeof opts.onSuccess === 'function') {
        opts.onSuccess(response);
      }
    } catch (error) {
      setLoading(false);

      if ('message' in error && error.message === 'notLoggedIn') {
        user.logOut();
      } else {
        console.error(error);
        if ('message' in error) {
          const message =
            error.message === 'Failed to fetch'
              ? 'Klarte ikke hente ressurs'
              : error.message;
          enqueueSnack(localize(errorMessages, message), {
            variant: 'error'
          });
        } else {
          enqueueSnack('Det har skjedd en feil i systemet. ', {
            variant: 'error'
          });
        }
      }
    }
  }

  if (opts.fetchOnMount) {
    useEffect(() => {
      fetchData(opts);
    }, []);
  }

  return [results, loading, hasMore, fetchData, hasFetched];
}

export default useApi;
export { useSearch };
