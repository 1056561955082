import React, { FunctionComponent } from 'react';
import { Input } from '../Form';
import { useSearch, SearchOpts } from '../../hooks/useApi';
import Downshift from 'downshift';
import Card from '../Card';
import List, { ListItem } from '../List';
import BusyBoy from '../../helpers/BusyBoy';

interface Props {
  endpoint: string;
  searchKey: string;
  labelKey: string;
  onSelect: (item: any) => any;
  fieldId?: string;
  filterFn?: (result: any, key: number) => boolean;
}

const SearchAndSelect: FunctionComponent<Props> = ({
  endpoint,
  searchKey,
  labelKey,
  onSelect,
  fieldId,
  filterFn
}) => {
  const searchOpts: SearchOpts<any> = { endpoint };
  const [results, loading, _, search] = useSearch<any>(searchOpts);

  return (
    <Downshift
      onChange={selection => selection && onSelect(selection)}
      itemToString={item => (item ? item[labelKey] : '')}
      onInputValueChange={inputValue =>
        !!inputValue &&
        search({
          ...searchOpts,
          queryParams: {
            [searchKey]: inputValue
          }
        })
      }
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        selectedItem
      }) => (
        <div>
          <BusyBoy exposeChildren busy={loading}>
            <Input id={fieldId} {...getInputProps()} />
            {isOpen ? (
              <Card>
                <List {...getMenuProps()}>
                  {results
                    .filter(
                      typeof filterFn === 'function' ? filterFn : () => true
                    )
                    .map((r, i) => (
                      <ListItem
                        tight
                        hover={highlightedIndex === i}
                        active={selectedItem && selectedItem._id === r._id}
                        {...getItemProps({
                          key: r._id,
                          index: i,
                          item: r
                        })}
                      >
                        {r[labelKey]}
                      </ListItem>
                    ))}
                </List>
              </Card>
            ) : null}
          </BusyBoy>
        </div>
      )}
    </Downshift>
  );
};

export default SearchAndSelect;
