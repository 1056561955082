import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { Personalization } from '../../types/apiResponses';
import useApi, { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const baseEndpoint = 'organizations/' + organizationID + '/personalization';

  const [personalizations, loading, hasMore, search, hasFetched] = useSearch<
    Personalization
  >({
    endpoint: baseEndpoint + '/search',
    fetchOnMount: true
  });

  const [_, settingActive, setActive] = useApi<Personalization | null>({
    method: 'PATCH',
    endpoint: '',
    initialData: null,
    onSuccess: () => {
      search({
        endpoint: baseEndpoint + '/search'
      });
    }
  });

  return (
    <>
      <Helper id="personalization" title="Hva er personalisering?">
        <Text>
          Personalisering er et åpent verktøy som lar deg eller dine utviklere
          vise målrettet innhold og funksjonalitet for dine kontakter. Det er
          litt teknisk, men med flinke utviklere kan man utrette store ting! 😁
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Personalisering
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem
                to="personalisering/opprett"
                icon={<Icon>add</Icon>}
                primary
              >
                Ny personalisering
              </MenuItem>
              {hasFetched && personalizations.length < 1 && (
                <ShowOff>
                  Legg til din første personalisering ved å trykke på knappen
                  over!
                </ShowOff>
              )}
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading} exposeChildren={hasFetched}>
              {hasFetched && personalizations.length < 1 && (
                <EmptyState>Ingen personaliseringer!</EmptyState>
              )}
              {hasFetched && personalizations.length > 0 && (
                <Grid container spacing={24}>
                  {personalizations.map(p => (
                    <Grid key={p._id} item sm={4}>
                      <CardLink
                        to={'personalisering/' + p._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>
                            {p.appData ? p.appData.name + ': ' : ''}
                            {p.name}
                          </Text>
                          <Text variant="headline">
                            {p.active ? 'Aktiv' : 'Inaktiv'}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Switch
                            checked={p.active}
                            onClick={e => e.stopPropagation()}
                            onChange={e => {
                              e.stopPropagation();

                              setActive({
                                endpoint: baseEndpoint + '/' + p._id,
                                method: 'PATCH',
                                body: {
                                  active: !p.active
                                }
                              });
                            }}
                          />
                        </CardIcon>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
