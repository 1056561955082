import React, { useState, useContext, useEffect } from 'react';
import * as Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import InfiniteScroller from 'react-infinite-scroller';
import Tooltip from '@material-ui/core/Tooltip';
import history from '../../util/history';
import styled from '../../util/styled';
import moment from '../../util/moment';
import { ThreadStatus, ThreadStatusFilter } from '../../constants/enums';
import useApi, { useSearch } from '../../hooks/useApi';
import useForm from '../../hooks/useForm';
import {
  Thread,
  Message,
  ThreadCount,
  EmailTemplate
} from '../../types/apiResponses';
import { UserContext } from '../../context/User';
import { UiContext } from '../../context/Ui';
import BusyBoy from '../../helpers/BusyBoy';
import { Flex, FlexKid, Container } from '../../helpers/Layout';
import Scroller from '../../helpers/Scroller';
import Card, { CardContent } from '../Card';
import Text from '../Text';
import List, { ListItem } from '../List';
import Hr from '../Hr';
import Chat, { ChatItem } from '../Chat';
import Form, { Label, Input, TextArea, Select, ExternalInput } from '../Form';
import Button, { IconButton, ButtonExternalLink, ButtonList } from '../Button';
import Icon from '../Icon';
import Notifier from '../Notifier';
import AggregatorScore from '../AggregatorScore';
import { mobileThreshold } from '../../constants/theme';
import getWindowWidth from '../../util/getWindowWidth';

const windowWidth = getWindowWidth();

function scrollToAnchor() {
  Scroll.scroller.scrollTo('anchor', {
    containerId: 'messageContainer',
    duration: 500,
    smooth: true
  });
}

interface WrapperProps {
  embiggen?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  right: ${props => props.theme.layout.spacing.large};
  bottom: ${props => props.theme.layout.spacing.large};
  width: ${props => (props.embiggen ? '35rem' : '25rem')};
  z-index: 3;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};

  @media (max-width: ${mobileThreshold}px) {
    right: ${props => props.theme.layout.spacing.small};
    bottom: ${props => props.theme.layout.spacing.small};
    width: calc(100% - (${props => props.theme.layout.spacing.small} * 2));
  }
`;

const Floater = styled.a`
  position: fixed;
  right: ${props => props.theme.layout.spacing.large};
  bottom: ${props => props.theme.layout.spacing.large};
  width: 5rem;
  height: 5rem;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: ${props => props.theme.shadows.large};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 3;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};

  &:hover,
  &:focus {
    transform: scale(1.1);
    box-shadow: ${props => props.theme.shadows.mega};
    text-decoration: none;
  }

  &:active {
    transform: scale(1);
    box-shadow: ${props => props.theme.shadows.large};
  }

  @media (max-width: ${mobileThreshold}px) {
    right: ${props => props.theme.layout.spacing.small};
    bottom: ${props => props.theme.layout.spacing.small};
  }
`;

interface Filter {
  status?: ThreadStatusFilter;
  participant?: string;
}

const baseFilter: Filter = {
  status: ThreadStatusFilter.Open
};

interface Props {
  organization: string;
  sticky?: boolean;
  defaultFilter?: Filter;
  defaultThread?: Thread;
}

function Correspondence(props: Props) {
  const { organization, sticky, defaultFilter, defaultThread } = props;
  const endpoint = 'organizations/' + organization + '/correspondence/threads';

  const user = useContext(UserContext).data;
  const uiContext = useContext(UiContext);
  const modalContext = uiContext.modal;
  const showThumbMenu = uiContext.thumbMenu.show;

  const [minimized, setMinimized] = useState(true);

  const [thread, setThread] = useState(
    defaultThread ? defaultThread : (null as Thread | null)
  );

  const [filter, setFilter] = useState(
    defaultFilter ? { ...baseFilter, ...defaultFilter } : baseFilter
  );

  const [threadCount, loadingThreadCount, refreshThreadCount] = useApi<
    ThreadCount
  >({
    endpoint: endpoint + '/count',
    initialData: { count: 0 },
    fetchOnMount: true
  });

  const [threads, loading, hasMore, search, hasFetched] = useSearch<Thread>({
    endpoint: endpoint + '/search',
    queryParams: filter
  });

  const [_, patching, patch] = useApi<Thread | null>({
    endpoint: thread ? endpoint + '/' + thread._id : endpoint,
    initialData: thread
  });

  const [deleteResponse, deleting, destroy] = useApi<Thread | null>({
    method: 'DELETE',
    endpoint: thread ? endpoint + '/' + thread!._id : '',
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      if (sticky) {
        setThread(null);
      } else {
        history.push('../meldinger');
      }
    }
  });

  let refreshRef: number;

  useEffect(() => {
    search({ endpoint: endpoint + '/search', queryParams: filter });

    refreshRef = window.setInterval(() => {
      refreshThreadCount({
        endpoint: endpoint + '/count'
      });

      if (!minimized && thread === null) {
        search({ endpoint: endpoint + '/search', queryParams: filter });
      }
    }, 30000);

    return () => {
      window.clearInterval(refreshRef);
    };
  }, [filter.status, minimized]);

  function renderThreads() {
    return (
      <>
        <Flex>
          <FlexKid>
            <CardContent tight hugBottom={windowWidth <= mobileThreshold}>
              <Text variant="title">{sticky ? 'Meldinger' : 'Tråder'}</Text>
            </CardContent>
          </FlexKid>
          <FlexKid hugRight>
            <CardContent tight hugBottom={windowWidth > mobileThreshold} hugTop>
              <ButtonList tight dontForceMobileVertical>
                <Tooltip key="new" title="Ny tråd" placement="top">
                  <IconButton
                    small
                    onClick={e => {
                      e.preventDefault();

                      modalContext.spawnModal(
                        <CreateThread
                          organization={organization}
                          close={modalContext.despawnModal}
                          participant={filter.participant}
                          onSuccess={thread => {
                            if (sticky) {
                              setThread(thread);
                            } else {
                              history.push('meldinger/' + thread._id);
                            }

                            search({
                              endpoint: endpoint + '/search',
                              queryParams: filter
                            });
                          }}
                        />,
                        'createThread'
                      );
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  key="status"
                  title={
                    'Status: ' +
                    getStatusText(filter.status || ThreadStatus.Open)
                  }
                  placement="top"
                >
                  <IconButton
                    small
                    onClick={e => {
                      e.preventDefault();

                      modalContext.spawnModal(
                        <SetStatus
                          status={filter.status || ThreadStatus.Open}
                          close={modalContext.despawnModal}
                          isForFilter
                          onChange={status =>
                            setFilter({
                              ...filter,
                              status: status as ThreadStatusFilter
                            })
                          }
                        />,
                        'setStatus'
                      );
                    }}
                  >
                    {renderStatusIcon(filter.status || ThreadStatus.Open)}
                  </IconButton>
                </Tooltip>
                {sticky && (
                  <Tooltip key="minimize" title="Minimer" placement="top">
                    <IconButton
                      small
                      onClick={e => {
                        e.preventDefault();
                        setMinimized(true);
                      }}
                    >
                      <Icon>expand_more</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </ButtonList>
            </CardContent>
          </FlexKid>
        </Flex>
        <BusyBoy busy={loading && !hasFetched}>
          <Hr hugBottom hugTop />
          {hasFetched && threads.length < 1 && (
            <CardContent tight>
              <Text centered variant="headline">
                Ingen meldingstråder enda!
              </Text>
            </CardContent>
          )}
          <Scroller
            maxHeight={sticky ? '60vh' : undefined}
            id="messageContainer"
          >
            <InfiniteScroller
              hasMore={!loading && hasMore}
              initialLoad
              useWindow={false}
              loadMore={() =>
                search({
                  endpoint: endpoint + '/search',
                  queryParams: filter,
                  paginate: true
                })
              }
            >
              <List>
                {threads.map(t => (
                  <ListItem
                    key={t._id}
                    onClick={() => {
                      if (sticky) {
                        setThread(t);
                      } else {
                        history.push('meldinger/' + t._id);
                      }
                    }}
                  >
                    <Flex>
                      <FlexKid dontSpaceMobile>
                        <Text>
                          <strong>{t.title}</strong>
                        </Text>
                      </FlexKid>
                      <FlexKid hugRight>
                        <Text variant="subheading">
                          {t.lastMessage
                            ? moment(t.lastMessage).fromNow(true) + ' · '
                            : ''}
                          {getStatusText(t.status)}
                        </Text>
                      </FlexKid>
                    </Flex>
                    <Text variant="subheading">
                      {t.participants
                        .filter(p => p.userRef !== user.id)
                        .map(p => p.populatedName)
                        .join(', ')}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </InfiniteScroller>
            <Scroll.Element name="anchor" />
          </Scroller>
        </BusyBoy>
      </>
    );
  }

  function renderThread() {
    return thread ? (
      <>
        <Flex>
          <FlexKid>
            <CardContent tight hugBottom={windowWidth <= mobileThreshold}>
              <Text variant="title">{thread.title}</Text>
              <Text>
                {thread.participants
                  .filter(p => p.userRef !== user.id)
                  .map(p => (
                    <Link
                      key={p._id}
                      to={
                        '/organisasjoner/' +
                        organization +
                        '/profiler/' +
                        p.userRef
                      }
                    >
                      {p.populatedName}
                      {typeof p.aggregatorScore === 'number' && (
                        <AggregatorScore score={p.aggregatorScore} line />
                      )}
                    </Link>
                  ))}
              </Text>
            </CardContent>
          </FlexKid>
          <FlexKid hugRight>
            <CardContent tight hugBottom={windowWidth > mobileThreshold} hugTop>
              <ButtonList tight dontForceMobileVertical>
                {sticky && (
                  <Tooltip key="back" title="Tilbake" placement="top">
                    <IconButton
                      small
                      onClick={e => {
                        e.preventDefault();
                        setThread(null);
                      }}
                    >
                      <Icon>arrow_back</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  key="status"
                  title={'Status: ' + getStatusText(thread.status)}
                  placement="top"
                >
                  <IconButton
                    small
                    onClick={e => {
                      e.preventDefault();

                      modalContext.spawnModal(
                        <SetStatus
                          status={thread.status || ThreadStatus.Open}
                          close={modalContext.despawnModal}
                          onChange={status => {
                            patch({
                              endpoint: endpoint + '/' + thread._id,
                              method: 'PATCH',
                              body: {
                                status
                              },
                              onSuccess: () => {
                                search({
                                  endpoint: endpoint + '/search',
                                  queryParams: filter
                                });

                                refreshThreadCount();
                              }
                            });

                            setThread({
                              ...thread,
                              status: status as ThreadStatus
                            });
                          }}
                        />,
                        'setStatus'
                      );
                    }}
                  >
                    {renderStatusIcon(thread.status)}
                  </IconButton>
                </Tooltip>
                {thread && (
                  <Tooltip key="delete" title="Slett tråd" placement="top">
                    <IconButton small onClick={() => destroy()}>
                      <Icon>delete_outline</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {sticky && (
                  <Tooltip key="minimize" title="Minimer" placement="top">
                    <IconButton
                      small
                      onClick={e => {
                        e.preventDefault();
                        setMinimized(true);
                      }}
                    >
                      <Icon>expand_more</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </ButtonList>
            </CardContent>
          </FlexKid>
        </Flex>
        <Hr hugBottom hugTop />
        <Messages
          baseUrl={endpoint}
          thread={thread}
          onMessageCreation={() => search({ endpoint })}
          sticky={sticky}
        />
      </>
    ) : null;
  }

  if (sticky) {
    if (!showThumbMenu) {
      return null;
    }

    if (minimized) {
      return (
        <Floater
          href="#"
          onClick={e => {
            e.preventDefault();
            setMinimized(false);
          }}
        >
          <Icon>chat</Icon>
          {threadCount.count > 0 && <Notifier>{threadCount.count}</Notifier>}
        </Floater>
      );
    }

    return (
      <Wrapper embiggen={thread !== null}>
        <Card level={3}>
          {thread === null ? renderThreads() : renderThread()}
        </Card>
      </Wrapper>
    );
  }

  return thread === null ? renderThreads() : renderThread();
}

interface MessagesProps {
  baseUrl: string;
  thread: Thread;
  sticky?: boolean;
  onMessageCreation?: () => any;
}

function Messages(props: MessagesProps) {
  const { baseUrl, thread, onMessageCreation, sticky } = props;
  const endpoint = baseUrl + '/' + thread._id + '/messages';

  const [hasNewMessages, setHasNewMessages] = useState(false);

  const [messages, loading, hasMore, search, hasFetched] = useSearch<Message>({
    endpoint,
    fetchOnMount: true,
    limit: 10,
    reverse: true,
    onInitialFetch: () => scrollToAnchor()
  });

  const [_, loadingNewMessages, hasMoreNewMessages, getNewMessages] = useSearch<
    Message
  >({
    endpoint,
    onSuccess: newMessagesFromServer =>
      setHasNewMessages(newMessagesFromServer.length > 0)
  });

  let refreshRef: number;

  useEffect(() => {
    const mountDate = new Date();

    refreshRef = window.setInterval(() => {
      getNewMessages({
        endpoint,
        queryParams: {
          since:
            messages.length > 0
              ? messages[messages.length - 1].timestamp
              : mountDate
        }
      });
    }, 5e3);

    return () => {
      clearInterval(refreshRef);
    };
  }, [messages.length]);

  return (
    <>
      <BusyBoy busy={loading} exposeChildren={hasFetched}>
        <Scroller maxHeight={sticky ? '60vh' : undefined} id="messageContainer">
          <Chat>
            {!loading && hasFetched && messages.length < 1 && (
              <Text centered variant="headline">
                Ingen meldinger enda!
              </Text>
            )}
            {hasMore && (
              <ButtonList align="stretch" gutterBottom>
                <Button onClick={() => search({ endpoint, paginate: true })}>
                  Last eldre meldinger
                </Button>
              </ButtonList>
            )}
            {messages.map(m => (
              <ChatItem
                key={m._id}
                thirdParty={m.isContact}
                byline={`${m.populatedName} · ${moment(m.timestamp).fromNow(
                  true
                )} siden`}
              >
                {m.message.startsWith('<!') ? '…' : m.message}
              </ChatItem>
            ))}
            {hasNewMessages && (
              <ButtonList align="stretch">
                <Button
                  variant="primary"
                  onClick={() => {
                    search({ endpoint });
                    setHasNewMessages(false);
                  }}
                >
                  Vis nye meldinger
                </Button>
              </ButtonList>
            )}
          </Chat>
          <Scroll.Element name="anchor" />
        </Scroller>
      </BusyBoy>
      <Hr hugBottom hugTop />
      <NewMessage
        baseUrl={baseUrl}
        thread={thread}
        onSuccess={() => {
          search({ endpoint });

          if (typeof onMessageCreation === 'function') {
            onMessageCreation();
          }
        }}
      />
    </>
  );
}

interface NewMessageProps extends MessagesProps {
  onSuccess?: () => any;
}

function NewMessage(props: NewMessageProps) {
  const { baseUrl, thread, onSuccess } = props;

  const { data, setData, submitting, submit } = useForm<Message>(
    {
      message: ''
    },
    {
      endpoint: baseUrl + '/' + thread._id + '/messages',
      onSuccess: () => {
        setData({ message: '' });

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  );

  return (
    <BusyBoy busy={submitting} exposeChildren>
      <CardContent tight>
        <Form onSubmit={submit} noMaxWidth>
          <Flex>
            <FlexKid flex={1}>
              <TextArea
                oneLiner
                placeholder="Ny melding…"
                value={data.message}
                onChange={e => setData({ ...data, message: e.target.value })}
              />
            </FlexKid>
            <FlexKid hugRight spaceLeft>
              <Button type="submit" variant="primary" disabled={submitting}>
                <Icon>send</Icon> Send
              </Button>
            </FlexKid>
          </Flex>
        </Form>
      </CardContent>
    </BusyBoy>
  );
}

interface CreateThreadProps {
  organization: string;
  participant?: string;
  close: (id: string) => any;
  onSuccess?: (thread: Thread) => any;
}

function CreateThread(props: CreateThreadProps) {
  const { organization, participant, close, onSuccess } = props;
  const baseEndpoint = 'organizations/' + organization;
  const searchEndpoint = baseEndpoint + '/contacts/search';

  const [messageType, setMessageType] = useState('text' as 'text' | 'template');

  const { data, setData, submit } = useForm<Thread>(
    {
      title: '',
      message: '',
      participants: participant
        ? [
            {
              isContact: true,
              userRef: participant
            }
          ]
        : []
    },
    {
      endpoint: baseEndpoint + '/correspondence/threads',
      onSuccess: thread => {
        close('createThread');

        if (typeof onSuccess === 'function') {
          onSuccess(thread as Thread);
        }
      }
    }
  );

  const [emailTemplates] = useSearch<EmailTemplate>({
    endpoint: 'organizations/' + organization + '/email-templates/search',
    fetchOnMount: true
  });

  return (
    <Container minWidth="38rem" spacious>
      <Form onSubmit={submit}>
        {!participant && (
          <ExternalInput
            endpoint={searchEndpoint}
            label="Mottaker *"
            onChange={result =>
              setData({
                participants: [
                  {
                    userRef: result._id,
                    isContact: true
                  }
                ]
              })
            }
          />
        )}
        <Label htmlFor="title">Emnefelt *</Label>
        <Input
          id="title"
          type="text"
          value={data.title}
          onChange={e => setData({ ...data, title: e.target.value })}
        />
        <Label htmlFor="messageType">Meldingstype</Label>
        <Select
          id="messageType"
          value={messageType}
          onChange={e => setMessageType(e.target.value as 'text')}
        >
          <option value="text">Tekst</option>
          <option value="template">Mal</option>
        </Select>
        {messageType === 'text' && (
          <>
            <Label htmlFor="message">Melding *</Label>
            <TextArea
              id="message"
              value={data.message}
              onChange={e => setData({ ...data, message: e.target.value })}
            />
          </>
        )}
        {messageType === 'template' && (
          <>
            <Label htmlFor="message">Mal *</Label>
            <Select
              id="message"
              defaultValue="none"
              onChange={e => {
                const template = emailTemplates.find(
                  t => t._id === e.target.value
                );

                if (template) {
                  setData({ ...data, message: template.html });
                }
              }}
            >
              <option disabled value="none">
                Vennligst velg…
              </option>
              {emailTemplates.map(t => (
                <option key={t._id} value={t._id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </>
        )}
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createThread');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          <Button type="submit" variant="primary">
            Send melding
          </Button>
        </ButtonList>
        {messageType === 'template' && data.message && (
          <>
            <Text variant="subheading" bottomGutter topGutter>
              Forhåndsvisning
            </Text>
            <Card>
              <CardContent tight>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.message
                  }}
                />
              </CardContent>
            </Card>
          </>
        )}
      </Form>
    </Container>
  );
}

interface SetStatusProps {
  status: ThreadStatus | ThreadStatusFilter;
  close: (id: string) => any;
  onChange: (status: ThreadStatus | ThreadStatusFilter) => any;
  isForFilter?: boolean;
}

function SetStatus(props: SetStatusProps) {
  const { status, close, onChange, isForFilter } = props;

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();
          close('setStatus');
        }}
      >
        <Label htmlFor="status">Status *</Label>
        <Select
          id="status"
          value={status}
          onChange={e => {
            onChange(
              (e.target.value !== ThreadStatusFilter.All &&
              e.target.value !== ThreadStatusFilter.AllOpen
                ? parseInt(e.target.value)
                : e.target.value) as ThreadStatusFilter
            );
            close('setStatus');
          }}
        >
          {isForFilter && (
            <option value={ThreadStatusFilter.All}>
              {getStatusText(ThreadStatusFilter.All)}
            </option>
          )}
          {isForFilter && (
            <option value={ThreadStatusFilter.AllOpen}>
              {getStatusText(ThreadStatusFilter.AllOpen)}
            </option>
          )}
          <option value={ThreadStatus.Open}>
            {getStatusText(ThreadStatus.Open)}
          </option>
          <option value={ThreadStatus.WaitingResponse}>
            {getStatusText(ThreadStatus.WaitingResponse)}
          </option>
          <option value={ThreadStatus.Closed}>
            {getStatusText(ThreadStatus.Closed)}
          </option>
          <option value={ThreadStatus.Resolved}>
            {getStatusText(ThreadStatus.Resolved)}
          </option>
        </Select>
        <ButtonList align="right">
          <Button type="submit" variant="primary">
            Lukk
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

function renderStatusIcon(status: ThreadStatus | ThreadStatusFilter) {
  let icon = 'outlined_flag';

  switch (status) {
    case ThreadStatusFilter.All:
      icon = 'all_inclusive';
      break;
    case ThreadStatus.Closed:
      icon = 'block';
      break;
    case ThreadStatus.Resolved:
      icon = 'done';
      break;
  }

  return <Icon>{icon}</Icon>;
}

function getStatusText(status: ThreadStatus | ThreadStatusFilter) {
  let text = 'Åpen';

  switch (status) {
    case ThreadStatusFilter.All:
      text = 'Alle';
      break;
    case ThreadStatusFilter.AllOpen:
      text = 'Alle åpne';
      break;
    case ThreadStatus.Closed:
      text = 'Lukket';
      break;
    case ThreadStatus.Resolved:
      text = 'Løst';
      break;
    case ThreadStatus.WaitingResponse:
      text = 'Venter på svar';
      break;
  }

  return text;
}

getStatusText;

export default Correspondence;
