import { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Organization as IOrganization,
  Member
} from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import { OrganizationContext } from '../../context/Organization';

interface OrganizationHandlerParams {
  organizationID: string;
}

function OrganizationHandler(
  props: RouteComponentProps<OrganizationHandlerParams>
) {
  const organizationID = props.match.params.organizationID;
  const organizationContext = useContext(OrganizationContext);

  useApi<IOrganization | null>({
    endpoint: 'organizations/' + organizationID,
    initialData: null,
    fetchOnMount: true,
    onSuccess: o => o && organizationContext.setData(o),
    onFail: () => {
      window.location.href = '/';
    }
  });

  const [m, lm, fetch] = useApi<Member[]>({
    endpoint: 'organizations/' + organizationID + '/members',
    initialData: [],
    onSuccess: m => organizationContext.setMembers(m as Member[])
  });

  useEffect(() => {
    if (organizationContext.members.length < 1) {
      fetch({
        endpoint: 'organizations/' + organizationID + '/members'
      });
    }
  }, [organizationContext.members.length]);

  return null;
}

export default OrganizationHandler;
