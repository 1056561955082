import { useState, FormEvent, useEffect } from 'react';
import equal from 'deep-equal';
import useApi from './useApi';
import api, { ApiOpts } from '../util/api';
import useSnackbar from './useSnackbar';
import localize from '../util/localize';
import { errorMessages } from '../constants/localization';

export type SetField = (name: string, value: any) => any;

interface Data {
  [key: string]: any;
}

interface UseFormInterface<T> {
  data: T;
  submitting: boolean;
  success: boolean;
  hasMadeChanges: boolean;
  loadingPrefill: boolean;
  setField: SetField;
  setData: (data: Data) => any;
  submit: (e?: FormEvent) => Promise<any>;
}

interface UseFormOpts extends ApiOpts {
  onSuccess?: (resource: any) => any;
  prefillEndpoint?: string;
  prefillFn?: (data: any) => Data | null | undefined;
}

function useForm<T>(initialData: Data, opts: UseFormOpts): UseFormInterface<T> {
  const [originalData, setOriginalData] = useState(initialData);
  const [data, setData] = useState(initialData);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [enqueueSnackbar] = useSnackbar();

  const [_, loading, fetch] = useApi({
    endpoint: opts.prefillEndpoint || '',
    initialData: {},
    onSuccess: prefillResource => {
      if (typeof opts.prefillFn === 'function') {
        const r = opts.prefillFn(prefillResource);

        if (r) {
          setData(r);
          setOriginalData(r);
        }
      } else {
        setData(prefillResource);
        setOriginalData(prefillResource);
      }
    }
  });

  useEffect(() => {
    if (opts.prefillEndpoint) {
      fetch();
    }
  }, []);

  function setField(name: string, value: any) {
    const updatedData = { ...data, [name]: value };
    let changesMade = false;

    for (const k in updatedData) {
      const value = updatedData[k];

      if (!(k in originalData) || !equal(originalData[k], value)) {
        changesMade = true;
      }
    }

    setData(updatedData);
    setHasMadeChanges(changesMade);
  }

  async function submit(e?: FormEvent) {
    if (e) {
      e.preventDefault();
    }

    setSubmitting(true);

    const dataToSend: Data = {};

    for (const k in data) {
      const value = data[k];

      if (
        !opts.prefillEndpoint ||
        (!(k in originalData) || originalData[k] !== value)
      ) {
        dataToSend[k] = value;
      }
    }

    try {
      const resource = await api<T>({
        ...opts,
        method: opts.method || 'POST',
        body: dataToSend
      });

      setSubmitting(false);
      setSuccess(true);
      setOriginalData(resource);
      setHasMadeChanges(false);

      if (typeof opts.onSuccess === 'function') {
        opts.onSuccess(resource);
      }
    } catch (error) {
      setSubmitting(false);
      setSuccess(false);
      if ('message' in error) {
        enqueueSnackbar(localize(errorMessages, error.message), {
          variant: 'error'
        });
      } else {
        enqueueSnackbar('Klarte ikke sende skjema', { variant: 'error' });
      }
    }
  }

  return {
    data: data as T,
    submitting,
    success,
    hasMadeChanges,
    loadingPrefill: loading,
    setField,
    setData,
    submit
  };
}

export default useForm;
