import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/DataPoints/List';
import Create from '../views/DataPoints/Create';
import CreateCategory from '../views/DataPoints/CreateCategory';
import Menu, { MenuItem } from '../components/Menu';
import Icon from '../components/Icon';
import Categories from '../views/DataPoints/Categories';
import Reorder from '../views/DataPoints/Reorder';

export function renderDataPointSidebarContent(baseUrl: string) {
  return (
    <Menu>
      <MenuItem
        exact
        to={baseUrl + '/karakteristikker'}
        icon={<Icon>all_inclusive</Icon>}
      >
        Alle
      </MenuItem>
      <MenuItem
        to={baseUrl + '/karakteristikker/kategorier'}
        icon={<Icon>category</Icon>}
      >
        Kategorier
      </MenuItem>
    </Menu>
  );
}

export default function dataPointRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/karakteristikker"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/karakteristikker/endre-rekkefolge"
        component={Reorder}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/karakteristikker/kategorier"
        component={Categories}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/karakteristikker/:dataPointID"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/karakteristikker/kategorier/:categoryID"
        component={CreateCategory}
      />
    </Switch>
  );
}
