import * as React from "react";
import moment from "moment";
import {
  Contact,
  Organization,
  OrganizationApp
} from "../../types/apiResponses";
import renderAddress from "../../util/renderAddress";
import categorizeDataPoints from "../../util/categorizeDataPoints";
import translateValue from "../../util/translateValue";
import {
  defaultConsents,
  acquisitionTypes
} from "../../constants/localization";
import localize from "../../util/localize";
import getContactName from "../../util/getContactName";

interface Props {
  contact: Contact;
  organization: Organization;
  apps: OrganizationApp[];
  doc: Document;
}

const stylesheet: any = {
  section: {
    margin: "24px 0"
  },
  card: {
    backgroundColor: "#fff",
    boxSizing: "border-box",
    padding: "0.5rem",
    border: "1px solid #999",
    display: "inline-block",
    margin: "8px 16px 16px 16px"
  },
  text: {
    normal: {
      margin: 0,
      fontWeight: 400,
      fontFamily: "'Noto Sans SC',sans-serif"
    },
    headline: {
      margin: 0,
      fontWeight: 400,
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: "#999",
      fontSize: "1em",
      marginBottom: " 16px",
      fontFamily: "'Noto Sans SC',sans-serif"
    },
    subheading: {
      margin: 0,
      fontWeight: 400,
      textTransform: "capitalize",
      fontFamily: "'Noto Sans SC',sans-serif",
      color: "#999"
    },
    display3: {
      margin: 0,
      fontFamily: "'Lora',serif",
      fontWeight: 700,
      fontSize: "2.5rem",
      marginBottom: "16px"
    }
  }
};

const fonts = [
  "https://fonts.googleapis.com/css?family=Noto+Sans+SC:400,700",
  "https://fonts.googleapis.com/css?family=Lora:400,600,700"
];

export default function ShowPrint({ organization, apps, contact, doc }: Props) {
  React.useEffect(() => {
    doc.title = contact.name;

    for (let fontIndex = 0; fontIndex < fonts.length; fontIndex++) {
      const font = fonts[fontIndex];
      const link = doc.createElement("link");
      link.rel = "stylesheet";
      link.href = font;

      doc.body.appendChild(link);
    }
  }, []);

  return (
    <>
      <div>
        <h1 style={stylesheet.text.display3}>{contact.name}</h1>
      </div>
      <div>
        <div style={stylesheet.section}>
          <h2 style={stylesheet.text.headline}>Saksbehandlere</h2>
          {"populatedHandlers" in contact && contact.populatedHandlers && (
            <ul>
              {contact.populatedHandlers.map(handler => {
                return <li key={handler._id}>{handler.name}</li>;
              })}
            </ul>
          )}
          <hr />
        </div>
        <div style={stylesheet.section}>
          <h2 style={stylesheet.text.headline}>Basisprofil</h2>
          <ul>
            <li style={stylesheet.text.normal}>
              <strong>E-postadresse:</strong>{" "}
              {contact.email ? contact.email : "INGEN"}
            </li>
            <li style={stylesheet.text.normal}>
              <strong>Telefonnummer:</strong>{" "}
              {contact.phone ? contact.phone : "INGEN"}
            </li>
            <li style={stylesheet.text.normal}>
              <strong>Adresse:</strong> {renderAddress(contact.address)}
            </li>
          </ul>
          <hr />
        </div>
        <div style={stylesheet.section}>
          {organization &&
            organization.dataPoints.length > 0 &&
            categorizeDataPoints(organization, apps, true).map(c =>
              c.dataPoints.length > 0 ? (
                <div key={c._id}>
                  <h3 style={stylesheet.text.headline}>{c.label}</h3>
                  {c.dataPoints.length > 0 && (
                    <div>
                      {c.dataPoints.map(d => {
                        const value = contact.profile.find(
                          p => p.dataPoint === d._id
                        );

                        return (
                          <div style={stylesheet.card} key={d._id}>
                            <div>
                              <p style={stylesheet.text.normal}>
                                {value || d.type === "interest"
                                  ? value
                                    ? value.value
                                    : 0
                                  : "INGEN"}
                              </p>
                              <p style={stylesheet.text.subheading}>
                                {d.label}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <hr />
                </div>
              ) : null
            )}
        </div>
        {contact.lists.length > 0 && (
          <div style={stylesheet.section}>
            <h2 style={stylesheet.text.headline}>Lister</h2>
            {contact.lists.map(l => {
              const list = organization.lists.find(ol => ol._id === l);

              if (!list) {
                return null;
              }

              return (
                <div style={stylesheet.card} key={l}>
                  <p style={stylesheet.text.normal}>{list ? list.label : l}</p>
                </div>
              );
            })}
            <hr />
          </div>
        )}
        {contact.profile.some(p => p.programmatic) && (
          <div style={stylesheet.section}>
            <p style={stylesheet.text.headline}>
              Programmatiske karakteristikker
            </p>
            <div>
              {contact.profile
                .filter(p => p.programmatic)
                .map(p => (
                  <div style={stylesheet.card} key={p._id}>
                    <p style={stylesheet.text.normal}>
                      {translateValue("interest", p.value, true)}
                    </p>
                    <p style={stylesheet.text.subheading}>{p.dataPoint}</p>
                  </div>
                ))}
            </div>
            <hr />
          </div>
        )}
        <div style={stylesheet.section}>
          <p style={stylesheet.text.headline}>Samtykker</p>
          <div>
            {contact.consents.length ? (
              contact.consents.map(c => {
                const defaultConsent =
                  c.__type in defaultConsents
                    ? defaultConsents[c.__type]
                    : undefined;

                return (
                  <div style={stylesheet.card} key={c._id}>
                    <p style={stylesheet.text.normal}>
                      {defaultConsent || c.__type}
                    </p>
                    <p style={stylesheet.text.subheading}>
                      Siden {moment(c.signed).format("D.M.YYYY")}
                    </p>
                  </div>
                );
              })
            ) : (
              <p style={stylesheet.text.normal}>INGEN</p>
            )}
          </div>
          <hr />
        </div>
        {contact.externalIdentifiers.length > 0 && (
          <div style={stylesheet.section}>
            <p style={stylesheet.text.headline}>Tredjepartsidentiteter</p>
            {contact.externalIdentifiers.map(i => (
              <div style={stylesheet.card} key={i._id}>
                <p style={stylesheet.text.normal}>{i.identifier}</p>
                <p style={stylesheet.text.subheading}>{i.service}</p>
              </div>
            ))}
            <hr />
          </div>
        )}
        <div style={stylesheet.section}>
          <p style={stylesheet.text.headline}>Opprinnelse</p>
          <ul>
            <li style={stylesheet.text.normal}>
              <strong>
                {contact.acquisition.acquisitionLabel
                  ? contact.acquisition.acquisitionLabel
                  : "Opprettelsesmåte"}
                :{" "}
              </strong>
              {localize(acquisitionTypes, contact.acquisition.type)}
            </li>
            <li style={stylesheet.text.normal}>
              <strong>Opprettelsestidspunkt: </strong>
              {moment(contact.acquisition.creationTimestamp).format(
                "D.M.YYYY [kl] HH:mm:ss"
              )}
            </li>
            {contact.acquisition.firstParty.service && (
              <li style={stylesheet.text.normal}>
                <strong>Førstepartskilde: </strong>
                {contact.acquisition.firstParty.service}
              </li>
            )}
            {contact.acquisition.thirdParty.service && (
              <li style={stylesheet.text.normal}>
                <strong>Tredjepartskilde: </strong>
                {contact.acquisition.thirdParty.service}
              </li>
            )}
          </ul>
          <hr />
        </div>
        <div style={stylesheet.section}>
          <p style={stylesheet.text.headline}>Kilder</p>
          {contact.sources.length > 0 ? (
            <>
              <div>
                {contact.sources
                  .sort((a, b) => {
                    if (a.used > b.used) {
                      return -1;
                    } else if (a.used < b.used) {
                      return 1;
                    }

                    return 0;
                  })
                  .map(s => (
                    <div style={stylesheet.card} key={s._id}>
                      <p style={stylesheet.text.normal}>
                        {s.service} {!s.isFirstParty && <sup>3p</sup>}
                      </p>

                      <p style={stylesheet.text.subheading}>
                        Registrert {s.used} gang(er)
                      </p>
                    </div>
                  ))}
              </div>
              <hr />
            </>
          ) : (
            <p style={stylesheet.text.normal}>INGEN</p>
          )}
          <hr />
        </div>
        <div style={stylesheet.section}>
          <p style={stylesheet.text.headline}>Lenkede profiler</p>
          {"populatedLinks" in contact &&
          contact.populatedLinks &&
          contact.populatedLinks.length > 0 ? (
            <>
              <ul>
                {(contact.populatedLinks || []).map(l => (
                  <li style={stylesheet.text.normal} key={l._id}>
                    {getContactName(l.contact)}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p style={stylesheet.text.normal}>INGEN</p>
          )}
        </div>
      </div>
    </>
  );
}
