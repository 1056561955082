import styled, { css } from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

interface Props {
  sidebar?: boolean;
}

const Heading = styled.div<Props>`
  padding: ${props =>
    `${props.theme.layout.spacing.large} ${
      props.theme.layout.spacing.large
    } 0`};

  @media (max-width: ${mobileThreshold}px) {
    padding: ${props =>
      `${props.theme.layout.spacing.small} ${
        props.theme.layout.spacing.small
      } 0`};
  }

  ${props =>
    props.sidebar &&
    css`
      margin-left: ${props.theme.layout.sidebarWidth};

      @media (max-width: ${mobileThreshold}px) {
        margin-left: ${props.theme.layout.sidebarWidthMobile};
      }
    `};
`;

export default Heading;
