import { useEffect, useContext } from 'react';
import history from '../../util/history';
import storage from '../../util/storage';
import { UserState } from '../../types/general';
import { User } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import { UserContext } from '../../context/User';

function SignOn() {
  const userContext = useContext(UserContext);
  const [user] = useApi<User | null>({
    endpoint: 'users/me',
    initialData: null,
    fetchOnMount: true
  });

  useEffect(() => {
    if (user && user._id) {
      const userState: UserState = {
        id: user._id,
        name: user.name,
        profileImage: user.profileImage,
        loggedIn: true,
        permissions: user.permissions
      };

      storage.setSerialize('user', userState);
      userContext.setData(userState);

      const redirect = storage.get('loginRedirect');
      storage.delete('loginRedirect');

      history.replace(redirect ? redirect : '/');
    }
  }, [user]);

  return null;
}

export default SignOn;
