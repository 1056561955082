import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Contacts/List';
import Lists from '../views/Contacts/Lists';
import Create from '../views/Contacts/Create';
import CreateList from '../views/Contacts/CreateList';
import Show from '../views/Contacts/Show';
import Log from '../views/Contacts/Log';
import Actions from '../views/Contacts/Actions';
import App from '../views/Contacts/App';
import Import from '../views/Contacts/Import';
import Messages from '../views/Contacts/Messages';
import Notes from '../views/Contacts/Notes';
import Menu, { MenuItem } from '../components/Menu';
import Icon from '../components/Icon';
import { OrganizationApp, Contact } from '../types/apiResponses';
import Doorman from '../components/Doorman';
import history from '../util/history';
import IconNotifier from '../components/IconNotifier';

export function renderContactsSidebarContent(baseUrl: string) {
  return (
    <Menu>
      <MenuItem
        exact
        to={baseUrl + '/profiler'}
        icon={<Icon>all_inclusive</Icon>}
      >
        Alle
      </MenuItem>
      <Doorman type="handler">
        <MenuItem to={baseUrl + '/profiler/lister'} icon={<Icon>list</Icon>}>
          Lister
        </MenuItem>
      </Doorman>
    </Menu>
  );
}

export function renderContactSidebarContent(
  baseUrl: string,
  profile: string,
  apps: OrganizationApp[],
  contact: Contact | null
) {
  return (
    <Menu>
      <MenuItem
        to={baseUrl + '/profiler/' + profile}
        exact
        icon={<Icon>person</Icon>}
      >
        Profil
      </MenuItem>
      <Doorman type="handler">
        <MenuItem
          to={baseUrl + '/profiler/' + profile + '/notater'}
          exact
          icon={
            contact && (contact.notes || []).length > 0 ? (
              <IconNotifier
                a11yTitle={`${contact.name} har ${contact.notes.length} notater.`}
                count={contact.notes.length}
                icon="note"
              />
            ) : (
              <Icon>note</Icon>
            )
          }
        >
          Interne notater
        </MenuItem>
        <MenuItem
          to={baseUrl + '/profiler/' + profile + '/meldinger'}
          icon={
            contact &&
            typeof contact.threadCount === 'number' &&
            contact.threadCount > 0 ? (
              <IconNotifier
                a11yTitle={`${contact.name} har ${contact.threadCount} meldingstråder.`}
                count={contact.threadCount}
                icon="chat"
              />
            ) : (
              <Icon>chat</Icon>
            )
          }
        >
          Meldinger
        </MenuItem>
      </Doorman>
      <MenuItem
        to={baseUrl + '/profiler/' + profile + '/handlinger'}
        exact
        icon={<Icon>touch_app</Icon>}
      >
        Handlinger
      </MenuItem>
      <MenuItem
        to={baseUrl + '/profiler/' + profile + '/logg'}
        exact
        icon={<Icon>list</Icon>}
      >
        Logg
      </MenuItem>
      {apps
        .filter(a => a.appData!.settings.showOnContactPage)
        .map(a => (
          <MenuItem
            key={a._id}
            to={baseUrl + '/profiler/' + profile + '/' + a.appData!.slug}
            exact
            icon={<Icon>grade</Icon>}
          >
            {a.appData!.name}
          </MenuItem>
        ))}
    </Menu>
  );
}

export default function contactsRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/lister"
        component={Lists}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/lister/:listID"
        component={CreateList}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/opprett"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/importer"
        component={Import}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID"
        render={props => <Show key={history.location.key} {...props} />}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/logg"
        component={Log}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/handlinger"
        component={Actions}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/rediger"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/notater"
        component={Notes}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/meldinger"
        component={Messages}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/meldinger/:threadID"
        component={Messages}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/profiler/:contactID/:appSlug"
        render={props => <App key={props.match.params.appSlug} {...props} />}
      />
    </Switch>
  );
}
