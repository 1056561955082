import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  EmailTemplate,
  Path,
  Automation,
  Campaign,
  Collector
} from '../../types/apiResponses';
import { OrganizationContext } from '../../context/Organization';
import BeeContainer from '../../components/BeeContainer';
import initializeBee from '../../util/initializeBee';
import Doorman from '../../components/Doorman';
import useApi from '../../hooks/useApi';
import history from '../../util/history';
import { UiContext } from '../../context/Ui';
import { Container } from '../../helpers/Layout';
import Form, { Label, Input } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../components/Button';
import useForm from '../../hooks/useForm';
import BusyBoy from '../../helpers/BusyBoy';

interface EditorParams {
  organizationID: string;
  emailTemplateID?: string;
  automationID?: string;
  pathID?: string;
  campaignID?: string;
  collectorID?: string;
}

function Editor(props: RouteComponentProps<EditorParams>) {
  const organizationID = props.match.params.organizationID;

  let id: string = '';
  let baseEndpoint: string = 'organizations/' + organizationID;
  let endpoint: string = '';
  let patchEndpoint: string = '';

  if (props.match.params.emailTemplateID) {
    id = props.match.params.emailTemplateID;
    baseEndpoint = baseEndpoint + '/email-templates';
    endpoint = baseEndpoint + '/' + id;
    patchEndpoint = endpoint;
  } else if (props.match.params.pathID) {
    id = props.match.params.pathID;
    baseEndpoint = baseEndpoint + '/automations';
    endpoint = baseEndpoint + '/' + props.match.params.automationID;
    patchEndpoint = endpoint + '/paths/' + id;
  } else if (props.match.params.collectorID) {
    id = props.match.params.collectorID;
    baseEndpoint = baseEndpoint + '/campaigns';
    endpoint = baseEndpoint + '/' + props.match.params.campaignID;
    patchEndpoint = endpoint + '/collectors/' + id;
  }

  const organization = useContext(OrganizationContext).data;
  const uiContext = useContext(UiContext);
  const toggleThumbMenu = uiContext.thumbMenu.toggleShow;

  let [data, _, fetch] = useApi<
    EmailTemplate | Automation | Path | Campaign | Collector | null
  >({
    endpoint,
    initialData: null,
    fetchOnMount: true
  });

  const templateID: string = data ? data._id : '';

  const emailer = {
    html: '',
    json: ''
  };

  if (data && 'paths' in data) {
    const tempData = data.paths.find(p => p._id === props.match.params.pathID);

    if (tempData) {
      data = tempData;
      emailer.html = data.email.html;
      emailer.json = data.email.json;
    }
  } else if (data && 'collectors' in data) {
    const tempData = data.collectors.find(
      c => c._id === props.match.params.collectorID
    );

    if (tempData) {
      data = tempData;
      emailer.html = data.receipt || '';
      emailer.json = data.receiptJson || '';
    }
  } else if (data && 'html' in data) {
    emailer.html = data.html;
    emailer.json = data.json;
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    toggleThumbMenu(false);

    if (organization._id && data) {
      initializeBee(organization, emailer, {
        onAutoSave: () => {}, // Removed this as it was unstable
        onSave: (json, html) => {
          fetch({
            endpoint: patchEndpoint,
            method: 'PATCH',
            body: getBody(data, json, html),
            onSuccess: () => history.push('../' + (data ? data._id : ''))
          });
        },
        onSend: html => {
          uiContext.modal.spawnModal(
            <SendPreviewModal
              organizationID={organizationID}
              html={html}
              close={uiContext.modal.despawnModal}
            />,
            'sendPreview'
          );
        }
      });

      document.body.classList.add('freeze');
    }

    return () => {
      document.body.classList.remove('freeze');
      toggleThumbMenu(true);
    };
  }, [organization._id, templateID]);

  return (
    <Doorman>
      <BeeContainer />
    </Doorman>
  );
}

function getBody(
  data: EmailTemplate | Automation | Path | Campaign | Collector | null,
  json: string,
  html: string
): { [key: string]: any } {
  if (data && 'email' in data) {
    return {
      email: {
        ...data.email,
        json,
        html
      }
    };
  } else if (data && 'receipt' in data) {
    return {
      receipt: html,
      receiptJson: json
    };
  } else {
    return {
      json,
      html
    };
  }
}

interface SendPreviewModalProps {
  organizationID: string;
  html: string;
  close: (id: string) => any;
}

export function SendPreviewModal(props: SendPreviewModalProps) {
  const { organizationID, html, close } = props;

  const { data, setField, submit, submitting } = useForm<{
    email: string;
    html: string;
  }>(
    {
      email: '',
      html: ''
    },
    {
      endpoint: 'organizations/' + organizationID + '/tools/preview-email'
    }
  );

  useEffect(() => {
    setField('html', html);
  }, []);

  return (
    <Container minWidth="38rem">
      <BusyBoy busy={submitting} exposeChildren>
        <Form
          onSubmit={e => {
            e.preventDefault();
            submit(e);
            close('sendPreview');
          }}
        >
          <Label htmlFor="email">E-post *</Label>
          <Input
            id="email"
            type="email"
            value={data.email}
            onChange={e => setField('email', e.target.value)}
          />
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('sendPreview');
              }}
            >
              Avbryt
            </ButtonExternalLink>
            <Button type="submit" variant="primary" disabled={submitting}>
              Send
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

export default Editor;
