import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import history from '../../util/history';
import localize from '../../util/localize';
import { permissions } from '../../constants/localization';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import useForm from '../../hooks/useForm';
import Button, { ButtonList, ButtonLink } from '../../components/Button';
import Form, { Label, Input, Select } from '../../components/Form';
import { Invite } from '../../types/apiResponses';
import { Permissions } from '../../constants/enums';
import BusyBoy from '../../helpers/BusyBoy';

interface InviteUserProps {
  organizationID?: string;
}

export default function InviteUser(
  props: RouteComponentProps<InviteUserProps>
) {
  const organizationID = props.match.params.organizationID;

  const { data, setField, submit, hasMadeChanges, submitting } = useForm<
    Invite
  >(
    {
      _id: '',
      email: '',
      permissions: ''
    },
    {
      endpoint: 'organizations/' + organizationID + '/invites',
      method: 'POST',
      onSuccess: () => {
        history.push('../innstillinger');
      }
    }
  );

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Inviter bruker
        </Text>
      </Container>
      <Container spacious hugTop>
        <BusyBoy busy={submitting} exposeChildren>
          <Form onSubmit={submit}>
            <Label htmlFor="inviteEmail">E-post *</Label>
            <Input
              type="email"
              id="inviteEmail"
              placeholder="E-post"
              value={data.email}
              onChange={e => setField('email', e.target.value)}
              required
            />
            <Label htmlFor="permissions">Tilgang</Label>
            <Select
              id="permissions"
              value={data.permissions}
              onChange={e => setField('permissions', e.target.value)}
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              <option value={Permissions.Reader}>
                {localize(permissions, Permissions.Reader)}
              </option>
              <option value={Permissions.Handler}>
                {localize(permissions, Permissions.Handler)}
              </option>
              <option value={Permissions.HandlerPlus}>
                {localize(permissions, Permissions.HandlerPlus)}
              </option>
              <option value={Permissions.Writer}>
                {localize(permissions, Permissions.Writer)}
              </option>
              <option value={Permissions.Admin}>
                {localize(permissions, Permissions.Admin)}
              </option>
            </Select>
            <ButtonList align="right">
              <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
              <Button
                disabled={!hasMadeChanges || submitting}
                type="submit"
                variant="primary"
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </BusyBoy>
      </Container>
    </Content>
  );
}
