import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Receiver } from '../../types/apiResponses';
import useApi, { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import { variableTypes } from '../../constants/localization';
import localize from '../../util/localize';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';
import { Switch } from '@material-ui/core';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const endpoint = 'organizations/' + organizationID + '/receivers';

  const [receivers, loading, hasMore, search, hasSearched] = useSearch<
    Receiver
  >({
    endpoint,
    fetchOnMount: true,
    limit: 999
  });

  const [_, settingActive, setActive] = useApi<Receiver | null>({
    method: 'PATCH',
    endpoint: '',
    initialData: null,
    onSuccess: () => {
      search({ endpoint });
    }
  });

  return (
    <>
      <Helper id="receivers" title="Hva er webhook-mottakere?">
        <Text>
          Webhook-mottakere lar deg generere lenker tredjepartsløsninger kan
          bruke for å sende data til Profiler, såkalte "webhooks".
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Webhook-mottakere
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem
                to="webhook-mottakere/opprett"
                icon={<Icon>add</Icon>}
                primary
              >
                Ny webhook-mottaker
              </MenuItem>
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading} exposeChildren={hasSearched}>
              {hasSearched && receivers.length < 1 && (
                <EmptyState>Ingen webhook-mottakere!</EmptyState>
              )}
              {receivers.length > 0 && (
                <Grid container spacing={24}>
                  {receivers.map(r => (
                    <Grid key={r._id} item sm={4}>
                      <CardLink
                        to={'webhook-mottakere/' + r._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{r.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Switch
                            checked={r.active}
                            onClick={e => e.stopPropagation()}
                            onChange={e => {
                              e.stopPropagation();

                              setActive({
                                endpoint: endpoint + '/' + r._id,
                                method: 'PATCH',
                                body: {
                                  active: !r.active
                                }
                              });
                            }}
                          />
                        </CardIcon>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
