import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { List, Organization as IOrganization } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select, TextArea } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { OrganizationContext } from '../../context/Organization';
import { UserContext } from '../../context/User';
import hasPermissions from '../../util/hasPermissions';
import { permissionsAdmin } from '../../util/prefilledPermissions';
import Doorman from '../../components/Doorman';

interface CreateListParams {
  organizationID: string;
  listID: string;
}

function CreateList(props: RouteComponentProps<CreateListParams>) {
  const organizationContext = useContext(OrganizationContext);
  const userContext = useContext(UserContext);
  const organizationID = props.match.params.organizationID;
  const listID = props.match.params.listID;
  const isUpdate = listID !== 'opprett';
  const isAdmin = hasPermissions(
    organizationContext.data,
    userContext.data.id,
    permissionsAdmin
  );

  const listTypeEndpoint = isAdmin ? '/lists' : '/private-lists';
  const baseEndpoint = 'organizations/' + organizationID + listTypeEndpoint;
  const endpoint = isUpdate ? baseEndpoint + '/' + listID : baseEndpoint;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<List>(
    {
      label: '',
      description: null,
      user: null
    },
    {
      onSuccess: organization => {
        history.push('../lister');
        organizationContext.setData(organization as IOrganization);
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? 'organizations/' + organizationID : undefined,
      prefillFn: organization =>
        (organization as IOrganization).lists.find(l => l._id === listID)
    }
  );

  const [_, deleting, destroy] = useApi<IOrganization | null>({
    method: 'DELETE',
    endpoint: baseEndpoint + '/' + listID,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: organization => {
      history.push('../lister');
      organizationContext.setData(organization as IOrganization);
    }
  });

  React.useEffect(() => {
    if (!isAdmin) {
      setField('user', userContext.data.id);
    }
  }, [userContext.data.id]);

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} liste
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="label">Navn *</Label>
            <Input
              id="label"
              type="text"
              required
              placeholder="Min liste (eksempel)"
              value={data.label}
              onChange={e => setField('label', e.target.value)}
            />
            <Label htmlFor="description">Beskrivelse</Label>
            <TextArea
              id="description"
              placeholder="Beskrivelsestekst..."
              value={data.description || ''}
              onChange={e => setField('description', e.target.value)}
            />
            {!isUpdate && (
              <Doorman type="admin">
                <Label htmlFor="privacy-selection">Privat liste</Label>
                <Select
                  id="privacy-selection"
                  defaultValue="no"
                  value={data.user || ''}
                  onChange={e => {
                    if (e.target.value === 'no') {
                      setField('user', null);
                    } else {
                      setField('user', e.target.value);
                    }
                  }}
                >
                  <option value="no">Nei</option>
                  <option value={userContext.data.id}>Ja</option>
                </Select>
              </Doorman>
            )}
            <ButtonList align="right">
              <ButtonLink to="../lister">Avbryt</ButtonLink>
              {data && data._id && (
                <>
                  {(data.user === userContext.data.id || isAdmin) && (
                    <ButtonExternalLink
                      onClick={() => destroy()}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                </>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default CreateList;
