import styled from '../../util/styled';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 14rem 1fr;
  grid-template-rows: ${props => props.theme.layout.headerHeight} auto;
  grid-template-areas:
    'header header'
    'main main';
  padding-bottom: 8rem;
`;

const Main = styled.div`
  grid-area: main;
  width: 100%;
  margin: 0 auto;
  padding-top: ${props => props.theme.layout.spacing.normal};
`;

export default Wrapper;
export { Main };
