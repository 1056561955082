import React, { ReactNode } from 'react';
import { Contact } from '../types/apiResponses';
import Text from '../components/Text';

export default function getContactName(contact: Contact): ReactNode {
  if (!contact.randomName && contact.active) {
    return contact.name;
  }

  const strings: string[] = [];

  if (contact.randomName) {
    strings.push('anonym');
  }

  if (!contact.active) {
    strings.push('inaktiv');
  }

  return (
    <span>
      {contact.name}{' '}
      <Text element="span" variant="subheading">
        ({strings.join(', ')})
      </Text>
    </span>
  );
}
