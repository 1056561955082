import React, { FunctionComponent, useEffect, useState } from 'react';
import DoubleScrollbar from 'react-double-scrollbar';
import styled, { css } from '../../util/styled';
import { FADE_IN } from '../../constants/animations';
import getWindowWidth from '../../util/getWindowWidth';
import { mobileThreshold } from '../../constants/theme';
import Icon from '../Icon';

const windowWidth = getWindowWidth();

const Table = styled.table`
  font-size: 13px;
  width: 100%;
`;

const TableHead = styled.thead``;

const TableBody = styled.tbody``;

const Tr = styled.tr`
  &:last-child {
    td {
      border: none;
    }
  }
`;

interface TdProps {
  centered?: boolean;
  right?: boolean;
  width?: string;
}

const Td = styled.td<TdProps>`
  padding: ${props =>
    `${props.theme.layout.spacing.small} ${props.theme.layout.spacing.normal}`};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  animation: ${FADE_IN} 0.24s ease-out both;
  white-space: nowrap;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.right &&
    css`
      text-align: right;
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

interface ThProps extends TdProps {}

const Th = styled.th<ThProps>`
  text-align: left;
  padding: ${props =>
    `${props.theme.layout.spacing.small} ${props.theme.layout.spacing.normal}`};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  animation: ${FADE_IN} 0.24s ease-out both;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${props =>
    props.right &&
    css`
      text-align: right;
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  a {
    color: inherit;
    position: relative;
    display: block;
    padding-right: 22px;

    i {
      display: inline;
      text-decoration: none;
      position: absolute;
      right: 0;
      bottom: -2px;
    }
  }
`;

interface TableScrollWrapperComponentProps {
  maxWidth: string;
}

const TableScrollWrapperComponent = styled.div<
  TableScrollWrapperComponentProps
>`
  max-width: ${props => props.maxWidth};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

interface TableScrollWrapperProps {
  offset?: number;
}

const TableScrollWrapper: FunctionComponent<TableScrollWrapperProps> = ({
  offset,
  children
}) => {
  const [maxWidth, setMaxWidth] = useState('none');

  useEffect(() => {
    setMaxWidth(
      window.innerWidth -
        (windowWidth > mobileThreshold ? 310 : 90) -
        (offset || 0) +
        'px'
    );
  }, []);

  return (
    <TableScrollWrapperComponent maxWidth={maxWidth}>
      <DoubleScrollbar>{children}</DoubleScrollbar>
    </TableScrollWrapperComponent>
  );
};

export type SortDirection = 'asc' | 'desc';

interface SortableThProps extends ThProps {
  onToggleSort?: (direction: SortDirection) => any;
  isSorting?: boolean;
  sortDirection?: SortDirection;
}

const SortableTh: FunctionComponent<SortableThProps> = ({
  isSorting,
  sortDirection,
  onToggleSort,
  children,
  ...rest
}) => {
  const [internalIsSorting, setInternalIsSorting] = useState(
    isSorting || false
  );

  const [internalSortDirection, setInternalSortDirection] = useState(
    sortDirection || 'asc'
  );

  useEffect(() => {
    setInternalIsSorting(isSorting || false);
  }, [isSorting]);

  return (
    <Th {...rest}>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          const newDirection: SortDirection =
            internalSortDirection === 'asc' ? 'desc' : 'asc';

          if (typeof onToggleSort === 'function') {
            onToggleSort(newDirection);
          }

          setInternalIsSorting(true);
          setInternalSortDirection(newDirection);
        }}
      >
        {children}
        <Icon>
          {internalIsSorting
            ? internalSortDirection === 'desc'
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_up'
            : 'remove'}
        </Icon>
      </a>
    </Th>
  );
};

export default Table;
export { TableScrollWrapper, TableHead, TableBody, Tr, Td, Th, SortableTh };
