import React, { createContext } from 'react';
import { withSnackbar } from 'notistack';

type Variant = 'success' | 'warning' | 'error' | 'info';

interface ISnack {
  enqueueSnackbar: (message: string, variant: { variant: Variant }) => void;
}

const defaultSnack: ISnack = {
  enqueueSnackbar: (message, variant) => {}
};

const SnackContext = createContext(defaultSnack);

function Provider({
  enqueueSnackbar,
  children
}: ISnack & JSX.ElementChildrenAttribute) {
  return (
    <SnackContext.Provider
      value={{
        enqueueSnackbar
      }}
    >
      {children}
    </SnackContext.Provider>
  );
}

const SnackProvider = withSnackbar(Provider);

export { SnackProvider, SnackContext };
