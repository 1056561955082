import { Permissions } from '../constants/enums';

export const permissionsAll = [
  Permissions.Reader,
  Permissions.Handler,
  Permissions.HandlerPlus,
  Permissions.Writer,
  Permissions.Admin
];

export const permissionsHandler = [
  Permissions.Handler,
  Permissions.HandlerPlus,
  Permissions.Writer,
  Permissions.Admin
];

export const permissionsHandlerPlus = [
  Permissions.HandlerPlus,
  Permissions.Writer,
  Permissions.Admin
];

export const permissionsWriter = [Permissions.Writer, Permissions.Admin];

export const permissionsAdmin = [Permissions.Admin];
