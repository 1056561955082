import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import history from '../../util/history';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Form, { Label, Input } from '../../components/Form';
import { User } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import Button, { ButtonList, ButtonLink } from '../../components/Button';
import Text from '../../components/Text';

export default function UpdateUser() {
  const { data, setField, submit, hasMadeChanges, submitting } = useForm<User>(
    {
      phone: ''
    },
    {
      endpoint: 'users/me',
      method: 'PATCH',
      prefillEndpoint: 'users/me',
      onSuccess: () => {
        history.push('../innstillinger');
        window.location.reload();
      }
    }
  );

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Endre brukerinnstillinger
        </Text>
      </Container>
      <Container spacious hugTop>
        <Form
          onSubmit={e => {
            submit(e);
          }}
        >
          <Label htmlFor="phone">Telefonnummer</Label>
          <Input
            type="text"
            id="phone"
            placeholder="Telefonnummer"
            value={data.phone || ''}
            onChange={e => setField('phone', e.target.value)}
          />
          <ButtonList align="right">
            <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
            <Button disabled={!hasMadeChanges} type="submit" variant="primary">
              {submitting ? 'Lagrer…' : 'Lagre'}
            </Button>
          </ButtonList>
        </Form>
      </Container>
    </Content>
  );
}
