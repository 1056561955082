import React, { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LaneItemType } from '../../constants/enums';
import {
  Board,
  Lane as LaneType,
  LaneItem as LaneItemInterfaceType
} from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import Lanes, { Lane, LaneItem } from '../../components/Lanes';
import getContactName from '../../util/getContactName';
import useForm from '../../hooks/useForm';
import Form, { ExternalInput } from '../../components/Form';
import Button, {
  ButtonExternalLink,
  ButtonList
} from '../../components/Button';
import { UiContext } from '../../context/Ui';

interface ShowParams {
  organizationID: string;
  boardID: string;
}

function Show(props: RouteComponentProps<ShowParams>) {
  const organizationID = props.match.params.organizationID;
  const boardID = props.match.params.boardID;
  const endpoint = 'organizations/' + organizationID + '/boards/' + boardID;

  const modalContext = useContext(UiContext).modal;

  const [board, loading, fetch, hasFetched] = useApi<Board | null>({
    endpoint,
    fetchOnMount: true,
    initialData: null,
    onSuccess: board => {
      setLanes((board as Board).lanes);
    }
  });

  const [_, patching, patch] = useApi<Board | null>({
    endpoint,
    initialData: null,
    onSuccess: () =>
      fetch({
        endpoint
      })
  });

  const [isDragging, setIsDragging] = useState(false);
  const [lanes, setLanes] = useState([] as LaneType[]);

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {board ? board.name : 'Laster…'}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {board && (
            <Menu>
              <MenuItem
                to={board._id + '/endre'}
                icon={<Icon>edit</Icon>}
                primary
              >
                Rediger brett
              </MenuItem>
            </Menu>
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={!hasFetched && loading} exposeChildren={!hasFetched}>
              {board && (
                <Lanes
                  onDragStart={() => setIsDragging(true)}
                  onSuccessfulDrop={d => {
                    setIsDragging(false);

                    setLanes(
                      lanes.map(l => {
                        const col = d.columns.find(c => c._id === l._id);

                        if (col) {
                          l.items = col.items.map(i => ({
                            ...i,
                            ref: i._id,
                            type: LaneItemType.Contact
                          }));
                        }

                        return l;
                      })
                    );

                    patch({
                      method: 'POST',
                      endpoint: endpoint + '/lanes/' + d.droppableID + '/items',
                      body: {
                        ref: d.draggableID,
                        pos: d.pos
                      }
                    });
                  }}
                  columnNumber={board.lanes.length}
                  columns={board.lanes.map(l => ({
                    _id: l._id,
                    items: l.items.map(i => ({
                      _id: i.ref,
                      pos: i.pos,
                      contact: i.contact
                    }))
                  }))}
                >
                  {lanes.map(l => (
                    <Lane
                      key={l._id}
                      id={l._id}
                      label={l.name}
                      hasItems={l.items.length > 0}
                      dragging={isDragging}
                      onAddClick={() =>
                        modalContext.spawnModal(
                          <AddItem
                            organization={organizationID}
                            board={boardID}
                            lane={l._id}
                            close={modalContext.despawnModal}
                            onSuccess={() =>
                              fetch({
                                endpoint
                              })
                            }
                          />,
                          'addItem'
                        )
                      }
                    >
                      {l.items.map((i, k) =>
                        i.contact ? (
                          <LaneItem key={i._id} id={i.ref} index={k}>
                            <Text>{getContactName(i.contact)}</Text>
                            <Text variant="subheading">{i.contact.email}</Text>
                          </LaneItem>
                        ) : null
                      )}
                    </Lane>
                  ))}
                </Lanes>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface AddItemProps {
  organization: string;
  board: string;
  lane: string;
  close: (id: string) => any;
  onSuccess?: () => any;
}

function AddItem(props: AddItemProps) {
  const { organization, board, lane, close, onSuccess } = props;
  const baseEndpoint = 'organizations/' + organization;
  const searchEndpoint = baseEndpoint + '/contacts/search';

  const { data, setField, submit } = useForm<LaneItemInterfaceType>(
    {
      ref: ''
    },
    {
      endpoint: baseEndpoint + '/boards/' + board + '/lanes/' + lane + '/items',
      onSuccess: () => {
        close('addItem');

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form onSubmit={submit}>
        <ExternalInput
          endpoint={searchEndpoint}
          label="Profil *"
          onChange={result => setField('ref', result._id)}
        />
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createThread');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          <Button
            type="submit"
            variant="primary"
            disabled={data.ref.length < 1}
          >
            Legg til
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

export default Show;
