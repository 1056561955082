import styled from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

interface Props {
  sidebar?: boolean;
}

const Content = styled.div<Props>`
  grid-area: content;
  padding-left: ${props =>
    props.sidebar ? 0 : props.theme.layout.sidebarWidth};

  @media (max-width: ${mobileThreshold}px) {
    padding-left: 0;
  }
`;

export default Content;
