import React, { useContext, ReactNode, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import moment from '../../util/moment';
import { Container, Flex, FlexKid, Spacer } from '../../helpers/Layout';
import Text from '../../components/Text';
import Card, { CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import { acquisitionTypes } from '../../constants/localization';
import localize from '../../util/localize';
import Doorman from '../../components/Doorman';
import {
  ContactsStats,
  ContactsChannelStatGroup,
  ShipmentsStats,
  CollectionsStats,
  CollectionsDetailStats,
  StatGroup,
  ClicksStats
} from '../../types/stats';
import { calculatePercentageGrowth } from '../../util/stats';
import Fader from '../../helpers/Fader';
import { numberFormat } from '../../util/intl';
import theme from '../../constants/theme';
import Service from '../../components/Service';
import Table, {
  TableHead,
  Th,
  TableBody,
  Tr,
  Td,
  TableScrollWrapper
} from '../../components/Table';
import {
  Organization,
  Campaign,
  Receiver,
  Automation
} from '../../types/apiResponses';
import { OrganizationContext } from '../../context/Organization';
import { StatType } from '../../constants/enums';
import { Label } from '../../components/Form';
import ReactDatePicker from '../../components/DatePicker';

interface ShowParams {
  organizationID: string;
  reportType: string;
  reportFilter?: string;
  reportSecondaryFilter?: string;
}

function Show(props: RouteComponentProps<ShowParams>) {
  const organizationID = props.match.params.organizationID;
  const reportType = props.match.params.reportType;
  const reportFilter = props.match.params.reportFilter;
  const reportSecondaryFilter = props.match.params.reportSecondaryFilter;
  const baseUrl = '/organisasjoner/' + organizationID;
  const coreEndpoint = 'organizations/' + organizationID;
  const baseEndpoint = coreEndpoint + '/stats';

  const organization = useContext(OrganizationContext).data;

  const [fromDate, setFromDate] = useState(null as Date | null);
  const [toDate, setToDate] = useState(null as Date | null);

  function renderReports() {
    switch (reportType) {
      case 'profiler':
        return (
          <Contacts
            key={`${fromDate}${toDate}`}
            organization={organization}
            baseEndpoint={baseEndpoint}
            fromDate={fromDate}
            toDate={toDate}
          />
        );

      case 'innsamlinger':
        if (reportFilter) {
          return (
            <CollectionsByCampaign
              key={`${fromDate}${toDate}`}
              organization={organization}
              baseEndpoint={baseEndpoint}
              fromDate={fromDate}
              toDate={toDate}
              filter={reportFilter}
            />
          );
        } else {
          return (
            <Collections
              key={`${fromDate}${toDate}`}
              organization={organization}
              baseEndpoint={baseEndpoint}
              fromDate={fromDate}
              toDate={toDate}
              filter={reportFilter}
            />
          );
        }

      case 'utsendelser':
        return (
          <Shipments
            key={`${fromDate}${toDate}${reportFilter}`}
            organization={organization}
            baseEndpoint={baseEndpoint}
            fromDate={fromDate}
            toDate={toDate}
            filter={reportFilter}
          />
        );

      case 'klikk':
        return (
          <Clicks
            key={`${fromDate}${toDate}${reportFilter}${reportSecondaryFilter}`}
            organization={organization}
            baseEndpoint={baseEndpoint}
            fromDate={fromDate}
            toDate={toDate}
            filter={reportFilter}
            secondaryFilter={reportSecondaryFilter}
          />
        );

      default:
        return null;
    }
  }

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Rapporter
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem
                to={baseUrl + '/rapporter/profiler'}
                icon={<Icon>group</Icon>}
              >
                Profiler
              </MenuItem>
              <MenuItem
                to={baseUrl + '/rapporter/innsamlinger'}
                icon={<Icon>move_to_inbox</Icon>}
              >
                innsamlinger
              </MenuItem>
              <MenuItem
                to={baseUrl + '/rapporter/utsendelser'}
                icon={<Icon>send</Icon>}
              >
                Utsendelser
              </MenuItem>
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <Flex>
              <FlexKid>
                <Label htmlFor="fromDate">Fra-dato</Label>
                <ReactDatePicker
                  id="fromDate"
                  selected={fromDate}
                  onChange={value => setFromDate(value)}
                  showTimeSelect
                  dateFormat="dd.MM.YYY HH:mm"
                />
              </FlexKid>
              <FlexKid spaceLeft>
                <Label htmlFor="toDate">Til-dato</Label>
                <ReactDatePicker
                  id="toDate"
                  selected={toDate}
                  onChange={value => setToDate(value)}
                  showTimeSelect
                  dateFormat="dd.MM.YYY HH:mm"
                />
              </FlexKid>
            </Flex>
            <Spacer spacious />
            {organization._id && renderReports()}
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface ReportProps {
  organization: Organization;
  baseEndpoint: string;
  fromDate: Date | null;
  toDate: Date | null;
  filter?: string;
  secondaryFilter?: string;
}

function Contacts(props: ReportProps) {
  const { organization, baseEndpoint, fromDate, toDate } = props;

  const [stats] = useApi<ContactsStats>({
    endpoint: baseEndpoint + '/contacts',
    initialData: {
      total: 0,
      acquisitionLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      acquisitionsByChannel: [],
      acquisitionsByFpSource: [],
      acquisitionsByTpSource: []
    },
    fetchOnMount: true,
    queryParams: {
      fromDate,
      toDate
    }
  });

  const [campaigns] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organization._id + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  const [receivers] = useApi<Receiver[]>({
    endpoint: 'organizations/' + organization._id + '/receivers',
    initialData: [],
    fetchOnMount: true
  });

  const percentageGrowth = calculatePercentageGrowth(
    stats.acquisitionLast7Days.currentPeriod,
    stats.acquisitionLast7Days.lastPeriod
  );

  return (
    <>
      <Text element="h2" variant="title">
        Profiler
      </Text>
      <Spacer spacious />
      <Fader variant="top">
        <Flex>
          <FlexKid flex={1} flexBasis="50%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">{numberFormat.format(stats.total)}</Text>
                <Text variant="subheading">Totalt antall</Text>
              </CardContent>
              <CardIcon>
                <Icon>group</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="50%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(
                    stats.acquisitionLast7Days.currentPeriod
                  )}{' '}
                  {stats.acquisitionLast7Days.currentPeriod > 0 && (
                    <Text
                      element="span"
                      weight={400}
                      color={
                        percentageGrowth >= 0
                          ? theme.colors.good
                          : theme.colors.warning
                      }
                    >
                      {percentageGrowth > 0 && '+'}
                      {percentageGrowth === 0
                        ? '+∞'
                        : numberFormat.format(percentageGrowth)}
                      %
                    </Text>
                  )}
                </Text>
                <Text variant="subheading">Nye siste 7 dager</Text>
              </CardContent>
              <CardIcon>
                <Icon>trending_up</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        <Spacer tight />
        <Flex>
          <FlexKid flex={1} flexBasis="33.332%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {stats.acquisitionsByChannel.length > 0
                    ? localize(
                        acquisitionTypes,
                        stats.acquisitionsByChannel[0]._id.type
                      )
                    : 'Ingen'}
                </Text>
                <Text variant="subheading">Vanligste første interaksjon</Text>
              </CardContent>
              <CardIcon>
                <Icon>touch_app</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="33.332%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {stats.acquisitionsByFpSource.length > 0 ? (
                    <Service
                      service={stats.acquisitionsByFpSource[0]._id}
                      firstParty
                      element="span"
                    />
                  ) : (
                    'Ingen'
                  )}
                </Text>
                <Text variant="subheading">Vanligste første kilde</Text>
              </CardContent>
              <CardIcon>
                <Icon>redo</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="33.332%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {stats.acquisitionsByTpSource.length > 0 ? (
                    <Service
                      service={stats.acquisitionsByTpSource[0]._id}
                      element="span"
                    />
                  ) : (
                    'Ingen'
                  )}
                </Text>
                <Text variant="subheading">
                  Vanligste første henvisningskilde
                </Text>
              </CardContent>
              <CardIcon>
                <Icon>share</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        {stats.acquisitionsByChannel.length > 0 && (
          <>
            <Spacer tight />
            <Flex align="flex-start">
              <FlexKid flex={1} flexBasis="100%">
                <Card secondary>
                  <CardContent>
                    <Text variant="title">Toppliste første interaksjon</Text>
                    <Spacer />
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th centered width="100px">
                            Plassering
                          </Th>
                          <Th>Interaksjon</Th>
                          <Th>Detaljer</Th>
                          <Th right>Antall</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {stats.acquisitionsByChannel.map((c, k) => (
                          <Tr key={k}>
                            <Td centered>{k + 1}.</Td>
                            <Td>{localize(acquisitionTypes, c._id.type)}</Td>
                            <Td>
                              {getInteractionDetails(
                                organization,
                                c,
                                campaigns,
                                receivers
                              )}
                            </Td>
                            <Td right>{numberFormat.format(c.count)}</Td>
                          </Tr>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </FlexKid>
            </Flex>
          </>
        )}
        {stats.acquisitionsByFpSource.length > 0 && (
          <>
            <Spacer tight />
            <Flex align="flex-start">
              <FlexKid flex={1} flexBasis="50%">
                <Card secondary>
                  <CardContent>
                    <Text variant="title">Toppliste første kilde</Text>
                    <Spacer />
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th centered width="100px">
                            Plassering
                          </Th>
                          <Th>Kilde</Th>
                          <Th right>Antall</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {stats.acquisitionsByFpSource.map((s, k) => (
                          <Tr key={k}>
                            <Td centered>{k + 1}.</Td>
                            <Td>{s._id}</Td>
                            <Td right>{numberFormat.format(s.count)}</Td>
                          </Tr>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </FlexKid>
              <FlexKid flex={1} flexBasis="50%" spaceLeft>
                <Card secondary>
                  <CardContent>
                    <Text variant="title">
                      Toppliste første henvisningskilde
                    </Text>
                    <Spacer />
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th centered width="100px">
                            Plassering
                          </Th>
                          <Th>Kilde</Th>
                          <Th right>Antall</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {stats.acquisitionsByTpSource.map((s, k) => (
                          <Tr key={k}>
                            <Td centered>{k + 1}.</Td>
                            <Td>{s._id}</Td>
                            <Td right>{numberFormat.format(s.count)}</Td>
                          </Tr>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </FlexKid>
            </Flex>
          </>
        )}
      </Fader>
    </>
  );
}

function Collections(props: ReportProps) {
  const { organization, baseEndpoint, fromDate, toDate } = props;

  const [campaigns] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organization._id + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  const [stats] = useApi<CollectionsStats>({
    endpoint: baseEndpoint + '/collections',
    initialData: {
      total: 0,
      failed: 0
    },
    fetchOnMount: true,
    queryParams: {
      fromDate,
      toDate
    }
  });

  function calcPercentage(): number {
    const successes = stats.total - stats.failed;

    if (!successes || !stats.total) {
      return 0;
    }

    return (successes / stats.total) * 100;
  }

  const successPercentage = calcPercentage();

  return (
    <>
      <Text element="h2" variant="title">
        Innsamlinger
      </Text>
      <Spacer spacious />
      <Fader variant="top">
        <Flex>
          <FlexKid flex={1} flexBasis="50%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">{numberFormat.format(stats.total)}</Text>
                <Text variant="subheading">Totalt antall innsamlinger</Text>
              </CardContent>
              <CardIcon>
                <Icon>move_to_inbox</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="50%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(successPercentage)}%
                </Text>
                <Text variant="subheading">Total suksessrate</Text>
              </CardContent>
              <CardIcon>
                <Icon>thumb_up</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        {campaigns.length > 0 && (
          <>
            <Spacer tight />
            <Flex align="flex-start">
              <FlexKid flex={1} flexBasis="100%">
                <Card secondary>
                  <CardContent>
                    <Text variant="title">Innsamlingskampanjer</Text>
                    <Spacer />
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th>Navn</Th>
                          <Th>Handling</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {campaigns.map(c => (
                          <Tr key={c._id}>
                            <Td>{c.name}</Td>
                            <Td>
                              <Link to={'innsamlinger/' + c._id}>
                                Vis detaljert rapport
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </FlexKid>
            </Flex>
          </>
        )}
      </Fader>
    </>
  );
}

function CollectionsByCampaign(props: ReportProps) {
  const { organization, baseEndpoint, filter, fromDate, toDate } = props;

  const [campaign] = useApi<Campaign | null>({
    endpoint: 'organizations/' + organization._id + '/campaigns/' + filter,
    initialData: null,
    fetchOnMount: true
  });

  const [stats] = useApi<CollectionsStats>({
    endpoint: baseEndpoint + '/collections',
    initialData: {
      total: 0,
      failed: 0
    },
    fetchOnMount: true,
    queryParams: {
      fromDate,
      toDate,
      meta1: filter
    }
  });

  const [collectionsDetail] = useApi<CollectionsDetailStats[]>({
    endpoint: baseEndpoint + '/collections-detail',
    initialData: [],
    fetchOnMount: true,
    queryParams: {
      meta1: filter
    }
  });

  function calcPercentage(): number {
    const successes = stats.total - stats.failed;

    if (!successes || !stats.total) {
      return 0;
    }

    return (successes / stats.total) * 100;
  }

  const successPercentage = calcPercentage();

  return (
    <>
      <Text element="h2" variant="title">
        Innsamlinger · {campaign ? campaign.name : 'Laster…'}
      </Text>
      <Spacer spacious />
      <Fader variant="top">
        <Flex>
          <FlexKid flex={1} flexBasis="50%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">{numberFormat.format(stats.total)}</Text>
                <Text variant="subheading">Antall innsamlinger</Text>
              </CardContent>
              <CardIcon>
                <Icon>move_to_inbox</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="50%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(successPercentage)}%
                </Text>
                <Text variant="subheading">Suksessrate</Text>
              </CardContent>
              <CardIcon>
                <Icon>thumb_up</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        <Spacer tight />
        <Flex align="flex-start">
          <FlexKid flex={1} flexBasis="100%">
            <Card secondary>
              <CardContent>
                <Text variant="title">Innsamlinger</Text>
                <Spacer />
                <Table>
                  <TableHead>
                    <Tr>
                      <Th>Innsamler</Th>
                      <Th>Profil</Th>
                      <Th>Status</Th>
                      <Th>Tidspunkt</Th>
                    </Tr>
                  </TableHead>
                  <TableBody>
                    {collectionsDetail.length > 0 ? (
                      collectionsDetail.map(c => (
                        <Tr key={c._id}>
                          <Td>{c.collectorName}</Td>
                          <Td>
                            {c.contact ? (
                              <Link
                                to={
                                  '/organisasjoner/' +
                                  organization._id +
                                  '/profiler/' +
                                  c.contact._id
                                }
                              >
                                {c.contact.name}
                              </Link>
                            ) : (
                              <Text variant="subheading">Slettet</Text>
                            )}
                          </Td>
                          <Td>{c.status}</Td>
                          <Td>
                            {moment(c.timestamp).format(
                              'D.M.YY [kl.] HH:mm:ss'
                            )}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Td colSpan={4}>
                        <Text variant="subheading" centered>
                          Ingen!
                        </Text>
                      </Td>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </FlexKid>
        </Flex>
      </Fader>
    </>
  );
}

function Shipments(props: ReportProps) {
  const { baseEndpoint, organization, fromDate, toDate, filter } = props;

  const [stats] = useApi<ShipmentsStats>({
    endpoint: baseEndpoint + '/shipments',
    initialData: {
      total: 0,
      sentVsRead: [],
      sentVsEngagement: [],
      readVsEngagement: [],
      sentVsBounce: [],
      clickReport: [],
      topShipmentsByReadRate: []
    },
    fetchOnMount: true,
    queryParams: {
      fromDate,
      toDate,
      meta1: filter
    }
  });

  const [shipment] = useApi<Automation | null>({
    endpoint: 'organizations/' + organization._id + '/automations/' + filter,
    initialData: null,
    fetchOnMount: !!filter
  });

  function calcPercentage(
    groups: StatGroup[],
    type: 'read' | 'ctr' | 'ctor' | 'bounce'
  ): number {
    let aObj: StatGroup | undefined = undefined;
    let bObj: StatGroup | undefined = undefined;
    let a = 0;
    let b = 0;

    switch (type) {
      case 'read':
        aObj = groups.find(g => g._id === StatType.ShipmentSent);
        bObj = groups.find(g => g._id === StatType.ShipmentRead);

        break;
      case 'ctr':
        aObj = groups.find(g => g._id === StatType.ShipmentSent);
        bObj = groups.find(g => g._id === StatType.ShipmentEngagement);

        break;
      case 'ctor':
        aObj = groups.find(g => g._id === StatType.ShipmentRead);
        bObj = groups.find(g => g._id === StatType.ShipmentEngagement);

        break;
      case 'bounce':
        aObj = groups.find(g => g._id === StatType.ShipmentSent);
        bObj = groups.find(g => g._id === StatType.ShipmentDeactivation);

        break;
    }

    if (aObj) {
      a = aObj.count;
    }

    if (bObj) {
      b = bObj.count;
    }

    if (!a || !b) {
      return 0;
    }

    return (b / a) * 100;
  }

  const percentage = calcPercentage(stats.sentVsRead, 'read');
  const percentageCtr = calcPercentage(stats.sentVsEngagement, 'ctr');
  const percentageCtor = calcPercentage(stats.readVsEngagement, 'ctor');
  const percentageBounce = calcPercentage(stats.sentVsBounce, 'bounce');

  return (
    <>
      <Text element="h2" variant="title">
        Utsendelser{' '}
        {filter ? <span>· {shipment ? shipment.name : 'Laster…'}</span> : ''}
      </Text>
      <Spacer spacious />
      <Fader variant="top">
        <Flex>
          <FlexKid flex={1} flexBasis="50%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">{numberFormat.format(stats.total)}</Text>
                <Text variant="subheading">Antall sendt</Text>
              </CardContent>
              <CardIcon>
                <Icon>send</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="50%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">{numberFormat.format(percentage)}%</Text>
                <Text variant="subheading">Åpningsrate</Text>
              </CardContent>
              <CardIcon>
                <Icon>remove_red_eye</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        <Spacer tight />
        <Flex>
          <FlexKid flex={1} flexBasis="33.2%">
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(percentageCtr)}%
                </Text>
                <Text variant="subheading">CTR</Text>
              </CardContent>
              <CardIcon>
                <Icon>mouse</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="33.2%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(percentageCtor)}%
                </Text>
                <Text variant="subheading">CTOR</Text>
              </CardContent>
              <CardIcon>
                <Icon>mouse</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
          <FlexKid flex={1} flexBasis="33.2%" spaceLeft>
            <Card horizontal secondary>
              <CardContent>
                <Text variant="title">
                  {numberFormat.format(percentageBounce)}%
                </Text>
                <Text variant="subheading">Avmeldingsrate</Text>
              </CardContent>
              <CardIcon>
                <Icon>sentiment_dissatisfied</Icon>
              </CardIcon>
            </Card>
          </FlexKid>
        </Flex>
        {stats.topShipmentsByReadRate.length > 0 && (
          <>
            <Spacer tight />
            <Flex align="flex-start">
              <FlexKid flex={1} flexBasis="100%">
                <Card secondary>
                  <CardContent>
                    <Text variant="title">
                      {!!filter ? 'Meldinger' : 'Toppliste åpningsrate'}
                    </Text>
                    <Spacer />
                    <TableScrollWrapper offset={80}>
                      <Table>
                        <TableHead>
                          <Tr>
                            <Th centered width="100px">
                              Plassering
                            </Th>
                            <Th>{!filter ? 'Utsendelse' : 'Melding'}</Th>
                            <Th right>Sendt</Th>
                            <Th right>Unike åpninger</Th>
                            <Th right>Åpningsrate</Th>
                            <Th right>Unike klikk</Th>
                            <Th right>CTR</Th>
                            <Th right>CTOR</Th>
                            <Th right>Avmeldinger</Th>
                            <Th right>Avmeldingsrate</Th>
                          </Tr>
                        </TableHead>
                        <TableBody>
                          {stats.topShipmentsByReadRate.map((s, k) => (
                            <Tr key={k}>
                              <Td centered>{k + 1}.</Td>
                              <Td>
                                {s.shipmentName && !filter ? (
                                  <Link to={'utsendelser/' + s.shipment}>
                                    {s.shipmentName}
                                  </Link>
                                ) : filter && s.messageName ? (
                                  s.messageName
                                ) : (
                                  <Text element="span" variant="subheading">
                                    Slettet
                                  </Text>
                                )}
                              </Td>
                              <Td right>{numberFormat.format(s.sent)}</Td>
                              <Td right>{numberFormat.format(s.read)}</Td>
                              <Td right>
                                {numberFormat.format(s.rate * 100)}%
                              </Td>
                              <Td right>
                                {numberFormat.format(s.engagements)}
                              </Td>
                              <Td right>{numberFormat.format(s.ctr * 100)}%</Td>
                              <Td right>
                                {numberFormat.format(s.ctor * 100)}%
                              </Td>
                              <Td right>{s.bounces}</Td>
                              <Td right>
                                {numberFormat.format(s.bounceRate * 100)}%
                              </Td>
                            </Tr>
                          ))}
                        </TableBody>
                      </Table>
                    </TableScrollWrapper>
                  </CardContent>
                </Card>
              </FlexKid>
            </Flex>
          </>
        )}
        {stats.clickReport.length > 0 && (
          <>
            <Spacer tight />
            <Flex align="flex-start">
              <FlexKid flex={1} flexBasis="100%">
                <Card secondary>
                  <CardContent>
                    <Text variant="title">Klikkrapport</Text>
                    <Spacer />
                    <TableScrollWrapper offset={80}>
                      <Table>
                        <TableHead>
                          <Tr>
                            <Th centered width="100px">
                              Plassering
                            </Th>
                            <Th>URL</Th>
                            <Th right>Unike klikk</Th>
                          </Tr>
                        </TableHead>
                        <TableBody>
                          {stats.clickReport.map((c, k) => (
                            <Tr key={k}>
                              <Td centered>{k + 1}.</Td>
                              <Td>{c._id}</Td>
                              <Td right>
                                <Link
                                  to={
                                    '/organisasjoner/' +
                                    organization._id +
                                    '/rapporter/klikk/' +
                                    encodeURIComponent(c._id) +
                                    (filter ? '/' + filter : '')
                                  }
                                >
                                  {numberFormat.format(c.count)}
                                </Link>
                              </Td>
                            </Tr>
                          ))}
                        </TableBody>
                      </Table>
                    </TableScrollWrapper>
                  </CardContent>
                </Card>
              </FlexKid>
            </Flex>
          </>
        )}
      </Fader>
    </>
  );
}

function Clicks(props: ReportProps) {
  const {
    baseEndpoint,
    organization,
    fromDate,
    toDate,
    filter,
    secondaryFilter
  } = props;

  const [stats] = useApi<ClicksStats[]>({
    endpoint: baseEndpoint + '/engagements',
    initialData: [],
    fetchOnMount: true,
    queryParams: {
      fromDate,
      toDate,
      meta3: filter,
      meta1: secondaryFilter
    }
  });

  const [shipment] = useApi<Automation | null>({
    endpoint:
      'organizations/' + organization._id + '/automations/' + secondaryFilter,
    initialData: null,
    fetchOnMount: !!secondaryFilter
  });

  return (
    <>
      <Text element="h2" variant="title">
        Klikkrapport
        {!!filter ? <span> · {decodeURIComponent(filter)}</span> : ''}
        {!!secondaryFilter ? (
          <span> · {shipment ? shipment.name : 'Laster…'}</span>
        ) : (
          ''
        )}
      </Text>
      <Spacer spacious />
      <Fader variant="top">
        <Spacer tight />
        <Flex align="flex-start">
          <FlexKid flex={1} flexBasis="100%">
            <Card secondary>
              <CardContent>
                <Text variant="title">Klikkrapport</Text>
                <Spacer />
                <TableScrollWrapper offset={80}>
                  <Table>
                    <TableHead>
                      <Tr>
                        <Th>Profil</Th>
                        <Th>Tidspunkt</Th>
                      </Tr>
                    </TableHead>
                    <TableBody>
                      {stats.map(s => (
                        <Tr key={s._id}>
                          <Td>
                            {s.contact && typeof s.contact !== 'string' ? (
                              <Link
                                to={
                                  '/organisasjoner/' +
                                  organization._id +
                                  '/profiler/' +
                                  s.contact._id
                                }
                              >
                                {s.contact.name}
                              </Link>
                            ) : (
                              <Text variant="subheading">Slettet</Text>
                            )}
                          </Td>
                          <Td>
                            {moment(s.timestamp).format(
                              'D.M.YY [kl.] HH:mm:ss'
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </TableBody>
                  </Table>
                </TableScrollWrapper>
              </CardContent>
            </Card>
          </FlexKid>
        </Flex>
      </Fader>
    </>
  );
}

function getInteractionDetails(
  organization: Organization,
  group: ContactsChannelStatGroup,
  campaigns: Campaign[],
  receivers: Receiver[]
): ReactNode {
  switch (group._id.type) {
    case 'dataPoint':
      const dataPoint = organization.dataPoints.find(
        d => d._id === group._id.source
      );

      if (dataPoint) {
        return dataPoint.label;
      }

      break;
    case 'correspondence':
      if (group._id.meta) {
        return group._id.meta;
      }

      break;
    case 'collection':
      const campaign = campaigns.find(c => c._id === group._id.source);

      if (campaign) {
        return campaign.name;
      }

      break;
    case 'receiver':
      const receiver = receivers.find(r => r._id === group._id.source);

      if (receiver) {
        return receiver.name;
      }

      break;
  }

  return <Text variant="subheading">N/A</Text>;
}

export default Show;
