import React, { FunctionComponent, MouseEvent } from 'react';
import styled, { css } from '../../util/styled';

const List = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
`;

interface ListItemWrapperProps {
  hover?: boolean;
  active?: boolean;
  tight?: boolean;
}

const ListItemWrapper = styled.a<ListItemWrapperProps>`
  display: block;
  padding: ${props => props.theme.layout.spacing.normal};

  &,
  > * {
    transition: all
      ${props =>
        `${props.theme.animation.timing.normal} ${
          props.theme.animation.easing.inOut
        }`};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.border};
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${props => props.theme.colors.background};
  }

  ${props =>
    props.hover &&
    css`
      text-decoration: none;
      background-color: ${props => props.theme.colors.background};
    `};

  ${props =>
    props.active &&
    css`
      font-weight: 700;
    `};

  ${props =>
    props.tight &&
    css`
      padding: ${props => props.theme.layout.spacing.small}
        ${props => props.theme.layout.spacing.normal};
    `};
`;

interface ListItemProps {
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => any;
  hover?: boolean;
  active?: boolean;
  tight?: boolean;
}

const ListItem: FunctionComponent<ListItemProps> = ({
  onClick,
  hover,
  active,
  tight,
  children
}) => {
  return (
    <ListItemWrapper
      href="#"
      hover={hover}
      active={active}
      tight={tight}
      onClick={e => {
        e.preventDefault();

        if (typeof onClick === 'function') {
          onClick(e);
        }
      }}
    >
      {children}
    </ListItemWrapper>
  );
};

export default List;
export { ListItem };
