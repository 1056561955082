import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Container } from '../../../helpers/Layout';
import moment from '../../../util/moment';
import {
  RobotTrigger,
  Robot,
  Organization,
  DataPoint,
  Campaign,
  Receiver,
  OrganizationApp,
  AppAction,
  List
} from '../../../types/apiResponses';
import Form, { Label, Select, Input } from '../../../components/Form';
import useForm from '../../../hooks/useForm';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../../components/Button';
import BusyBoy from '../../../helpers/BusyBoy';
import {
  RobotTriggerTimingType,
  RobotTriggerType,
  TargetLogic
} from '../../../constants/enums';
import {
  TriggerAggregatorScoreInstructions,
  TriggerBaseInstructions,
  TriggerDataPointInstructions,
  TriggerSourceSetInstructions,
  TriggerCollectionInstructions,
  TriggerDateInstructions,
  TriggerReceiverInstructions,
  TriggerActionInstructions,
  TriggerListInstructions,
  TriggerThreadCreateInstructionns
} from '../../../types/robotInstructions';
import ParameterExpander from './ParameterExpander';
import useApi from '../../../hooks/useApi';
import labelDataPoints from '../../../util/labelDataPoints';
import { AppsContext } from '../../../context/Apps';
import { targetLogic } from '../../../constants/localization';
import ReactDatePicker from '../../../components/DatePicker';

const triggerTimes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface TriggerModalProps {
  organization: Organization;
  robot: Robot;
  close: (id: string) => any;
}

function Trigger(props: TriggerModalProps) {
  const { organization, robot, close } = props;

  const endpoint = 'organizations/' + organization._id + '/robots/' + robot._id;

  const apps = useContext(AppsContext).data;

  const { data, submitting, loadingPrefill, setField, submit } = useForm<Robot>(
    robot,
    {
      endpoint,
      method: 'PATCH',
      prefillEndpoint: endpoint,
      onSuccess: () => close('trigger')
    }
  );

  const [campaigns] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organization._id + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  function setInstructionsField(key: string, value: any) {
    setField('trigger', {
      ...data.trigger,
      instructions: {
        ...data.trigger.instructions,
        [key]: value
      }
    });
  }

  function renderInstructions() {
    switch (data.trigger.__type) {
      case RobotTriggerType.DataPointSet:
        return renderDataPoint(
          data.trigger,
          setInstructionsField,
          labelDataPoints(organization.dataPoints, apps)
        );
      case RobotTriggerType.AggregatorScore:
        return renderAggregatorScore(data.trigger, setInstructionsField);
      case RobotTriggerType.SourceSet:
        return renderSourceSet(data.trigger, setInstructionsField);
      case RobotTriggerType.Collection:
        return renderCollection(data.trigger, setInstructionsField, campaigns);
      case RobotTriggerType.Date:
        return renderDate(data.trigger, setInstructionsField, setField);
      case RobotTriggerType.Action:
        return renderAction(data.trigger, setInstructionsField, apps);
      default:
        return null;
    }
  }

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy busy={loadingPrefill || submitting} exposeChildren>
        <Form onSubmit={submit}>
          {renderInstructions()}
          <Label htmlFor="timingValue">Start *</Label>
          <Grid container spacing={24}>
            <Grid item xs={4}>
              <Select
                id="timingValue"
                required
                value={data.trigger.timingValue}
                onChange={e => {
                  const value = parseInt(e.target.value);

                  if (value === 0) {
                    setField('trigger', {
                      ...data.trigger,
                      timingType: RobotTriggerTimingType.Infinite,
                      timingValue: value
                    });
                  } else {
                    setField('trigger', {
                      ...data.trigger,
                      timingType:
                        data.trigger.timingType ===
                        RobotTriggerTimingType.Infinite
                          ? RobotTriggerTimingType.Finite
                          : data.trigger.timingType,
                      timingValue: value
                    });
                  }
                }}
              >
                <option value={0}>Uendelig</option>
                {triggerTimes.map(t => (
                  <option key={t} value={t}>
                    {t + ' gang(er)'}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="timingDurationType"
                required
                value={
                  data.trigger.timingType === RobotTriggerTimingType.Every
                    ? data.trigger.timingDurationType || ''
                    : 'ever'
                }
                onChange={e => {
                  if (e.target.value === 'ever') {
                    setField('trigger', {
                      ...data.trigger,
                      timingType: RobotTriggerTimingType.Finite,
                      timingDurationType: e.target.value
                    });
                  } else {
                    setField('trigger', {
                      ...data.trigger,
                      timingType: RobotTriggerTimingType.Every,
                      timingDurationType: e.target.value
                    });
                  }
                }}
              >
                <option value="ever">noensinne</option>
                <option value="hours">per time</option>
                <option value="days">per dag</option>
                <option value="weeks">per uke</option>
                <option value="months">per måned</option>
                <option value="years">per år</option>
              </Select>
            </Grid>
          </Grid>
          <ParameterExpander
            organization={organization}
            parameters={
              data.trigger.instructions
                ? (data.trigger.instructions as TriggerBaseInstructions)
                    .parameters || []
                : []
            }
            onSet={parameters =>
              setField('trigger', {
                ...data.trigger,
                instructions: {
                  ...data.trigger.instructions,
                  parameters
                }
              })
            }
          />
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('trigger');
              }}
            >
              Avbryt
            </ButtonExternalLink>
            <Button type="submit" variant="primary">
              Lagre
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

type SetField = (key: string, value: any) => any;

export function renderDataPoint(
  trigger: RobotTrigger,
  setField: SetField,
  dataPoints: DataPoint[]
) {
  const instructions = trigger.instructions as TriggerDataPointInstructions;

  return (
    <>
      <Label htmlFor="dataPoint">Karakteristikk *</Label>
      <Select
        id="dataPoint"
        required
        value={instructions.dataPoint}
        onChange={e => setField('dataPoint', e.target.value)}
      >
        <option disabled selected={!instructions.dataPoint}>
          Vennligst velg…
        </option>
        {dataPoints.map(d => (
          <option key={d._id} value={d._id}>
            {d.label}
          </option>
        ))}
      </Select>
    </>
  );
}

export function renderAggregatorScore(
  trigger: RobotTrigger,
  setField: SetField
) {
  const instructions = trigger.instructions as TriggerAggregatorScoreInstructions;

  return (
    <>
      <Label htmlFor="overUnder">
        Kjør når Profilskår™ bikker over/under *
      </Label>
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <Select
            id="overUnder"
            required
            value={instructions.overUnder}
            onChange={e => setField('overUnder', e.target.value)}
          >
            <option disabled selected={!instructions.overUnder}>
              Vennligst velg…
            </option>
            <option value="over">Over</option>
            <option value="under">Under</option>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Input
            id="value"
            type="number"
            required
            value={instructions.value}
            onChange={e => setField('value', parseFloat(e.target.value))}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function renderSourceSet(trigger: RobotTrigger, setField: SetField) {
  const instructions = trigger.instructions as TriggerSourceSetInstructions;

  return (
    <>
      <Label htmlFor="fpSources">Førstepartskilder (kommaseparert)</Label>
      <Input
        id="fpSources"
        type="text"
        value={instructions.fpSources}
        onChange={e => setField('fpSources', e.target.value)}
      />
      <Label htmlFor="tpSources">Tredjepartskilder (kommaseparert)</Label>
      <Input
        id="tpSources"
        type="text"
        value={instructions.tpSources}
        onChange={e => setField('tpSources', e.target.value)}
      />
      <Label htmlFor="ips">IP-adresser (kommaseparert)</Label>
      <Input
        id="ips"
        type="text"
        value={instructions.ips}
        onChange={e => setField('ips', e.target.value)}
      />
    </>
  );
}

export function renderDate(
  trigger: RobotTrigger,
  setField: SetField,
  setGlobalField: SetField
) {
  const instructions = trigger.instructions as TriggerDateInstructions;

  return (
    <>
      <Label htmlFor="timestamp">Tidspunkt *</Label>
      <ReactDatePicker
        id="timestamp"
        selected={
          instructions.timestamp
            ? moment(instructions.timestamp).toDate()
            : new Date()
        }
        onChange={value => setField('timestamp', value)}
        showTimeSelect
        dateFormat="dd.MM.YYY HH:mm"
      />
      <Label htmlFor="repeat">Gjenta</Label>
      <Select
        id="repeat"
        required
        value={instructions.repeatType}
        defaultValue="none"
        onChange={e => {
          const value = e.target.value;

          if (value === 'none') {
            setGlobalField('trigger', {
              ...trigger,
              instructions: {
                ...trigger.instructions,
                repeatValue: 0,
                repeatType: null
              }
            });
          } else {
            setGlobalField('trigger', {
              ...trigger,
              instructions: {
                ...trigger.instructions,
                repeatValue: 1,
                repeatType: value
              }
            });
          }
        }}
      >
        <option value="none">Aldri</option>
        <option value="hours">Hver time</option>
        <option value="days">Hver dag</option>
        <option value="weeks">Hver uke</option>
        <option value="months">Hver måned</option>
        <option value="years">Hvert år</option>
      </Select>
    </>
  );
}

export function renderCollection(
  trigger: RobotTrigger,
  setField: SetField,
  campaigns: Campaign[]
) {
  const instructions = trigger.instructions as TriggerCollectionInstructions;

  return (
    <>
      <Label htmlFor="campaign">Innsamlingskampanje</Label>
      <Select
        id="campaign"
        required
        value={instructions.campaign}
        onChange={e => setField('campaign', e.target.value)}
      >
        <option disabled selected={!instructions.campaign}>
          Vennligst velg…
        </option>
        {campaigns.map(c => (
          <option key={c._id} value={c._id}>
            {c.name}
          </option>
        ))}
      </Select>
    </>
  );
}

export function renderReceiver(
  trigger: RobotTrigger,
  setField: SetField,
  receivers: Receiver[]
) {
  const instructions = trigger.instructions as TriggerReceiverInstructions;

  return (
    <>
      <Label htmlFor="receiver">Webhook-mottaker</Label>
      <Select
        id="receiver"
        required
        value={instructions.receiver}
        onChange={e => setField('receiver', e.target.value)}
      >
        <option disabled selected={!instructions.receiver}>
          Vennligst velg…
        </option>
        {receivers.map(r => (
          <option key={r._id} value={r._id}>
            {r.name}
          </option>
        ))}
      </Select>
    </>
  );
}

interface RenderActionFieldOpts {
  key: string;
  label: string;
  isNumber?: boolean;
}

export function renderAction(
  trigger: RobotTrigger,
  setField: SetField,
  apps: OrganizationApp[]
) {
  const instructions = trigger.instructions as TriggerActionInstructions;

  const appActions: AppAction[] = [];
  let selectedAppAction: AppAction | undefined = undefined;

  for (let i = 0; i < apps.length; i++) {
    const app = apps[i];

    if (app.appData!.actions) {
      if (!selectedAppAction) {
        selectedAppAction = app.appData!.actions.find(
          a => a._id === instructions.appRef
        );
      }

      for (let j = 0; j < app.appData!.actions.length; j++) {
        const action = app.appData!.actions[j];

        appActions.push({
          ...action,
          name: app.appData!.name + ': ' + action.name
        });
      }
    }
  }

  function renderField(opts: RenderActionFieldOpts) {
    const appActionDef =
      selectedAppAction && selectedAppAction[opts.key as 'category']
        ? selectedAppAction[opts.key as 'category']
        : undefined;

    if (appActionDef && appActionDef.hide) {
      return null;
    }

    let label = opts.label;
    let defaultValue: string | number | undefined = undefined;

    if (appActionDef && appActionDef.label) {
      label = appActionDef.label;
    }

    if (appActionDef && appActionDef.defaultValue) {
      defaultValue = appActionDef.defaultValue;
    }

    const logic = instructions[opts.key as 'category']
      ? instructions[opts.key as 'category']!.logic
      : '0';
    const value = instructions[opts.key as 'category']
      ? instructions[opts.key as 'category']!.value
      : '';

    return (
      <>
        <Label htmlFor={opts.key}>{label}</Label>
        <Grid container spacing={24}>
          <Grid
            item
            xs={
              logic === TargetLogic.IsSet || logic === TargetLogic.IsNotSet
                ? 12
                : 3
            }
          >
            <Select
              value={logic}
              onChange={e =>
                setField(opts.key, { logic: parseInt(e.target.value), value })
              }
            >
              {Object.keys(targetLogic).map(k => (
                <option value={k}>{targetLogic[k]}</option>
              ))}
            </Select>
          </Grid>
          {logic !== TargetLogic.IsSet && logic !== TargetLogic.IsNotSet && (
            <Grid item xs={9}>
              {appActionDef &&
              appActionDef.pickValues &&
              appActionDef.pickValues.length > 0 ? (
                <Select
                  id={opts.key}
                  defaultValue=""
                  value={value}
                  onChange={e =>
                    setField(opts.key, { logic, value: e.target.value })
                  }
                >
                  <option value="" />
                  {appActionDef.pickValues.map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  type={opts.isNumber ? 'number' : 'text'}
                  id={opts.key}
                  defaultValue={defaultValue as string}
                  value={value}
                  onChange={e =>
                    setField(opts.key, { logic, value: e.target.value })
                  }
                />
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Label htmlFor="meta">Type handling *</Label>
      <Select
        id="meta"
        value={instructions.appRef || 'none'}
        defaultValue="none"
        onChange={e => setField('appRef', e.target.value)}
      >
        <option value="none">Egendefinert</option>
        {appActions.map(a => (
          <option key={a._id} value={a._id}>
            {a.name}
          </option>
        ))}
      </Select>
      {renderField({
        label: 'Kategori',
        key: 'category'
      })}
      {renderField({
        label: 'Handling',
        key: 'action'
      })}
      {renderField({
        label: 'Etikett',
        key: 'label'
      })}
      {renderField({
        label: 'Verdi',
        key: 'value',
        isNumber: true
      })}
    </>
  );
}

export function renderList(
  trigger: RobotTrigger,
  setField: SetField,
  lists: List[]
) {
  const instructions = trigger.instructions as TriggerListInstructions;

  return (
    <>
      <Label htmlFor="list">Liste</Label>
      <Select
        id="list"
        required
        value={instructions.list}
        onChange={e => setField('list', e.target.value)}
      >
        <option disabled selected={!instructions.list}>
          Vennligst velg…
        </option>
        {lists.map(l => (
          <option key={l._id} value={l._id}>
            {l.label}
          </option>
        ))}
      </Select>
      <Label htmlFor="remove">Fjernet fra listen</Label>
      <Select
        id="remove"
        value={instructions.remove ? 'true' : 'false'}
        onChange={e => setField('remove', e.target.value === 'true')}
      >
        <option value="false">Nei</option>
        <option value="true">Ja</option>
      </Select>
    </>
  );
}

export function renderThreadCreate(trigger: RobotTrigger, setField: SetField) {
  const instructions = trigger.instructions as TriggerThreadCreateInstructionns;

  return (
    <>
      <Label htmlFor="subject">Emnefelt inneholder</Label>
      <Input
        id="subject"
        type="text"
        value={instructions.subject}
        onChange={e => setField('subject', e.target.value)}
      />
    </>
  );
}

export default Trigger;
