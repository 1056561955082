import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MonacoEditor from 'react-monaco-editor';
import { Personalization } from '../../types/apiResponses';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { CardContent, CardIcon, CardExternalLink } from '../../components/Card';
import { OrganizationContext } from '../../context/Organization';
import { UiContext } from '../../context/Ui';
import Expander from '../../components/Expander';
import Icon from '../../components/Icon';
import Doorman from '../../components/Doorman';
import {
  CreateParameterModal,
  renderProfileSelectionRule,
  PreviewPathModal
} from '../Automation/Show';
import HTMLPreview from '../../components/HTMLPreview';
import Hr from '../../components/Hr';
import getTheme, { Theme } from '../../util/getTheme';
import { MessageType } from '../../constants/enums';

const theme = getTheme();

interface CreateParams {
  organizationID: string;
  personalizationID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const personalizationID = props.match.params.personalizationID;
  const isUpdate = personalizationID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/personalization';
  const endpoint = isUpdate
    ? baseEndpoint + '/' + personalizationID
    : baseEndpoint;

  const organization = useContext(OrganizationContext).data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Personalization>(
    {
      name: '',
      parameters: [],
      forRobot: false
    },
    {
      onSuccess: () => history.push('../personalisering'),
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<Personalization | null>({
    method: 'DELETE',
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => history.push('../personalisering')
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} personalisering
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min personalisering"
              value={data.name}
              disabled={!!data.app}
              onChange={e => setField('name', e.target.value)}
            />
            {!data.app && (
              <>
                <Label htmlFor="js">JavaScript</Label>
                <MonacoEditor
                  language="javascript"
                  theme={theme === Theme.Dark ? 'hc-black' : 'vs'}
                  height="300"
                  options={{
                    colorDecorators: true,
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    contextmenu: false,
                    extraEditorClassName:
                      theme === Theme.Dark
                        ? 'profiler-monaco-dark'
                        : 'profiler-monaco-light'
                  }}
                  value={data.js || ''}
                  onChange={code => setField('js', code)}
                />
                <Label htmlFor="html">HTML</Label>
                <MonacoEditor
                  language="html"
                  theme={theme === Theme.Dark ? 'hc-black' : 'vs'}
                  height="300"
                  options={{
                    colorDecorators: true,
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    contextmenu: false,
                    extraEditorClassName:
                      theme === Theme.Dark
                        ? 'profiler-monaco-dark'
                        : 'profiler-monaco-light'
                  }}
                  value={data.html || ''}
                  onChange={code => setField('html', code)}
                />
              </>
            )}
            <Label htmlFor="htmlQuerySelector">
              Destinasjon for HTML (query selector)
            </Label>
            <Input
              id="htmlQuerySelector"
              type="text"
              placeholder="body"
              value={data.htmlQuerySelector || ''}
              onChange={e => setField('htmlQuerySelector', e.target.value)}
            />
            <Label htmlFor="htmlPlacement">Plassering av HTML</Label>
            <Select
              id="htmlPlacement"
              value={data.htmlPlacement || 'beforeend'}
              onChange={e => setField('htmlPlacement', e.target.value)}
            >
              <option value="beforebegin">beforebegin</option>
              <option value="afterbegin">afterbegin</option>
              <option value="beforeend">beforeend</option>
              <option value="afterend">afterend</option>
              <option value="replace">Erstatt innhold</option>
            </Select>
            <Label htmlFor="forRobot">Til bruk av ProBot 🤖?</Label>
            <Select
              id="forRobot"
              value={data.forRobot === true ? 'true' : 'false'}
              onChange={e => setField('forRobot', e.target.value === 'true')}
            >
              <option value="false">Nei</option>
              <option value="true">Ja</option>
            </Select>
            {!data.forRobot && (
              <Expander label={`Filter (${data.parameters.length})`} open>
                <Grid container spacing={24}>
                  {data.parameters.map(p => {
                    const list = organization.lists.find(
                      l => l._id === p.targetRef
                    );

                    const dataPoint = organization.dataPoints.find(
                      d => d._id === p.targetRef
                    );

                    return (
                      <Grid key={p._id} item xs={12}>
                        <CardExternalLink
                          href="#"
                          clickable
                          horizontal
                          onClick={e => {
                            e.preventDefault();
                            spawnModal(
                              <CreateParameterModal
                                defaultData={p}
                                organization={organization}
                                onCreate={parameter => {
                                  const index = data.parameters.findIndex(
                                    pa => pa._id === parameter._id
                                  );

                                  if (index !== -1) {
                                    const copy = [...data.parameters];
                                    copy[index] = parameter;

                                    setField('parameters', copy);
                                  }
                                }}
                                onDelete={ref => {
                                  setField(
                                    'parameters',
                                    data.parameters.filter(pa =>
                                      pa.__ref
                                        ? pa.__ref !== ref
                                        : pa._id !== ref
                                    )
                                  );
                                }}
                                close={despawnModal}
                              />,
                              'createParameter'
                            );
                          }}
                        >
                          <CardContent tight>
                            {renderProfileSelectionRule(p, list, dataPoint)}
                          </CardContent>
                          <CardIcon tight>
                            <Icon>edit</Icon>
                          </CardIcon>
                        </CardExternalLink>
                      </Grid>
                    );
                  })}
                  <Grid item xs={6}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      secondary
                      onClick={e => {
                        e.preventDefault();
                        spawnModal(
                          <PreviewPathModal
                            organization={organization}
                            path={{
                              _id: '111111111111111111111111',
                              name: '',
                              type: MessageType.Email,
                              email: {
                                subject: '',
                                plain: '',
                                html: '',
                                json: '',
                                templateRef: null
                              },
                              parameters: data.parameters
                            }}
                            close={despawnModal}
                          />,
                          'previewPath'
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>Forhåndsvis filter</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>remove_red_eye</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                  <Grid item xs={6}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      primary={data.parameters.length < 1}
                      secondary={data.parameters.length > 0}
                      onClick={e => {
                        e.preventDefault();
                        spawnModal(
                          <CreateParameterModal
                            organization={organization}
                            onCreate={parameter => {
                              setField('parameters', [
                                ...data.parameters,
                                parameter
                              ]);
                            }}
                            onDelete={ref => {
                              setField(
                                'parameters',
                                data.parameters.filter(p => p._id !== ref)
                              );
                            }}
                            close={despawnModal}
                          />,
                          'createParameter'
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>Legg til regel</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>add</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                </Grid>
              </Expander>
            )}
            <ButtonList align="right">
              <ButtonLink to="../personalisering">Avbryt</ButtonLink>
              <Doorman>
                <>
                  {data && data._id && !data.app && (
                    <ButtonExternalLink
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        destroy();
                      }}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
            {data.html && (
              <>
                <Hr />
                <Text variant="headline">Forhåndsvisning</Text>
                <HTMLPreview html={data.html} />
              </>
            )}
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Create;
