import styled, { css } from '../../util/styled';

interface Props {
  hugTop?: boolean;
  hugBottom?: boolean;
  tight?: boolean;
}

const Hr = styled.hr<Props>`
  padding: 0;
  margin: ${props =>
      props.tight
        ? props.theme.layout.spacing.small
        : props.theme.layout.spacing.normal}
    0;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  ${props =>
    props.hugTop &&
    css`
      margin-top: 0;
    `};

  ${props =>
    props.hugBottom &&
    css`
      margin-bottom: 0;
    `};
`;

export default Hr;
