import React from "react";
import { Switch, Route } from "react-router-dom";
import OrganizationSettings from "../views/OrganizationSettings";
import Update from "../views/OrganizationSettings/Update";
import InviteUser from "../views/OrganizationSettings/InviteUser";
import CreateGroup from "../views/OrganizationSettings/CreateGroup";
import UpdateMember from "../views/OrganizationSettings/UpdateMember";
import UpdateUser from "../views/OrganizationSettings/UpdateUser";
import CreateApiKey from "../views/OrganizationSettings/CreateApiKey";

export default function organizationSettingsRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger"
        component={OrganizationSettings}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger/endre"
        component={Update}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger/endre-brukerinnstillinger"
        component={UpdateUser}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger/inviter-bruker"
        component={InviteUser}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger/opprett-api-nokkel"
        component={CreateApiKey}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/innstillinger/:memberID"
        component={UpdateMember}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/grupper/:groupID"
        component={CreateGroup}
      />
    </Switch>
  );
}
