import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from '../../util/styled';

interface Props {
  image?: string | null;
  inverted?: boolean;
  embiggen?: boolean;
}

const Wrapper = styled(({ inverted, embiggen, ...props }) => (
  <Link {...props} />
))<Props>`
  line-height: 1;
  font-family: 'Pacifico', serif;
  color: ${props => (props.inverted ? props.theme.colors.text : 'white')};
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  display: block;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};

  ${props =>
    props.embiggen &&
    css`
      letter-spacing: 8px;
      font-size: 3.6rem;
    `};

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  img {
    max-height: 36px;
    max-width: 180px;
  }
`;

const Logo: FunctionComponent<Props> = ({ image, inverted, embiggen }) => (
  <Wrapper to="/" inverted={inverted} embiggen={embiggen}>
    {image ? (
      <img src={process.env.REACT_APP_PLZ_URL + '/files/' + image} />
    ) : (
      'Profiler'
    )}
  </Wrapper>
);

export default Logo;
