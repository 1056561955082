import React, { FunctionComponent } from 'react';
import styled from '../../util/styled';

const IconWrapper = styled.i``;

const Icon: FunctionComponent = ({ children }) => (
  <IconWrapper className="material-icons material-icons--outline">
    {children}
  </IconWrapper>
);

export default Icon;
