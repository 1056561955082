import styled, { css } from '../../util/styled';
import { mobileThreshold } from '../../constants/theme';

interface SpacingProps {
  spacious?: boolean;
  hugTop?: boolean;
  hugBottom?: boolean;
  minWidth?: string;
  maxWidth?: string;
  tight?: boolean;
  height?: string;
}

const Container = styled.div<SpacingProps>`
  padding: ${props => props.theme.layout.spacing.normal};
  position: relative;

  ${props =>
    props.spacious &&
    css`
      padding: ${props.theme.layout.spacing.large};

      @media (max-width: ${mobileThreshold}px) {
        padding: ${props.theme.layout.spacing.small};
      }
    `};

  ${props =>
    props.tight &&
    css`
      padding: ${props.theme.layout.spacing.small};
    `};

  ${props =>
    props.hugTop &&
    css`
      padding-top: 0;
    `};

  ${props =>
    props.hugBottom &&
    css`
      padding-bottom: 0;
    `};

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};

      @media (max-width: ${mobileThreshold}px) {
        min-width: 0;
        max-width: calc(85vw);
      }
    `};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`;

const VerticalContainer = styled.div<SpacingProps>`
  margin: ${props => props.theme.layout.spacing.normal} 0;

  ${props =>
    props.spacious &&
    css`
      margin: ${props.theme.layout.spacing.large} 0;
    `};

  ${props =>
    props.tight &&
    css`
      margin: ${props.theme.layout.spacing.small} 0;
    `};
`;

interface CentererProps {
  noPadding?: boolean;
}

const Centerer = styled.div<CentererProps>`
  padding: ${props => props.theme.layout.spacing.normal};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};
`;

const Spacer = styled.div<SpacingProps>`
  height: ${props =>
    props.height
      ? props.height
      : props.spacious
      ? props.theme.layout.spacing.large
      : props.tight
      ? props.theme.layout.spacing.small
      : props.theme.layout.spacing.normal};

  @media (max-width: ${mobileThreshold}px) {
    height: ${props =>
      props.height
        ? props.height
        : props.spacious
        ? props.theme.layout.spacing.small
        : props.tight
        ? props.theme.layout.spacing.small
        : props.theme.layout.spacing.small};
  }
`;

const TextAligner = styled.span`
  display: inline-flex;
  align-items: center;
`;

interface FlexProps {
  align?: string;
  justify?: string;
  direction?: string;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-direction: ${props => props.direction || 'row'};

  @media (max-width: ${mobileThreshold}px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: ${props => props.direction || 'column'};
  }
`;

interface FlexKidProps {
  hugRight?: boolean;
  spaceLeft?: boolean;
  spaceRight?: boolean;
  centerContent?: boolean;
  alignSelf?: string;
  flex?: number;
  width?: string;
  maxWidth?: string;
  flexBasis?: string;
  spacious?: boolean;
  sticky?: boolean;
  topOffset?: string;
  dontSpaceMobile?: boolean;
}

const FlexKid = styled.div<FlexKidProps>`
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};

  ${props =>
    props.flexBasis &&
    css`
      flex-basis: ${props.flexBasis};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  ${props =>
    props.hugRight &&
    css`
      margin-left: auto;
    `};

  ${props =>
    props.spaceLeft &&
    css`
      padding-left: ${props.theme.layout.spacing.small};
    `};

  ${props =>
    props.spaceRight &&
    css`
      padding-right: ${props.theme.layout.spacing.small};
    `};

  ${props =>
    props.spacious &&
    css`
      ${props.spaceLeft &&
        css`
          padding-left: ${props.theme.layout.spacing.large};
        `};

      ${props.spaceRight &&
        css`
          padding-right: ${props.theme.layout.spacing.large};
        `};
    `};

  ${props =>
    props.centerContent &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};

  ${props =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `};

  ${props =>
    props.sticky &&
    css`
      position: sticky;
      top: ${props.topOffset || 0};
    `};

  @media (max-width: ${mobileThreshold}px) {
    width: 100%;
    padding-left: 0;

    ${props =>
      !props.dontSpaceMobile &&
      css`
        &:not(:last-child) {
          padding-bottom: ${props.theme.layout.spacing.small};
        }
      `}
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
`;

const Positioner = styled.div`
  position: relative;
  display: inline-block;
`;

export {
  Container,
  VerticalContainer,
  Spacer,
  Centerer,
  TextAligner,
  Flex,
  FlexKid,
  Sticky,
  Positioner
};
