import React, { useContext, useState, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid/v4';
import {
  Organization as IOrganization,
  Receiver,
  ReceiverInstruction,
  RunIfInstruction,
  ReceiverJob,
  AppAction
} from '../../types/apiResponses';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input, Select } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import { CardContent, CardIcon, CardExternalLink } from '../../components/Card';
import { OrganizationContext } from '../../context/Organization';
import { UiContext } from '../../context/Ui';
import {
  receiverTypes,
  coreData,
  selectionLogic,
  targetLogic,
  action
} from '../../constants/localization';
import localize from '../../util/localize';
import {
  ReceiverType,
  ReceiverInstructionType,
  ReceiverSetType
} from '../../constants/enums';
import Expander from '../../components/Expander';
import Icon from '../../components/Icon';
import Hr from '../../components/Hr';
import renderField from '../../util/renderField';
import { AppsContext } from '../../context/Apps';
import labelDataPoints, { labelDataPoint } from '../../util/labelDataPoints';

interface CreateParams {
  organizationID: string;
  receiverID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const receiverID = props.match.params.receiverID;
  const isUpdate = receiverID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/receivers';
  const endpoint = isUpdate ? baseEndpoint + '/' + receiverID : baseEndpoint;

  const organization = useContext(OrganizationContext).data;
  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    setData,
    submit
  } = useForm<Receiver>(
    {
      name: '',
      jobs: [],
      runIf: [],
      externalIdentifierService: null,
      externalIdentifierPath: null
    },
    {
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined,
      onSuccess: receiver => {
        setData(receiver as Receiver);
        history.push('../webhook-mottakere/' + (receiver as Receiver)._id);
      }
    }
  );

  const [_, deleting, destroy] = useApi<Receiver | null>({
    method: 'DELETE',
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../webhook-mottakere');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} webhook-mottaker
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min webhook-mottaker"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <Expander label={`Jobber (${data.jobs.length})`} open>
              <Grid container spacing={24}>
                {data.jobs.map(j => (
                  <Grid key={j._id} item xs={12}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      onClick={e => {
                        e.preventDefault();
                        spawnModal(
                          <CreateJobModal
                            organization={organization}
                            defaultData={j}
                            onCreate={job => {
                              const index = data.jobs.findIndex(
                                jb => jb._id === job._id
                              );

                              if (index !== -1) {
                                const copy = [...data.jobs];
                                copy[index] = job;

                                setField('jobs', copy);
                              }
                            }}
                            onDelete={ref => {
                              setField(
                                'jobs',
                                data.jobs.filter(jb =>
                                  jb.__ref ? jb.__ref !== ref : jb._id !== ref
                                )
                              );
                            }}
                            spawnModal={spawnModal}
                            close={despawnModal}
                          />,
                          'createJob'
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>
                          {localize(receiverTypes, j.__type as string)}
                        </Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>edit</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <CardExternalLink
                    href="#"
                    clickable
                    horizontal
                    primary={data.jobs.length < 1}
                    secondary={data.jobs.length > 0}
                    onClick={e => {
                      e.preventDefault();
                      spawnModal(
                        <CreateJobModal
                          organization={organization}
                          onCreate={job => {
                            setField('jobs', [...data.jobs, job]);
                          }}
                          onDelete={ref => {
                            setField(
                              'jobs',
                              data.jobs.filter(j => j._id !== ref)
                            );
                          }}
                          spawnModal={spawnModal}
                          close={despawnModal}
                        />,
                        'createJob'
                      );
                    }}
                  >
                    <CardContent tight>
                      <Text>Legg til jobb</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>add</Icon>
                    </CardIcon>
                  </CardExternalLink>
                </Grid>
              </Grid>
            </Expander>
            {data.jobs.length > 0 && (
              <Expander label={`Kjør dersom (${data.runIf.length})`}>
                <Grid container spacing={24}>
                  {data.runIf.map(i => (
                    <Grid key={i._id} item xs={12}>
                      <CardExternalLink
                        href="#"
                        clickable
                        horizontal
                        onClick={e => {
                          e.preventDefault();
                          spawnModal(
                            <CreateRunIfModal
                              defaultData={i}
                              onCreate={runIf => {
                                const index = data.runIf.findIndex(
                                  rif => rif._id === runIf._id
                                );

                                if (index !== -1) {
                                  const copy = [...data.runIf];
                                  copy[index] = runIf;

                                  setField('runIf', copy);
                                }
                              }}
                              onDelete={ref => {
                                setField(
                                  'runIf',
                                  data.runIf.filter(rif =>
                                    rif.__ref
                                      ? rif.__ref !== ref
                                      : rif._id !== ref
                                  )
                                );
                              }}
                              close={despawnModal}
                            />,
                            'createRunIf'
                          );
                        }}
                      >
                        <CardContent tight>
                          <Text>
                            <strong>
                              {localize(selectionLogic, `${i.logic}`)}
                            </strong>{' '}
                            {i.path}{' '}
                            <strong>
                              {localize(targetLogic, `${i.query}`)}
                            </strong>{' '}
                            {i.query !== 2 && i.query !== 3 && i.value}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardExternalLink>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      primary={data.runIf.length < 1}
                      secondary={data.runIf.length > 0}
                      onClick={e => {
                        e.preventDefault();
                        spawnModal(
                          <CreateRunIfModal
                            onCreate={runIf => {
                              setField('runIf', [...data.runIf, runIf]);
                            }}
                            onDelete={ref => {
                              setField(
                                'runIf',
                                data.runIf.filter(i => i._id !== ref)
                              );
                            }}
                            close={despawnModal}
                          />,
                          'createRunIf'
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>Legg til regel</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>add</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                </Grid>
              </Expander>
            )}
            <Label htmlFor="externalIdentifierService">
              Tredjepartsidentifikatortjeneste
            </Label>
            <Input
              id="externalIdentifierService"
              type="text"
              placeholder="GitHub, Facebook etc"
              value={data.externalIdentifierService || ''}
              onChange={e =>
                setField('externalIdentifierService', e.target.value)
              }
            />
            {data.externalIdentifierService &&
              data.externalIdentifierService.length > 0 && (
                <>
                  <Label htmlFor="externalIdentifierPath">
                    Tredjepartsidentifikatorsti
                  </Label>
                  <Input
                    id="externalIdentifierPath"
                    type="text"
                    placeholder="foo.bar"
                    value={data.externalIdentifierPath || ''}
                    onChange={e =>
                      setField('externalIdentifierPath', e.target.value)
                    }
                  />
                </>
              )}
            <Label htmlFor="successMessage">OK-melding</Label>
            <Input
              id="successMessage"
              type="text"
              placeholder="Det funket!"
              value={data.successMessage || ''}
              onChange={e => setField('successMessage', e.target.value)}
            />
            <Label htmlFor="failMessage">Ikke OK-melding</Label>
            <Input
              id="failMessage"
              type="text"
              placeholder="Det funket ikke!"
              value={data.failMessage || ''}
              onChange={e => setField('failMessage', e.target.value)}
            />
            {data && data.url && (
              <>
                <Hr />
                <Label htmlFor="url">Mottaker-URL:</Label>
                <Input
                  id="url"
                  type="text"
                  value={data.url}
                  readOnly
                  onFocus={e => e.target.select()}
                />
                <Label htmlFor="basicAuthUrl">
                  Mottaker-URL med Basic Auth:
                </Label>
                <Input
                  id="basicAuthUrl"
                  type="text"
                  value={data.basicAuthUrl}
                  readOnly
                  onFocus={e => e.target.select()}
                />
                <Text variant="subheading">
                  Mottak: {data.triggers}{' '}
                  {data.triggers > 0 && (
                    <span>
                      ({data.successfulTriggers} OK, {data.failedTriggers} ikke
                      OK)
                    </span>
                  )}
                </Text>
              </>
            )}
            <ButtonList align="right">
              <ButtonLink to="../webhook-mottakere">
                {data._id ? 'Tilbake' : 'Avbryt'}
              </ButtonLink>
              {data && data._id && (
                <ButtonExternalLink onClick={() => destroy()} variant="warning">
                  {deleting ? 'Sletter…' : 'Slett'}
                </ButtonExternalLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

interface CreateJobModalProps {
  organization: IOrganization;
  close: (id: string) => any;
  spawnModal: (content: ReactNode, id?: string) => any;
  onCreate: (data: ReceiverJob) => any;
  onDelete?: (ref: string) => any;
  defaultData?: ReceiverJob;
}

const defaultJob: ReceiverJob = {
  _id: '',
  __ref: '',
  __type: null,
  meta: null,
  instructions: []
};

function CreateJobModal(props: CreateJobModalProps) {
  const { organization, defaultData, close, onCreate, onDelete } = props;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;
  const apps = useContext(AppsContext).data;

  const [job, setJob] = useState(
    defaultData ? defaultData : { ...defaultJob, __ref: uuid() }
  );

  const appActions: AppAction[] = [];
  let selectedAppAction: AppAction | undefined = undefined;

  for (let i = 0; i < apps.length; i++) {
    const app = apps[i];

    if (app.appData!.actions) {
      selectedAppAction = app.appData!.actions.find(a => a._id === job.meta);

      for (let j = 0; j < app.appData!.actions.length; j++) {
        const action = app.appData!.actions[j];
        appActions.push({
          ...action,
          name: app.appData!.name + ': ' + action.name
        });
      }
    }
  }

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete job._id;
          }

          onCreate(job);
          close('createJob');
        }}
      >
        <Label htmlFor="type">Type *</Label>
        <Select
          id="__type"
          value={job.__type || 'none'}
          defaultValue="none"
          required
          onChange={e =>
            setJob({ ...job, __type: e.target.value as ReceiverType })
          }
        >
          <option value="none" disabled>
            Vennligst velg…
          </option>
          {Object.keys(receiverTypes).map(k => (
            <option key={k} value={k}>
              {receiverTypes[k]}
            </option>
          ))}
        </Select>
        {job.__type === ReceiverType.PushAction && (
          <>
            <Label htmlFor="meta">Type handling *</Label>
            <Select
              id="meta"
              value={job.meta || 'none'}
              defaultValue="none"
              onChange={e => setJob({ ...job, meta: e.target.value })}
            >
              <option value="none">Egendefinert</option>
              {appActions.map(a => (
                <option key={a._id} value={a._id}>
                  {a.name}
                </option>
              ))}
            </Select>
          </>
        )}
        {job.__type && (
          <Expander label={`Instruksjoner (${job.instructions.length})`} open>
            <Grid container spacing={24}>
              {job.instructions.map(i => {
                const dataPoint = organization.dataPoints.find(
                  d => d._id === i.target
                );

                return (
                  <Grid key={i._id} item xs={12}>
                    <CardExternalLink
                      href="#"
                      clickable
                      horizontal
                      onClick={e => {
                        e.preventDefault();
                        spawnModal(
                          <CreateInstructionModal
                            defaultData={i}
                            organization={organization}
                            job={job}
                            onCreate={instruction => {
                              const index = job.instructions.findIndex(
                                ins => ins._id === instruction._id
                              );

                              if (index !== -1) {
                                const copy = [...job.instructions];
                                copy[index] = instruction;

                                setJob({
                                  ...job,
                                  instructions: copy
                                });
                              }
                            }}
                            onDelete={ref => {
                              setJob({
                                ...job,
                                instructions: job.instructions.filter(ins =>
                                  ins.__ref
                                    ? ins.__ref !== ref
                                    : ins._id !== ref
                                )
                              });
                            }}
                            close={despawnModal}
                          />,
                          'createInstruction'
                        );
                      }}
                    >
                      <CardContent tight>
                        <Text>
                          {job.__type === ReceiverType.PushAction
                            ? selectedAppAction && i.target in selectedAppAction
                              ? selectedAppAction[i.target as 'label'].label
                              : localize(action, i.target)
                            : dataPoint
                            ? labelDataPoint(dataPoint, apps).label
                            : localize(coreData, i.target)}{' '}
                          {i.setType === ReceiverSetType.Increment
                            ? '+'
                            : i.setType === ReceiverSetType.Decrement
                            ? '-'
                            : ''}
                          ={' '}
                          {i.__type === ReceiverInstructionType.Path
                            ? i.path
                            : i.value}
                        </Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>edit</Icon>
                      </CardIcon>
                    </CardExternalLink>
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <CardExternalLink
                  href="#"
                  clickable
                  horizontal
                  primary={job.instructions.length < 1}
                  secondary={job.instructions.length > 0}
                  onClick={e => {
                    e.preventDefault();
                    spawnModal(
                      <CreateInstructionModal
                        organization={organization}
                        job={job}
                        onCreate={instruction => {
                          setJob({
                            ...job,
                            instructions: [...job.instructions, instruction]
                          });
                        }}
                        onDelete={ref => {
                          setJob({
                            ...job,
                            instructions: job.instructions.filter(
                              i => i._id !== ref
                            )
                          });
                        }}
                        close={despawnModal}
                      />,
                      'createInstruction'
                    );
                  }}
                >
                  <CardContent tight>
                    <Text>Legg til instruksjon</Text>
                  </CardContent>
                  <CardIcon tight>
                    <Icon>add</Icon>
                  </CardIcon>
                </CardExternalLink>
              </Grid>
            </Grid>
          </Expander>
        )}
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createInstruction');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createInstruction');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

interface CreateInstructionModalProps {
  organization: IOrganization;
  job: ReceiverJob;
  close: (id: string) => any;
  onCreate: (data: ReceiverInstruction) => any;
  onDelete?: (ref: string) => any;
  defaultData?: ReceiverInstruction;
}

const defaultInstruction: ReceiverInstruction = {
  _id: '',
  __ref: '',
  target: '',
  __type: ReceiverInstructionType.Value,
  setType: ReceiverSetType.Set,
  value: null,
  path: null
};

function CreateInstructionModal(props: CreateInstructionModalProps) {
  const { organization, job, defaultData, close, onCreate, onDelete } = props;

  const apps = useContext(AppsContext).data;
  let selectedAction: AppAction | undefined = undefined;

  if (job.__type === ReceiverType.PushAction && job.meta) {
    for (let i = 0; i < apps.length; i++) {
      const app = apps[i];
      selectedAction =
        app.appData!.actions &&
        app.appData!.actions.find(a => a._id === job.meta);

      if (selectedAction) {
        break;
      }
    }
  }

  function renderFields() {
    switch (job.__type) {
      case ReceiverType.CreateContact:
      case ReceiverType.UpdateContact:
        return (
          <>
            <option value="name">Navn</option>
            <option value="email">E-post</option>
            <option value="phone">Telefonnummer</option>
            {labelDataPoints(organization.dataPoints, apps).map(d => (
              <option key={d._id} value={d._id}>
                {d.label}
              </option>
            ))}
          </>
        );
      case ReceiverType.PushAction:
        return Object.keys(action)
          .filter(k => {
            if (selectedAction) {
              const val =
                k in selectedAction ? selectedAction[k as 'category'] : null;

              if (val && val.hide) {
                return false;
              }
            }

            return true;
          })
          .map(k => (
            <option key={k} value={k}>
              {selectedAction &&
              k in selectedAction &&
              selectedAction[k as 'label'].label
                ? selectedAction[k as 'label'].label
                : action[k]}
            </option>
          ));
      default:
        return null;
    }
  }

  const [instruction, setInstruction] = useState(
    defaultData ? defaultData : { ...defaultInstruction, __ref: uuid() }
  );

  const selectedDataPoint = organization.dataPoints.find(
    d => d._id === instruction.target
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete instruction._id;
          }

          onCreate(instruction);
          close('createInstruction');
        }}
      >
        <Label htmlFor="target">Mål *</Label>
        <Select
          id="target"
          required
          value={instruction.target || 'none'}
          defaultValue="none"
          onChange={e =>
            setInstruction({
              ...instruction,
              target: e.target.value
            })
          }
        >
          <option value="none" disabled>
            Vennligst velg…
          </option>
          {renderFields()}
        </Select>
        <Label htmlFor="__type">Type *</Label>
        <Select
          id="__type"
          required
          value={instruction.__type}
          onChange={e =>
            setInstruction({
              ...instruction,
              value: null,
              path: null,
              __type: e.target.value as ReceiverInstructionType
            })
          }
        >
          <option value={ReceiverInstructionType.Value}>Verdi</option>
          <option value={ReceiverInstructionType.Path}>Sti</option>
        </Select>
        {selectedDataPoint && selectedDataPoint.type === 'number' && (
          <>
            <Label htmlFor="setType">Operasjon</Label>
            <Select
              id="setType"
              required
              value={instruction.setType}
              onChange={e =>
                setInstruction({
                  ...instruction,
                  setType: e.target.value as ReceiverSetType
                })
              }
            >
              <option value={ReceiverSetType.Set}>Overskriv</option>
              <option value={ReceiverSetType.Increment}>Øk</option>
              <option value={ReceiverSetType.Decrement}>Minsk</option>
            </Select>
          </>
        )}
        {instruction.__type === ReceiverInstructionType.Value && (
          <>
            <Label htmlFor="value">Verdi</Label>
            {selectedDataPoint ? (
              renderField(selectedDataPoint, instruction.value, (_, value) =>
                setInstruction({
                  ...instruction,
                  value
                })
              )
            ) : (
              <Input
                id="value"
                type="text"
                value={instruction.value || ''}
                placeholder="fooBar"
                onChange={e =>
                  setInstruction({
                    ...instruction,
                    value: e.target.value
                  })
                }
              />
            )}
          </>
        )}
        {instruction.__type === ReceiverInstructionType.Path && (
          <>
            <Label htmlFor="path">Sti *</Label>
            <Input
              id="path"
              type="text"
              required
              value={instruction.path || ''}
              placeholder="foo.bar"
              onChange={e =>
                setInstruction({
                  ...instruction,
                  path: e.target.value
                })
              }
            />
          </>
        )}
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createInstruction');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createInstruction');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

interface CreateRunIfModalProps {
  close: (id: string) => any;
  onCreate: (data: RunIfInstruction) => any;
  onDelete?: (ref: string) => any;
  defaultData?: RunIfInstruction;
}

const defaultRunIf: RunIfInstruction = {
  _id: '',
  __ref: '',
  logic: 0,
  query: 0,
  path: '',
  value: ''
};

function CreateRunIfModal(props: CreateRunIfModalProps) {
  const { defaultData, close, onCreate, onDelete } = props;

  const [runIf, setRunIf] = useState(
    defaultData ? defaultData : { ...defaultRunIf, __ref: uuid() }
  );

  return (
    <Container minWidth="38rem" spacious>
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (!defaultData) {
            delete runIf._id;
          }

          onCreate(runIf);
          close('createRunIf');
        }}
      >
        <Label htmlFor="logic">Valglogikk *</Label>
        <Select
          id="logic"
          required
          value={runIf.logic}
          onChange={e =>
            setRunIf({
              ...runIf,
              logic: parseInt(e.target.value)
            })
          }
        >
          <option value={0}>{localize(selectionLogic, '0')}</option>
          <option value={1}>{localize(selectionLogic, '1')}</option>
        </Select>
        <Label htmlFor="path">Sti *</Label>
        <Input
          id="path"
          type="text"
          value={runIf.path || ''}
          placeholder="foo.bar"
          onChange={e =>
            setRunIf({
              ...runIf,
              path: e.target.value
            })
          }
        />
        <Label htmlFor="query">Regel *</Label>
        <Select
          id="query"
          required
          value={runIf.query}
          onChange={e =>
            setRunIf({
              ...runIf,
              query: parseInt(e.target.value)
            })
          }
        >
          <option value={0}>{localize(targetLogic, '0')}</option>
          <option value={1}>{localize(targetLogic, '1')}</option>
          <option value={2}>{localize(targetLogic, '2')}</option>
          <option value={3}>{localize(targetLogic, '3')}</option>
          <option value={4}>{localize(targetLogic, '4')}</option>
          <option value={5}>{localize(targetLogic, '5')}</option>
        </Select>
        {runIf.query !== 2 && runIf.query !== 3 && (
          <>
            <Label htmlFor="value">Verdi *</Label>
            <Input
              id="value"
              type="text"
              value={runIf.value || ''}
              onChange={e =>
                setRunIf({
                  ...runIf,
                  value: e.target.value
                })
              }
            />
          </>
        )}
        <ButtonList align="right">
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();
              close('createRunIf');
            }}
          >
            Avbryt
          </ButtonExternalLink>
          {onDelete && defaultData && (
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();

                onDelete(
                  defaultData.__ref ? defaultData.__ref : defaultData._id
                );

                close('createRunIf');
              }}
              variant="warning"
            >
              Slett
            </ButtonExternalLink>
          )}
          <Button type="submit" variant="primary">
            {defaultData ? 'Lagre' : 'Legg til'}
          </Button>
        </ButtonList>
      </Form>
    </Container>
  );
}

export default Create;
