import React, {
  FunctionComponent,
  createContext,
  useState,
  ReactNode
} from 'react';
import uuid from 'uuid/v4';
import { Modal, Notification } from '../types/ui';

const defaultModalState = {
  stack: [] as Modal[]
};

const defaultNotificationState = {
  stack: [] as Notification[]
};

const UiContext = createContext({
  thumbMenu: {
    show: true,
    toggleShow: (show: boolean) => {}
  },
  modal: {
    data: defaultModalState,
    spawnModal: (content: ReactNode, id?: string) => {},
    despawnModal: (id?: string) => {},
    despawnAllModals: () => {}
  },
  notifications: {
    data: defaultNotificationState,
    spawnNotification: (id: string, content: ReactNode, icon?: ReactNode) => {},
    despawnNotification: (id: string) => {}
  }
});

const UiProvider: FunctionComponent = ({ children }) => {
  const [showThumbMenuState, toggleShowThumbMenu] = useState(true);
  const [modalState, setModalState] = useState(defaultModalState);
  const [notificationState, setNotificationState] = useState(
    defaultNotificationState
  );

  return (
    <UiContext.Provider
      value={{
        thumbMenu: {
          show: showThumbMenuState,
          toggleShow: show => toggleShowThumbMenu(show)
        },
        modal: {
          data: modalState,
          spawnModal: (content, id) =>
            setModalState({
              ...modalState,
              stack: [
                ...modalState.stack,
                { id: id || uuid(), content, fadeOut: false }
              ]
            }),
          despawnModal: id => {
            if (!id && modalState.stack.length > 0) {
              id = modalState.stack[modalState.stack.length - 1].id;
            }

            setModalState({
              ...modalState,
              stack: modalState.stack.map(m => {
                if (m.id === id) {
                  m.fadeOut = true;
                }

                return m;
              })
            });

            window.setTimeout(() => {
              setModalState({
                ...modalState,
                stack: modalState.stack.filter(m => m.id !== id)
              });
            }, 240);
          },
          despawnAllModals: () => {
            setModalState({
              ...modalState,
              stack: modalState.stack.map(m => ({
                ...m,
                fadeOut: true
              }))
            });

            window.setTimeout(() => {
              setModalState({
                ...modalState,
                stack: []
              });
            }, 240);
          }
        },
        notifications: {
          data: notificationState,
          spawnNotification: (
            id: string,
            content: ReactNode,
            icon?: ReactNode
          ) => {
            setNotificationState({
              ...notificationState,
              stack: [
                { id, fadeOut: false, content, icon },
                ...notificationState.stack
              ]
            });
          },
          despawnNotification: id => {
            setNotificationState({
              ...notificationState,
              stack: notificationState.stack.map(n => {
                if (n.id === id) {
                  n.fadeOut = true;
                }

                return n;
              })
            });

            window.setTimeout(() => {
              setModalState({
                ...modalState,
                stack: notificationState.stack.filter(n => n.id !== id)
              });
            }, 240);
          }
        }
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export { UiContext, UiProvider };
