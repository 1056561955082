import { Permissions } from '../constants/enums';
import { Organization } from '../types/apiResponses';

function hasPermissions(
  organization: Organization,
  userID: string,
  permissions: Permissions[]
): boolean {
  const member = organization.members.find(m => m.user === userID);

  if (!member) {
    return false;
  }

  return permissions.some(p => p === member.permissions);
}

export default hasPermissions;
