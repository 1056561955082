import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Organization as IOrganization } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import { variableTypes } from '../../constants/localization';
import localize from '../../util/localize';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const [organization, loading] = useApi<IOrganization | null>({
    endpoint: 'organizations/' + organizationID,
    initialData: null,
    fetchOnMount: true
  });

  return (
    <>
      <Helper id="variables" title="Hva er variabler?">
        <Text>
          Når du setter opp en ProBot 🤖 (automatiserings-roboten vår), kan det
          være en fordel å opprette variabler for å slippe å måtte skrive inn
          samme innhold i flere e-poster. Variabler kan også endres underveis,
          og verdien vil da bli endret i alle e-poster som bruker variablen.
          Variabler er helt valgfritt å ta i bruk, men test de gjerne ut!
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Variabler
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem to="variabler/opprett" icon={<Icon>add</Icon>} primary>
                Ny variabel
              </MenuItem>
              {organization && organization.variables.length < 1 && (
                <ShowOff>
                  Legg til din første variabel ved å trykke på knappen over!
                </ShowOff>
              )}
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {organization && organization.variables.length < 1 && (
                <EmptyState>Ingen variabler!</EmptyState>
              )}
              {organization && organization.variables.length > 0 && (
                <Grid container spacing={24}>
                  {organization.variables.map(v => (
                    <Grid key={v._id} item sm={4}>
                      <CardLink to={'variabler/' + v._id} horizontal clickable>
                        <CardContent tight>
                          <Text>{v.label}</Text>
                          <Text variant="subheading" capitalize>
                            {localize(variableTypes, `${v.type}`)}:{' '}
                            {v.value ? v.value : ''}
                            {v.conditions
                              ? v.conditions.map(c => c.value).join(', ')
                              : ''}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
