import React, { useContext, useEffect } from 'react';
import styled, { css } from '../../util/styled';
import { UiContext } from '../../context/Ui';
import {
  FADE_IN_TOP,
  FADE_OUT_BOTTOM,
  FADE_IN,
  FADE_OUT
} from '../../constants/animations';

interface SharedProps {
  level: number;
  despawn?: boolean;
}

const Backdrop = styled.div<SharedProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${props => 3 + props.level};
  animation: ${FADE_IN} 0.24s ease-out both;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.despawn &&
    css`
      animation: ${FADE_OUT} 0.12s 0.12s ease-out both;
    `};
`;

const Modal = styled.div<SharedProps>`
  background-color: ${props => props.theme.colors.background};
  position: fixed;
  border-radius: ${props => props.theme.layout.rounding};
  box-shadow: ${props => props.theme.shadows.large};
  animation: ${FADE_IN_TOP} 0.24s 0.24s ease-out both;
  z-index: ${props => 4 + props.level};
  max-height: calc(100vh - (${props => props.theme.layout.spacing.large} * 2));
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.despawn &&
    css`
      animation: ${FADE_OUT_BOTTOM} 0.12s ease-out both;
    `};
`;

function Modals() {
  const modalContext = useContext(UiContext).modal;
  const modalState = modalContext.data;
  const hasModals = modalState.stack.length > 0;

  function keyListener(e: KeyboardEvent) {
    switch (e.keyCode) {
      case 27:
        modalContext.despawnModal();
        break;
    }
  }

  useEffect(() => {
    if (hasModals) {
      document.body.classList.add('freeze');
      window.addEventListener('keydown', keyListener);
    } else {
      document.body.classList.remove('freeze');
    }

    return () => {
      window.removeEventListener('keydown', keyListener);
    };
  }, [hasModals, modalState]);

  return hasModals ? (
    <>
      {modalState.stack.map((m, k) => (
        <Backdrop
          key={m.id}
          level={k}
          onClick={() => modalContext.despawnModal(m.id)}
        >
          <Modal
            level={k}
            despawn={m.fadeOut}
            onClick={e => e.stopPropagation()}
          >
            {m.content}
          </Modal>
        </Backdrop>
      ))}
    </>
  ) : null;
}

export default Modals;
