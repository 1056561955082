import React, { FunctionComponent, createContext, useState } from 'react';
import { OrganizationApp } from '../types/apiResponses';

const AppsContext = createContext({
  data: [] as OrganizationApp[],
  setData: (data: OrganizationApp[]) => {}
});

const AppsProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState([] as OrganizationApp[]);

  return (
    <AppsContext.Provider
      value={{
        data: state,
        setData: setState
      }}
    >
      {children}
    </AppsContext.Provider>
  );
};

export { AppsContext, AppsProvider };
