import { Address } from '../types/apiResponses';

interface GetAddressInterface {
  addressArr: string[];
  hasAddress: boolean;
}

function getAddress(address: Address): GetAddressInterface {
  const addressArr = Object.keys(address)
    //@ts-ignore How to fix this?
    .filter(k => k in address && address[k])
    //@ts-ignore How to fix this?
    .map(k => address[k]);

  return {
    addressArr,
    hasAddress: addressArr.length > 0
  };
}

export default getAddress;
