import React, { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EmailTemplate } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import history from '../../util/history';
import Doorman from '../../components/Doorman';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import BusyBoy from '../../helpers/BusyBoy';
import Form, { Input, Label } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import HTMLPreview from '../../components/HTMLPreview';
import { UiContext } from '../../context/Ui';
import { PickTemplateModal } from '../Automation/Show';

interface TemplateCategory {
  templates: Template[];
}

interface Template {
  id: string;
  name: string;
  thumbnail: string;
}

interface CreateParams {
  organizationID: string;
  emailTemplateID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const emailTemplateID = props.match.params.emailTemplateID;
  const isUpdate = emailTemplateID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/email-templates';
  const endpoint = isUpdate
    ? baseEndpoint + '/' + emailTemplateID
    : baseEndpoint;

  const { spawnModal, despawnModal } = useContext(UiContext).modal;

  const [templates, setTemplates] = useState([] as Template[]);

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<EmailTemplate>(
    {
      name: '',
      html: '',
      json: ''
    },
    {
      onSuccess: resource => {
        if (!isUpdate) {
          spawnModal(
            <PickTemplateModal
              organizationID={organizationID}
              template={resource as EmailTemplate}
              close={despawnModal}
            />,
            'pickTemplate'
          );
        } else {
          history.push('../e-postmaler');
        }
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<EmailTemplate | null>({
    method: 'DELETE',
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../e-postmaler');
    }
  });

  async function fetchTemplates() {
    const response = await fetch('https://rsrc.getbee.io/api/templates');
    const json = (await response.json()) as TemplateCategory[];

    if (Array.isArray(json) && json.length > 0) {
      setTemplates(json[0].templates);
    }
  }

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} e-postmal
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min e-postmal"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <ButtonList align="right">
              <ButtonLink to="../e-postmaler">
                {isUpdate ? 'Tilbake' : 'Avbryt'}
              </ButtonLink>
              <Doorman>
                <>
                  {data && data._id && (
                    <ButtonExternalLink
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        destroy();
                      }}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  {isUpdate && (
                    <ButtonLink to={emailTemplateID + '/utform'}>
                      Gå til design-modus
                    </ButtonLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting
                      ? 'Lagrer…'
                      : isUpdate
                      ? 'Lagre'
                      : 'Lagre og start å designe'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
            {data.html && <HTMLPreview html={data.html} />}
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Create;
