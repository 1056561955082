import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EmailTemplate, Board } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import history from '../../util/history';
import Doorman from '../../components/Doorman';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import BusyBoy from '../../helpers/BusyBoy';
import Form, { Input, Label } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../components/Button';

interface CreateParams {
  organizationID: string;
  boardID?: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const boardID = props.match.params.boardID;
  const isUpdate = !!boardID;
  const baseEndpoint = 'organizations/' + organizationID + '/boards';
  const endpoint = isUpdate ? baseEndpoint + '/' + boardID : baseEndpoint;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Board>(
    {
      name: '',
      lanes: []
    },
    {
      onSuccess: board => {
        if (isUpdate) {
          history.push('../' + (board as Board)._id);
        } else {
          history.push('../brett/' + (board as Board)._id);
        }
      },
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<EmailTemplate | null>({
    method: 'DELETE',
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../../brett');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Endre' : 'Opprett'} brett
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Mitt brett"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <ButtonList align="right">
              <ButtonExternalLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Avbryt
              </ButtonExternalLink>
              <Doorman>
                <>
                  {data && data._id && (
                    <ButtonExternalLink
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        destroy();
                      }}
                      variant="warning"
                    >
                      {deleting ? 'Sletter…' : 'Slett'}
                    </ButtonExternalLink>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isUpdate && !hasMadeChanges}
                  >
                    {submitting ? 'Lagrer…' : 'Lagre'}
                  </Button>
                </>
              </Doorman>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Create;
