import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/EmailTemplates/List';
import Editor from '../views/EmailTemplates/Editor';
import Create from '../views/EmailTemplates/Create';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/e-postmaler"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/e-postmaler/:emailTemplateID"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/e-postmaler/:emailTemplateID/utform"
        component={Editor}
      />
    </Switch>
  );
}
