import React from 'react';
import { Container } from '../../../helpers/Layout';
import {
  Robot,
  Organization,
  RobotPath,
  RobotSplit,
  DataPoint,
  EmailTemplate,
  Campaign
} from '../../../types/apiResponses';
import Form, { Label, Select } from '../../../components/Form';
import useForm from '../../../hooks/useForm';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../../components/Button';
import BusyBoy from '../../../helpers/BusyBoy';
import { RobotSplitType } from '../../../constants/enums';
import { robotSplits } from '../../../constants/localization';
import {
  SplitRandomInstructions,
  ConditionSplitInstructions,
  SplitCallbackInstructions
} from '../../../types/robotInstructions';
import useApi from '../../../hooks/useApi';
import ParameterExpander from './ParameterExpander';

interface SplitModalProps {
  organization: Organization;
  robot: Robot;
  path: RobotPath;
  close: (id: string) => any;
}

function Split(props: SplitModalProps) {
  const { organization, robot, path, close } = props;

  const baseEndpoint =
    'organizations/' + organization._id + '/robots/' + robot._id;
  const endpoint = baseEndpoint + '/paths/' + path._id;

  const { data, submitting, loadingPrefill, setField, submit } = useForm<
    RobotPath
  >(
    {
      split: {
        __type: RobotSplitType.None,
        instructions: {}
      }
    },
    {
      endpoint,
      method: 'PATCH',
      prefillEndpoint: baseEndpoint,
      prefillFn: robot => (robot as Robot).paths.find(p => p._id === path._id),
      onSuccess: () => close('split')
    }
  );

  const [_, deletingSplit, destroySplit] = useApi<null>({
    endpoint: endpoint + '/split',
    method: 'DELETE',
    initialData: null,
    onSuccess: () => close('split')
  });

  const [emails] = useApi<EmailTemplate[]>({
    endpoint: 'organizations/' + organization._id + '/email-templates/search',
    initialData: [],
    fetchOnMount: true
  });

  const [campaigns] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organization._id + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  const isNew = path.split.__type === RobotSplitType.None;

  function setInstructions(key: string, value: any) {
    setField('split', {
      ...data.split,
      instructions: {
        ...data.split.instructions,
        [key]: value
      }
    });
  }

  function renderInstructions() {
    switch (data.split.__type) {
      case RobotSplitType.Random:
        return renderRandom(data.split, setInstructions, isNew);
      case RobotSplitType.Condition:
        return renderCondition(data.split, setInstructions, organization);
      case RobotSplitType.Callback:
        return renderCallback(
          data.split,
          setInstructions,
          organization.dataPoints,
          emails,
          campaigns,
          organization
        );
      default:
        return null;
    }
  }

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy busy={loadingPrefill || submitting} exposeChildren>
        <Form onSubmit={submit}>
          <Label htmlFor="type">Type *</Label>
          <Select
            id="type"
            defaultValue={RobotSplitType.None}
            disabled={!isNew}
            value={data.split.__type}
            onChange={e => {
              setField('split', { ...data.split, __type: e.target.value });
            }}
          >
            <option disabled value={RobotSplitType.None}>
              Vennligst velg…
            </option>
            {Object.keys(robotSplits).map(k => (
              <option key={k} value={k}>
                {robotSplits[k]}
              </option>
            ))}
          </Select>
          {renderInstructions()}
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('trigger');
              }}
            >
              Avbryt
            </ButtonExternalLink>
            {!isNew && (
              <ButtonExternalLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  destroySplit({
                    endpoint: endpoint + '/split',
                    method: 'DELETE',
                    askBeforeFetch: 'Er du sikker på at du vil slette?'
                  });
                }}
                variant="warning"
              >
                {deletingSplit ? 'Sletter…' : 'Slett'}
              </ButtonExternalLink>
            )}
            <Button type="submit" variant="primary">
              Lagre
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

type SetValue = (key: string, value: any) => any;

function renderRandom(
  split: RobotSplit,
  setInstruction: SetValue,
  isNew: boolean
) {
  const instructions = split.instructions as SplitRandomInstructions;

  return (
    <>
      <Label htmlFor="numbers">Fordeling *</Label>
      <Select
        id="numbers"
        required
        disabled={!isNew}
        defaultValue="none"
        value={instructions ? instructions.numbers : 'none'}
        onChange={e => setInstruction('numbers', e.target.value)}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        <option value="50,100">50%-50%</option>
        <option value="33,66,100">33%-33%-33%</option>
        <option value="25,100">25%-75%</option>
      </Select>
    </>
  );
}

function renderCondition(
  split: RobotSplit,
  setInstruction: SetValue,
  organization: Organization
) {
  const instructions = split.instructions as ConditionSplitInstructions;

  return (
    <ParameterExpander
      organization={organization}
      parameters={instructions ? instructions.parameters || [] : []}
      onSet={parameters => setInstruction('parameters', parameters)}
      expanded
    />
  );
}

function renderCallback(
  split: RobotSplit,
  setInstruction: SetValue,
  dataPoints: DataPoint[],
  emails: EmailTemplate[],
  campaigns: Campaign[],
  organization: Organization
) {
  const instructions = split.instructions as SplitCallbackInstructions;

  function renderType() {
    switch (instructions.__type) {
      case 'setDataPoint':
        return (
          <>
            <Label htmlFor="ref">Karakteristikk *</Label>
            <Select
              id="ref"
              value={
                instructions && dataPoints.some(d => d._id === instructions.ref)
                  ? instructions.ref
                  : 'none'
              }
              defaultValue="none"
              onChange={e => setInstruction('ref', e.target.value)}
            >
              <option disabled value="none">
                Vennligst velg…
              </option>
              {dataPoints.map(d => (
                <option key={d._id} value={d._id}>
                  {d.label}
                </option>
              ))}
            </Select>
          </>
        );
      case 'readMail':
        return (
          <>
            <Label htmlFor="ref">E-postmal *</Label>
            <Select
              id="ref"
              value={
                instructions && emails.some(e => e._id === instructions.ref)
                  ? instructions.ref
                  : 'none'
              }
              defaultValue="none"
              onChange={e => setInstruction('ref', e.target.value)}
            >
              <option disabled value="none">
                Vennligst velg…
              </option>
              {emails.map(e => (
                <option key={e._id} value={e._id}>
                  {e.name}
                </option>
              ))}
            </Select>
          </>
        );
      case 'collection':
        return (
          <>
            <Label htmlFor="ref">Innsamlingskampanje *</Label>
            <Select
              id="ref"
              value={
                instructions && campaigns.some(c => c._id === instructions.ref)
                  ? instructions.ref
                  : 'none'
              }
              defaultValue="none"
              onChange={e => setInstruction('ref', e.target.value)}
            >
              <option disabled value="none">
                Vennligst velg…
              </option>
              {campaigns.map(c => (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <Label htmlFor="__type">Type *</Label>
      <Select
        id="__type"
        value={instructions ? instructions.__type : 'none'}
        defaultValue="none"
        onChange={e => {
          setInstruction('__type', e.target.value);
        }}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        <option value="setDataPoint">Karakteristikk oppdatert</option>
        <option value="readMail">Lest e-post</option>
        <option value="collection">Innsamling</option>
      </Select>
      {!!instructions && renderType()}
      {!!instructions && !!instructions.__type && (
        <ParameterExpander
          organization={organization}
          parameters={instructions ? instructions.parameters || [] : []}
          onSet={parameters => setInstruction('parameters', parameters)}
        />
      )}
    </>
  );
}

export default Split;
