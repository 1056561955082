import { Permissions } from './enums';

interface Localization {
  [key: string]: string;
}

export const dataPointTypes: Localization = {
  text: 'tekst',
  number: 'nummer',
  boolean: 'ja/nei',
  interest: 'interesse',
  date: 'dato',
  address: 'adresse',
  pick: 'valgmulighet',
  pickNumber: 'valgmulighet (tall)'
};

export const errorMessages: Localization = {
  missingOrganization: 'Mangler organisasjon',
  tokenNotSet: 'Ingen tilgangsnøkkel',
  badScope: 'Ingen tilgang',
  missingOrgOrCampaign: 'Fant ikke organisasjon eller kampanje',
  organizationNotFound: 'Fant ikke organisasjon',
  emailEmpty: 'Mangler e-post',
  messageNotFound: 'Fant ikke melding',
  pageNotFound: 'Fant ikke siden'
};

export const triggerTypes: Localization = {
  ['0']: 'Send så snart som mulig',
  ['1']: 'Send på et bestemt tidspunkt',
  ['2']: 'Send når en spesifikk karakteristikk oppdateres',
  ['3']: 'Send når en karakteristikk i en karakteristikkategori oppdateres'
};

export const selectionLogic: Localization = {
  ['0']: 'OG',
  ['1']: 'ELLER'
};

export const targetLogic: Localization = {
  ['0']: 'ER LIK',
  ['1']: 'ER IKKE LIK',
  ['2']: 'ER SATT',
  ['3']: 'ER IKKE SATT',
  ['4']: 'ER STØRRE ENN',
  ['5']: 'ER MINDRE ENN',
  ['6']: 'INNEHOLDER',
  ['7']: 'INNEHOLDER IKKE'
};

export const variableTypes: Localization = {
  ['0']: 'Konstant',
  ['1']: 'Betinget'
};

export const variableConditionTypes: Localization = {
  ['0']: 'Karakteristikk'
};

export const permissions: Localization = {
  [Permissions.None]: 'N/A',
  [Permissions.Reader]: 'Lesetilgang',
  [Permissions.Handler]: 'Konsulent',
  [Permissions.HandlerPlus]: 'Utvidet konsulent',
  [Permissions.Writer]: 'Skrivetilgang',
  [Permissions.Admin]: 'Administrator',
  [Permissions.ApiReader]: 'Lesetilgang',
  [Permissions.ApiWriter]: 'Skrivetilgang',
  [Permissions.ApiAdmin]: 'Administratortilgang'
};

export const acquisitionTypes: Localization = {
  creation: 'Brukeropprettet',
  import: 'Brukerimportert',
  collection: 'Innsamlingskampanje',
  dataPoint: 'Karakteristikkregistrering',
  correspondence: 'Melding',
  receiver: 'Webhook-mottaker',
  sourceRegistration: 'Kilderegistrering',
  session: 'Nettsidebesøk',
  pageView: 'Sidevisning'
};

export const robotTriggers: Localization = {
  dataPointSet: 'Karakteristikk oppdateres',
  date: 'Spesifikt tidspunkt',
  aggregatorScore: 'Profilskår™',
  sourceSet: 'Kilderegistrering',
  collection: 'Innsamling',
  receiver: 'Webhook-mottak',
  handlerAdd: 'Saksbehandler lagt til',
  handlerRemove: 'Saksbehandler fjernet',
  handlerAction: 'Saksbehandlerhandling',
  adminAction: 'Administratorhandling',
  list: 'Liste',
  action: 'Ny handling',
  threadCreate: 'Ny meldingstråd'
};

export const robotSplits: Localization = {
  condition: 'Hvis om dersom',
  callback: 'Vent på handling',
  random: 'Tilfeldig'
};

export const robotActions: Localization = {
  debug: 'Debug',
  delay: 'Vent',
  setDataPoint: 'Oppdater karakteristikk',
  sendEmail: 'Send melding',
  mergeData: 'Slå sammen data',
  togglePersonalization: 'Skru av/på personalisering',
  createNote: 'Lag notat',
  sendInternalMail: 'Send intern e-post',
  toggleList: 'Legg til/fjern fra liste',
  toggleHandler: 'Legg til/fjern saksbehandler',
  delete: 'Slett profil',
  webhook: 'Send webhook'
};

export const coreData: Localization = {
  name: 'navn',
  email: 'e-post',
  phone: 'telefonnummer',
  randomName: 'anonym',
  aggregatorScore: 'Profilskår™'
};

export const receiverTypes: Localization = {
  createContact: 'Opprett profil',
  updateContact: 'Oppdater profil',
  pushAction: 'Registrer handling'
};

export const action: Localization = {
  category: 'Kategori',
  action: 'Handling',
  label: 'Etikett',
  value: 'Verdi'
};

export const defaultConsents: Localization = {
  basic: 'Standard',
  email: 'E-post',
  phone: 'SMS',
  actions: 'Handlinger'
};
