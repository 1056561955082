import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Automation } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderShipmentsSidebarContent } from '../../routing/Shipments';
import Menu, { MenuItem, MenuSplitter } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const baseUrl = '/organisasjoner/' + organizationID;
  const isInactive = window.location.href.includes('inaktive');
  const isUnderDevelopment = window.location.href.includes('under-arbeid');
  const isActive = !isInactive && !isUnderDevelopment;

  const [automations, loading, _, initialFetch] = useApi<Automation[]>({
    endpoint: 'organizations/' + organizationID + '/automations/search',
    initialData: [],
    fetchOnMount: true
  });

  const filteredAutomations = automations.filter(a => {
    if (isUnderDevelopment) {
      return !a.active;
    } else if (isInactive) {
      return a.triggers.every(t => t.fulfilled);
    }

    return a.active && !a.triggers.every(t => t.fulfilled);
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {isInactive
            ? 'Inaktive utsendelser'
            : isUnderDevelopment
            ? 'Utsendelser under arbeid'
            : 'Aktive utsendelser'}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderShipmentsSidebarContent(baseUrl)}
          <MenuSplitter />
          <Menu>
            <MenuItem
              to={(isActive ? 'utsendelser/' : '') + 'opprett'}
              icon={<Icon>add</Icon>}
              primary
            >
              Ny utsendelse
            </MenuItem>
            {initialFetch && !loading && automations.length < 1 && (
              <ShowOff>
                Opprett din første utsendelse ved å trykke på knappen over!
              </ShowOff>
            )}
          </Menu>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {!loading && initialFetch && filteredAutomations.length < 1 && (
                <EmptyState>Ingen utsendelser!</EmptyState>
              )}
              {filteredAutomations.length > 0 && (
                <Grid container spacing={24}>
                  {filteredAutomations.map(a => (
                    <Grid key={a._id} item sm={4}>
                      <CardLink
                        to={(isActive ? 'utsendelser/' : '') + a._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{a.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
