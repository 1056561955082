import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import history from '../../util/history';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import Form, { Label, Select } from '../../components/Form';
import { Member, Organization } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import Text from '../../components/Text';
import localize from '../../util/localize';
import { permissions } from '../../constants/localization';
import useApi from '../../hooks/useApi';
import { Permissions } from '../../constants/enums';
import BusyBoy from '../../helpers/BusyBoy';

interface Props {
  organizationID?: string;
  memberID?: string;
}

export default function UpdateMember(props: RouteComponentProps<Props>) {
  const organizationID = props.match.params.organizationID;
  const memberID = props.match.params.memberID;

  const { data, setField, submit, hasMadeChanges, submitting } = useForm<
    Member
  >(
    {
      _id: '',
      user: '',
      permissions: 0
    },
    {
      endpoint: 'organizations/' + organizationID + '/members/' + memberID,
      method: 'PATCH',
      prefillEndpoint: 'organizations/' + organizationID,
      prefillFn: organization => {
        return (organization as Organization).members.find(
          m => m._id === memberID
        );
      },
      onSuccess: () => {
        history.push('../innstillinger');
      }
    }
  );

  const [_, deleting, destroy] = useApi<Member | null>({
    method: 'DELETE',
    endpoint: 'organizations/' + organizationID + '/members/' + memberID,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette brukeren?',
    onSuccess: () => {
      history.push('../innstillinger');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Endre tilgangsnivå
        </Text>
      </Container>
      <Container spacious hugTop>
        <BusyBoy busy={deleting || submitting} exposeChildren>
          <Form onSubmit={submit}>
            <Label htmlFor="permissions">Tilgang</Label>
            <Select
              id="permissions"
              value={data.permissions}
              onChange={e => setField('permissions', e.target.value)}
            >
              <option value={Permissions.Reader}>
                {localize(permissions, Permissions.Reader)}
              </option>
              <option value={Permissions.Handler}>
                {localize(permissions, Permissions.Handler)}
              </option>
              <option value={Permissions.HandlerPlus}>
                {localize(permissions, Permissions.HandlerPlus)}
              </option>
              <option value={Permissions.Writer}>
                {localize(permissions, Permissions.Writer)}
              </option>
              <option value={Permissions.Admin}>
                {localize(permissions, Permissions.Admin)}
              </option>
            </Select>
            <ButtonList align="right">
              <ButtonLink to="../innstillinger">Avbryt</ButtonLink>
              <ButtonExternalLink onClick={() => destroy()} variant="warning">
                {deleting ? 'Sletter…' : 'Slett'}
              </ButtonExternalLink>
              <Button
                disabled={!hasMadeChanges || deleting || submitting}
                type="submit"
                variant="primary"
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </BusyBoy>
      </Container>
    </Content>
  );
}
