import React, { useState, useContext } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import localize from "../../util/localize";
import { permissions } from "../../constants/localization";
import Text from "../../components/Text";
import useApi from "../../hooks/useApi";
import Sidebar, { SidebarWrapper } from "../../components/Sidebar";
import Menu, { MenuItem, MenuSplitter } from "../../components/Menu";
import Icon from "../../components/Icon";
import Heading from "../../components/Heading";
import { Organization, Member, ApiKey } from "../../types/apiResponses";
import Content from "../../components/Content";
import { Container } from "../../helpers/Layout";
import { CardLink, CardContent, CardIcon } from "../../components/Card";
import { ButtonLink, ButtonList } from "../../components/Button";
import Table, {
  TableScrollWrapper,
  TableBody,
  TableHead,
  Th,
  Tr,
  Td
} from "../../components/Table";
import Card from "../../components/Card";
import logout from "../../util/logout";
import BusyBoy from "../../helpers/BusyBoy";
import { UserContext, defaultUser } from "../../context/User";
import storage from "../../util/storage";
import Doorman from "../../components/Doorman";
import { Permissions } from "../../constants/enums";
import hasPermissions from "../../util/hasPermissions";
import { UiContext } from "../../context/Ui";

const theme = storage.get("theme");

interface Props {
  organizationID: string;
}

export default function OrganizationSettings(
  props: RouteComponentProps<Props>
) {
  const organizationID = props.match.params.organizationID;

  const [loggingOut, setLoggingOut] = useState(false);
  const userContext = useContext(UserContext);
  const { spawnNotification, despawnNotification } =
    useContext(UiContext).notifications;

  const [organization] = useApi<Organization>({
    endpoint: "organizations/" + organizationID,
    initialData: {
      _id: "",
      notificationEmail: null,
      name: "",
      members: [],
      dataPoints: [],
      dataPointCategories: [],
      lists: [],
      variables: [],
      invites: [],
      groups: [],
      apps: [],
      consents: []
    },
    fetchOnMount: true
  });

  const [members] = useApi<Member[]>({
    endpoint: "organizations/" + organizationID + "/members",
    initialData: [],
    fetchOnMount: true
  });

  const [_, resendingInvite, resendInvite] = useApi<any>({
    endpoint: "TEMP",
    initialData: null,
    onSuccess: () => {
      despawnNotification("resendingInvitation");

      spawnNotification(
        "invitationResent",
        "Invitasjon sendt på nytt!",
        <Icon>thumb_up</Icon>
      );

      window.setTimeout(() => {
        despawnNotification("invitationResent");
      }, 3000);
    }
  });

  const [apiKeys, loadingApiKeys, loadApiKeys] = useApi<ApiKey[]>({
    endpoint: "organizations/" + organizationID + "/api-keys",
    initialData: [],
    fetchOnMount: true
  });

  const [__, deletingApiKey, deleteApiKey] = useApi<any>({
    endpoint: "TEMP",
    initialData: null,
    onSuccess: () => {
      loadApiKeys({
        endpoint: "organizations/" + organizationID + "/api-keys"
      });

      despawnNotification("deletingApiKey");

      spawnNotification(
        "apiKeyDeleted",
        "API-nøkkel slettet!",
        <Icon>thumb_up</Icon>
      );

      window.setTimeout(() => {
        despawnNotification("apiKeyDeleted");
      }, 3000);
    }
  });

  async function handleLogout() {
    setLoggingOut(true);
    await logout();
    userContext.setData(defaultUser);
    setLoggingOut(false);
  }

  return (
    <BusyBoy busy={loggingOut}>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {organization.name}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Menu>
            <Doorman type="admin">
              <MenuItem to="innstillinger/endre" icon={<Icon>settings</Icon>}>
                Endre innstillinger
              </MenuItem>
            </Doorman>
            <MenuItem
              to="innstillinger/endre-brukerinnstillinger"
              icon={<Icon>person</Icon>}
            >
              Endre brukerinnstillinger
            </MenuItem>
          </Menu>
          <MenuSplitter />

          <Menu>
            <MenuItem
              to="#"
              icon={
                <Icon>
                  {theme === "light" ? "brightness_2" : "brightness_5"}
                </Icon>
              }
              onClick={e => {
                e.preventDefault();
                storage.set("theme", theme === "light" ? "dark" : "light");
                window.location.reload();
              }}
            >
              {theme === "light" ? "Skru på mørkmodus" : "Skru av mørkmodus"}
            </MenuItem>
          </Menu>
          <MenuSplitter />
          <Menu>
            <MenuItem
              to="#"
              icon={<Icon>exit_to_app</Icon>}
              onClick={e => {
                e.preventDefault();
                handleLogout();
              }}
            >
              Logg ut
            </MenuItem>
          </Menu>
        </Sidebar>
        <Content sidebar>
          <Doorman type="admin">
            <Container spacious>
              <Grid container spacing={24}>
                <Grid item xs={12} md={4}>
                  <CardLink to="innstillinger/endre" horizontal clickable>
                    <CardContent tight>
                      <Text>E-post</Text>
                      <Text variant="subheading">
                        {organization.notificationEmail
                          ? organization.notificationEmail
                          : "Ingen"}
                      </Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>edit</Icon>
                    </CardIcon>
                  </CardLink>
                </Grid>
              </Grid>
            </Container>
            <Container spacious hugTop>
              <Text element="h2" variant="title" bottomGutter>
                Organisasjonsgrupper
              </Text>
              <Grid container spacing={24}>
                {organization.groups
                  .filter(group => !group.user)
                  .map(group => (
                    <Grid key={group._id} item xs={12} md={4}>
                      <CardLink
                        to={"grupper/" + group._id}
                        horizontal
                        clickable
                      >
                        <CardContent tight>
                          <Text>{group.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                <Grid item xs={12} md={4}>
                  <CardLink to="grupper/opprett" secondary horizontal clickable>
                    <CardContent tight>
                      <Text>Opprett gruppe</Text>
                    </CardContent>
                    <CardIcon tight>
                      <Icon>add</Icon>
                    </CardIcon>
                  </CardLink>
                </Grid>
              </Grid>
            </Container>
          </Doorman>
          <Container
            spacious
            hugTop={hasPermissions(organization, userContext.data.id, [
              Permissions.Admin
            ])}
          >
            <Text element="h2" variant="title" bottomGutter>
              Brukergrupper
            </Text>
            <Grid container spacing={24}>
              {organization.groups
                .filter(
                  group => !!group.user && group.user === userContext.data.id
                )
                .map(group => (
                  <Grid key={group._id} item xs={12} md={4}>
                    <CardLink
                      to={"grupper/" + group._id + "?privat=1"}
                      horizontal
                      clickable
                    >
                      <CardContent tight>
                        <Text>{group.name}</Text>
                      </CardContent>
                      <CardIcon tight>
                        <Icon>edit</Icon>
                      </CardIcon>
                    </CardLink>
                  </Grid>
                ))}
              <Grid item xs={12} md={4}>
                <CardLink
                  to="grupper/opprett?privat=1"
                  secondary
                  horizontal
                  clickable
                >
                  <CardContent tight>
                    <Text>Opprett brukergruppe</Text>
                  </CardContent>
                  <CardIcon tight>
                    <Icon>add</Icon>
                  </CardIcon>
                </CardLink>
              </Grid>
            </Grid>
          </Container>
          <Doorman type="admin">
            <Container spacious hugTop>
              <Text element="h2" variant="title" bottomGutter>
                Brukere
              </Text>
              <Card>
                <TableScrollWrapper>
                  <Table>
                    <TableHead>
                      <Tr>
                        <Th>Navn</Th>
                        <Th>E-post</Th>
                        <Th colSpan={2}>Tilgangsnivå</Th>
                      </Tr>
                    </TableHead>
                    <TableBody>
                      {members.length < 1 ? (
                        <Tr>
                          <Td>
                            <Text variant="headline">Ingen Brukere!</Text>
                          </Td>
                        </Tr>
                      ) : (
                        <>
                          {members.map(member => (
                            <Tr key={member._id}>
                              <Td>
                                {userContext.data.id !== member.user ? (
                                  <Link to={`innstillinger/${member._id}`}>
                                    {member.userData && member.userData.name}
                                  </Link>
                                ) : (
                                  member.userData && member.userData.name
                                )}
                              </Td>
                              <Td>
                                {member.userData && member.userData.email}
                              </Td>
                              <Td>
                                {localize(permissions, `${member.permissions}`)}{" "}
                              </Td>
                            </Tr>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableScrollWrapper>
              </Card>
            </Container>
            <Container spacious hugTop>
              <Text element="h2" variant="title" bottomGutter>
                Invitasjoner
              </Text>
              <ButtonList gutterBottom>
                <ButtonLink to="innstillinger/inviter-bruker">
                  <Icon>add</Icon>
                  Inviter bruker
                </ButtonLink>
              </ButtonList>
              {organization.invites && organization.invites.length > 0 && (
                <Card>
                  <TableScrollWrapper>
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th>E-post</Th>
                          <Th>Tilgangsnivå</Th>
                          <Th>Handlinger</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {organization.invites.map(invite => (
                          <Tr key={invite._id}>
                            <Td>{invite.email}</Td>
                            <Td>
                              {localize(permissions, `${invite.permissions}`)}
                            </Td>
                            <Td>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();

                                  if (
                                    window.confirm(
                                      "Er du sikker på at du vil sende invitasjonen på nytt?"
                                    )
                                  ) {
                                    resendInvite({
                                      endpoint:
                                        "organizations/" +
                                        organizationID +
                                        "/invites/" +
                                        invite._id +
                                        "/resend"
                                    });

                                    spawnNotification(
                                      "resendingInvitation",
                                      "Sender invitasjon på nytt…",
                                      <Icon>send</Icon>
                                    );
                                  }
                                }}
                              >
                                Send invitasjon på nytt
                              </a>
                            </Td>
                          </Tr>
                        ))}
                      </TableBody>
                    </Table>
                  </TableScrollWrapper>
                </Card>
              )}
            </Container>
            <Container spacious hugTop>
              <Text element="h2" variant="title" bottomGutter>
                API-nøkler
              </Text>
              <ButtonList gutterBottom>
                <ButtonLink to="innstillinger/opprett-api-nokkel">
                  <Icon>add</Icon>
                  Opprett API-nøkkel
                </ButtonLink>
              </ButtonList>
              {(apiKeys || []).length > 0 && (
                <BusyBoy busy={loadingApiKeys || deletingApiKey} exposeChildren>
                  <Card>
                    <TableScrollWrapper>
                      <Table>
                        <TableHead>
                          <Tr>
                            <Th>Nøkkel</Th>
                            <Th>Tilgangsnivå</Th>
                            <Th>Opprettet</Th>
                            <Th>Handlinger</Th>
                          </Tr>
                        </TableHead>
                        <TableBody>
                          {apiKeys.map(apiKey => (
                            <Tr key={apiKey._id}>
                              <Td>SKJULT</Td>
                              <Td>
                                {localize(permissions, `${apiKey.permissions}`)}
                              </Td>
                              <Td>
                                {moment(apiKey.timestamp).format(
                                  "D.M.YYYY [kl] HH:mm:ss"
                                )}
                              </Td>
                              <Td>
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();

                                    if (
                                      window.confirm(
                                        "Er du sikker på at du vil slette API-nøkkelen?"
                                      )
                                    ) {
                                      deleteApiKey({
                                        method: "DELETE",
                                        endpoint:
                                          "organizations/" +
                                          organizationID +
                                          "/api-keys/" +
                                          apiKey._id
                                      });

                                      spawnNotification(
                                        "deletingApiKey",
                                        "Sletter API-nøkkel…",
                                        <Icon>trash</Icon>
                                      );
                                    }
                                  }}
                                >
                                  Slett
                                </a>
                              </Td>
                            </Tr>
                          ))}
                        </TableBody>
                      </Table>
                    </TableScrollWrapper>
                  </Card>
                </BusyBoy>
              )}
            </Container>
          </Doorman>
        </Content>
      </SidebarWrapper>
    </BusyBoy>
  );
}
