import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Board } from '../../types/apiResponses';
import { useSearch } from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Menu, { MenuItem } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import Doorman from '../../components/Doorman';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const [boards, loading, hasMore, search, hasFetched] = useSearch<Board>({
    endpoint: 'organizations/' + organizationID + '/boards/search',
    fetchOnMount: true
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Brett
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Doorman>
            <Menu>
              <MenuItem to="brett/opprett" icon={<Icon>add</Icon>} primary>
                Nytt brett
              </MenuItem>
            </Menu>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {hasFetched && boards.length < 1 && (
                <EmptyState>Ingen brett!</EmptyState>
              )}
              {hasFetched && boards.length > 0 && (
                <Grid container spacing={24}>
                  {boards.map(b => (
                    <Grid key={b._id} item sm={4}>
                      <CardLink to={'brett/' + b._id} horizontal clickable>
                        <CardContent tight>
                          <Text>{b.name}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
