import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from '../../util/moment';
import { Note, Contact } from '../../types/apiResponses';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import BusyBoy from '../../helpers/BusyBoy';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import useApi, { useSearch } from '../../hooks/useApi';
import { OrganizationContext } from '../../context/Organization';
import Chat, { ChatItem } from '../../components/Chat';
import Button, {
  ButtonExternalLink,
  ButtonList
} from '../../components/Button';
import Icon from '../../components/Icon';
import Form, { Label, TextArea, Select } from '../../components/Form';
import { UiContext } from '../../context/Ui';
import useForm from '../../hooks/useForm';
import Helper from '../../components/Helper';
import { ContactContext } from '../../context/Contact';
import { AppsContext } from '../../context/Apps';
import { UserContext } from '../../context/User';
import Doorman from '../../components/Doorman';
import { ExternalLink } from '../../components/Link';
import theme from '../../constants/theme';
import { nameWithSleep } from './Show';
import ReactDatePicker from '../../components/DatePicker';

interface NotesParams {
  organizationID: string;
  contactID: string;
}

function Notes(props: RouteComponentProps<NotesParams>) {
  const user = useContext(UserContext).data;
  const organization = useContext(OrganizationContext).data;
  const modalContext = useContext(UiContext).modal;
  const apps = useContext(AppsContext).data;

  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const baseEndpoint =
    'organizations/' + organizationID + '/contacts/' + contactID;
  const endpoint = baseEndpoint + '/notes';
  const baseUrl = '/organisasjoner/' + organizationID;

  const contactContext = useContext(ContactContext);

  useApi<Contact | null>({
    endpoint: baseEndpoint,
    fetchOnMount: true,
    initialData: null,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  const [notes, loading, _, search] = useSearch<Note>({
    endpoint,
    fetchOnMount: true,
    reverse: true
  });

  const [deletedResource, deleting, destroy] = useApi<Note | null>({
    method: 'DELETE',
    endpoint,
    initialData: null,
    onSuccess: () => {
      search({
        endpoint
      });
    }
  });

  return (
    <>
      <Helper id="notes" title="Hva er notater?">
        <Text>
          Med notater kan du holde orden på kontaktene dine med egne ord.
          Notater er interne og ikke synlige for kontaken, men alle i
          organisasjonen din med lesetilgang kan se og endre hverandres notater.
        </Text>
        <Text variant="headline" topGutter bottomGutter>
          Tips: søk basert på notater
        </Text>
        <Text>
          Ved å bruke søkefunksjonen kan du finne profiler med notater som
          matcher søket ditt.
        </Text>
      </Helper>
      <Heading sidebar>
        {contactContext.data && (
          <Text element="h1" variant="display3" bottomGutter>
            {nameWithSleep(contactContext.data)}
          </Text>
        )}
        <Text element="h2" variant="title">
          Interne notater
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(
            baseUrl,
            contactID,
            apps,
            contactContext.data
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <ButtonList>
              <Button
                onClick={() =>
                  modalContext.spawnModal(
                    <CreateNoteModal
                      rootEndpoint={endpoint}
                      close={modalContext.despawnModal}
                      onSuccess={() =>
                        search({
                          endpoint
                        })
                      }
                    />,
                    'createNote'
                  )
                }
              >
                <Icon>add</Icon> Nytt notat
              </Button>
            </ButtonList>
          </Container>
          <BusyBoy busy={loading || deleting} exposeChildren>
            {organization && (
              <Container spacious hugTop>
                <Chat hug>
                  {notes.map(n => (
                    <ChatItem
                      key={n._id}
                      byline={`Laget av ${
                        n.originalAuthor ? n.originalAuthor.name : 'ProBot 🤖'
                      }, sist oppdatert ${moment(n.lastUpdated).format(
                        'D.M.YY [kl.] HH:mm:ss'
                      )}`}
                      secondaryContent={
                        n.notification && n.notificationSchedule ? (
                          <Text
                            lineThrough={moment(
                              n.notificationSchedule
                            ).isBefore(moment())}
                          >
                            <strong>Påminnelse:</strong>{' '}
                            {moment(n.notificationSchedule).format(
                              'D.M.YYYY [kl] HH:mm:ss'
                            )}
                          </Text>
                        ) : undefined
                      }
                      actions={
                        n.originalAuthor && n.originalAuthor._id === user.id ? (
                          <>
                            <ExternalLink
                              color={theme.colors.text}
                              href="#"
                              onClick={e => {
                                e.preventDefault();

                                modalContext.spawnModal(
                                  <CreateNoteModal
                                    rootEndpoint={endpoint}
                                    close={modalContext.despawnModal}
                                    defaultData={n}
                                    onSuccess={() =>
                                      search({
                                        endpoint
                                      })
                                    }
                                  />,
                                  'createNote'
                                );
                              }}
                            >
                              Rediger
                            </ExternalLink>{' '}
                            ·{' '}
                            <ExternalLink
                              color={theme.colors.text}
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                destroy({
                                  method: 'DELETE',
                                  endpoint: endpoint + '/' + n._id,
                                  askBeforeFetch:
                                    'Er du sikker på at du vil slette?'
                                });
                              }}
                            >
                              Slett
                            </ExternalLink>
                          </>
                        ) : (
                          <Doorman>
                            <ExternalLink
                              color={theme.colors.text}
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                destroy({
                                  method: 'DELETE',
                                  endpoint: endpoint + '/' + n._id,
                                  askBeforeFetch:
                                    'Er du sikker på at du vil slette?'
                                });
                              }}
                            >
                              Slett
                            </ExternalLink>
                          </Doorman>
                        )
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: n.parsedNote || ''
                        }}
                      />
                    </ChatItem>
                  ))}
                </Chat>
              </Container>
            )}
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface CreateNoteModalProps {
  close: (id: string) => any;
  rootEndpoint: string;
  defaultData?: Note;
  onSuccess?: () => any;
}

function CreateNoteModal(props: CreateNoteModalProps) {
  const { close, rootEndpoint, defaultData, onSuccess } = props;

  const endpoint = defaultData
    ? rootEndpoint + '/' + defaultData._id
    : rootEndpoint;

  const { data, setField, submit, submitting } = useForm<Note>(
    {
      note: defaultData ? defaultData.note : '',
      notification: defaultData ? defaultData.notification : false,
      notificationToHandlers:
        defaultData && defaultData.notificationToHandlers
          ? defaultData.notificationToHandlers
          : false,
      notificationSchedule: defaultData
        ? defaultData.notificationSchedule
        : undefined
    },
    {
      endpoint,
      method: defaultData ? 'PATCH' : 'POST',
      onSuccess: () => {
        close('createNote');

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  );

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy busy={submitting} exposeChildren>
        <Form onSubmit={submit}>
          <Label htmlFor="note">Notat *</Label>
          <TextArea
            id="note"
            value={data.note}
            onChange={e => setField('note', e.target.value)}
            required
          />
          <Label htmlFor="notification">Påminn meg på et tidspunkt</Label>
          <Select
            id="notification"
            value={data.notification === true ? 'true' : 'false'}
            onChange={e => setField('notification', e.target.value === 'true')}
          >
            <option value="false">Nei</option>
            <option value="true">Ja</option>
          </Select>
          {data.notification && (
            <>
              <Label htmlFor="notificationSchedule">Tidspunkt *</Label>
              <ReactDatePicker
                id="notificationSchedule"
                required
                selected={
                  data.notificationSchedule
                    ? moment(data.notificationSchedule).toDate()
                    : undefined
                }
                onChange={value => setField('notificationSchedule', value)}
                showTimeSelect
                dateFormat="dd.MM.YYY HH:mm"
              />
              <Label htmlFor="notificationToHandlers">
                Varse saksbehandlere
              </Label>
              <Select
                id="notificationToHandlers"
                value={data.notificationToHandlers === true ? 'true' : 'false'}
                onChange={e =>
                  setField('notificationToHandlers', e.target.value === 'true')
                }
              >
                <option value="false">Nei</option>
                <option value="true">Ja</option>
              </Select>
            </>
          )}
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('createNote');
              }}
            >
              Avbryt
            </ButtonExternalLink>
            <Button type="submit" variant="primary" disabled={submitting}>
              {submitting ? 'Lagrer…' : 'Lagre'}
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

export default Notes;
