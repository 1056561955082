import React, { FunctionComponent } from 'react';
import _Linkify from 'react-linkify';

const Linkify: FunctionComponent = ({ children }) => {
  return (
    <_Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          href={decoratedHref}
          key={key}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {decoratedText}
        </a>
      )}
    >
      {children}
    </_Linkify>
  );
};

export default Linkify;
