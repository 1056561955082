import React, { useContext, ReactNode } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from '../../util/moment';
import { Contact, ConsentLogType } from '../../types/apiResponses';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import BusyBoy from '../../helpers/BusyBoy';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Card from '../../components/Card';
import useApi, { useSearch } from '../../hooks/useApi';
import { OrganizationContext } from '../../context/Organization';
import Table, {
  TableHead,
  TableBody,
  Td,
  Th,
  Tr
} from '../../components/Table';
import { ContactContext } from '../../context/Contact';
import { AppsContext } from '../../context/Apps';
import { nameWithSleep } from './Show';
import localize from '../../util/localize';
import { defaultConsents } from '../../constants/localization';
import { TimelineItem as TimelineItemType } from '../../types/stats';
import { Timeline, TimelineItem } from '../../components/Timeline';
import { StatType } from '../../constants/enums';

interface LogParams {
  organizationID: string;
  contactID: string;
}

function Log(props: RouteComponentProps<LogParams>) {
  const organization = useContext(OrganizationContext).data;

  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const endpoint = 'organizations/' + organizationID + '/contacts/' + contactID;
  const baseUrl = '/organisasjoner/' + organizationID;

  const contactContext = useContext(ContactContext);
  const apps = useContext(AppsContext).data;

  const [contact, loading] = useApi<Contact | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  const [timeline, loadingTimeline] = useSearch<TimelineItemType>({
    endpoint: 'organizations/' + organizationID + '/stats/timeline',
    fetchOnMount: true,
    queryParams: {
      contact: contactID
    }
  });

  return (
    <>
      <Heading sidebar>
        {contactContext.data && (
          <Text element="h1" variant="display3" bottomGutter>
            {nameWithSleep(contactContext.data)}
          </Text>
        )}
        <Text element="h2" variant="title">
          Logg
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(
            baseUrl,
            contactID,
            apps,
            contactContext.data
          )}
        </Sidebar>
        <Content sidebar>
          <BusyBoy busy={loading}>
            {contact && organization && (
              <>
                <Container spacious>
                  <Text element="h3" variant="headline" bottomGutter>
                    Samtykker
                  </Text>
                  <Card>
                    <Table>
                      <TableHead>
                        <Tr>
                          <Th>Type</Th>
                          <Th>Samtykke</Th>
                          <Th>Tidspunkt</Th>
                          <Th>Referent</Th>
                        </Tr>
                      </TableHead>
                      <TableBody>
                        {contact.consentLog.length > 0 ? (
                          contact.consentLog.map(l => (
                            <Tr key={l._id}>
                              <Td>
                                {localize(defaultConsents, l.consentType)}
                              </Td>
                              <Td>
                                {l.__type === ConsentLogType.Consent
                                  ? 'Ja'
                                  : 'Nei'}
                              </Td>
                              <Td>
                                {moment(l.logDate).format(
                                  'D.M.YY [kl.] HH:mm:ss'
                                )}
                              </Td>
                              <Td>
                                {l.referrer ? (
                                  l.referrer
                                ) : (
                                  <Text variant="subheading">Ingen</Text>
                                )}
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td colSpan={4} centered>
                              <Text variant="subheading">Ingen samtykker</Text>
                            </Td>
                          </Tr>
                        )}
                      </TableBody>
                    </Table>
                  </Card>
                </Container>
                <Container spacious hugTop>
                  <Text element="h3" variant="headline" bottomGutter>
                    Tidslinje
                  </Text>
                  <Timeline>
                    {timeline.map(t => (
                      <TimelineItemHandler key={t._id} item={t} />
                    ))}
                  </Timeline>
                </Container>
              </>
            )}
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface TimelineItemHandlerProps {
  item: TimelineItemType;
}

function TimelineItemHandler({ item }: TimelineItemHandlerProps) {
  return (
    <TimelineItem
      icon={getIcon(item.__type)}
      byline={moment(item.timestamp).format('D.M.YY [kl.] HH:mm:ss')}
    >
      {getContent(item)}
    </TimelineItem>
  );
}

function getIcon(type: StatType): string {
  switch (type) {
    case StatType.ContactCreation:
      return 'star_border';
    case StatType.SetDataPointValue:
      return 'edit';
    case StatType.ShipmentSent:
    case StatType.ShipmentRead:
      return 'mail_outline';
    case StatType.ShipmentEngagement:
    case StatType.Action:
      return 'touch_app';
    case StatType.SourceRegistration:
    case StatType.Session:
      return 'language';
    case StatType.PageView:
      return 'open_in_browser';
    case StatType.Collection:
      return 'keyboard';
    case StatType.Message:
      return 'comment';
    default:
      return 'watch_later';
  }
}

function getContent(item: TimelineItemType): ReactNode {
  switch (item.__type) {
    case StatType.ContactCreation:
      return 'Profil opprettet';
    case StatType.SetDataPointValue:
      return (
        <>
          Karakteristikk <strong>{item.dataPoint}</strong> satt til{' '}
          <strong>{item.value}</strong>
        </>
      );
    case StatType.ShipmentSent:
      return item.shipment ? (
        <>
          Fikk tilsendt meldingen <strong>{item.message}</strong> fra
          utsendelsen <strong>{item.shipment}</strong>, med emnefeltet{' '}
          <strong>{item.subject}</strong>
        </>
      ) : (
        <>
          Fikk tilsendt melding med emnefelt <strong>{item.subject}</strong>
        </>
      );
    case StatType.ShipmentRead:
      return item.shipment ? (
        <>
          Åpnet meldingen <strong>{item.message}</strong> fra utsendelsen{' '}
          <strong>{item.shipment}</strong>, med emnefeltet{' '}
          <strong>{item.subject}</strong>
        </>
      ) : (
        <>
          Åpnet meldingen med emnefelt <strong>{item.subject}</strong>
        </>
      );
    case StatType.ShipmentEngagement:
      return item.shipment ? (
        <>
          Klikket på lenken <strong>{item.url}</strong> i meldingen{' '}
          <strong>{item.message}</strong> fra utsendelsen{' '}
          <strong>{item.shipment}</strong>, med emnefeltet{' '}
          <strong>{item.subject}</strong>
        </>
      ) : (
        <>
          Klikket på lenken <strong>{item.url}</strong> i meldingen med emnefelt{' '}
          <strong>{item.subject}</strong>
        </>
      );
    case StatType.SourceRegistration:
      return (
        <>
          Ny kilderegistrering:{' '}
          <strong>
            {item.firstParty}
            {!!item.thirdParty ? `(Tredjepart: ${item.thirdParty})` : ''}
          </strong>
        </>
      );
    case StatType.Session:
      return (
        <>
          Startet ny sesjon på{' '}
          <strong>
            {item.url}
            {!!item.referrer ? `(Referert fra: ${item.referrer})` : ''}
          </strong>
        </>
      );
    case StatType.Collection:
      if (item.status === 'success') {
        return (
          <>
            Fullførte innsamling <strong>{item.collector}</strong> i kampanje{' '}
            <strong>{item.campaign}</strong>.
          </>
        );
      }

      return (
        <>
          Forsøkte innsamling <strong>{item.collector}</strong> i kampanje{' '}
          <strong>{item.campaign}</strong>, men feilet.
        </>
      );
    case StatType.PageView:
      return (
        <>
          Besøkte siden <strong>{item.url}</strong> i{' '}
          {moment.duration(item.duration, 'seconds').humanize()}
        </>
      );
    case StatType.Action:
      return (
        <>
          Fullførte handling{' '}
          <strong>
            {item.action}
            {item.label ? ` (${item.label})` : ''}
          </strong>{' '}
          i kategori <strong>{item.category}</strong> til verdi{' '}
          <strong>{item.value}</strong>.
        </>
      );
    case StatType.Message:
      return (
        <>
          Sendte en melding i tråden{' '}
          <strong>
            <Link to={`meldinger/${item.thread}`}>{item.threadTitle}</Link>
          </strong>
          .
        </>
      );
    default:
      return '';
  }
}

export default Log;
