import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Boards/List';
import Create from '../views/Boards/Create';
import Show from '../views/Boards/Show';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/brett"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/brett/opprett"
        component={Create}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/brett/:boardID"
        component={Show}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/brett/:boardID/endre"
        component={Create}
      />
    </Switch>
  );
}
