import React, { FunctionComponent, ReactNode } from 'react';
import styled from '../../util/styled';
import Icon from '../Icon';
import Text from '../Text';
import Card, { CardContent } from '../Card';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  i {
    margin-right: ${props => props.theme.layout.spacing.small};
    color: ${props => props.theme.colors.textSecondary};
  }
`;

interface Props {
  icon?: ReactNode;
}

const EmptyState: FunctionComponent<Props> = ({ icon, children }) => (
  <Card>
    <CardContent tight>
      <Wrapper>
        {icon && icon}
        <Text variant="headline">{children}</Text>
      </Wrapper>
    </CardContent>
  </Card>
);

export default EmptyState;
