import React from 'react';
import { RouteComponentProps, Switch, Route, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import moment from '../../util/moment';
import { Event, EventDate } from '../../types/apiResponses';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Input } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonLink,
  ButtonExternalLink
} from '../../components/Button';
import BusyBoy from '../../helpers/BusyBoy';
import history from '../../util/history';
import Content from '../../components/Content';
import Doorman from '../../components/Doorman';
import Heading from '../../components/Heading';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Menu, { MenuItem } from '../../components/Menu';
import Icon from '../../components/Icon';
import EmptyState from '../../components/EmptyState';
import Card, { CardLink, CardContent, CardIcon } from '../../components/Card';
import Table, {
  TableHead,
  Tr,
  Th,
  TableBody,
  Td
} from '../../components/Table';
import getContactName from '../../util/getContactName';
import { numberFormat } from '../../util/intl';
import ReactDatePicker from '../../components/DatePicker';

function renderMenu(baseUrl: string, isUpdate: boolean, eventID?: string) {
  return (
    <Menu>
      <MenuItem
        exact
        to={baseUrl + (isUpdate ? `/${eventID}` : '/opprett')}
        icon={<Icon>info</Icon>}
      >
        Informasjon
      </MenuItem>
      {isUpdate && (
        <>
          <MenuItem
            exact
            to={`${baseUrl}/${eventID}/datoer`}
            icon={<Icon>calendar_today</Icon>}
          >
            Datoer
          </MenuItem>
          <MenuItem
            exact
            to={`${baseUrl}/${eventID}/deltakere`}
            icon={<Icon>people</Icon>}
          >
            Deltakere
          </MenuItem>
        </>
      )}
    </Menu>
  );
}

interface CreateParams {
  organizationID: string;
  eventID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const eventID = props.match.params.eventID;
  const isUpdate = eventID !== 'opprett';
  const baseEndpoint = 'organizations/' + organizationID + '/events';
  const endpoint = isUpdate ? baseEndpoint + '/' + eventID : baseEndpoint;
  const baseUrl = '/organisasjoner/' + organizationID + '/arrangementer';

  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/arrangementer/:eventID"
        render={() => (
          <Basic
            organizationID={organizationID}
            isUpdate={isUpdate}
            eventID={eventID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/arrangementer/:eventID/datoer"
        render={() => (
          <Dates
            organizationID={organizationID}
            isUpdate={isUpdate}
            eventID={eventID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/arrangementer/:eventID/datoer/:dateID"
        component={CreateDate}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/arrangementer/:eventID/deltakere"
        render={() => (
          <Participants
            organizationID={organizationID}
            isUpdate={isUpdate}
            eventID={eventID}
            baseUrl={baseUrl}
            endpoint={endpoint}
          />
        )}
      />
    </Switch>
  );
}

interface BasicProps {
  organizationID: string;
  isUpdate: boolean;
  endpoint: string;
  baseUrl: string;
  eventID?: string;
}

function Basic(props: BasicProps) {
  const { isUpdate, endpoint, baseUrl, eventID } = props;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<Event>(
    {
      name: '',
      dates: []
    },
    {
      onSuccess: () => history.push('../arrangementer'),
      endpoint,
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? endpoint : undefined
    }
  );

  const [_, deleting, destroy] = useApi<Event | null>({
    method: 'DELETE',
    endpoint: endpoint,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => history.push('../arrangementer')
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} arrangement
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>{renderMenu(baseUrl, isUpdate, eventID)}</Sidebar>
        <Content sidebar>
          <BusyBoy
            busy={loadingPrefill || submitting || deleting}
            exposeChildren
          >
            <Container spacious>
              <Form onSubmit={submit}>
                <Label htmlFor="name">Navn *</Label>
                <Input
                  id="name"
                  type="text"
                  required
                  placeholder="Mitt arrangemennt"
                  value={data.name}
                  onChange={e => setField('name', e.target.value)}
                />
                <ButtonList align="right">
                  <ButtonLink to="../arrangementer">Avbryt</ButtonLink>
                  <Doorman>
                    <>
                      {data && data._id && (
                        <ButtonExternalLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            destroy();
                          }}
                          variant="warning"
                        >
                          {deleting ? 'Sletter…' : 'Slett'}
                        </ButtonExternalLink>
                      )}
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isUpdate && !hasMadeChanges}
                      >
                        {submitting ? 'Lagrer…' : 'Lagre'}
                      </Button>
                    </>
                  </Doorman>
                </ButtonList>
              </Form>
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

function Dates(props: BasicProps) {
  const { isUpdate, endpoint, baseUrl, eventID } = props;

  const [event, loading, fetch, initialFetch] = useApi<Event>({
    endpoint,
    initialData: {
      _id: '',
      organization: '',
      name: '',
      dates: []
    },
    fetchOnMount: true
  });

  const dates = event.dates;

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Datoer
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>{renderMenu(baseUrl, isUpdate, eventID)}</Sidebar>
        <Content sidebar>
          <Container hugBottom spacious>
            <ButtonLink to="datoer/opprett">
              <Icon>add</Icon> Ny dato
            </ButtonLink>
          </Container>
          <BusyBoy busy={loading}>
            <Container spacious>
              {!loading && initialFetch && dates.length < 1 && (
                <EmptyState>Ingen datoer!</EmptyState>
              )}
              {dates.length > 0 && (
                <Grid container spacing={24}>
                  {dates.map(d => (
                    <Grid key={d._id} item sm={4}>
                      <CardLink to={`datoer/${d._id}`} clickable horizontal>
                        <CardContent tight>
                          <Text>{d.name}</Text>
                          <Text variant="subheading">
                            {d.start && d.end
                              ? `Fra ${moment(d.start).calendar()} til ${moment(
                                  d.end
                                ).calendar()}`
                              : d.start
                              ? moment(d.start).calendar()
                              : 'Ingen tidspunkt satt'}
                          </Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface CreateDateParams {
  organizationID: string;
  eventID: string;
  dateID: string;
}

function CreateDate(props: RouteComponentProps<CreateDateParams>) {
  const organizationID = props.match.params.organizationID;
  const eventID = props.match.params.eventID;
  const dateID = props.match.params.dateID;
  const isUpdate = dateID !== 'opprett';
  const endpoint = 'organizations/' + organizationID + '/events';
  const prefillEndpoint = endpoint + '/' + eventID;

  const {
    data,
    submitting,
    loadingPrefill,
    hasMadeChanges,
    setField,
    submit
  } = useForm<EventDate>(
    {
      name: '',
      participants: []
    },
    {
      endpoint: prefillEndpoint + '/dates' + (isUpdate ? `/${dateID}` : ''),
      method: isUpdate ? 'PATCH' : 'POST',
      prefillEndpoint: isUpdate ? prefillEndpoint : undefined,
      prefillFn: isUpdate
        ? event => (event as Event).dates.find(d => d._id === dateID)
        : undefined,
      onSuccess: () => {
        history.push('../datoer');
      }
    }
  );

  const [_, deleting, destroy] = useApi<Event | null>({
    method: 'DELETE',
    endpoint: prefillEndpoint + '/datoer/' + data._id,
    initialData: null,
    askBeforeFetch: 'Er du sikker på at du vil slette?',
    onSuccess: () => {
      history.push('../datoer');
    }
  });

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          {isUpdate ? 'Rediger' : 'Opprett'} dato
        </Text>
      </Container>
      <BusyBoy busy={loadingPrefill || submitting || deleting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <Label htmlFor="start">Fra-tidspunkt</Label>
            <ReactDatePicker
              id="start"
              selected={data.start ? moment(data.start).toDate() : undefined}
              onChange={value => setField('start', value)}
              showTimeSelect
              dateFormat="dd.MM.YYY HH:mm"
            />
            <Label htmlFor="end">Til-tidspunkt</Label>
            <ReactDatePicker
              id="end"
              selected={data.end ? moment(data.end).toDate() : undefined}
              onChange={value => setField('end', value)}
              showTimeSelect
              dateFormat="dd.MM.YYY HH:mm"
            />
            <Label htmlFor="slots">Ledige plasser</Label>
            <Input
              id="slots"
              type="number"
              value={data.slots}
              onChange={e => setField('slots', e.target.value)}
            />
            <ButtonList align="right">
              <ButtonLink to="../datoer">Avbryt</ButtonLink>
              {isUpdate && (
                <ButtonExternalLink onClick={() => destroy()} variant="warning">
                  Slett
                </ButtonExternalLink>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={isUpdate && !hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Lagre'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

function Participants(props: BasicProps) {
  const { isUpdate, endpoint, baseUrl, eventID } = props;

  const [event, loading, fetch, initialFetch] = useApi<Event>({
    endpoint: endpoint + '/participants',
    initialData: {
      _id: '',
      organization: '',
      name: '',
      dates: []
    },
    fetchOnMount: true
  });

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Deltakere
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>{renderMenu(baseUrl, isUpdate, eventID)}</Sidebar>
        <Content sidebar>
          <BusyBoy busy={loading} exposeChildren>
            <Container spacious hugTop>
              {event.dates.map(d => {
                const showSlots = typeof d.slots === 'number';

                return (
                  <React.Fragment key={d._id}>
                    <Text element="h2" variant="title" topGutter>
                      {d.name}
                    </Text>
                    {d.start && (
                      <Text variant="subheading" bottomGutter={!showSlots}>
                        {d.start && d.end
                          ? `Fra ${moment(d.start).calendar()} til ${moment(
                              d.end
                            ).calendar()}`
                          : moment(d.start).calendar()}
                      </Text>
                    )}
                    <Text variant="subheading" bottomGutter={!showSlots}>
                      Påmeldte:{' '}
                      <strong>
                        {numberFormat.format(d.participants.length)}
                      </strong>
                    </Text>
                    {showSlots && (
                      <Text variant="subheading" bottomGutter>
                        Ledige plasser:{' '}
                        <strong>
                          {numberFormat.format(
                            d.slots! - d.participants.length
                          )}
                        </strong>
                      </Text>
                    )}
                    <Card>
                      <Table>
                        <TableHead>
                          <Tr>
                            <Th>Profil</Th>
                            <Th right>Påmeldt</Th>
                          </Tr>
                        </TableHead>
                        <TableBody>
                          {d.participants.map(p => (
                            <Tr key={p._id}>
                              <Td>
                                {p.contact ? (
                                  <Link to={'../../profiler/' + p.contact._id}>
                                    {getContactName(p.contact)}
                                  </Link>
                                ) : (
                                  'N/A'
                                )}
                              </Td>
                              <Td right>{moment(p.timestamp).fromNow()}</Td>
                            </Tr>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                  </React.Fragment>
                );
              })}
            </Container>
          </BusyBoy>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default Create;
