import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled, { css } from '../../util/styled';
import Icon from '../Icon';
import Text from '../Text';

const Wrapper = styled.div``;

interface StyledProps {
  open: boolean;
}

interface LabelProps extends StyledProps {
  backgroundColor?: string;
  between?: boolean;
}

const Label = styled.a<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.between ? 'space-between' : 'center')};
  color: ${props => props.theme.colors.textSecondary};
  box-shadow: ${props => props.theme.shadows.bottomNormal};
  padding-bottom: ${props => props.theme.layout.spacing.normal};
  padding-right: 8px;
  position: relative;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.background};
  z-index: 2;

  &,
  i {
    transition: all
      ${props =>
        `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};
  }

  i {
    margin-left: calc(${props => props.theme.layout.spacing.small} / 2);
  }

  &:hover {
    box-shadow: ${props => props.theme.shadows.bottomLarge};
    transform: scale(1.024);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    box-shadow: ${props => props.theme.shadows.bottomNormal};
    transform: scale(1);
    text-decoration: none;
  }

  ${props =>
    props.open &&
    css`
      box-shadow: none !important;

      i {
        transform: rotateZ(180deg);
      }
    `};
`;

const Content = styled.div<StyledProps>`
  box-shadow: none;
  position: relative;
  z-index: 3;

  &,
  > div {
    transition: all
      ${props =>
        `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};
  }

  > div {
    transform: translateY(-30px);
    opacity: 0;
  }

  ${props =>
    props.open &&
    css`
      padding-bottom: ${props => props.theme.layout.spacing.normal};
      box-shadow: ${props => props.theme.shadows.bottomNormal};

      > div {
        transform: translateY(0);
        opacity: 1;
      }
    `};
`;

interface Props {
  label: ReactNode;
  open?: boolean;
  backgroundColor?: string;
  between?: boolean;
}

const Expander: FunctionComponent<Props> = ({
  label,
  open,
  backgroundColor,
  between,
  children
}) => {
  const [isOpen, setOpen] = useState(open || false);
  const [mountChildren, setMountChildren] = useState(open || false);

  function toggleOpen() {
    const set = !isOpen;

    setOpen(set);

    if (!set) {
      window.setTimeout(() => setMountChildren(set), 250);
    } else {
      setMountChildren(set);
    }
  }

  return (
    <Wrapper>
      <Label
        open={isOpen}
        backgroundColor={backgroundColor}
        between={between}
        href="#"
        onClick={e => {
          e.preventDefault();
          toggleOpen();
        }}
      >
        <Text variant="headline">{label}</Text>
        <Icon>expand_more</Icon>
      </Label>
      <Content open={isOpen}>
        <div>{mountChildren && children}</div>
      </Content>
    </Wrapper>
  );
};

export default Expander;
