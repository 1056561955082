import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MonacoEditor from 'react-monaco-editor';
import { Container } from '../../../helpers/Layout';
import {
  Robot,
  Organization,
  DataPoint,
  RobotAction,
  RobotPath,
  List,
  EmailTemplate,
  Personalization,
  Member
} from '../../../types/apiResponses';
import Form, { Label, Select, Input, TextArea } from '../../../components/Form';
import useForm, { SetField } from '../../../hooks/useForm';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../../components/Button';
import BusyBoy from '../../../helpers/BusyBoy';
import { RobotActionType, MessageType } from '../../../constants/enums';
import {
  DelayInstructions,
  ToggleListInstructions,
  CreateNoteInstructions,
  DebugInstructions,
  SetDataPointInstructions,
  SendEmailInstructions,
  SendInternalMailInstructions,
  TogglePersonalizationInstructions,
  MergeDataInstructions,
  ToggleHandlerInstructions,
  WebhookInstructions
} from '../../../types/robotInstructions';
import { robotActions } from '../../../constants/localization';
import useApi from '../../../hooks/useApi';
import renderField from '../../../util/renderField';
import { AppsContext } from '../../../context/Apps';
import labelDataPoints from '../../../util/labelDataPoints';
import { OrganizationContext } from '../../../context/Organization';
import getTheme, { Theme } from '../../../util/getTheme';
import Text from '../../../components/Text';

const theme = getTheme();

interface ActionModalProps {
  organization: Organization;
  robot: Robot;
  path: RobotPath;
  actionID?: string;
  close: (id: string) => any;
}

function Action(props: ActionModalProps) {
  const { organization, robot, path, actionID, close } = props;

  const baseEndpoint =
    'organizations/' + organization._id + '/robots/' + robot._id;
  const endpoint = baseEndpoint + '/paths/' + path._id;

  const apps = useContext(AppsContext).data;
  const members = useContext(OrganizationContext).members;

  const { data, submitting, loadingPrefill, setField, submit } =
    useForm<RobotPath>(path, {
      endpoint,
      method: 'PATCH',
      prefillEndpoint: baseEndpoint,
      prefillFn: robot => (robot as Robot).paths.find(p => p._id === path._id),
      onSuccess: () => close('action')
    });

  const [emails] = useApi<EmailTemplate[]>({
    endpoint: 'organizations/' + organization._id + '/email-templates/search',
    initialData: [],
    fetchOnMount: true
  });

  const [personalizations] = useApi<Personalization[]>({
    endpoint: 'organizations/' + organization._id + '/personalization/search',
    initialData: [],
    fetchOnMount: true
  });

  const [_, deleting, destroy] = useApi<null>({
    endpoint,
    method: 'PATCH',
    initialData: null,
    onSuccess: () => close('action')
  });

  const [action, setAction] = useState({
    ref: 'new',
    __type: RobotActionType.Delay,
    instructions: {}
  } as RobotAction);

  useEffect(() => {
    if (!!actionID && data) {
      const currentAction = (data.actions || []).find(a => a._id === actionID);

      if (currentAction) {
        setAction(currentAction);
      }
    } else if (!actionID) {
      const hasNew = data.actions.some(a => !!a.ref && a.ref === 'new');

      if (!hasNew) {
        setField('actions', [...data.actions, action]);
      }
    }
  }, [actionID, loadingPrefill, data.actions.length]);

  function setValue(key: string, value: any) {
    setAction({
      ...action,
      [key]: value
    });

    if (!action._id) {
      setField(
        'actions',
        data.actions.map(a => {
          if (!!a.ref && a.ref === 'new') {
            return {
              ...action,
              [key]: value
            };
          }

          return a;
        })
      );
    } else {
      setField(
        'actions',
        data.actions.map(a => {
          if (a._id === actionID) {
            return {
              ...action,
              [key]: value
            };
          }

          return a;
        })
      );
    }
  }

  function setInstructions(key: string, value: any) {
    setValue('instructions', {
      ...action.instructions,
      [key]: value
    });
  }

  function renderInstructions() {
    if (!action) {
      return null;
    }

    switch (action.__type) {
      case RobotActionType.Delay:
        return renderDelay(action, setInstructions);
      case RobotActionType.ToggleList:
        return renderToggleList(action, setInstructions, organization.lists);
      case RobotActionType.ToggleHandler:
        return renderToggleHandler(action, setInstructions, members);
      case RobotActionType.TogglePersonalization:
        return renderTogglePersonalization(
          action,
          setInstructions,
          personalizations
        );
      case RobotActionType.CreateNote:
        return renderCreateNote(action, setInstructions);
      case RobotActionType.Debug:
        return renderDebug(action, setInstructions);
      case RobotActionType.SetDataPoint:
        return renderSetDataPoint(
          action,
          setInstructions,
          labelDataPoints(organization.dataPoints, apps)
        );
      case RobotActionType.SendEmail:
        return renderSendEmail(action, setInstructions, setValue, emails);
      case RobotActionType.SendInternalMail:
        return renderSendInternalMail(
          action,
          setInstructions,
          emails,
          organization
        );
      case RobotActionType.MergeData:
        return renderMergeData(
          action,
          setInstructions,
          organization.dataPoints
        );
      case RobotActionType.Webhook:
        return renderWebhook(action, setInstructions);
      default:
        return null;
    }
  }

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy busy={loadingPrefill || submitting} exposeChildren>
        <Form onSubmit={submit}>
          <Label htmlFor="type">Type *</Label>
          <Select
            id="type"
            value={action.__type}
            onChange={e => {
              setValue('__type', e.target.value);
            }}
          >
            <option disabled selected={!action.__type}>
              Vennligst velg…
            </option>
            {Object.keys(robotActions).map(k => (
              <option key={k} value={k}>
                {robotActions[k]}
              </option>
            ))}
          </Select>
          {renderInstructions()}
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close('trigger');
              }}
            >
              Avbryt
            </ButtonExternalLink>
            {action._id && (
              <ButtonExternalLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  destroy({
                    endpoint,
                    method: 'PATCH',
                    askBeforeFetch: 'Er du sikker på at du vil slette?',
                    body: {
                      actions: data.actions.filter(a => a._id !== action._id)
                    }
                  });
                }}
                variant="warning"
              >
                {deleting ? 'Sletter…' : 'Slett'}
              </ButtonExternalLink>
            )}
            <Button type="submit" variant="primary">
              Lagre
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

type SetValue = (key: string, value: any) => any;

function renderDelay(action: RobotAction, setInstruction: SetValue) {
  const instructions = action.instructions as DelayInstructions;

  return (
    <>
      <Label htmlFor="value">Vent i *</Label>
      <Grid container spacing={24}>
        <Grid item xs={3}>
          <Input
            id="value"
            type="number"
            required
            value={instructions.value}
            onChange={e => setInstruction('value', parseInt(e.target.value))}
          />
        </Grid>
        <Grid item xs={9}>
          <Select
            id="durationType"
            required
            value={instructions.durationType}
            onChange={e => setInstruction('durationType', e.target.value)}
          >
            <option disabled selected={!instructions.durationType}>
              Vennligst velg…
            </option>
            <option value="hours">time(r)</option>
            <option value="days">dag(er)</option>
            <option value="weeks">uke(r)</option>
            <option value="months">månede(r)</option>
            <option value="years">år</option>
          </Select>
        </Grid>
      </Grid>
    </>
  );
}

function renderToggleList(
  action: RobotAction,
  setInstruction: SetValue,
  lists: List[]
) {
  const instructions = action.instructions as ToggleListInstructions;

  return (
    <>
      <Label htmlFor="add">Legg til / fjern *</Label>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <Select
            id="add"
            required
            value={instructions.add ? 'true' : 'false'}
            defaultValue="false"
            onChange={e => setInstruction('add', e.target.value === 'true')}
          >
            <option value="true">Legg til i</option>
            <option value="false">Fjern fra</option>
          </Select>
        </Grid>
        <Grid item xs={8}>
          <Select
            id="list"
            required
            value={instructions.list || 'none'}
            defaultValue="none"
            onChange={e => setInstruction('list', e.target.value)}
          >
            <option disabled value="none">
              Vennligst velg…
            </option>
            {lists.map(l => (
              <option key={l._id} value={l._id}>
                {l.label}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}

function renderToggleHandler(
  action: RobotAction,
  setInstruction: SetValue,
  members: Member[]
) {
  const instructions = action.instructions as ToggleHandlerInstructions;

  return (
    <>
      <Label htmlFor="add">Legg til / fjern *</Label>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <Select
            id="add"
            required
            value={instructions.add ? 'true' : 'false'}
            defaultValue="false"
            onChange={e => setInstruction('add', e.target.value === 'true')}
          >
            <option value="true">Legg til</option>
            <option value="false">Fjern</option>
          </Select>
        </Grid>
        <Grid item xs={8}>
          <Select
            id="user"
            required
            value={instructions.user || 'none'}
            defaultValue="none"
            onChange={e => setInstruction('user', e.target.value)}
          >
            <option disabled value="none">
              Vennligst velg…
            </option>
            {members.map(m => (
              <option key={m.user} value={m.user}>
                {m.userData!.name}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}

function renderTogglePersonalization(
  action: RobotAction,
  setInstruction: SetValue,
  personalizations: Personalization[]
) {
  const instructions = action.instructions as TogglePersonalizationInstructions;

  return (
    <>
      <Label htmlFor="turnOn">Skru på / av *</Label>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <Select
            id="turnOn"
            required
            value={instructions.turnOn ? 'true' : 'false'}
            defaultValue="false"
            onChange={e => setInstruction('turnOn', e.target.value === 'true')}
          >
            <option value="true">På</option>
            <option value="false">Av</option>
          </Select>
        </Grid>
        <Grid item xs={8}>
          <Select
            id="personalization"
            required
            value={instructions.personalization || 'none'}
            defaultValue="none"
            onChange={e => setInstruction('personalization', e.target.value)}
          >
            <option disabled value="none">
              Vennligst velg…
            </option>
            {personalizations.map(p => (
              <option key={p._id} value={p._id}>
                {p.name}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}

function renderCreateNote(action: RobotAction, setInstruction: SetValue) {
  const instructions = action.instructions as CreateNoteInstructions;

  return (
    <>
      <Label htmlFor="note">Notat *</Label>
      <TextArea
        id="note"
        required
        value={instructions.note}
        onChange={e => setInstruction('note', e.target.value)}
      />
    </>
  );
}

function renderDebug(action: RobotAction, setInstruction: SetValue) {
  const instructions = action.instructions as DebugInstructions;

  return (
    <>
      <Label htmlFor="message">Melding *</Label>
      <TextArea
        id="message"
        required
        value={instructions.message}
        onChange={e => setInstruction('message', e.target.value)}
      />
    </>
  );
}

function renderSetDataPoint(
  action: RobotAction,
  setInstruction: SetValue,
  dataPoints: DataPoint[]
) {
  const instructions = action.instructions as SetDataPointInstructions;
  const selectedDataPoint = dataPoints.find(
    d => d._id === instructions.dataPoint
  );

  return (
    <>
      <Label htmlFor="dataPoint">Karakteristikk *</Label>
      <Select
        id="dataPoint"
        required
        defaultValue="none"
        value={instructions.dataPoint}
        onChange={e => setInstruction('dataPoint', e.target.value)}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        {dataPoints.map(d => (
          <option key={d._id} value={d._id}>
            {d.label}
          </option>
        ))}
      </Select>
      {selectedDataPoint && (
        <>
          <Label htmlFor="value">Sett til *</Label>
          {renderField(selectedDataPoint, instructions.value, (_, value) =>
            setInstruction('value', value)
          )}
        </>
      )}
    </>
  );
}

function renderWebhook(action: RobotAction, setInstruction: SetValue) {
  const instructions = action.instructions as WebhookInstructions;

  return (
    <>
      <Label htmlFor="payload">Payload *</Label>
      <Select
        id="payload"
        required
        defaultValue="none"
        value={instructions.payload}
        onChange={e => setInstruction('payload', e.target.value)}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        <option value="contact">Profildata</option>
      </Select>
      <Label htmlFor="url">URL *</Label>
      <Input
        id="url"
        type="url"
        required
        value={instructions.url}
        onChange={e => setInstruction('url', e.target.value)}
      />
      <Label htmlFor="password">Valgfritt passord</Label>
      <Input
        id="password"
        type="text"
        required
        value={instructions.password}
        onChange={e => setInstruction('password', e.target.value)}
      />
    </>
  );
}

type CreateEmailType = 'template' | 'plain' | 'sms';

function renderSendEmail(
  action: RobotAction,
  setInstruction: SetValue,
  setValue: SetField,
  emails: EmailTemplate[]
) {
  return (
    <SendEmail
      action={action}
      setInstruction={setInstruction}
      setValue={setValue}
      emails={emails}
    />
  );
}

interface SendEmailProps {
  action: RobotAction;
  setInstruction: SetValue;
  setValue: SetField;
  emails: EmailTemplate[];
}

function SendEmail(props: SendEmailProps) {
  const { action, setInstruction, setValue, emails } = props;
  const orgContext = useContext(OrganizationContext);

  const instructions = action.instructions as SendEmailInstructions;
  const [type, setType] = useState(
    'html' in instructions && instructions.html!.length > 0
      ? 'plain'
      : instructions.type === MessageType.SMS
      ? MessageType.SMS
      : ('template' as CreateEmailType)
  );

  React.useEffect(() => {
    if (instructions.type === MessageType.SMS) {
      setInstruction('subject', 'SMS');
    }
  }, [instructions.type]);

  return (
    <>
      {instructions.type !== MessageType.SMS && (
        <>
          <Label htmlFor="subject">Emnefelt *</Label>
          <Input
            id="subject"
            type="text"
            required
            value={instructions.subject}
            onChange={e => setInstruction('subject', e.target.value)}
          />
        </>
      )}
      <Label htmlFor="type">Meldingstype *</Label>
      <Select
        id="type"
        name="type"
        defaultValue="template"
        value={type}
        onChange={e => {
          setType(e.target.value as CreateEmailType);
          setInstruction(
            'type',
            e.target.value === MessageType.SMS
              ? MessageType.SMS
              : MessageType.Email
          );
        }}
      >
        <option value="template">E-postmal</option>
        <option value="plain">Enkel HTML</option>
        {!!orgContext.data.smsProviderSettings && (
          <option value="sms">SMS</option>
        )}
      </Select>
      {type === 'template' && (
        <>
          <Label htmlFor="emailTemplate">E-post *</Label>
          <Select
            id="emailTemplate"
            required
            value={instructions.emailTemplate}
            onChange={e => {
              delete instructions.html;

              setValue('instructions', {
                ...instructions,
                emailTemplate: e.target.value
              });
            }}
          >
            <option disabled selected={!instructions.emailTemplate}>
              Vennligst velg…
            </option>
            {emails.map(e => (
              <option key={e._id} value={e._id}>
                {e.name}
              </option>
            ))}
          </Select>
        </>
      )}
      {type === 'plain' && (
        <>
          <Label>HTML *</Label>
          <MonacoEditor
            language="html"
            theme={theme === Theme.Dark ? 'hc-black' : 'vs'}
            height="300"
            options={{
              colorDecorators: true,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              contextmenu: false,
              extraEditorClassName:
                theme === Theme.Dark
                  ? 'profiler-monaco-dark'
                  : 'profiler-monaco-light'
            }}
            value={instructions.html || ''}
            onChange={html => {
              delete instructions.emailTemplate;

              setValue('instructions', {
                ...instructions,
                html
              });
            }}
          />
        </>
      )}
      {type === MessageType.SMS && (
        <>
          <Label htmlFor={MessageType.SMS}>SMS</Label>
          <TextArea
            id={MessageType.SMS}
            value={instructions.plain}
            onChange={e => setInstruction('plain', e.target.value)}
          />
        </>
      )}
      {instructions.type !== MessageType.SMS && (
        <>
          <Label htmlFor="fromName">Fra-navn</Label>
          <Input
            id="subject"
            type="text"
            value={instructions.fromName}
            onChange={e => setInstruction('fromName', e.target.value)}
          />
          <Label htmlFor="fromEmail">Fra-e-post</Label>
          <Input
            id="fromEmail"
            type="email"
            value={instructions.fromEmail}
            onChange={e => setInstruction('fromEmail', e.target.value)}
          />
          <Label htmlFor="transactional">Send som transaksjons-epost</Label>
          <Select
            id="transactional"
            name="transactional"
            defaultValue="false"
            value={instructions.transactional ? 'true' : 'false'}
            onChange={e =>
              setInstruction('transactional', e.target.value === 'true')
            }
          >
            <option value="false">Nei</option>
            <option value="true">Ja</option>
          </Select>
          <Text>
            <strong>Informasjon:</strong> Transaksjonse-poster sendes uavhengig
            av om mottaker gitt samtykke til å motta e-poster.
          </Text>
        </>
      )}
    </>
  );
}

function renderSendInternalMail(
  action: RobotAction,
  setInstruction: SetValue,
  emails: EmailTemplate[],
  organization: Organization
) {
  const instructions = action.instructions as SendInternalMailInstructions;

  return (
    <>
      <Label htmlFor="subject">Emnefelt *</Label>
      <Input
        id="subject"
        type="text"
        required
        value={instructions.subject}
        onChange={e => setInstruction('subject', e.target.value)}
      />
      <Label htmlFor="template">E-post</Label>
      <Select
        id="template"
        defaultValue=""
        value={instructions.template}
        onChange={e => setInstruction('template', e.target.value)}
      >
        <option value="">Egenkomponert</option>
        {emails.map(e => (
          <option key={e._id} value={e._id}>
            {e.name}
          </option>
        ))}
      </Select>
      {!instructions.template && (
        <>
          <Label>Melding *</Label>
          <MonacoEditor
            language="html"
            theme={theme === Theme.Dark ? 'hc-black' : 'vs'}
            height="300"
            options={{
              colorDecorators: true,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              contextmenu: false,
              extraEditorClassName:
                theme === Theme.Dark
                  ? 'profiler-monaco-dark'
                  : 'profiler-monaco-light'
            }}
            value={instructions.message || ''}
            onChange={html => setInstruction('message', html)}
          />
        </>
      )}
      <Label htmlFor="type">Til</Label>
      <Select
        id="type"
        defaultValue="organization"
        value={instructions.type}
        onChange={e => setInstruction('type', e.target.value)}
      >
        <option value="organization">
          Organisasjon ({organization.notificationEmail})
        </option>
        <option value="handlers">Saksbehandlere</option>
        <option value="groups">Grupper</option>
        <option value="customTo">Egendefinert</option>
      </Select>
      {instructions.type === 'customTo' && (
        <>
          <Label htmlFor="to">Til</Label>
          <Input
            id="to"
            type="email"
            placeholder="epost@eksempel.no"
            value={instructions.to}
            onChange={e => setInstruction('to', e.target.value)}
          />
        </>
      )}
    </>
  );
}

function renderMergeData(
  action: RobotAction,
  setInstruction: SetValue,
  dataPoints: DataPoint[]
) {
  const instructions = action.instructions as MergeDataInstructions;

  return (
    <>
      <Label htmlFor="from">Fra *</Label>
      <Select
        id="from"
        defaultValue="none"
        value={instructions.from}
        onChange={e => setInstruction('from', e.target.value)}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        {dataPoints.map(d => (
          <option key={d._id} value={d._id}>
            {d.label}
          </option>
        ))}
      </Select>
      <Label htmlFor="to">Til *</Label>
      <Select
        id="to"
        defaultValue="none"
        value={instructions.to}
        onChange={e => setInstruction('to', e.target.value)}
      >
        <option disabled value="none">
          Vennligst velg…
        </option>
        <option value="email">E-post</option>
        <option value="name">Navn</option>
        <option value="phone">Telefonnummer</option>
      </Select>
    </>
  );
}

export default Action;
