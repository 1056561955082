import React from 'react';
import moment from 'moment';
import AggregatorScore from '../components/AggregatorScore';
import Linkify from '../components/Linkify';

function translateValue(
  type: string,
  value: any,
  textAdjustAggregator?: boolean,
  aggregatorWidth?: string
): any {
  switch (type) {
    case 'date':
      const time = moment(value);
      return time.isValid() ? time.format('D.M.YYYY [kl] HH:mm:ss') : value;
    case 'boolean':
      return value ? 'Ja' : 'Nei';
    case 'interest':
      return (
        <AggregatorScore
          score={value}
          textAdjust={textAdjustAggregator}
          width={aggregatorWidth}
        />
      );
    default:
      return <Linkify>{value}</Linkify>;
  }
}

export default translateValue;
