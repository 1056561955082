import React, { useContext, FunctionComponent, useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { registerLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import 'prismjs/themes/prism.css';
import Header from './components/Header';
import Wrapper, { Main } from './components/Wrapper';
import theme, { mobileThreshold } from './constants/theme';
import {
  OrganizationProvider,
  OrganizationContext
} from './context/Organization';
import { UiProvider } from './context/Ui';
import { UserContext, UserProvider } from './context/User';
import { SnackProvider } from './context/Snackbar';
import { ContactProvider } from './context/Contact';
import Modals from './helpers/Modals';
import Notifications from './helpers/Notifications';
import OrganizationHandler from './helpers/OrganizationHandler';
import { UserState } from './types/general';
import history from './util/history';
import storage from './util/storage';
import { createGlobalStyle, ThemeProvider } from './util/styled';
import Login from './views/Login';
import CreateOrganization from './views/Organizations/Create';
import OrganizationList from './views/Organizations/List';
import SignOn from './views/SignOn';
import Invitation from './views/Invitation';
import Correspondence from './components/Correspondence';
import { AppsProvider, AppsContext } from './context/Apps';

// Routes
import dataPointRoutes from './routing/DataPoints';
import campaignsRoutes from './routing/Campaigns';
import contactsRoutes from './routing/Contacts';
import automationRoutes from './routing/Automation';
import variablesRoutes from './routing/Variables';
import organizationSettingsRoutes from './routing/OrganizationSettings';
import personalizationRoutes from './routing/Personalization';
import emailsRoutes from './routing/EmailTemplates';
import boardsRoutes from './routing/Boards';
import shipmentsRoutes from './routing/Shipments';
import reportsRoutes from './routing/Reports';
import receiversRoutes from './routing/Receivers';
import appsRoutes from './routing/Apps';

import 'react-datepicker/dist/react-datepicker.css';
import useApi from './hooks/useApi';
import { OrganizationApp } from './types/apiResponses';
import Doorman from './components/Doorman';
import ScrollToTop from './components/ScrollToTop';
import eventsRoutes from './routing/Events';

registerLocale('nb', nb);

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.background};
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.text};
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
    overflow-x: hidden;

    &.freeze {
      overflow: hidden;
      height: 100vh;
      width: 100vw;
    }
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  label,
  input,
  select {
    display: block;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block!important;
  }

  .react-datepicker-popper {
    position: relative !important;
    transform: none !important;
  }

  .react-datepicker__input-container input {
    font-size: 1rem;
    border: 1px solid ${props => props.theme.colors.border};
    width: 100%;
    padding: 0 ${props => props.theme.layout.spacing.normal};
    color: inherit;
    border-radius: ${props => props.theme.layout.rounding};
    height: 3.5rem;
    background-color: ${props => props.theme.colors.contentBackground};

    ::placeholder {
      opacity: 0.54;
    }
  }

  #htmlQuerySelector {
    font-family: monospace;
    font-size: 1.1rem;
  }

  .monaco-editor {
    border: 1px solid ${props => props.theme.colors.border} !important;
    background: none !important;

    .margin {
      border-right: 1px solid ${props => props.theme.colors.border} !important;
    }

    .selected-text, .inline-selected-text {
      background-color: ${props => props.theme.colors.primary} !important;
    }
  }

  .profiler-monaco-dark {
    .margin, .view-lines {
      background-color: ${props =>
        props.theme.colors.contentBackground} !important;
    }
  }

  .react-select__value-container {
    @media (max-width: ${mobileThreshold}px) {
      max-width: 70vw;
    }
  }
`;

function RouteHandler() {
  const user = useContext(UserContext);
  const storageUser = storage.getUnserialize<UserState>('user');

  const loggedIn =
    (storageUser && storageUser.loggedIn) || (user.data && user.data.loggedIn);

  if (
    !loggedIn &&
    window.location.pathname !== '/logg-inn' &&
    window.location.pathname !== '/sign-on'
  ) {
    storage.set('loginRedirect', window.location.pathname);
  }

  return (
    <Router history={history}>
      <ScrollToTop>
        {!loggedIn ? (
          <Switch>
            <Route exact path="/invitasjon" component={Invitation} />
            <Route exact path="/sign-on" component={SignOn} />
            <Route path="/logg-inn" component={Login} />
            <Route path="*" render={() => <Redirect to="/logg-inn" />} />
          </Switch>
        ) : (
          <>
            <Route exact path="/" component={OrganizationList} />
            <Route exact path="/invitasjon" component={Invitation} />
            <Route
              exact
              path="/opprett-organisasjon"
              component={CreateOrganization}
            />
            <Route
              path="/organisasjoner/:organizationID"
              component={OrganizationHandler}
            />
            <Route
              path="/organisasjoner/:organizationID"
              render={props => (
                <Wrapper>
                  <Header />
                  <Main>
                    {dataPointRoutes()}
                    {contactsRoutes()}
                    {campaignsRoutes()}
                    {automationRoutes()}
                    {variablesRoutes()}
                    {personalizationRoutes()}
                    {eventsRoutes()}
                    {emailsRoutes()}
                    {boardsRoutes()}
                    {shipmentsRoutes()}
                    {reportsRoutes()}
                    {receiversRoutes()}
                    {appsRoutes()}
                    {organizationSettingsRoutes()}
                    <Doorman type="handler">
                      <Correspondence
                        organization={props.match.params.organizationID}
                        sticky
                      />
                    </Doorman>
                  </Main>
                </Wrapper>
              )}
            />
          </>
        )}
      </ScrollToTop>
    </Router>
  );
}

const ThemeWrapper: FunctionComponent = () => {
  const organization = useContext(OrganizationContext).data;

  theme.colors.primary =
    organization && organization.theme && organization.theme.color
      ? organization.theme.color
      : '#05af8a';

  return (
    <ThemeProvider theme={theme}>
      <UiProvider>
        <RouteHandler />
        <Modals />
        <Notifications />
        <GlobalStyle />
      </UiProvider>
    </ThemeProvider>
  );
};

const LoadApps: FunctionComponent = ({ children }) => {
  const organization = useContext(OrganizationContext).data;
  const setApps = useContext(AppsContext).setData;

  const endpoint = 'organizations/' + organization._id + '/apps';

  const [apps, loading, fetch] = useApi<OrganizationApp[]>({
    endpoint,
    initialData: [],
    onSuccess: apps => setApps(apps as OrganizationApp[])
  });

  useEffect(() => {
    if (organization._id) {
      fetch({
        endpoint
      });
    }
  }, [organization._id]);

  return <>{children}</>;
};

function App() {
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <SnackProvider>
        <UserProvider>
          <OrganizationProvider>
            <AppsProvider>
              <ContactProvider>
                <LoadApps>
                  <ThemeWrapper />
                </LoadApps>
              </ContactProvider>
            </AppsProvider>
          </OrganizationProvider>
        </UserProvider>
      </SnackProvider>
    </SnackbarProvider>
  );
}

export default App;
