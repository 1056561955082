import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

const ScrollToTop: React.FC<RouteComponentProps> = ({
  children,
  location: { pathname }
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);
