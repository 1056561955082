import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Variables/List';
import Create from '../views/Variables/Create';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/variabler"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/variabler/:variableID"
        component={Create}
      />
    </Switch>
  );
}
