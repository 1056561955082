import * as React from 'react';
import styled, { css } from '../../util/styled';
import { CardExternalLink } from '../Card';
import Icon from '../Icon';

const dotSize = '9px';

const Canvas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 24px;
`;

const NodeWrapper = styled.div<NodeProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: ${props => props.theme.layout.spacing.large};

  ${props =>
    props.entryPoint &&
    css`
      padding-top: 0;
    `};

  ${props =>
    props.connectorDir === NodeConnectorDir.Top &&
    css`
      margin-top: ${dotSize};
    `};

  ${props =>
    props.connectorDir === NodeConnectorDir.Branching &&
    css`
      margin-top: 0;
    `};
`;

interface NodeContentProps {
  entryPoint?: boolean;
}

const NodeContent = styled.div<NodeContentProps>`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    !props.entryPoint &&
    css`
      padding-top: ${props => props.theme.layout.spacing.small};
    `};

  > div > * {
    margin-bottom: ${props => props.theme.layout.spacing.small};
  }
`;

interface LineProps {
  horizontal?: boolean;
  pos?: BranchPos;
}

const Line = styled.div<LineProps>`
  z-index: 1;
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.borderFull};

  ${props =>
    props.horizontal &&
    css`
      width: auto;
      height: 1px;
      top: calc(-${dotSize} / 2);
      bottom: auto;
      left: ${props.pos === BranchPos.Start ? `calc(50% + ${dotSize} / 2)` : 0};
      right: ${props.pos === BranchPos.End ? `calc(50% + ${dotSize} / 2)` : 0};
    `};
`;

interface DotProps {
  top?: boolean;
  bottom?: boolean;
}

const Dot = styled.div<DotProps>`
  z-index: 1;
  position: absolute;
  width: ${dotSize};
  height: ${dotSize};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.borderFull};
  box-shadow: ${props => props.theme.shadows.normal};

  ${props =>
    props.top &&
    css`
      top: -${dotSize};
    `};

  ${props =>
    props.bottom &&
    css`
      bottom: -${dotSize};
    `};
`;

const ActionDot = styled.a`
  z-index: 2;
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.shadows.normal};
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -21px;
  cursor: pointer;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${
        props.theme.animation.easing.inOut
      }`};

  i {
    transition: all
      ${props =>
        `${props.theme.animation.timing.normal} ${
          props.theme.animation.easing.inOut
        }`};
  }

  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.shadows.large};
    transform: scale(1.1);
    text-decoration: none;
    color: white;
    background-color: ${props => props.theme.colors.primary};

    i {
      transform: scale(1.25);
    }
  }

  &:active {
    box-shadow: ${props => props.theme.shadows.normal};
    transform: scale(1);

    i {
      transform: scale(1);
    }
  }
`;

const Action = styled(CardExternalLink)`
  padding: ${props =>
    `${props.theme.layout.spacing.small} ${props.theme.layout.spacing.normal}`};
  min-width: 12rem;
  max-width: 26rem;
  text-align: center;
  overflow: hidden;
`;

const Branches = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

interface LabelProps {
  color: string;
}

const BranchLabel = styled.div<LabelProps>`
  color: white;
  background-color: ${props => props.color};
  border-radius: ${props => props.theme.layout.rounding};
  padding: 2px 8px;
  font-size: 0.9em;
  position: relative;
  z-index: 2;
  margin-top: -16px;
  box-shadow: ${props => props.theme.shadows.normal};
`;

const BranchWrapper = styled.div<BranchProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  ${props =>
    props.child &&
    css`
      margin-top: calc(${dotSize});
    `}
`;

interface HitsProps {
  bottom?: boolean;
}

const Hits = styled.div<HitsProps>`
  position: absolute;
  padding: 2px 8px;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.85em;

  > i {
    margin-bottom: -7px;
    font-size: 20px;
  }

  ${props =>
    !props.bottom &&
    css`
      top: 0;
    `};

  ${props =>
    props.bottom &&
    css`
      bottom: 3px;
    `};
`;

export enum NodeConnectorDir {
  Top = 'top',
  Branching = 'branching'
}

interface NodeProps {
  entryPoint?: boolean;
  connectorDir?: NodeConnectorDir;
  actionable?: boolean;
  hits?: number;
  hitsBottom?: boolean;
  bottomDot?: boolean;
  actionableAction?: () => any;
}

const Node: React.FunctionComponent<NodeProps> = ({
  entryPoint,
  connectorDir,
  actionable,
  actionableAction,
  hits,
  hitsBottom,
  bottomDot,
  children
}) => {
  return (
    <NodeWrapper entryPoint={entryPoint} connectorDir={connectorDir}>
      <NodeContent entryPoint={entryPoint}>
        {typeof hits !== 'undefined' && (
          <Hits bottom={hitsBottom}>
            <Icon>expand_more</Icon>
            {hits}
          </Hits>
        )}
        {children}
      </NodeContent>
      {!entryPoint && connectorDir !== NodeConnectorDir.Top && <Dot top />}
      <Line />
      {actionable ? (
        <ActionDot
          href="#"
          onClick={e => {
            e.preventDefault();

            if (typeof actionableAction === 'function') {
              actionableAction();
            }
          }}
        >
          <Icon>add</Icon>
        </ActionDot>
      ) : bottomDot ? (
        <Dot bottom />
      ) : null}
    </NodeWrapper>
  );
};

export enum BranchPos {
  Start = 'start',
  End = 'end'
}

interface BranchProps {
  child?: boolean;
  pos?: BranchPos;
  label?: React.ReactNode;
}

const Branch: React.FunctionComponent<BranchProps> = ({
  child,
  pos,
  label,
  children
}) => {
  return (
    <BranchWrapper child={child}>
      {child && <Line horizontal pos={pos} />}
      {label}
      {children}
    </BranchWrapper>
  );
};

export default Canvas;
export { Node, Action, Branches, Branch, BranchLabel };
