import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Automation/List';
import Create from '../views/Automation/Create';
import Show from '../views/Automation/Show';
import Menu, { MenuItem } from '../components/Menu';
import Icon from '../components/Icon';

export function renderAutomationSidebarContent(baseUrl: string) {
  return (
    <Menu>
      <MenuItem exact to={baseUrl + '/proboter'} icon={<Icon>alarm_on</Icon>}>
        Aktive
      </MenuItem>
      <MenuItem
        to={baseUrl + '/proboter/under-arbeid'}
        icon={<Icon>file_copy</Icon>}
      >
        Under arbeid
      </MenuItem>
    </Menu>
  );
}

export default function automationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/proboter"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/proboter/under-arbeid"
        component={List}
      />
      <Route
        path="/organisasjoner/:organizationID/proboter/opprett"
        component={Create}
      />
      <Route
        path="/organisasjoner/:organizationID/proboter/:robotID"
        component={Show}
      />
    </Switch>
  );
}
