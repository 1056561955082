import storage from "./storage";
import api from "./api";

async function logout(): Promise<void> {
  await api({
    endpoint: "users/sso/logout",
  });

  storage.delete("user");
  window.location.href = "/logg-inn";
}

export default logout;
