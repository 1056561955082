import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import styled from '../../util/styled';
import storage from '../../util/storage';
import Fader from '../../helpers/Fader';
import Text from '../Text';
import Avatar from '../Avatar';
import Logo from '../Logo';
import { OrganizationContext } from '../../context/Organization';
import { UserContext } from '../../context/User';
import Doorman, { DoormanPermissions } from '../Doorman';
import {
  ButtonList,
  TextButton,
  InvisiLinkButton,
  ButtonExternalLink
} from '../Button';
import Icon from '../Icon';
import { UiContext } from '../../context/Ui';
import { Group } from '../../types/apiResponses';
import { Container } from '../../helpers/Layout';
import { CardExternalLink, CardContent, CardIcon } from '../Card';
import history from '../../util/history';
import { AppsContext } from '../../context/Apps';
import getWindowWidth from '../../util/getWindowWidth';
import { mobileThreshold } from '../../constants/theme';

const windowWidth = getWindowWidth();

const Wrapper = styled.header`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.contentBackground};
  grid-area: header;
  padding: 0 ${props => props.theme.layout.spacing.large};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${mobileThreshold}px) {
    padding: 0 ${props => props.theme.layout.spacing.small};
  }
`;

const HeaderNavWrapper = styled.div`
  display: flex;
`;

const HeaderNavItem = styled(({ ...props }) => <NavLink {...props} />)`
  height: calc(${props => props.theme.layout.headerHeight} - 3px);
  margin-bottom: -3px;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.layout.spacing.small};
  color: ${props => props.theme.colors.textSecondary};
  text-decoration: none;
  transition: all
    ${props =>
      `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};
  border-bottom: 3px solid transparent;
  cursor: pointer;

  &.active {
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  @media (max-width: ${mobileThreshold}px) {
    padding: 0 0.5em;
  }
`;

const HeaderNavItemButton = HeaderNavItem.withComponent('a');

const HeaderNavItemDummy = HeaderNavItem.withComponent('span');

interface MenuItem {
  url: string;
  label: string;
  permissions: DoormanPermissions;
}

function Header() {
  const organization = useContext(OrganizationContext).data;
  const modalContext = useContext(UiContext).modal;
  const user = useContext(UserContext);
  const apps = useContext(AppsContext).data;

  const [group, setGroup] = useState(null as Group | null);

  const organizationLoaded = organization._id.length > 0;
  const baseUrl = '/organisasjoner/' + organization._id + '/';

  useEffect(() => {
    const groupID = storage.get('group');

    if (groupID) {
      const foundGroup = organization.groups.find(d => d._id === groupID);

      if (foundGroup) {
        setGroup(foundGroup);
      }
    }
  }, [organization._id]);

  const appItems: MenuItem[] = [];

  for (let i = 0; i < apps.length; i++) {
    const app = apps[i];

    for (let j = 0; j < app.appData!.actions.length; j++) {
      const action = app.appData!.actions[j];

      if (action.settings && action.settings.showInMainMenu) {
        appItems.push({
          url: baseUrl + 'apper/' + app.appData!.slug + '/' + action._id,
          label: action.namePlural,
          permissions: 'all'
        });
      }
    }
  }

  const menuItems: MenuItem[] = [
    {
      url: baseUrl + 'profiler',
      label: 'Profiler',
      permissions: 'all'
    },
    ...appItems,
    {
      url: baseUrl + 'kampanjer',
      label: 'Innsamlingskampanjer',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'utsendelser',
      label: 'Utsendelser',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'proboter',
      label: 'ProBot 🤖',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'personalisering',
      label: 'Personalisering',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'karakteristikker',
      label: 'Karakteristikker',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'arrangementer',
      label: 'Arrangementer',
      permissions: 'handler'
    },
    {
      url: baseUrl + 'variabler',
      label: 'Variabler',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'e-postmaler',
      label: 'E-postmaler',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'brett',
      label: 'Brett',
      permissions: 'writer'
    },
    {
      url: baseUrl + 'webhook-mottakere',
      label: 'Webhook-mottakere',
      permissions: 'admin'
    },
    {
      url: baseUrl + 'rapporter/profiler',
      label: 'Rapporter',
      permissions: 'writer'
    }
  ];

  const firstLevel = menuItems.slice(0, windowWidth > 600 ? 6 : 1);
  const secondLevel = menuItems.slice(
    windowWidth > mobileThreshold ? 6 : 1,
    menuItems.length
  );

  return (
    <Wrapper as={Fader}>
      {windowWidth > mobileThreshold && (
        <Logo image={organization.logo} inverted />
      )}
      <HeaderNavWrapper>
        {firstLevel.map(i => {
          if (organizationLoaded) {
            return (
              <Doorman key={i.url} type={i.permissions}>
                <HeaderNavItem to={i.url}>{i.label}</HeaderNavItem>
              </Doorman>
            );
          }

          return (
            <Doorman key={i.url} type={i.permissions}>
              <HeaderNavItemDummy>{i.label}</HeaderNavItemDummy>
            </Doorman>
          );
        })}
        {organizationLoaded && secondLevel.length > 0 && (
          <HeaderNavItemButton
            href="#"
            onClick={e => {
              e.preventDefault();

              modalContext.spawnModal(
                <PickToolModal
                  close={modalContext.despawnModal}
                  items={secondLevel}
                />,
                'pickTool'
              );
            }}
          >
            Mer…
          </HeaderNavItemButton>
        )}
      </HeaderNavWrapper>
      <div>
        <ButtonList dontForceMobileVertical>
          {organization.groups.length > 0 && (
            <TextButton
              href="#"
              onClick={e => {
                e.preventDefault();
                modalContext.spawnModal(
                  <PickGroupModal
                    groups={organization.groups}
                    selectedGroup={group ? group._id : undefined}
                    close={modalContext.despawnModal}
                  />,
                  'pickGroup'
                );
              }}
            >
              {group ? group.name : 'Master'} <Icon>expand_more</Icon>
            </TextButton>
          )}
          <InvisiLinkButton to={baseUrl + 'innstillinger'}>
            {user.data.profileImage ? (
              <Avatar size="40px" image={user.data.profileImage} />
            ) : (
              <Avatar size="40px">
                <Text>
                  {user.data.name.length > 0 ? user.data.name.charAt(0) : ''}
                </Text>
              </Avatar>
            )}
          </InvisiLinkButton>
        </ButtonList>
      </div>
    </Wrapper>
  );
}

interface PickGroupModalProps {
  groups: Group[];
  selectedGroup?: string;
  close: (id: string) => any;
}

function PickGroupModal(props: PickGroupModalProps) {
  const { groups, selectedGroup, close } = props;

  const user = useContext(UserContext).data;

  if (!user.id) {
    return null;
  }

  function pick(group?: string) {
    if (group) {
      storage.set('group', group);
    } else {
      storage.delete('group');
    }

    close('pickGroup');
    window.location.reload();
  }

  return (
    <Container maxWidth="28rem">
      <Text variant="title" bottomGutter centered>
        Velg gruppe
      </Text>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <CardExternalLink
            href="#"
            clickable={selectedGroup !== null && selectedGroup !== undefined}
            horizontal
            secondary={selectedGroup !== null && selectedGroup !== undefined}
            primary={!selectedGroup}
            onClick={e => {
              e.preventDefault();
              pick();
            }}
          >
            <CardContent tight>
              <Text>Master</Text>
            </CardContent>
            <CardIcon tight>
              <Icon>{!selectedGroup ? 'check' : 'cached'}</Icon>
            </CardIcon>
          </CardExternalLink>
        </Grid>
        {groups
          .filter(g => !g.user || g.user === user.id)
          .map(g => (
            <Grid key={g._id} item xs={12}>
              <CardExternalLink
                href="#"
                clickable={g._id !== selectedGroup}
                horizontal
                secondary={g._id !== selectedGroup}
                primary={g._id === selectedGroup}
                onClick={e => {
                  e.preventDefault();
                  pick(g._id);
                }}
              >
                <CardContent tight>
                  <Text>{g.name}</Text>
                </CardContent>
                <CardIcon tight>
                  <Icon>{g._id === selectedGroup ? 'check' : 'cached'}</Icon>
                </CardIcon>
              </CardExternalLink>
            </Grid>
          ))}
      </Grid>
      <ButtonList align="right" gutterTop>
        <ButtonExternalLink
          href="#"
          onClick={e => {
            e.preventDefault();
            close('pickGroup');
          }}
        >
          Avbryt
        </ButtonExternalLink>
      </ButtonList>
    </Container>
  );
}

interface PickToolModalProps {
  items: MenuItem[];
  close: (id: string) => any;
}

function PickToolModal(props: PickToolModalProps) {
  const { items, close } = props;

  return (
    <Container maxWidth="28rem">
      <Grid container spacing={24}>
        {items.map(i => (
          <Grid key={i.url} item xs={12}>
            <CardExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                history.push(i.url);
                close('pickTool');
              }}
              clickable
              horizontal
              secondary
            >
              <CardContent tight>
                <Text>{i.label}</Text>
              </CardContent>
              <CardIcon tight>
                <Icon>chevron_right</Icon>
              </CardIcon>
            </CardExternalLink>
          </Grid>
        ))}
      </Grid>
      <ButtonList align="right" gutterTop>
        <ButtonExternalLink
          href="#"
          onClick={e => {
            e.preventDefault();
            close('pickGroup');
          }}
        >
          Lukk
        </ButtonExternalLink>
      </ButtonList>
    </Container>
  );
}

export default Header;
