import React, {
  FunctionComponent,
  useEffect,
  useState,
  ReactNode
} from 'react';
import styled from '../../util/styled';
import storage from '../../util/storage';
import Card from '../Card';
import Text from '../Text';
import Button, { ButtonList } from '../Button';
import Icon from '../Icon';
import { Container, Flex, FlexKid } from '../../helpers/Layout';
import Fader from '../../helpers/Fader';
import Hr from '../Hr';
import { mobileThreshold } from '../../constants/theme';

const Wrapper = styled.div`
  margin-top: ${props => props.theme.layout.spacing.small};
  padding: 0 ${props => props.theme.layout.spacing.large};

  @media (max-width: ${mobileThreshold}px) {
    display: none;
  }
`;

const Content = styled.div`
  max-width: 50rem;
`;

const IconWrapper = styled.div`
  margin-top: 4px;
`;

interface Helpers {
  [id: string]: boolean;
}

interface Props {
  id: string;
  title: string;
  exampleTitle?: ReactNode;
  example?: ReactNode;
}

const Helper: FunctionComponent<Props> = ({
  id,
  title,
  exampleTitle,
  example,
  children
}) => {
  const [show, setShow] = useState(false);
  const [showExample, setShowExample] = useState(false);

  useEffect(() => {
    const helpers = storage.getUnserialize<Helpers>('helpers') || {};
    let foundHelper = false;

    if (id in helpers) {
      foundHelper = true;
      setShow(helpers[id]);
    }

    if (!foundHelper) {
      setShow(true);
    }
  }, []);

  function hide() {
    setShow(false);

    const helpers = storage.getUnserialize<Helpers>('helpers') || {};

    helpers[id] = false;
    storage.setSerialize('helpers', helpers);
  }

  return show ? (
    <Wrapper>
      <Fader>
        <Card secondary>
          <Container>
            <Flex align="flex-end">
              <FlexKid spaceRight centerContent alignSelf="flex-start">
                <IconWrapper>
                  <Icon>help_outline</Icon>
                </IconWrapper>
              </FlexKid>
              <FlexKid flex={1}>
                <Content>
                  <Text variant="title" bottomGutter>
                    {title}
                  </Text>
                  {children}
                  {example && exampleTitle && showExample && (
                    <>
                      <Hr />
                      <Text variant="headline" topGutter bottomGutter>
                        Eksempel: {exampleTitle}
                      </Text>
                    </>
                  )}
                  {example && showExample ? example : null}
                </Content>
              </FlexKid>
              <FlexKid hugRight spaceLeft>
                <ButtonList>
                  {example && (
                    <Button onClick={() => setShowExample(!showExample)}>
                      {showExample ? 'Skul' : 'Vis'} eksempel
                    </Button>
                  )}
                  <Button
                    onClick={e => {
                      hide();
                    }}
                  >
                    Lukk
                  </Button>
                </ButtonList>
              </FlexKid>
            </Flex>
          </Container>
        </Card>
      </Fader>
    </Wrapper>
  ) : null;
};

export default Helper;
