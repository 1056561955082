import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Show from '../views/Reports/Show';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/rapporter/:reportType"
        component={Show}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/rapporter/:reportType/:reportFilter"
        component={Show}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/rapporter/:reportType/:reportFilter/:reportSecondaryFilter"
        component={Show}
      />
    </Switch>
  );
}
