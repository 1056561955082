import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import moment from '../../util/moment';
import useApi from '../../hooks/useApi';
import { Campaign } from '../../types/apiResponses';
import Heading from '../../components/Heading';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Text from '../../components/Text';
import Content from '../../components/Content';
import { Container } from '../../helpers/Layout';
import BusyBoy from '../../helpers/BusyBoy';
import EmptyState from '../../components/EmptyState';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import { renderCampaignsSidebarContent } from '../../routing/Campaigns';
import Menu, { MenuSplitter, MenuItem } from '../../components/Menu';
import Progress from '../../components/Progress';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';

export interface ListParams {
  organizationID: string;
  activeState?: string;
}

enum Type {
  Active,
  Inactive,
  Upcoming
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const isInactive = window.location.href.includes('inaktive');
  const isUpcoming = window.location.href.includes('kommende');

  const [campaigns, loading, _, initialFetch] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organizationID + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  let type = Type.Active;

  if (isInactive) {
    type = Type.Inactive;
  } else if (isUpcoming) {
    type = Type.Upcoming;
  }

  const now = moment();

  const filteredCampaigns = campaigns.filter(c => {
    const start = moment(c.start);
    const end = moment(c.end);

    switch (type) {
      case Type.Inactive:
        return end.isBefore(now);
      case Type.Upcoming:
        return start.isAfter(now);
      default:
        return now.isBetween(start, end);
    }
  });

  return (
    <>
      <Helper
        id="campaigns"
        title="Hva er innsamlingskampanjer?"
        example={
          <>
            <Text>
              La oss si du driver en klesbutikk og er veldig opptatt av dine
              kunders favorittfarge. Du har opprettet en karakteristikk som
              heter <em>Favorittfarge</em>, slik at du kan sende ut nyhetsbrev
              med spesialisert innhold avhengig av hvilken farge de foretrekker.
              Problemet er at du ikke har noe data om dine kunders favorittfarge
              enda! Du trenger en <em>innsamlingskampanje</em>.
            </Text>
            <Text topGutter>
              Du bestemmer deg for å arrangere en konkurranse hvor du gir bort
              en gratis t-skjorte i favorittfargen til den som melder seg på.
              Denne konkurransen arrangeres på nettsiden din, og deltakere blir
              med ved å fylle ut et skjema, eller en <em>innsamler</em> som vi
              kaller det her.
            </Text>
            <Text topGutter>
              Innsamlingskampanjen vi skal opprette er veldig enkel: Vi kaller
              den "T-skjortekonkurranse", setter den til å være aktiv hele neste
              uke og oppretter én innsamler. I denne innsamleren begrenser vi
              innsamlinger til én innsamling per bruker, samt at vi legger til{' '}
              <em>Favorittfarge</em> som en karakteristikk som må fylles ut.
              Deretter skriver vi en liten kvitteringse-post som vil sendes ut
              når en deltaker sender inn skjemaet til konkurransen.
            </Text>
            <Text topGutter>
              Legg så skjemaet som er generert inn på nettsiden din, og se at
              innsamlingene strømmer inn! Gratulerer, du har nå en rikere
              profildatabase!
            </Text>
          </>
        }
        exampleTitle="T-skjortekonkurranse"
      >
        <Text>
          Det er vel og bra å ha et rikt sett med karakteristikker, men dersom
          ingen av kundene dine har har fylt inn karakteristikkene sine med data
          har de null verdi. Det er flere måter å fylle ut karakteristikker på,
          men innsamlingskampanjer er kanskje det enkleste, og mest effektive.
          Du kan på mange måter se på en innsamlingskampanje som ett eller flere
          skjemaer som kan fylles ut av en kunde på nettsiden din. Når du setter
          opp en innsamlingskampanje, velger du hvilke karakteristikker som skal
          samles inn, samt eventuelle regler og retningslinjer.
        </Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {type === Type.Inactive
            ? 'Inaktive'
            : type === Type.Upcoming
            ? 'Kommende'
            : 'Aktive'}{' '}
          innsamlings&shy;kampanjer
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderCampaignsSidebarContent('/organisasjoner/' + organizationID)}
          <Doorman>
            <>
              <MenuSplitter />
              <Menu>
                <MenuItem
                  to={`${type === Type.Active ? 'kampanjer/' : ''}opprett`}
                  icon={<Icon>add</Icon>}
                  primary
                >
                  Opprett kampanje
                </MenuItem>
              </Menu>
            </>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {!loading && initialFetch && filteredCampaigns.length < 1 && (
                <EmptyState>
                  Ingen{' '}
                  {type === Type.Inactive
                    ? 'inaktive'
                    : type === Type.Upcoming
                    ? 'kommende'
                    : 'aktive'}{' '}
                  kampanjer!
                </EmptyState>
              )}
              {filteredCampaigns.length > 0 && (
                <Grid container spacing={24}>
                  {filteredCampaigns.map(c => {
                    const start = moment(c.start);
                    const end = moment(c.end);
                    const elapsed = now.unix() - start.unix();
                    let progress =
                      (elapsed / (end.unix() - start.unix())) * 100;

                    if (progress < 2) {
                      progress = 2;
                    }

                    return (
                      <Grid key={c._id} item sm={4}>
                        <CardLink
                          to={`${type === Type.Active ? 'kampanjer/' : ''}${
                            c._id
                          }`}
                          clickable
                        >
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <CardContent tight>
                                <Text>{c.name}</Text>
                                <Text variant="subheading">
                                  Fra {start.format('D.M.YY')} til{' '}
                                  {end.format('D.M.YY')}
                                </Text>
                              </CardContent>
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                              <CardIcon tight>
                                <Icon>edit</Icon>
                              </CardIcon>
                            </Grid>
                            {type === Type.Active && (
                              <Grid item sm={12}>
                                <Progress value={progress > 0 ? progress : 0} />
                              </Grid>
                            )}
                          </Grid>
                        </CardLink>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
