import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Organization as IOrganization } from '../../types/apiResponses';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderDataPointSidebarContent } from '../../routing/DataPoints';
import Menu, { MenuItem, MenuSplitter } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Doorman from '../../components/Doorman';

interface CategoriesParams {
  organizationID: string;
}

function Categories(props: RouteComponentProps<CategoriesParams>) {
  const organizationID = props.match.params.organizationID;
  const [organization, loading] = useApi<IOrganization | null>({
    endpoint: 'organizations/' + organizationID,
    initialData: null,
    fetchOnMount: true
  });

  const baseUrl = '/organisasjoner/' + organizationID;

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Karakteristik&shy;kategorier
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderDataPointSidebarContent(baseUrl)}
          <Doorman>
            <>
              <MenuSplitter />
              <Menu>
                <MenuItem
                  to="kategorier/opprett"
                  icon={<Icon>add</Icon>}
                  primary
                >
                  Ny kategori
                </MenuItem>
                {organization &&
                  organization.dataPointCategories.length < 1 && (
                    <ShowOff>
                      Legg til din første kategori ved å trykke på knappen over!
                    </ShowOff>
                  )}
              </Menu>
            </>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {organization && organization.dataPointCategories.length < 1 && (
                <EmptyState>Ingen kategorier!</EmptyState>
              )}
              <Grid container spacing={24}>
                {organization &&
                  organization.dataPointCategories.map(c => (
                    <Grid key={c._id} item xs={12} md={4}>
                      <CardLink to={'kategorier/' + c._id} horizontal clickable>
                        <CardContent tight>
                          <Text>{c.label}</Text>
                        </CardContent>
                        <CardIcon tight>
                          <Icon>edit</Icon>
                        </CardIcon>
                      </CardLink>
                    </Grid>
                  ))}
              </Grid>
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default Categories;
