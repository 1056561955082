import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from '../../util/styled';

interface CardProps {
  clickable?: boolean;
  horizontal?: boolean;
  to?: string;
  minHeight?: string;
  height?: string;
  primary?: boolean;
  primaryAlternate?: boolean;
  secondary?: boolean;
  alternate?: boolean;
  opaque?: boolean;
  level?: 1 | 2 | 3;
  noTransition?: boolean;
}

const CardStyles = css<CardProps>`
  display: flex;
  background-color: ${props => props.theme.colors.contentBackground};
  border-radius: ${props => props.theme.layout.rounding};
  box-shadow: ${props => props.theme.shadows.normal};
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  color: inherit;
  text-decoration: none;

  ${props =>
    !props.noTransition &&
    css`
      transition: all
        ${props =>
          `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};

      i {
        transition: all
          ${props =>
            `${props.theme.animation.timing.normal} ${props.theme.animation.easing.inOut}`};
      }
    `};

  ${props =>
    props.horizontal &&
    css`
      align-items: center;
    `};

  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;

      &:hover,
      &:focus {
        box-shadow: ${props.theme.shadows.large};
        transform: scale(1.024);
        text-decoration: none;

        i {
          transform: scale(1.25);
        }
      }

      &:active {
        box-shadow: ${props.theme.shadows.normal};
        transform: scale(1);

        i {
          transform: scale(1);
        }
      }
    `};

  ${props =>
    props.primary &&
    css`
      background-color: transparent;
      box-shadow: none;
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};

      &:hover {
        text-decoration: none;
      }

      ${props.opaque &&
        css`
          background-color: ${props.theme.colors.background};
        `};

      ${props.clickable &&
        css`
          &:hover,
          &:focus,
          &:active {
            border-color: transparent;
            background-color: ${props.theme.colors.primary};
            color: white;
          }
        `}
    `};

  ${props =>
    props.primaryAlternate &&
    css`
      border-color: transparent;
      background-color: ${props.theme.colors.primary};
      color: white;
    `}

  ${props =>
    props.secondary &&
    css`
      background-color: transparent;
      box-shadow: none;
      border: 1px solid ${props.theme.colors.border};

      ${props.opaque &&
        css`
          background-color: ${props.theme.colors.background};
        `};

      ${props.clickable &&
        css`
          &:hover,
          &:focus,
          &:active {
            border-color: transparent;
            background-color: ${props.theme.colors.contentBackground};
          }
        `};
    `};

  ${props =>
    props.alternate &&
    css`
      background-color: ${props.theme.colors.text};
      color: white;
    `};

  ${props =>
    props.level &&
    props.level === 2 &&
    css`
      box-shadow: ${props.theme.shadows.large};
    `};

  ${props =>
    props.level &&
    props.level === 3 &&
    css`
      box-shadow: ${props.theme.shadows.mega};
    `};
`;

const Card = styled.div<CardProps>`
  ${CardStyles};
`;

interface CardElementProps {
  tight?: boolean;
  tighter?: boolean;
  hugTop?: boolean;
  hugBottom?: boolean;
  fill?: boolean;
}

const CardContent = styled.div<CardElementProps>`
  padding: ${props =>
    props.tight
      ? props.theme.layout.spacing.normal
      : props.theme.layout.spacing.large};

  ${props =>
    props.tighter &&
    css`
      padding: ${props.theme.layout.spacing.small};
    `};

  ${props =>
    props.hugTop &&
    css`
      padding-top: 0;
    `};

  ${props =>
    props.hugBottom &&
    css`
      padding-bottom: 0;
    `};

  ${props =>
    props.fill &&
    css`
      flex: 1;
    `};
`;

const CardTitle = styled(CardContent)``;

const CardActions = styled.div`
  padding: 0
    ${props =>
      `${props.theme.layout.spacing.large} ${props.theme.layout.spacing.large}`};
`;

const CardIcon = styled(CardContent)`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    color: inherit;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const CardLink = styled(
  ({
    clickable,
    horizontal,
    minHeight,
    primary,
    secondary,
    alternate,
    level,
    ...props
  }) => <Link {...props} />
)`
  ${CardStyles};
`;

const CardExternalLink = styled.a`
  ${CardStyles};

  &:hover {
    text-decoration: none;
  }
`;

const CardMedia = styled.div<{
  variant?: 'full';
  width?: number;
  height?: number;
}>`
  display: flex;

  img {
    width: ${props => (props.width ? `${props.width}px` : 'auto')};
    height: ${props => (props.height ? `${props.height}px` : 'auto')};
  }

  ${props =>
    props.variant === 'full' &&
    css`
      flex-direction: column;

      img {
        max-width: 100%;
        width: 100%;
      }
    `};
`;

export default Card;
export {
  CardLink,
  CardExternalLink,
  CardContent,
  CardTitle,
  CardActions,
  CardIcon,
  CardMedia
};
