import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Events/List';
import Create from '../views/Events/Create';

export default function eventsRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/arrangementer"
        component={List}
      />
      <Route
        path="/organisasjoner/:organizationID/arrangementer/:eventID"
        component={Create}
      />
    </Switch>
  );
}
