import styled from '../../util/styled';
import { FADE_IN, FADE_IN_TOP } from '../../constants/animations';

type Variant = 'normal' | 'top';

interface Props {
  variant?: Variant;
}

function getAnimation(variant?: Variant) {
  switch (variant) {
    case 'top':
      return FADE_IN_TOP;
    default:
      return FADE_IN;
  }
}

const Fader = styled.div<Props>`
  animation: ${props => getAnimation(props.variant)} 0.24s ease-out both;
`;

export default Fader;
