import React, { ReactNode } from 'react';

import moment from './moment';
import {
  Organization,
  RobotSplit,
  RobotAction,
  RobotTrigger,
  OrganizationApp
} from '../types/apiResponses';
import {
  RobotSplitType,
  RobotActionType,
  RobotTriggerType
} from '../constants/enums';
import Text from '../components/Text';
import localize from './localize';
import {
  robotActions,
  coreData,
  robotTriggers,
  robotSplits
} from '../constants/localization';
import Hr from '../components/Hr';
import theme from '../constants/theme';
import {
  CreateNoteInstructions,
  DebugInstructions,
  SetDataPointInstructions,
  MergeDataInstructions,
  SendEmailInstructions,
  TogglePersonalizationInstructions,
  SendInternalMailInstructions,
  DelayInstructions,
  TriggerDateInstructions,
  TriggerDataPointInstructions,
  TriggerBaseInstructions,
  ConditionSplitInstructions,
  SplitCallbackInstructions,
  TriggerAggregatorScoreInstructions,
  ToggleListInstructions,
  TriggerSourceSetInstructions,
  TriggerCollectionInstructions,
  TriggerReceiverInstructions,
  ToggleHandlerInstructions,
  TriggerActionInstructions,
  TriggerListInstructions,
  TriggerThreadCreateInstructionns,
  WebhookInstructions
} from '../types/robotInstructions';
import { renderProfileSelectionRule } from '../views/Automation/Show';

function getColor(split: RobotSplit, index: number): string {
  switch (split.__type) {
    case RobotSplitType.Condition:
    case RobotSplitType.Callback:
      return index === 0 ? theme.colors.goodDarker : theme.colors.warning;
    default:
      return theme.colors.neutralDarker;
  }
}

function translateInstructions(action: RobotAction): string | null | undefined {
  if (action.__type === RobotActionType.SetDataPoint) {
    const instructions = action.instructions as SetDataPointInstructions;

    return `${instructions.dataPointLabel} til ${instructions.value}`;
  } else if (action.__type === RobotActionType.Debug) {
    const instructions = action.instructions as DebugInstructions;

    return instructions.message;
  } else if (action.__type === RobotActionType.CreateNote) {
    const instructions = action.instructions as CreateNoteInstructions;

    return instructions.note;
  } else if (action.__type === RobotActionType.MergeData) {
    const instructions = action.instructions as MergeDataInstructions;

    if (instructions.type === 'dpToCore') {
      return `${instructions.dataPointLabel} til ${localize(
        coreData,
        instructions.to || ''
      )}`;
    }
  } else if (action.__type === RobotActionType.SendEmail) {
    const instructions = action.instructions as SendEmailInstructions;

    return instructions.emailName
      ? instructions.emailName
      : instructions.subject;
  } else if (action.__type === RobotActionType.TogglePersonalization) {
    const instructions =
      action.instructions as TogglePersonalizationInstructions;

    return `Skru ${instructions.turnOn ? 'på' : 'av'} ${
      instructions.personalizationName
    }`;
  } else if (action.__type === RobotActionType.SendInternalMail) {
    const instructions = action.instructions as SendInternalMailInstructions;

    if (instructions.emailName) {
      return instructions.emailName;
    }

    return instructions.subject;
  } else if (action.__type === RobotActionType.Delay) {
    const instructions = action.instructions as DelayInstructions;

    return (
      moment
        //@ts-ignore Moment typing being stupid again
        .duration(instructions.value, instructions.durationType)
        .humanize()
    );
  } else if (action.__type === RobotActionType.ToggleList) {
    const instructions = action.instructions as ToggleListInstructions;

    return `${instructions.add ? 'Legg til i' : 'Fjern fra'} ${
      instructions.listLabel
    }`;
  } else if (action.__type === RobotActionType.ToggleHandler) {
    const instructions = action.instructions as ToggleHandlerInstructions;

    return `${instructions.add ? 'Legg til' : 'Fjern'} ${
      instructions.userName
    }`;
  } else if (action.__type === RobotActionType.Webhook) {
    const instructions = action.instructions as WebhookInstructions;
    return `POST profildata til ${instructions.url}`;
  }

  return null;
}

function getLabel(action: RobotAction): ReactNode {
  return (
    <>
      <Text>{localize(robotActions, action.__type)}</Text>
      <Text variant="subheading">{translateInstructions(action)}</Text>
    </>
  );
}

function getTrigger(
  trigger: RobotTrigger,
  organization: Organization,
  apps: OrganizationApp[]
): ReactNode {
  let label: string = '';

  switch (trigger.__type) {
    case RobotTriggerType.Date:
      const d = trigger.instructions as TriggerDateInstructions;
      label = moment(d.timestamp).format('D.M.YYYY [kl] HH:mm:ss');
      break;
    case RobotTriggerType.DataPointSet:
      const s = trigger.instructions as TriggerDataPointInstructions;
      label = s.dataPointLabel || '';
      break;
    case RobotTriggerType.AggregatorScore:
      const a = trigger.instructions as TriggerAggregatorScoreInstructions;
      label = `Bikker ${a.overUnder} ${a.value}`;
      break;
    case RobotTriggerType.SourceSet:
      const ss = trigger.instructions as TriggerSourceSetInstructions;
      const data: string[] = [];

      if (ss.fpSources) {
        data.push('Førstepart: ' + ss.fpSources);
      }

      if (ss.tpSources) {
        data.push('Tredjepart: ' + ss.tpSources);
      }

      if (ss.ips) {
        data.push('IP: ' + ss.ips);
      }

      label = data.join(' · ');
      break;
    case RobotTriggerType.Collection:
      const c = trigger.instructions as TriggerCollectionInstructions;
      label = c.campaignName || '';
      break;
    case RobotTriggerType.Receiver:
      const r = trigger.instructions as TriggerReceiverInstructions;
      label = r.receiverName || '';
      break;
    case RobotTriggerType.Action:
      const ac = trigger.instructions as TriggerActionInstructions;

      if (ac.appRef) {
        for (let i = 0; i < apps.length; i++) {
          const app = apps[i];
          if (app.appData!.actions) {
            const appAction = app.appData!.actions.find(
              a => a._id === ac.appRef
            );

            if (appAction) {
              label = app.appData!.name + ': ' + appAction.name;
              break;
            }
          }
        }
      }

      label = label.length > 0 ? label : 'Egendefinert';
      break;
    case RobotTriggerType.List:
      const li = trigger.instructions as TriggerListInstructions;
      label = `${li.remove ? 'Fjernet fra' : 'Lagt til'} ${li.listLabel}`;
      break;
    case RobotTriggerType.ThreadCreate:
      const tc = trigger.instructions as TriggerThreadCreateInstructionns;
      label = `Ny meldingstråd${
        !!tc.subject ? ` hvis emnefelt inneholder ${tc.subject}` : ''
      }`;
      break;
  }

  return (
    <>
      <Text>{localize(robotTriggers, trigger.__type)}</Text>
      <Text variant="subheading">{label}</Text>
      {trigger.instructions &&
        'parameters' in trigger.instructions &&
        trigger.instructions.parameters.length > 0 && (
          <>
            <Hr />
            {(trigger.instructions as TriggerBaseInstructions).parameters.map(
              (p, k) => {
                const list = organization.lists.find(
                  l => l._id === p.targetRef
                );

                const dataPoint = organization.dataPoints.find(
                  d => d._id === p.targetRef
                );

                return (
                  <Text variant="subheading">
                    {renderProfileSelectionRule(p, list, dataPoint, k === 0)}
                  </Text>
                );
              }
            )}
          </>
        )}
    </>
  );
}

function getSplit(split: RobotSplit, organization: Organization): ReactNode {
  let label: string | null = null;

  switch (split.__type) {
    case RobotSplitType.Callback:
      const c = split.instructions as SplitCallbackInstructions;

      switch (c.__type) {
        case 'readMail':
          label = `Lest e-post ${c.emailName}`;
          break;
        case 'setDataPoint':
          label = `${c.dataPointLabel} oppdatert`;
          break;
        case 'collection':
          label = `Innsamling i ${c.campaignName}`;
          break;
      }

      break;
  }

  return (
    <>
      <Text>{localize(robotSplits, split.__type)}</Text>
      {label && <Text variant="subheading">{label}</Text>}
      {'parameters' in split.instructions &&
        (split.instructions as ConditionSplitInstructions)!.parameters!.length >
          0 && (
          <>
            <Hr />
            {(split.instructions as ConditionSplitInstructions)!.parameters!.map(
              (p, k) => {
                const list = organization.lists.find(
                  l => l._id === p.targetRef
                );

                const dataPoint = organization.dataPoints.find(
                  d => d._id === p.targetRef
                );

                return (
                  <Text variant="subheading">
                    {renderProfileSelectionRule(p, list, dataPoint, k === 0)}
                  </Text>
                );
              }
            )}
          </>
        )}
    </>
  );
}

export { getColor, translateInstructions, getLabel, getTrigger, getSplit };
