import { useEffect, useContext } from 'react';
import history from '../../util/history';
import api from '../../util/api';
import getQueryParamValue from '../../util/getQueryParamValue';
import storage from '../../util/storage';
import { InviteState } from '../../types/general';
import { UserContext } from '../../context/User';

async function handleInvite(inviteState: InviteState) {
  try {
    await api({
      endpoint:
        'organizations/' +
        inviteState.organization +
        '/invites/' +
        inviteState.code +
        '/accept'
    });

    storage.delete('invite');

    history.replace(
      '/organisasjoner/' + inviteState.organization + '/profiler'
    );
  } catch (error) {
    console.error(error);
  }
}

function Invitation() {
  const user = useContext(UserContext);

  useEffect(() => {
    const organization = getQueryParamValue('organisasjon');
    const code = getQueryParamValue('kode');

    if (organization && code) {
      const inviteState: InviteState = {
        organization,
        code
      };

      storage.setSerialize('invite', inviteState);
    }

    if (user.data.loggedIn) {
      const inviteState = storage.getUnserialize<InviteState>('invite');

      if (inviteState && inviteState.code) {
        handleInvite(inviteState);
      }
    } else {
      history.replace('/');
    }
  }, []);

  return null;
}

export default Invitation;
