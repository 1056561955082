import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Robot, Campaign, Receiver } from '../../types/apiResponses';
import { RobotTriggerType } from '../../constants/enums';
import history from '../../util/history';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Form, { Label, Select, Input } from '../../components/Form';
import Button, {
  ButtonExternalLink,
  ButtonList
} from '../../components/Button';
import { OrganizationContext } from '../../context/Organization';
import { robotTriggers } from '../../constants/localization';
import {
  renderDataPoint,
  renderAggregatorScore,
  renderSourceSet,
  renderCollection,
  renderDate,
  renderReceiver,
  renderAction,
  renderList,
  renderThreadCreate
} from './modals/Trigger';
import Content from '../../components/Content';
import useForm from '../../hooks/useForm';
import useApi from '../../hooks/useApi';
import { AppsContext } from '../../context/Apps';
import labelDataPoints from '../../util/labelDataPoints';

interface CreateParams {
  organizationID: string;
}

function Create(props: RouteComponentProps<CreateParams>) {
  const organizationID = props.match.params.organizationID;
  const endpoint = 'organizations/' + organizationID + '/robots';

  const organization = useContext(OrganizationContext).data;
  const apps = useContext(AppsContext).data;

  if (!organization._id) {
    return null;
  }

  const { data, submit, submitting, setField, hasMadeChanges } = useForm<Robot>(
    {
      name: '',
      trigger: {
        __type: null,
        instructions: {}
      }
    },
    {
      endpoint,
      onSuccess: robot => history.replace('../proboter/' + (robot as Robot)._id)
    }
  );

  const [campaigns] = useApi<Campaign[]>({
    endpoint: 'organizations/' + organization._id + '/campaigns/search',
    initialData: [],
    fetchOnMount: true
  });

  const [receivers] = useApi<Receiver[]>({
    endpoint: 'organizations/' + organization._id + '/receivers',
    initialData: [],
    fetchOnMount: true
  });

  function setInstructionsField(key: string, value: any) {
    setField('trigger', {
      ...data.trigger,
      instructions: {
        ...data.trigger.instructions,
        [key]: value
      }
    });
  }

  function renderInstructions() {
    switch (data.trigger.__type) {
      case RobotTriggerType.DataPointSet:
        return renderDataPoint(
          data.trigger,
          setInstructionsField,
          labelDataPoints(organization.dataPoints, apps)
        );
      case RobotTriggerType.AggregatorScore:
        return renderAggregatorScore(data.trigger, setInstructionsField);
      case RobotTriggerType.SourceSet:
        return renderSourceSet(data.trigger, setInstructionsField);
      case RobotTriggerType.Collection:
        return renderCollection(data.trigger, setInstructionsField, campaigns);
      case RobotTriggerType.Receiver:
        return renderReceiver(data.trigger, setInstructionsField, receivers);
      case RobotTriggerType.Date:
        return renderDate(data.trigger, setInstructionsField, setField);
      case RobotTriggerType.Action:
        return renderAction(data.trigger, setInstructionsField, apps);
      case RobotTriggerType.List:
        return renderList(
          data.trigger,
          setInstructionsField,
          organization.lists
        );
      case RobotTriggerType.ThreadCreate:
        return renderThreadCreate(data.trigger, setInstructionsField);
      default:
        return null;
    }
  }

  return (
    <Content>
      <Container spacious>
        <Text element="h1" variant="display3">
          Opprett ProBot
        </Text>
      </Container>
      <BusyBoy busy={submitting} exposeChildren>
        <Container spacious hugTop>
          <Form onSubmit={submit}>
            <Label htmlFor="name">Navn *</Label>
            <Input
              id="name"
              type="text"
              required
              placeholder="Min ProBot"
              value={data.name}
              onChange={e => setField('name', e.target.value)}
            />
            <Label htmlFor="triggerType">Utløsertype *</Label>
            <Select
              id="triggerType"
              required
              value={data.trigger.__type}
              defaultValue="none"
              onChange={e =>
                setField('trigger', { ...data.trigger, __type: e.target.value })
              }
            >
              <option disabled value="none">
                Vennligst velg…
              </option>
              {Object.keys(robotTriggers).map(k => (
                <option key={k} value={k}>
                  {robotTriggers[k]}
                </option>
              ))}
            </Select>
            {renderInstructions()}
            <ButtonList align="right">
              <ButtonExternalLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Avbryt
              </ButtonExternalLink>
              <Button
                type="submit"
                variant="primary"
                disabled={!hasMadeChanges}
              >
                {submitting ? 'Lagrer…' : 'Neste'}
              </Button>
            </ButtonList>
          </Form>
        </Container>
      </BusyBoy>
    </Content>
  );
}

export default Create;
