import styled, { css } from '../../util/styled';

interface Props {
  disable?: boolean;
}

const Opacity = styled.div<Props>`
  ${props =>
    !props.disable &&
    css`
      opacity: 0.5;
    `};
`;

export default Opacity;
