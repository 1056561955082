import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from '../views/Shipments/List';
import Create from '../views/Shipments/Create';
import Menu, { MenuItem } from '../components/Menu';
import Icon from '../components/Icon';

export function renderShipmentsSidebarContent(baseUrl: string) {
  return (
    <Menu>
      <MenuItem
        exact
        to={baseUrl + '/utsendelser'}
        icon={<Icon>alarm_on</Icon>}
      >
        Aktive
      </MenuItem>
      <MenuItem
        to={baseUrl + '/utsendelser/under-arbeid'}
        icon={<Icon>file_copy</Icon>}
      >
        Under arbeid
      </MenuItem>
      <MenuItem
        to={baseUrl + '/utsendelser/inaktive'}
        icon={<Icon>alarm_off</Icon>}
      >
        Inaktive
      </MenuItem>
    </Menu>
  );
}

export default function automationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/under-arbeid"
        component={List}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/utsendelser/inaktive"
        component={List}
      />
      <Route
        path="/organisasjoner/:organizationID/utsendelser/:automationID"
        component={Create}
      />
    </Switch>
  );
}
