import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Contact } from '../../types/apiResponses';
import { Container, Spacer } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Card, { CardContent } from '../../components/Card';
import useApi from '../../hooks/useApi';
import { ContactContext } from '../../context/Contact';
import { AppsContext } from '../../context/Apps';
import { Grid } from '@material-ui/core';
import Opacity from '../../components/Opacity';
import translateValue from '../../util/translateValue';
import { ActionsTable } from './Actions';
import { nameWithSleep } from './Show';

interface AppParams {
  organizationID: string;
  contactID: string;
  appSlug: string;
}

function App(props: RouteComponentProps<AppParams>) {
  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const appSlug = props.match.params.appSlug;

  const endpoint = 'organizations/' + organizationID + '/contacts/' + contactID;
  const baseUrl = '/organisasjoner/' + organizationID;

  const apps = useContext(AppsContext).data;
  const contactContext = useContext(ContactContext);

  const app = apps.find(a => 'appData' in a && a.appData!.slug === appSlug);

  const [contact] = useApi<Contact | null>({
    endpoint,
    initialData: null,
    fetchOnMount: true,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  return (
    <>
      <Heading sidebar>
        {contactContext.data && (
          <Text element="h1" variant="display3" bottomGutter>
            {nameWithSleep(contactContext.data)}
          </Text>
        )}
        <Text element="h2" variant="title">
          {app ? app.appData!.name : 'Laster…'}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(
            baseUrl,
            contactID,
            apps,
            contactContext.data
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            {app && contact && (
              <>
                <Grid container spacing={24}>
                  {app.appData!.dataPoints.map(d => {
                    const value = contact.profile.find(
                      p => p.dataPoint === d._id
                    );

                    return (
                      <Grid key={d._id} item sm={4}>
                        <Opacity disable={value !== undefined}>
                          <Card horizontal>
                            <CardContent tight fill>
                              <Text>
                                {value || d.type === 'interest'
                                  ? translateValue(
                                      d.type,
                                      value ? value.value : 0,
                                      true
                                    )
                                  : 'INGEN'}
                              </Text>
                              <Text variant="subheading" capitalize>
                                {d.label}
                              </Text>
                            </CardContent>
                          </Card>
                        </Opacity>
                      </Grid>
                    );
                  })}
                </Grid>
                {app.appData!.actions.map(a => (
                  <React.Fragment key={a._id}>
                    {app.appData!.dataPoints.length > 0 && <Spacer spacious />}
                    <Text variant="headline" element="h3" bottomGutter>
                      {a.namePlural || a.name}
                    </Text>
                    <ActionsTable
                      organizationID={organizationID}
                      endpoint={endpoint + '/actions/search'}
                      app={app}
                      action={a}
                      pushEndpoint={endpoint + '/actions'}
                      mutateEnpoint={
                        'organizations/' + organizationID + '/contacts/actions'
                      }
                    />
                    <Spacer />
                  </React.Fragment>
                ))}
              </>
            )}
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default App;
