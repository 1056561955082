import React, { useContext, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfiniteScroller from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import moment from '../../util/moment';
import {
  Contact,
  Action,
  OrganizationApp,
  AppAction
} from '../../types/apiResponses';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { renderContactSidebarContent } from '../../routing/Contacts';
import BusyBoy from '../../helpers/BusyBoy';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import Card from '../../components/Card';
import useApi, { useSearch, SearchOpts } from '../../hooks/useApi';
import Table, {
  TableScrollWrapper,
  TableHead,
  TableBody,
  Td,
  Th,
  Tr
} from '../../components/Table';
import { ContactContext } from '../../context/Contact';
import { AppsContext } from '../../context/Apps';
import Form, { Label, Input, Select, TextArea } from '../../components/Form';
import Button, {
  ButtonList,
  ButtonExternalLink
} from '../../components/Button';
import useForm from '../../hooks/useForm';
import { ActionSource } from '../../constants/enums';
import { OrganizationContext } from '../../context/Organization';
import { nameWithSleep } from './Show';
import { UiContext } from '../../context/Ui';
import Icon from '../../components/Icon';
import { Spacer } from '../../helpers/Layout';
import getContactName from '../../util/getContactName';
import theme from '../../constants/theme';

interface ActionsParams {
  organizationID: string;
  contactID: string;
}

function Actions(props: RouteComponentProps<ActionsParams>) {
  const organizationID = props.match.params.organizationID;
  const contactID = props.match.params.contactID;
  const endpoint = 'organizations/' + organizationID + '/contacts/' + contactID;
  const baseUrl = '/organisasjoner/' + organizationID;

  const contactContext = useContext(ContactContext);
  const apps = useContext(AppsContext).data;

  useApi<Contact | null>({
    endpoint,
    fetchOnMount: true,
    initialData: null,
    onSuccess: contact => contactContext.setData(contact as Contact)
  });

  return (
    <>
      <Heading sidebar>
        {contactContext.data && (
          <Text element="h1" variant="display3" bottomGutter>
            {nameWithSleep(contactContext.data)}
          </Text>
        )}
        <Text element="h2" variant="title">
          Handlinger
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderContactSidebarContent(
            baseUrl,
            contactID,
            apps,
            contactContext.data
          )}
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <Card>
              <ActionsTable
                organizationID={organizationID}
                endpoint={endpoint + '/actions/search'}
              />
            </Card>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

interface ActionsTableProps {
  organizationID: string;
  endpoint: string;
  app?: OrganizationApp;
  action?: AppAction;
  pushEndpoint?: string;
  mutateEnpoint?: string;
  showOwner?: boolean;
  handleStatus?: 'handled' | 'unhandled' | 'all';
}

export function ActionsTable(props: ActionsTableProps) {
  const {
    organizationID,
    endpoint,
    app,
    action,
    pushEndpoint,
    mutateEnpoint,
    showOwner,
    handleStatus
  } = props;

  const queryOpts: SearchOpts<Action> = {
    endpoint,
    limit: 20,
    queryParams: {
      app: app ? app.appData!._id : 'none',
      action: action ? action._id : 'none',
      handleStatus
    }
  };

  const [actions, loading, hasMore, search] = useSearch<Action>(queryOpts);

  const { spawnModal, despawnModal } = useContext(UiContext).modal;
  const members = useContext(OrganizationContext).members;

  let colspan = mutateEnpoint ? 7 : 6;

  const hideCategory = action && action.category.hide;
  const hideAction = action && action.action.hide;
  const hideLabel = action && action.label.hide;
  const hideValue = action && action.value.hide;

  const categoryLabel = action ? action.category.label : undefined;
  const actionLabel = action ? action.action.label : undefined;
  const labelLabel = action ? action.label.label : undefined;
  const valueLabel = action ? action.value.label : undefined;

  const sourceLabel = action ? action.sourceLabel : undefined;

  if (hideCategory) {
    --colspan;
  }

  if (hideAction) {
    --colspan;
  }

  if (hideLabel) {
    --colspan;
  }

  if (hideValue) {
    --colspan;
  }

  if (action && action.settings && action.settings.handleable) {
    colspan = colspan + 4;
  }

  function getSource(action: Action): ReactNode {
    switch (action.source) {
      case ActionSource.Probot:
        return 'ProBot 🤖';
      case ActionSource.Receiver:
        return 'Webhook-mottaker';
      case ActionSource.User:
        const user = members.find(m => m.user === action.sourceRef);

        if (user) {
          return user.userData!.name;
        }
    }

    return <Text variant="subheading">N/A</Text>;
  }

  function getHandler(action: Action): ReactNode {
    if (action.handledBy) {
      const handler = members.find(m => m.user === action.handledBy);

      if (handler) {
        return handler.userData!.name;
      }
    }

    return <Text variant="subheading">N/A</Text>;
  }

  return (
    <>
      {pushEndpoint && action && (
        <>
          <ButtonExternalLink
            href="#"
            onClick={e => {
              e.preventDefault();

              spawnModal(
                <PushActionModal
                  app={app}
                  appRef={action}
                  close={() => despawnModal('pushAction')}
                  endpoint={pushEndpoint}
                  onSuccess={() =>
                    search({
                      ...queryOpts,
                      paginate: false
                    })
                  }
                />,
                'pushAction'
              );
            }}
          >
            <Icon>add</Icon>
            {action.nameAction}
          </ButtonExternalLink>
          <Spacer />
        </>
      )}
      <Card>
        <BusyBoy busy={loading} exposeChildren>
          <InfiniteScroller
            hasMore={!loading && hasMore}
            initialLoad
            loadMore={() =>
              search({
                ...queryOpts,
                paginate: true
              })
            }
          >
            <TableScrollWrapper>
              <Table>
                <TableHead>
                  <Tr>
                    {action &&
                      action.settings &&
                      action.settings.handleable && <Th centered>Behandlet</Th>}
                    {!hideCategory && (
                      <Th>{categoryLabel ? categoryLabel : 'Kategori'}</Th>
                    )}
                    {!hideAction && (
                      <Th>{actionLabel ? actionLabel : 'Handling'} </Th>
                    )}
                    {!hideLabel && (
                      <Th>{labelLabel ? labelLabel : 'Etikett'}</Th>
                    )}
                    {!hideValue && (
                      <Th right>{valueLabel ? valueLabel : 'Verdi'}</Th>
                    )}
                    {showOwner && <Th>Profil</Th>}
                    <Th>{sourceLabel ? sourceLabel : 'Opprinnelse'}</Th>
                    <Th>Tidspunkt</Th>
                    {action && action.settings && action.settings.handleable && (
                      <>
                        <Th>Behandlet av</Th>
                        <Th>Behandlingstidspunkt</Th>
                        <Th>Kommentar</Th>
                      </>
                    )}
                    {mutateEnpoint && <Th>Handlinger</Th>}
                  </Tr>
                </TableHead>
                <TableBody>
                  {actions.length < 1 ? (
                    <Tr>
                      <Td colSpan={colspan}>
                        <Text variant="headline" centered>
                          Ingen!
                        </Text>
                      </Td>
                    </Tr>
                  ) : (
                    actions.map(a => (
                      <Tr key={a._id}>
                        {action &&
                          action.settings &&
                          action.settings.handleable && (
                            <Td centered>
                              <Text
                                color={
                                  a.handled
                                    ? theme.colors.good
                                    : theme.colors.warning
                                }
                              >
                                {a.handled ? 'Ja' : 'Nei'}
                              </Text>
                            </Td>
                          )}
                        {!hideCategory && <Td>{a.category}</Td>}
                        {!hideAction && <Td>{a.action}</Td>}
                        {!hideLabel && (
                          <Td>
                            {a.label ? (
                              a.label
                            ) : (
                              <Text element="span" variant="subheading">
                                Ingen
                              </Text>
                            )}
                          </Td>
                        )}
                        {!hideValue && <Td right>{a.value}</Td>}
                        {showOwner && typeof a.contact !== 'string' && (
                          <Td>
                            <Link
                              to={`/organisasjoner/${organizationID}/profiler/${a.contact._id}`}
                            >
                              {getContactName(a.contact)}
                            </Link>
                          </Td>
                        )}
                        <Td>{getSource(a)}</Td>
                        <Td>
                          {moment(a.timestamp).format('D.M.YY [kl.] HH:mm:ss')}
                        </Td>
                        {action &&
                          action.settings &&
                          action.settings.handleable && (
                            <>
                              <Th>{getHandler(a)}</Th>
                              <Th>
                                {a.handledTimestamp ? (
                                  moment(a.handledTimestamp).format(
                                    'D.M.YY [kl.] HH:mm:ss'
                                  )
                                ) : (
                                  <Text variant="subheading">N/A</Text>
                                )}
                              </Th>
                              <Th>{a.comment}</Th>
                            </>
                          )}
                        {(mutateEnpoint || (a.handleable && !a.handled)) && (
                          <Th>
                            {a.handleable && !a.handled && (
                              <span>
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();

                                    spawnModal(
                                      <HandleModal
                                        endpoint={
                                          mutateEnpoint +
                                          '/' +
                                          a._id +
                                          '/handle'
                                        }
                                        close={() =>
                                          despawnModal('handleAction')
                                        }
                                        onSuccess={() =>
                                          search({
                                            ...queryOpts,
                                            paginate: false
                                          })
                                        }
                                      />,
                                      'handleAction'
                                    );
                                  }}
                                >
                                  Behandle
                                </a>
                                {mutateEnpoint && ' · '}
                              </span>
                            )}
                            {mutateEnpoint && (
                              <span>
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();

                                    spawnModal(
                                      <PushActionModal
                                        app={app}
                                        appRef={action}
                                        close={() =>
                                          despawnModal('mutateAction')
                                        }
                                        mutateEndpoint={
                                          mutateEnpoint + '/' + a._id
                                        }
                                        onSuccess={() =>
                                          search({
                                            ...queryOpts,
                                            paginate: false
                                          })
                                        }
                                      />,
                                      'mutateAction'
                                    );
                                  }}
                                >
                                  Rediger
                                </a>
                              </span>
                            )}
                          </Th>
                        )}
                      </Tr>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableScrollWrapper>
          </InfiniteScroller>
        </BusyBoy>
      </Card>
    </>
  );
}

interface PushActionModalProps {
  close: () => any;
  app?: OrganizationApp;
  appRef?: AppAction;
  onSuccess?: () => any;
  endpoint?: string;
  mutateEndpoint?: string;
}

export function PushActionModal(props: PushActionModalProps) {
  const { endpoint, close, app, appRef, onSuccess, mutateEndpoint } = props;

  const { data, setField, submitting, submit } = useForm<Action>(
    {
      category:
        appRef && appRef.category.defaultValue
          ? appRef.category.defaultValue
          : null,
      action:
        appRef && appRef.action.defaultValue
          ? appRef.action.defaultValue
          : null,
      label:
        appRef && appRef.label.defaultValue ? appRef.label.defaultValue : null,
      value:
        appRef && appRef.value.defaultValue ? appRef.value.defaultValue : 1,
      app: app ? app.app : undefined,
      appRef: appRef ? appRef._id : undefined
    },
    {
      endpoint: mutateEndpoint ? mutateEndpoint : endpoint || '',
      prefillEndpoint: mutateEndpoint ? mutateEndpoint : undefined,
      method: mutateEndpoint ? 'PATCH' : 'POST',
      onSuccess: () => {
        close();

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  );

  const [_, deleting, destroy] = useApi<null>({
    endpoint: mutateEndpoint || '',
    initialData: null,
    onSuccess: () => {
      close();

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  });

  interface RenderFieldOpts {
    field: 'category' | 'action' | 'label' | 'value';
    label: string;
    fieldType?: 'text' | 'number' | 'textArea';
    required?: boolean;
    picks?: string[];
  }

  function renderField(opts: RenderFieldOpts) {
    function renderInput() {
      if (opts.picks && opts.picks.length > 0) {
        return (
          <Select
            id={opts.field}
            defaultValue="none"
            value={data[opts.field as 'category']}
            onChange={e => setField(opts.field, e.target.value)}
            required={opts.required}
          >
            <option value="none" disabled>
              Vennligst velg…
            </option>
            {opts.picks.map(p => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </Select>
        );
      }

      return (
        <Input
          id={opts.field}
          type={opts.fieldType === 'number' ? 'number' : 'text'}
          value={data[opts.field as 'category']}
          onChange={e => setField(opts.field, e.target.value)}
          required={opts.required}
        />
      );
    }

    return (
      <>
        <Label htmlFor={opts.field}>
          {opts.label}
          {opts.required ? ' *' : ''}
        </Label>
        {renderInput()}
      </>
    );
  }

  function renderFields() {
    if (appRef) {
      return (
        <>
          {!appRef.category.hide &&
            renderField({
              field: 'category',
              label: appRef.category.label || 'Kategori',
              required: true,
              picks: appRef.category.pickValues
            })}
          {!appRef.action.hide &&
            renderField({
              field: 'action',
              label: appRef.action.label || 'Handling',
              required: true,
              picks: appRef.action.pickValues
            })}
          {!appRef.label.hide &&
            renderField({
              field: 'label',
              label: appRef.label.label || 'Etikett',
              picks: appRef.label.pickValues
            })}
          {!appRef.value.hide &&
            renderField({
              field: 'value',
              label: appRef.value.label || 'Verdi',
              picks: appRef.value.pickValues,
              fieldType: 'number'
            })}
        </>
      );
    }

    return (
      <>
        {renderField({ field: 'category', label: 'Kategori', required: true })}
        {renderField({ field: 'action', label: 'Handling', required: true })}
        {renderField({ field: 'label', label: 'Etikett' })}
        {renderField({ field: 'value', label: 'Verdi', fieldType: 'number' })}
      </>
    );
  }

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy exposeChildren busy={submitting || deleting}>
        <Form onSubmit={submit}>
          {renderFields()}
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close();
              }}
            >
              Avbryt
            </ButtonExternalLink>
            {mutateEndpoint && (
              <ButtonExternalLink
                variant="warning"
                onClick={e => {
                  e.preventDefault();

                  destroy({
                    endpoint: mutateEndpoint,
                    method: 'DELETE',
                    askBeforeFetch: 'Er du sikker på at du vil slette?'
                  });
                }}
              >
                Slett
              </ButtonExternalLink>
            )}
            <Button type="submit" variant="primary">
              Lagre
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

interface HandleModalProps {
  endpoint: string;
  close: () => any;
  onSuccess?: () => any;
}

export function HandleModal(props: HandleModalProps) {
  const { endpoint, close, onSuccess } = props;

  const { data, setField, submitting, submit } = useForm<{ comment?: string }>(
    {
      comment: undefined
    },
    {
      endpoint: endpoint,
      method: 'POST',
      onSuccess: () => {
        close();

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  );

  return (
    <Container minWidth="38rem" spacious>
      <BusyBoy exposeChildren busy={submitting}>
        <Form onSubmit={submit}>
          <Label htmlFor="comment">Eventuell kommentar</Label>
          <TextArea
            id="comment"
            value={data.comment}
            onChange={e => setField('comment', e.target.value)}
          />
          <ButtonList align="right">
            <ButtonExternalLink
              href="#"
              onClick={e => {
                e.preventDefault();
                close();
              }}
            >
              Avbryt
            </ButtonExternalLink>
            <Button type="submit" variant="primary">
              Behandle
            </Button>
          </ButtonList>
        </Form>
      </BusyBoy>
    </Container>
  );
}

export default Actions;
