import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ShowAction from '../views/Apps/ShowAction';

export default function variablesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/organisasjoner/:organizationID/apper/:appSlug/:appRef"
        component={ShowAction}
      />
      <Route
        exact
        path="/organisasjoner/:organizationID/apper/:appSlug/:appRef/:type"
        component={ShowAction}
      />
    </Switch>
  );
}
