import React from 'react';
import styled from '../../util/styled';

const BeeContainerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: -${props => props.theme.layout.spacing.normal};
`;

function BeeContainer() {
  return <BeeContainerWrapper id="beeContainer" />;
}

export default BeeContainer;
