import styled, { css } from '../../util/styled';

interface Props {
  maxHeight?: string;
}

const Scroller = styled.div<Props>`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `};
`;

export default Scroller;
