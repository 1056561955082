import {
  Organization,
  DataPoint,
  DataPointCategory,
  OrganizationApp
} from '../types/apiResponses';

interface CategoryWithDataPoints extends DataPointCategory {
  dataPoints: DataPoint[];
}

function categorizeDataPoints(
  organization: Organization,
  apps: OrganizationApp[],
  removeAppDataPoints?: boolean
): CategoryWithDataPoints[] {
  const uncategorized: CategoryWithDataPoints = {
    _id: 'uncategorized',
    label: 'Ukategorisert',
    dataPoints: organization.dataPoints.filter(
      d => !d.app && d.categories.length < 1
    )
  };

  const appCategories: CategoryWithDataPoints[] = removeAppDataPoints
    ? []
    : apps
        .filter(a => a.appData!.dataPoints.length > 0)
        .map(a => ({
          _id: a.app,
          label: a.appData!.name,
          dataPoints: organization.dataPoints.filter(
            d => d.app && d.app === a.app
          )
        }));

  return [
    uncategorized,
    ...organization.dataPointCategories.map(c => ({
      ...c,
      dataPoints: organization.dataPoints.filter(d =>
        d.categories.some(cat => cat === c._id)
      )
    })),
    ...appCategories
  ];
}

export default categorizeDataPoints;
