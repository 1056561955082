import React, { FunctionComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from '../../util/styled';
import Card, {
  CardContent,
  CardTitle,
  CardIcon,
  CardExternalLink
} from '../Card';
import Text from '../Text';
import Hr from '../Hr';
import { TableScrollWrapper } from '../Table';
import Icon from '../Icon';

interface Column {
  _id: string;
  items: Listable[];
}

interface Listable {
  _id: string;
  pos: number;
}

interface LanesProps {
  columnNumber: number;
}

const LanesWrapper = styled.div<LanesProps>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(${props => props.columnNumber}, 450px);
  grid-column-gap: ${props => props.theme.layout.spacing.normal};
`;

const LaneWrapper = styled.div``;

const LaneContent = styled.div`
  padding: ${props => props.theme.layout.spacing.small};
  padding-bottom: 0;
`;

const LaneItemWrapper = styled.div`
  margin-bottom: ${props => props.theme.layout.spacing.small};
`;

const LaneAction = styled.div`
  margin-top: ${props => props.theme.layout.spacing.small};
`;

interface LaneProps {
  id: string;
  onAddClick: () => any;
  hasItems?: boolean;
  dragging?: boolean;
  label?: string;
}

const Lane: FunctionComponent<LaneProps> = ({
  id,
  hasItems,
  dragging,
  label,
  onAddClick,
  children
}) => (
  <LaneWrapper>
    <Card>
      {label && (
        <>
          <CardTitle>
            <Text centered variant="headline">
              {label}
            </Text>
          </CardTitle>
          <Hr hugTop hugBottom />
        </>
      )}
      <LaneContent>
        <Droppable droppableId={id}>
          {(p, s) => (
            <div
              ref={p.innerRef}
              style={{
                minHeight: !hasItems && !s.isDraggingOver ? '114px' : 0
              }}
            >
              {children}
              {p.placeholder}
            </div>
          )}
        </Droppable>
      </LaneContent>
    </Card>
    <LaneAction>
      <CardExternalLink
        href="#"
        onClick={e => {
          e.preventDefault();
          onAddClick();
        }}
        clickable
        secondary
        horizontal
      >
        <CardContent tight>
          <Text>Legg til</Text>
        </CardContent>
        <CardIcon tight>
          <Icon>add</Icon>
        </CardIcon>
      </CardExternalLink>
    </LaneAction>
  </LaneWrapper>
);

interface LanesComponentProps extends LanesProps {
  columns: Column[];
  onSuccessfulDrop: (val: {
    droppableID: string;
    draggableID: string;
    pos: number | null;
    columns: Column[];
  }) => any;
  onDragStart: () => any;
}

const Lanes: FunctionComponent<LanesComponentProps> = ({
  columnNumber,
  columns,
  onSuccessfulDrop,
  onDragStart,
  children
}) => (
  <TableScrollWrapper>
    <DragDropContext
      onDragStart={() => onDragStart()}
      onDragEnd={e => {
        if (e.destination) {
          const column = columns.find(
            c => c._id === e.destination!.droppableId
          );

          if (column) {
            const { pos, updatedColumns } = calculatePos(
              e.destination.index,
              e.source.index,
              e.draggableId,
              e.source.droppableId,
              e.destination.droppableId,
              columns
            );

            onSuccessfulDrop({
              droppableID: e.destination.droppableId,
              draggableID: e.draggableId,
              pos,
              columns: updatedColumns
            });
          }
        }
      }}
    >
      <LanesWrapper columnNumber={columnNumber}>{children}</LanesWrapper>
    </DragDropContext>
  </TableScrollWrapper>
);

interface LaneItemProps {
  id: string;
  index: number;
}

const LaneItem: FunctionComponent<LaneItemProps> = ({
  id,
  index,
  children
}) => (
  <Draggable draggableId={id} index={index}>
    {p => (
      <LaneItemWrapper
        ref={p.innerRef}
        {...p.draggableProps}
        {...p.dragHandleProps}
      >
        <Card clickable secondary>
          <CardContent tight>{children}</CardContent>
        </Card>
      </LaneItemWrapper>
    )}
  </Draggable>
);

function calculatePos(
  targetIndex: number,
  fromIndex: number,
  fromID: string,
  fromContainerID: string,
  toContainerID: string,
  columns: Column[]
): { pos: number; updatedColumns: Column[] } {
  let min: number = 0;
  let max: number | null = 0;
  let newPos: number | null = 0;

  const fromColumn = columns.find(c => c._id === fromContainerID);
  const toColumn =
    fromContainerID === toContainerID
      ? fromColumn
      : columns.find(c => c._id === toContainerID);

  if (!fromColumn || !toColumn) {
    throw new Error('columnsNotFound');
  }

  const fromList = fromColumn.items;
  const toList = toColumn.items;
  const [removed] = fromList.splice(fromIndex, 1);

  toList.splice(targetIndex, 0, removed);

  const newIndex = toList.findIndex(l => l._id === fromID);

  if (newIndex === 0) {
    min = 0;
  } else {
    min = toList[newIndex - 1].pos;
  }

  if (newIndex === toList.length - 1) {
    max = null;
  } else {
    max = toList[newIndex + 1].pos;
  }

  if (max === null) {
    newPos = min + 65536;
  } else {
    newPos = (min + max) / 2;
  }

  toList[newIndex].pos = newPos;

  return {
    pos: newPos,
    updatedColumns: columns.map(c => {
      if (c._id === fromContainerID) {
        c.items = fromList;
      } else if (c._id === toContainerID) {
        c.items = toList;
      }

      return c;
    })
  };
}

export default Lanes;
export { Lane, LaneItem };
