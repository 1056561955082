import React, { FunctionComponent, createContext, useState } from 'react';
import { Contact } from '../types/apiResponses';

const ContactContext = createContext({
  data: null as Contact | null,
  setData: (data: Contact) => {}
});

const ContactProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState(null as Contact | null);

  return (
    <ContactContext.Provider
      value={{
        data: state,
        setData: setState
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export { ContactContext, ContactProvider };
