import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import Card from '../../components/Card';
import Content from '../../components/Content';
import { AppsContext } from '../../context/Apps';
import { ActionsTable } from '../Contacts/Actions';
import Heading from '../../components/Heading';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Menu, { MenuItem } from '../../components/Menu';
import history from '../../util/history';

interface ShowActionParams {
  organizationID: string;
  appSlug: string;
  appRef: string;
  type?: string;
}

function ShowAction(props: RouteComponentProps<ShowActionParams>) {
  const organizationID = props.match.params.organizationID;
  const appSlug = props.match.params.appSlug;
  const appRef = props.match.params.appRef;

  let type = props.match.params.type;

  const apps = useContext(AppsContext).data;

  const app = apps.find(a => a.appData!.slug === appSlug);

  if (!app) {
    return null;
  }

  const action = app.appData!.actions.find(a => a._id === appRef);

  if (!action) {
    return null;
  }

  const url =
    '/organisasjoner/' +
    organizationID +
    '/apper/' +
    app.appData!.slug +
    '/' +
    action._id;

  useEffect(() => {
    if (!props.match.params.type) {
      history.replace(
        url +
          '/' +
          (action.settings && action.settings.handleable
            ? 'ubehandlet'
            : 'alle')
      );
    }
  }, [action._id, props.match.params.type]);

  switch (type) {
    case 'behandlet':
      type = 'handled';
      break;
    case 'ubehandlet':
      type = 'unhandled';
      break;
    default:
      type = 'all';
      break;
  }

  return (
    <>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          {action.namePlural}
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          <Menu>
            {action.settings && action.settings.handleable && (
              <>
                <MenuItem to={url + '/ubehandlet'}>Ubehandlet</MenuItem>
                <MenuItem to={url + '/behandlet'}>Behandlet</MenuItem>
              </>
            )}
            <MenuItem to={url + '/alle'}>Alle</MenuItem>
          </Menu>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <Card>
              <ActionsTable
                key={action._id + type}
                organizationID={organizationID}
                endpoint={'organizations/' + organizationID + '/actions/search'}
                app={app}
                action={action}
                handleStatus={type as 'all'}
                showOwner
                mutateEnpoint={
                  'organizations/' + organizationID + '/contacts/actions'
                }
              />
            </Card>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default ShowAction;
