import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Organization as IOrganization } from '../../types/apiResponses';
import localize from '../../util/localize';
import { dataPointTypes } from '../../constants/localization';
import useApi from '../../hooks/useApi';
import BusyBoy from '../../helpers/BusyBoy';
import { Container } from '../../helpers/Layout';
import Text from '../../components/Text';
import { CardLink, CardContent, CardIcon } from '../../components/Card';
import Icon from '../../components/Icon';
import Sidebar, { SidebarWrapper } from '../../components/Sidebar';
import Content from '../../components/Content';
import Heading from '../../components/Heading';
import { renderDataPointSidebarContent } from '../../routing/DataPoints';
import categorizeDataPoints from '../../util/categorizeDataPoints';
import Menu, { MenuItem, MenuSplitter } from '../../components/Menu';
import EmptyState from '../../components/EmptyState';
import ShowOff from '../../components/ShowOff';
import Doorman from '../../components/Doorman';
import Helper from '../../components/Helper';

interface ListParams {
  organizationID: string;
}

function List(props: RouteComponentProps<ListParams>) {
  const organizationID = props.match.params.organizationID;
  const [organization, loading] = useApi<IOrganization | null>({
    endpoint: 'organizations/' + organizationID,
    initialData: null,
    fetchOnMount: true
  });

  const baseUrl = '/organisasjoner/' + organizationID;

  return (
    <>
      <Helper id="dataPoints" title="Hva er karakteristikker?">
        <Text>
          I <em>Profiler</em> er kontaktene, eller <em>profilene</em>, som vi
          kaller det, din mest verdifulle data. Men, profiler er kun verdifulle
          dersom det er konfigurert et sett med karakteristikker som beskriver
          dem. <strong>Fødselsdag</strong>, <strong>Favorittfarge</strong> og{' '}
          <strong>Alder</strong> er alle eksempler på karakteristikker, men
          hvilke karakteristikker du ønsker skal definere profilene dine er helt
          opp til deg.
        </Text>
        <Text variant="headline" topGutter bottomGutter>
          Tips: Mange karakteristikker å holde styr på?
        </Text>
        <Text>Samle karakteristikkene dine i kategorier for enklere bruk.</Text>
      </Helper>
      <Heading sidebar>
        <Text element="h1" variant="display3">
          Karakteristikker
        </Text>
      </Heading>
      <SidebarWrapper>
        <Sidebar>
          {renderDataPointSidebarContent(baseUrl)}
          <Doorman>
            <>
              <MenuSplitter />
              <Menu>
                {!!organization && organization.dataPoints.length > 0 && (
                  <MenuItem
                    to="karakteristikker/endre-rekkefolge"
                    primary
                    icon={<Icon>reorder</Icon>}
                  >
                    Endre rekkefølge
                  </MenuItem>
                )}
                <MenuItem
                  to="karakteristikker/opprett"
                  icon={<Icon>add</Icon>}
                  primary
                >
                  Ny karakteristikk
                </MenuItem>
                {organization && organization.dataPoints.length < 1 && (
                  <ShowOff>
                    Legg til din første karakteristikk ved å trykke på knappen
                    over!
                  </ShowOff>
                )}
              </Menu>
            </>
          </Doorman>
        </Sidebar>
        <Content sidebar>
          <Container spacious>
            <BusyBoy busy={loading}>
              {organization && organization.dataPoints.length < 1 && (
                <EmptyState>Ingen karakteristikker!</EmptyState>
              )}
              {organization && organization.dataPoints.length > 0 && (
                <Grid container spacing={24} direction="column">
                  {categorizeDataPoints(organization, []).map(c =>
                    c.dataPoints.length > 0 ? (
                      <Grid key={c._id} item>
                        <Text variant="headline" bottomGutter>
                          {c.label}
                        </Text>
                        {c.dataPoints.length > 0 && (
                          <Grid container spacing={24}>
                            {c.dataPoints.map(d => (
                              <Grid key={d._id} item xs={12} md={4}>
                                <CardLink
                                  to={'karakteristikker/' + d._id}
                                  horizontal
                                  clickable
                                >
                                  <CardContent tight>
                                    <Text>{d.label}</Text>
                                    <Text variant="subheading" capitalize>
                                      {localize(dataPointTypes, d.type)}
                                    </Text>
                                  </CardContent>
                                  <CardIcon tight>
                                    <Icon>edit</Icon>
                                  </CardIcon>
                                </CardLink>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    ) : null
                  )}
                </Grid>
              )}
            </BusyBoy>
          </Container>
        </Content>
      </SidebarWrapper>
    </>
  );
}

export default List;
