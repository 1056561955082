import storage from '../util/storage';
import getTheme, { Theme } from '../util/getTheme';

const color = storage.get('color');
const theme = getTheme();

export interface ThemeInterface {
  colors: {
    primary: string;
    text: string;
    textSecondary: string;
    background: string;
    contentBackground: string;
    border: string;
    borderFull: string;
    warning: string;
    neutral: string;
    neutralDarker: string;
    good: string;
    goodDarker: string;
  };
  shadows: {
    normal: string;
    large: string;
    mega: string;
    rightNormal: string;
    bottomNormal: string;
    bottomLarge: string;
  };
  animation: {
    timing: {
      normal: string;
    };
    easing: {
      inOut: string;
    };
  };
  layout: {
    headerHeight: string;
    sidebarWidth: string;
    sidebarWidthMobile: string;
    spacing: {
      small: string;
      normal: string;
      large: string;
    };
    rounding: string;
  };
  fonts: {
    primary: string;
    display: string;
  };
}

const light = {
  colors: {
    primary: color || '#05af8a',
    text: '#555',
    textSecondary: '#999',
    background: '#f4f6f9',
    contentBackground: 'white',
    border: 'rgba(0, 0, 0, 0.08)',
    borderFull: '#ddd',
    warning: '#f44242',
    neutral: '#f4cf27',
    neutralDarker: '#cc800e',
    good: '#31f762',
    goodDarker: '#49b20c'
  },
  shadows: {
    normal: '0 2px 2px rgba(0, 0, 0, .06)',
    large: '0 4px 12px rgba(0, 0, 0, .06)',
    mega: '2px 4px 44px 0 rgba(0,0,0,.19)',
    rightNormal: '4px 0 12px -4px rgba(0, 0, 0, .06)',
    bottomNormal: '0 4px 8px -4px rgba(0, 0, 0, .08)',
    bottomLarge: '0 8px 16px -8px rgba(0, 0, 0, .12)'
  },
  animation: {
    timing: {
      normal: '0.2s'
    },
    easing: {
      inOut: 'ease-in-out'
    }
  },
  layout: {
    headerHeight: '5rem',
    sidebarWidth: '14rem',
    sidebarWidthMobile: '48px',
    spacing: {
      small: '16px',
      normal: '24px',
      large: '40px'
    },
    rounding: '2px'
  },
  fonts: {
    primary: "'Noto Sans SC', sans-serif",
    display: "'Lora', serif"
  }
} as ThemeInterface;

const dark = {
  colors: {
    primary: color || '#05af8a',
    text: 'white',
    textSecondary: 'rgba(255, 255, 255, .5)',
    background: '#1F1F29',
    contentBackground: '#252531',
    border: 'rgba(255, 255, 255, 0.08)',
    borderFull: '#393948',
    warning: '#f44242',
    neutral: '#f4cf27',
    neutralDarker: '#cc800e',
    good: '#31f762',
    goodDarker: '#49b20c'
  },
  shadows: {
    normal: '0 2px 2px rgba(0, 0, 0, .12)',
    large: '0 4px 12px rgba(0, 0, 0, .12)',
    mega: '2px 4px 44px 0 rgba(0,0,0,.32)',
    rightNormal: '4px 0 12px -4px rgba(0, 0, 0, .12)',
    bottomNormal: '0 4px 8px -4px rgba(0, 0, 0, .12)',
    bottomLarge: '0 8px 16px -8px rgba(0, 0, 0, .24)'
  },
  animation: {
    timing: {
      normal: '0.2s'
    },
    easing: {
      inOut: 'ease-in-out'
    }
  },
  layout: {
    headerHeight: '5rem',
    sidebarWidth: '14rem',
    sidebarWidthMobile: '48px',
    spacing: {
      small: '16px',
      normal: '24px',
      large: '40px'
    },
    rounding: '2px'
  },
  fonts: {
    primary: "'Noto Sans SC', sans-serif",
    display: "'Lora', serif"
  }
} as ThemeInterface;

export const mobileThreshold = 600;

export default (theme === Theme.Light ? light : dark);
