import styled, { css } from '../../util/styled';

interface Props {
  text?: boolean;
}

const Notifier = styled.div<Props>`
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: -0.15rem;
  right: -0.15rem;
  color: white;
  background-color: ${props => props.theme.colors.warning};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;

  ${props =>
    props.text &&
    css`
      position: static;
      width: 1.05rem;
      height: 1.1rem;
      font-size: 0.85em;
      display: inline-flex;
      margin-left: 5px;
      background: transparent;
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    `};
`;

export default Notifier;
